import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Card, Flex, Typography } from "antd";

import style from "./style.module.scss";
import { useSelector } from "react-redux";
import { IUsers } from "../../types/users";
import { FormProfile } from "./FormProfile";
import CustomDrawer from "../CustomDrawer/CustomDrawer";
import { CREATE, EDIT, VIEW } from "../../helpers/string-helpers";
import { AvatarProfile } from "./components/AvatarProfile/AvatarProfile";
import { ViewProfile } from "./ViewProfile";
import { TooltipPlacement } from "antd/es/tooltip";
import classnames from "classnames";
import socket from "../../socket";
import { Utils } from "../../utils";
import { useStoreDispatch, RootState } from "../../store";
import { getUserPermissions, updateUserActive } from "../../store/users";
import { setUserPermissions } from "../../store/users";

interface IProfile {
  user?: IUsers;
  isDrawer: boolean;
  placement?: TooltipPlacement;
  className?: string;
  isActive?: boolean;
  isShowActive?: boolean;
}

export const Profile: React.FC<IProfile> = ({
  isDrawer,
  user,
  placement,
  className = "",
  isShowActive = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  // const [currentUser, setCurrentUser] = useState(Utils.getUserInfo());
  // const [roleId, setRoleId] = useState<number | null>(null);
  // const [roleGroup, setRoleGroup] = useState<string>("");
  // const [roleValue, setRoleValue] = useState<string>("");

  const [typeForm, setTypeForm] = useState<string>(CREATE);
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { fio, description } = user || {
    avatar: null,
    fio: "",
    description: "",
  };
  // const permissionsData: any = useSelector(
  //   (state: RootState) => state.users.permissionsData
  // );

  const onOpen = () => {
    setTypeForm(VIEW);
    setIsOpen(true);
  };
  const onClose = () => {
    setTypeForm(CREATE);
    setIsOpen(false);
  };

  const onEdit = () => setTypeForm(EDIT);
  const onView = () => setTypeForm(VIEW);

  useEffect(() => {
    socket.on('return-users-active', (item) => {

      dispatch(updateUserActive(item));
      if (user?.id === item?.id) {
        setIsActive(!!item?.active);
      }
  });
    return () => {
      socket.off("return-users-active");
    };
  }, [user?.id]);

  // useEffect(() => {
  //   const fetchPermissions = () => {
  //     dispatch(getUserPermissions({ userId: currentUser, entityId: [1] }))
  //       .unwrap()
  //       .then((data) => {
  //         setUserPermissions(data);

  //         // и отдельно сохраняем role_id в стейт
  //         if (data.role_id) {
  //           setRoleId(data.role_id);
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   };

  //   fetchPermissions();

  //   socket.on("res-update-group-entities", (data) => {
  //     dispatch(setUserPermissions(data));
  //   });
  //   socket.on("res-update-entities", (data) => {
  //     dispatch(setUserPermissions(data));
  //   });

  //   return () => {
  //     socket.off("res-update-group-entities");
  //     socket.off("res-update-entities");
  //   };
  // }, [dispatch, currentUser, permissionsData, roleGroup, roleValue]);
  // useEffect(() => {
  //   const fetchPermissions = () => {
  //     dispatch(getUserPermissions({ userId: currentUser, entityId: [0] }))
  //       .unwrap()
  //       .then((data) => {
  //         setUserPermissions(data);
  //         console.log("data", data);
  //       })
  //       .catch((err) => console.log(err));
  //   };

  //   fetchPermissions();

  //   socket.on("res-update-group-entities", (data) => {
  //     dispatch(setUserPermissions(data));
  //   });
  //   socket.on("res-update-entities", (data) => {
  //     dispatch(setUserPermissions(data));
  //   });

  //   return () => {
  //     socket.off("res-update-group-entities");
  //     socket.off("res-update-entities");
  //   };
  // }, [dispatch, currentUser, permissionsData]);

  const tooltipInfo = description ? (
    <Card size="small" title={fio}>
      <Typography.Paragraph
        className={style["profile__description"]}
        ellipsis={{
          rows: 1,
          tooltip: { placement: "bottomLeft", title: description },
        }}
      >
        {description || ""}
      </Typography.Paragraph>
    </Card>
  ) : (
    <div className={style["profile__card"]}>
      <Typography.Paragraph style={{ margin: 0 }}>
        {fio || ""}
      </Typography.Paragraph>
    </div>
  );

  return (
    <>
      <div
        className={classnames(style["profile"], {
          [className]: className,
        })}
        onClick={onOpen}
      >
        <AvatarProfile
          placement={placement}
          size={isDrawer ? 42 : 36}
          isHiddenInfo={isDrawer}
          avatar={user?.avatar}
          title={user?.fio}
          tooltipInfo={tooltipInfo}
          isActive={isActive}
          isShowActive={isShowActive}
        />
        <Flex
          hidden={isDrawer}
          vertical
          gap={2}
          style={{
            alignSelf: description ? "baseline" : "center",
            marginLeft: "10px",
          }}
        >
          <Typography.Paragraph
            className={style["profile__title"]}
            ellipsis={{
              rows: 1,
              tooltip: { placement: "bottomRight", title: t(fio || "Гость") },
            }}
          >
            {t(fio || "Гость")}
          </Typography.Paragraph>
          {!!description && (
            <Typography.Paragraph
              className={style["profile__description"]}
              ellipsis={{
                rows: 1,
                tooltip: { placement: "bottomLeft", title: description },
              }}
            >
              {description}
            </Typography.Paragraph>
          )}
        </Flex>
      </div>
      <CustomDrawer
        isHeight
        minWidth={1000}
        open={isOpen}
        onClose={onClose}
        placement="left"
      >
        {typeForm === VIEW ? (
          <ViewProfile
            isOpen={isOpen}
            typeForm={typeForm}
            initialValue={user}
            // initialValue={
            //   {
            //     ...user,
            //     role_group: roleGroup,
            //     role: roleValue,
            //   } as IUsers
            // }
            title={fio}
            onClose={onClose}
            isCopy
            // isEdit
            // {...(roleId === 1 && { isEdit: true })}
            onEdit={onEdit}
            isActive={isActive}
            isShowActive
          />
        ) : (
          <FormProfile
            isOpen={isOpen}
            typeForm={typeForm}
            initialValue={user}
            title={fio}
            onClose={onClose}
            isEdit
            // {...(roleId === 1 && { isEdit: true })}
            onView={onView}
            isActive={isActive}
            isShowActive
          />
        )}
      </CustomDrawer>
    </>
  );
};
