import { Flex, Typography } from 'antd';
import { IColumnType } from '../../components/ui/Table/Table';
import { isValidHttpUrl } from '../../helpers/link-helper';
import { IColumnsImport } from './service';

export const columns: IColumnType<any>[] = [
  {
    title: '',
    dataIndex: 'id',
    filterIndex: 'id',
    checkbox: true,
    menu: true,
    type: 'string',
    key: '0',
  },
  {
    title: 'Номер контейнера',
    dataIndex: 'num_container',
    filterIndex: 'num_container',
    link: true,
    width: '100%',
    type: 'string',
    key: '1',
  },
  {
    title: 'Тип',
    dataIndex: 'type_container',
    filterIndex: 'type_container',
    width: '20%',
    type: 'dropdown',
    key: '2',
  },
  {
    title: 'Терминал',
    dataIndex: 'terminal',
    filterIndex: 'terminal.name',
    type: 'string',
    width: '100px',
    key: '3',
    render: (columns, item) => {
      const terminal = item?.terminal?.name ?? '';
      return terminal ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: { placement: 'top', title: terminal },
          }}
          style={{ maxWidth: '150px', margin: 0 }}
        >
          {terminal}
        </Typography.Paragraph>
      ) : null;
    },
  },
  {
    title: 'Родитель',
    dataIndex: 'location',
    filterIndex: 'location.city',
    type: 'string',
    width: '100px',
    key: '4',
    render: (columns, item) => {
      const location = item?.location?.city ?? '';
      return location ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: { placement: 'top', title: location },
          }}
          style={{ maxWidth: '150px', margin: 0 }}
        >
          {location}
        </Typography.Paragraph>
      ) : null;
    },
  },
  {
    title: 'Сток',
    dataIndex: 'terminalStock',
    filterIndex: 'terminalStock.label',
    type: 'string',
    width: '30%',
    key: '5',
    render: (columns, item) => {
      const stock = item?.terminalStock?.label ?? '';
      return stock ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: { placement: 'top', title: stock },
          }}
          style={{ maxWidth: '150px', margin: 0 }}
        >
          {stock}
        </Typography.Paragraph>
      ) : null;
    },
  },
  {
    title: 'Фото',
    dataIndex: 'photo_bool',
    filterIndex: '',
    width: '20%',
    type: '',
    key: '6',
    render: (columns, item) =>
      item?.photo_bool && isValidHttpUrl(item?.photo_bool) ? (
        <Typography.Link href={item?.photo_bool} target="_blank">
          Да
        </Typography.Link>
      ) : (
        <Typography.Text>
          {item?.ids_photo?.length ? 'Да' : 'Нет'}
        </Typography.Text>
      ),
  },
  {
    title: 'В ремонте',
    dataIndex: 'repair',
    filterIndex: 'repair',
    type: 'boolean',
    width: '30%',
    key: '7',
  },
  {
    title: 'Клиент',
    dataIndex: 'contractors',
    filterIndex: 'contractors.name_company',
    type: 'string',
    width: '100px',
    key: '8',
    render: (columns, item) => {
      const company =
        item?.contractors?.name_company ||
        item?.['contractors.name_company'] ||
        '';
      return company ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: { placement: 'top', title: company },
          }}
          style={{ maxWidth: '150px', margin: 0 }}
        >
          {company}
        </Typography.Paragraph>
      ) : null;
    },
  },
  {
    title: 'Состояние',
    dataIndex: 'quality',
    filterIndex: 'quality',
    type: 'string',
    width: '20%',
    key: '9',
  },
  {
    title: 'Входящая ставка',
    dataIndex: 'currency_price',
    filterIndex: 'currency_price',
    type: 'string',
    width: '20%',
    key: '10',
    render: (col, item) => {
      const price = item?.currency_price;
      const currency = item?.currency || '';
      return price ? (
        <Flex gap={5}>
          <div>{price}</div>
          <div>{currency}</div>
        </Flex>
      ) : null;
    },
  },
  {
    title: 'Текущий статус',
    dataIndex: 'status',
    filterIndex: 'status.name',
    type: 'string',
    width: '20%',
    key: '11',
    render: (columns, item) => {
      const status = item?.status?.name ?? '';
      return status ? <div>{status}</div> : null;
    },
  },
  {
    title: 'Бронь До',
    dataIndex: 'booking_before',
    filterIndex: 'booking_before',
    type: 'date',
    width: '20%',
    key: '12',
  },
  {
    title: 'Ответственный',
    dataIndex: 'responsible',
    filterIndex: 'responsible.fio',
    type: 'string',
    width: '20%',
    key: '13',
    render: (columns, item) =>
      item?.responsible?.fio ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: { placement: 'top', title: item?.responsible?.fio },
          }}
          style={{ maxWidth: '250px', margin: 0 }}
        >
          {item?.responsible?.fio}
        </Typography.Paragraph>
      ) : null,
  },
  {
    title: 'Комментарии',
    dataIndex: 'comment',
    filterIndex: 'comment',
    type: 'string',
    width: '20%',
    key: '14',
    render: (text: any, item) =>
      item?.comment ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: { placement: 'top', title: item.comment },
          }}
          style={{ maxWidth: '250px', margin: 0 }}
        >
          {item.comment}
        </Typography.Paragraph>
      ) : null,
  },
  {
    title: 'Дата прибытия',
    dataIndex: 'arrival_date',
    filterIndex: 'arrival_date',
    type: 'date',
    width: '20%',
    key: '15',
  },
  {
    title: 'Дата убытия',
    dataIndex: 'departure_date',
    filterIndex: 'departure_date',
    type: 'date',
    width: '30%',
    key: '16',
  },
  {
    title: '',
    dataIndex: 'hidden',
    filterIndex: 'hidden',
    width: '20%',
    key: '17',
  },
];

// Для импорта
export const columnsImport: IColumnsImport[] = [
  {
    key: '0',
    title: 'Номер контейнера',
    dataIndex: 'num_container',
    type: 'num_container',
  },
  {
    key: '1',
    title: 'Родитель',
    dataIndex: 'location',
    type: 'parent',
  },
  {
    key: '2',
    title: 'Терминал',
    dataIndex: 'terminal',
    type: 'terminal',
  },
  {
    key: '3',
    title: 'Сток',
    dataIndex: 'stock',
    type: 'stock',
  },
  {
    key: '4',
    title: 'Тип контейнера',
    dataIndex: 'type_container',
    type: 'type_container',
  },
  {
    key: '5',
    title: 'Фото',
    dataIndex: 'photo_bool',
    type: 'string',
  },
  {
    key: '6',
    title: 'Собственник',
    dataIndex: 'owner',
    type: 'company',
  },
  {
    key: '7',
    title: 'Состояние',
    dataIndex: 'quality',
    type: 'quality',
  },
  {
    key: '8',
    title: 'Входящая ставка',
    dataIndex: 'currency_price',
    type: 'string',
  },
  {
    key: '9',
    title: 'Валюта',
    dataIndex: 'currency',
    type: 'currency',
  },
  {
    key: '10',
    title: 'Бронь До',
    dataIndex: 'booking_before',
    type: 'date',
  },
  {
    key: '11',
    title: 'Дата входа',
    dataIndex: 'date_entry',
    type: 'date',
  },
  {
    key: '12',
    title: 'Комментарий',
    dataIndex: 'comment',
    type: 'string',
  },
  {
    key: '13',
    title: 'Дата прибытия',
    dataIndex: 'arrival_date',
    type: 'date',
  },
  {
    key: '14',
    title: 'Дата убытия',
    dataIndex: 'departure_date',
    type: 'date',
  },
];
