import React, { useMemo, useRef } from "react";

import { Button, Flex, Typography } from "antd";
import { useTranslation } from "react-i18next";

import style from "../style.module.scss";
import Responsible from "./list/Responsible";
import ITabs from "../../../../components/ui/Tabs";
import { TabDeal, TabRepair, TabTable } from "./tabs/view";
import { ITab } from "../../../../components/ui/Tabs/Tabs";
import { IFormDeal } from "../../../../store/sales";
import ScrollList from "../../../../components/ui/ScrollList";
import { TAB_DEAL, TAB_DOCUMENT, TAB_REPAIR } from "../constants";
import CopyButton from "../../../../components/ui/CopyButton/CopyButton";
import { CopyBufferIconDefault } from "../../../../assets/image/svg";
import { SALES_DEAL } from "../../../../constants";
import { useLink } from "../../../../hooks";
import IconButton from "../../../../components/ui/IconButton";
import Icon from "@ant-design/icons";
import { useStoreDispatch } from "../../../../store";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import {
  hasViewPermission,
  hasEditPermission,
  hasDeletePermission,
} from "../../../../components/Menu/permissions";

const ViewDeal: React.FC<IFormDeal> = ({
  onClose,
  onEdit,
  isCopy,
  isOpen,
  initialValue: deal,
  canEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const refResponsible = useRef<HTMLDivElement>(null);
  const link = useLink({ key: SALES_DEAL, id: deal?.id as number });
  //==============================================
  // подключаемся к хранилищу данных с правами
  //==============================================
  const permissionsData: any = useSelector(
    (state: RootState) => state.users.permissionsData
  );
  const items: ITab[] = useMemo(
    () => [
      {
        key: TAB_DEAL,
        label: "Сделка",
        forceRender: isOpen,
        content: <TabDeal isOpen={isOpen} deal={deal} />,
      },
      {
        key: TAB_DOCUMENT,
        label: "Документы",
        forceRender: isOpen,
        content: <TabTable isOpen={isOpen} deal={deal} />,
      },
      {
        key: TAB_REPAIR,
        label: "В ремонте",
        forceRender: isOpen,
        content: <TabRepair isOpen={isOpen} deal={deal} />,
      },
    ],
    [isOpen]
  );
  return (
    <div className={style["tabs-form"]}>
      <Flex
        vertical
        style={{ width: "100%", height: "100%" }}
        justify={"space-between"}
      >
        <Flex
          justify={"space-between"}
          align={"center"}
          style={{ marginBottom: 24 }}
        >
          <h2 className={style.title}>
            {t("Сделка")} {deal?.id_deal}
          </h2>
          <Flex gap={5} align={"center"}>
            {canEdit && (
              <IconButton
                hidden={!!deal?.delete || !onEdit || !deal?.edit}
                iType="edit-form"
                onClick={onEdit}
              />
            )}
            {isCopy && (
              <CopyButton
                text={link}
                textSuccess={t("Ссылка скопирована")}
                icon={<Icon component={CopyBufferIconDefault} />}
              />
            )}
          </Flex>
        </Flex>
        <ITabs className={style["tabs-form__tab"]} tabs={items} />
      </Flex>
      <Flex gap={20} vertical>
        <Flex gap={6} vertical>
          <Typography.Title
            level={4}
            className={style["subtitle"]}
            style={{ fontWeight: 700 }}
          >
            {t("Дополнительно")}
          </Typography.Title>
          <Typography.Text style={{ marginTop: 0 }}>
            {t("Ответственный")}
          </Typography.Text>
          <div className={style["box-list"]}>
            {!!deal?.responsible?.length && (
              <ScrollList
                setLastItemReached={(v) => v}
                height={
                  deal?.responsible?.length >= 3
                    ? 200
                    : (deal?.responsible?.length || 1) * 80
                }
                totalTableActivity={1}
                data={deal?.responsible}
                tableRef={refResponsible}
                paginationActivity={() => {}}
                isTable={false}
                endMessage={null}
              >
                {deal?.responsible?.map((cont, i) => (
                  <Responsible
                    key={i}
                    title={cont.name}
                    avatar={cont.avatar}
                    description={cont?.isResponsible ? t("Ответственный") : ""}
                  />
                ))}
              </ScrollList>
            )}
          </div>
        </Flex>

        <Flex
          gap={8}
          justify="space-between"
          style={{ margin: "32px 0 10px auto", maxWidth: "500px" }}
        >
          <Button
            onClick={() => onClose(true)}
            type="primary"
            style={{ width: "174px" }}
          >
            {t("Закрыть")}
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};

export default ViewDeal;
