import socket from "../socket";


export class Rest {
    static getOwner(payload: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-owner', payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static saveCity(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('set-city', payload, (response: any) => {
                try {
                    resolve(response);
                } catch (e) {
                    reject(e);
                }
            })
        })
    }
    static getCityTab(): Promise<any>{
        return new Promise(async (resolve, reject) => {
             socket.emit('get-city-tab',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }
    static getCityInput(): Promise<any>{
        return new Promise(async (resolve, reject) => {
             socket.emit('get-city-input',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }
    static getRentKTK(): Promise<any>{
        return new Promise(async (resolve, reject) => {
             socket.emit('get-rent-ktk',(response: any) => {
                try {
                    response.forEach((el: any) =>{
                        el.value = el.name
                        el.label = el.name
                    })
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }
    static getSaleKTK(): Promise<any>{
        return new Promise(async (resolve, reject) => {
             socket.emit('get-sale-ktk',(response: any) => {
                try {
                    response.forEach((el: any) =>{
                        el.value = el.name
                        el.label = el.name
                    })
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }
    static getCustomsServices(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-customs-services',(response: any) => {
                try {
                    response.forEach((el: any) =>{
                        el.value = el.name
                        el.label = el.name
                    })
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }
    static getGeographyPresence(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-geography-presence',(response: any) => {
                try {
                    response.forEach((el: any) =>{
                        el.value = el.name
                        el.label = el.name
                    })
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }
    static findTerminalList(payload: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-terminal-list',payload,(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }
    static getCurrency(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-currency',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static getPercent(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-percent',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }
    static getPhoneMask(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-phone-mask',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }
}
