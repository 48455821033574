import { table } from 'console';
import { apiAxios } from '../api/config';

export class Rest_files {
  static submitFiles(files: any[]): Promise<any> {
    return apiAxios.post('/uploadAllFiles', files);
  }
  static submitFile(file: any): Promise<any> {
    return apiAxios.post('/uploadFiles', file);
  }
  static getFiles(files: number[]): Promise<any> {
    return apiAxios.post('/downloadFiles', files);
  }
  static deleteFiles(files: number[]): Promise<any> {
    return apiAxios.post('/deleteFiles', files);
  }
  static getImageFiles(files: number[]): Promise<any> {
    return apiAxios.post('/images', files);
  }
  static submitImageFiles(files: any): Promise<any> {
    return apiAxios.post('/upload', files);
  }
  static uploadExcel(dto: {table: string, ids: number[]}): Promise<any> {
      return apiAxios.post('/files/get-uploading-excel', dto, {responseType: 'blob'});
  }
  // сохранение файлов по уникальным именам
  static submitUniqueFiles(formData: FormData): Promise<any> {
    console.log(formData);

    return apiAxios.post('/files/multiple-files', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  // получение файлов по уникальным именам
  static getUniqueFiles(files: { filenames: string[] }): Promise<any> {
    return apiAxios.post('/files/get-files', {filenames: files});
  }
}
