import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import socket from "../../socket";

import { useTranslation } from "react-i18next";
import { Button, Flex, Typography } from "antd";

import { pathsCrm } from "./constants";
import style from "./style.module.scss";
import { useStoreDispatch } from "../../store";
import { RootState } from "../../store";
import { CREATE } from "../../helpers/string-helpers";
import { TableAgreements } from "./components/TableAgreements";
import {
  defaultAgreement,
  setIsOpenDrawer,
  setOneAgreement,
  setTypeForm,
} from "../../store/agreement";

import { getUserPermissions } from "../../store/users";
import { setUserPermissions } from "../../store/users";
import { Utils } from "../../utils";
import {
  hasViewPermission,
  hasEditPermission,
  hasDeletePermission,
} from "../../components/Menu/permissions";

interface IAgreementsCRM {
  title: string;
  activeKey: string;
}

export const AgreementsCRM: React.FC<IAgreementsCRM> = ({
  activeKey,
  title,
}) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  //==============================================
  // подключаемся к хранилищу данных с правами
  //==============================================
  const permissionsData: any = useSelector(
    (state: RootState) => state.users.permissionsData
  );
  // получаем данные только по запросу (не все)
  // через утилиту hasView/Edit/DeletePermission
  const canView = hasViewPermission(permissionsData, "agreements");
  const canEdit = hasEditPermission(permissionsData, "agreements");
  const canDelete = hasDeletePermission(permissionsData, "agreements");

  // if (!canView) {
  //   return <div>у вас нет прав! 😝</div>;
  // }

  const onCreate = () => {
    dispatch(setOneAgreement(defaultAgreement));
    dispatch(setTypeForm(CREATE));
    dispatch(setIsOpenDrawer(true));
  };

  return (
    <div className={style.container}>
      <Flex
        justify={"space-between"}
        align={"flex-start"}
        style={{ margin: "20px 28px 0px" }}
      >
        <Typography.Title style={{ textTransform: "capitalize" }} level={3}>
          {t(title)}
        </Typography.Title>
        <Flex gap={16}>
          {canEdit && (
            <Button onClick={onCreate} type={"primary"}>
              {t("Создать")}
            </Button>
          )}
        </Flex>
      </Flex>
      <TableAgreements
        paths={pathsCrm}
        activeKey={activeKey}
        canView={canView}
        canEdit={canEdit}
        canDelete={canDelete}
      />
    </div>
  );
};
