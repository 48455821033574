import React from "react";
import { List, Input, Button, Form } from "antd";
import Chat from "../../../../../components/Chat";

interface Props {
  initialValue: any;
  isViewMode?: boolean;
}

const ChatTab: React.FC<Props> = ({ initialValue, isViewMode }) => {
  const form = Form.useFormInstance();

  const requestId = form?.getFieldValue("id") || initialValue?.id;

  return (
    <div
      style={{
        pointerEvents: isViewMode ? "none" : "auto",
        opacity: isViewMode ? 0.7 : 1
      }}
    >
      <Chat
        row_id={requestId}
        user={form.getFieldValue("toUser")}
        table="rent_request"
      />
    </div>
  );
};

export default ChatTab;
