import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Empty, Flex, Typography } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useParamsHistory } from '../hooks';
import { RouteNames } from '../router/names';

const Page404 = () => {
  const isAuth = useSelector((state: RootState) => state.users.auth);
  const {history, location} = useParamsHistory();
  const {pathname} = location;
  const reload = () => {
    history.push(RouteNames.MAIN);
  };
  useEffect(() => {
    if (!isAuth) {
      history.push(RouteNames.LOGIN);
      return;
    }
    if (pathname === RouteNames.LOGIN) {
      history.push(RouteNames.MAIN);
    }
  }, [isAuth, pathname]);

  return (
    <Flex justify={'center'} align={'center'} style={{ height: '100vh' }}>
      {isAuth && pathname !== RouteNames.LOGIN && (
        <Empty
          description={
            <Flex vertical gap={40}>
              <Typography.Title level={2} type="warning">
                404 Oops!
              </Typography.Title>
              <Typography.Paragraph>
                Страница не существует.<br></br>Попробуйте изменить путь или
                вернитесь на главную.
              </Typography.Paragraph>
            </Flex>
          }
        >
          <Button type="default" icon={<ArrowLeftOutlined />} onClick={reload}>
            Вернуться на сайт?
          </Button>
        </Empty>
      )}
    </Flex>
  );
};

export default Page404;
