import { CONTAINER } from '../../constants';
import { RouteNames } from '../../router/names';

const pathsDepot = {
  parent: RouteNames.RELEASES,
};

// ктк для excel
const nameColumnsKtk = ['Размер', 'Тип', '№КТК', 'Комментарий', 'Кол-во'];
const keyNamesKtk = ['size', 'type', 'num_container', 'comment', 'count'];
const FORMAT_DATE_KTK = 'DD.MM.YYYY';
const BASE_TAB = 'baseTab';
const TABLE_TAB = 'tableTab';

const COMPLETED = 'completed';
const PART_COMPLETED = 'partCompleted';
const CANCELLED = 'cancelled'
const NEW = 'new'

const releaseStatus = {
  [COMPLETED]: {
    title: 'Выполнен',
    style: {
      color: '#00B288',
      background: '#E6F6EC',
    }
  },
  [PART_COMPLETED]: {
    title: 'Частично выполнен',
    style: {
      color: '#a3a9b1',
      background: '#E6F6EC',
    }
  },
  [NEW]: {
    title: 'Нет статуса',
    style: {
      color: '#a3a9b1',
      background: '#E6F6EC',
    }
  },
  [CANCELLED]: {
    title: 'Отменен',
    style: {
      color: '#E14453',
      background: '#FCE8EA',
    }
  },
};

const columnsTransporter = [
  {key: 'fio', title: 'ФИО', style: {}},
  {key: 'phone', title: 'Номер телефона', style: {}},
  {key: 'gos_number', title: 'Номер авто', style: {}},
  {key: 'series_passport', title: 'Серия паспорта', style: {}},
  {key: 'number_passport', title: 'Номер паспорта', style: {}},
];
const columnsKtk = [
  {key: 'size', title: 'Размер', style: {}},
  {key: 'type_container', title: 'Тип', style: {}},
  {link: {id: 'container_id', key: CONTAINER}, key: 'num_container', title: '№КТК', style: {}},
  {key: 'comment', title: 'Комментарий', style: {maxWidth: '200px', width: '200px'}},
  {key: 'count', title: 'Кол-во', style: {}},
];

export {
  pathsDepot,
  BASE_TAB,
  TABLE_TAB,
  FORMAT_DATE_KTK,
  COMPLETED,
  PART_COMPLETED,
  CANCELLED,
  NEW,
  nameColumnsKtk,
  keyNamesKtk,
  releaseStatus,
  columnsTransporter,
  columnsKtk
};
