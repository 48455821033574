import { AGREEMENT, COMPANY, CONTACT, CONTAINER, FINANCE_BILLING, FINANCE_PAYMENT, RELEASE, RENT_DEAL, RENT_REQUEST, SALES_DEAL, TERMINAL } from '../constants';

export const UPDATE = 'update';
export const EDIT = 'edit';
export const CREATE = 'create';
export const DELETE = 'delete';
export const VIEW = 'view';
export const HISTORY = 'history';
export const VIEW_SEARCH = '/view/';

export const getTypeForm = (str: string) => {
  if (!str) return '';
  if (str?.includes(EDIT)) {
    return EDIT;
  }
  if (str?.includes(CREATE)) {
    return CREATE;
  }
  if (str?.includes(VIEW)) {
    return VIEW;
  }
  return '';
};

export const createMarkup = (html: string) => ({ __html: html });

export const toFixedNumber = (val: number, round = 2) => {
  if (typeof val !== 'number') return '';

  const numbers = val.toString().split('.');
  if (numbers?.length < 2) {
    return val;
  }
  return (
    `${numbers[0]}.` + `${numbers[1]}`.substring(0, +numbers[0] <= 0 ? 4 : 2)
  );
};

export const getNamePage = ({ table }: { table: string }) => {
  switch (table) {
    case AGREEMENT:
      return 'Договоры';
    case COMPANY:
      return 'Компании';
    case CONTACT:
      return 'Контакты';
    case TERMINAL:
      return 'Терминалы';
    case CONTAINER:
      return 'Контейнеры';
    case RELEASE:
      return 'Релизы';
    case SALES_DEAL:
      return 'Сделки Продажи';
    case RENT_DEAL:
      return 'Сделки Аренды';
    case RENT_REQUEST:
      return 'Запросы Аренды';
    case FINANCE_BILLING:
      return 'Выставление счета';
    case FINANCE_PAYMENT:
      return 'Оплата счета';
    default:
      return '---//---';
  }
};
