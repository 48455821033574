import { Flex, Typography } from 'antd';
import IRate from '../../../components/ui/Rate';
import { IColumnType } from '../../../components/ui/Table/Table';
import RouteList from './components/list/RouteList';
import Scroll from '../../../components/ui/List/Scroll';
import { Link } from 'react-router-dom';
import { getSearchLink } from '../../../helpers/link-helper';
import { RELEASE } from '../../../constants';
import { IRelease } from '../../../store/releases';

export const columns: IColumnType<any>[] = [
  {
    dataIndex: 'id',
    filterIndex: 'id',
    checkbox: true,
    menu: true,
    type: 'string',
    key: '0',
  },
  {
    title: 'ID',
    dataIndex: 'id_deal',
    filterIndex: 'id_deal',
    link: true,
    width: '40%',
    type: 'string',
    key: '1',
  },
  {
    title: 'Название',
    dataIndex: 'name_deal',
    filterIndex: 'name_deal',
    width: '20%',
    type: 'string',
    key: '2',
    render: (col, item) =>
      item?.name_deal ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: { placement: 'top', title: item.name_deal },
          }}
          style={{ maxWidth: '250px', margin: 0 }}
        >
          {item.name_deal}
        </Typography.Paragraph>
      ) : null,
  },
  {
    title: 'Релизы',
    dataIndex: 'releases',
    filterIndex: 'releases',
    width: '20%',
    type: 'string',
    key: '3',
    render: (col, item) =>
      item?.releases?.length ? (
        <Flex vertical>
          {item?.releases.map((rel: IRelease, index: number) => (
            <Typography.Paragraph
              key={index}
              ellipsis={{
                rows: 2,
                tooltip: { placement: 'top', title: rel?.release_id },
              }}
              style={{ maxWidth: '250px', margin: 0, color: '#0148D7' }}
            >
              <Link
                style={{ color: 'currentcolor' }}
                to={getSearchLink({ key: RELEASE, id: rel?.id, noUrl: true })}
              >
                {rel?.release_id}
              </Link>
            </Typography.Paragraph>
          ))}
        </Flex>
      ) : null,
  },
  {
    title: 'Клиент',
    dataIndex: 'company',
    filterIndex: 'company',
    width: '20%',
    type: 'string',
    key: '4',
  },
  {
    title: 'Телефон',
    dataIndex: 'phone',
    filterIndex: 'phone',
    width: '30%',
    type: 'string',
    key: '5',
  },
  {
    title: 'Ответственный',
    dataIndex: 'responsible_name',
    filterIndex: 'responsible_name',
    width: '200px',
    key: '6',
    type: 'string',
  },
  {
    title: 'Кол-во КТК',
    dataIndex: 'containers_count',
    filterIndex: 'containers_count',
    width: '20%',
    key: '7',
    type: 'range_integer',
  },
  {
    title: 'Маршрут',
    dataIndex: 'routeText',
    filterIndex: 'routeText',
    width: '200px',
    key: '8',
    type: 'string',
    render: (col, item) => <RouteList array={item?.route} width={col?.width} />,
  },
  {
    title: 'Статус',
    dataIndex: 'status_name',
    filterIndex: 'status_name',
    width: '20%',
    key: '9',
    type: 'string',
    render: (_, item) =>
      item?.status && item?.status_id ? (
        <IRate isOne desc={[item.status]} value={item.status_id} />
      ) : null,
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment_last',
    filterIndex: 'comment_last',
    width: '20%',
    key: '10',
    type: 'string',
    render: (_, item) =>
      item?.comment_last ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: { placement: 'left', title:  <Scroll>{item.comment_last}</Scroll> },
          }}
          style={{ maxWidth: '130px', margin: 0 }}
        >
          {item.comment_last}
        </Typography.Paragraph>
      ) : null,
  },
  {
    title: '',
    dataIndex: 'hidden',
    filterIndex: 'hidden',
    width: '20%',
    key: '11',
  },
];
