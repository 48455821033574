import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ktkDefault, NEW } from "../pages/finance/billing/constants";
import { FormInstance } from "antd";
import { Rest_finance } from "../services/rest_finance";

export interface IFormDeal<T> {
  onEdit?: () => void;
  onView?: () => void;
  onClose: (isClear?: boolean) => void;
  isOpen: boolean;
  isCopy?: boolean;
  isDisabled?: boolean;
  typeForm?: string;
  width?: string;
  title?: string;
  initialValue: T;
  disabled?: boolean;
  canEdit?: boolean;
}

export interface ITab {
  initialValue?: IPayment;
  isDisabled?: boolean;
  isActive: boolean;
  form: FormInstance;
}

export interface IResponsible {
  avatar: string;
  name: string;
  id: number;
  isResponsible: boolean;
}

export interface IResponsiblePersons {
  responsible_id: number;
  options: any[];
}

export interface IComments {
  comment: string;
  id_deal: string;
  user_id: number;
  user?: { avatar: string; fio: string; id: number; name: string };
  edit?: boolean;
  createdAt?: string;
}

export interface IBilling {
  id?: number;
  id_deal?: string;
  billing_id?: string;
  type_deal_id?: number | null;
  type_deal: string;
  type_deal_char: string;
  account_type?: string;
  account_type_id?: number | null;
  priority?: string;
  priority_id?: number | null;
  company?: string;
  company_id?: number;
  email?: string;
  phone?: string;
  my_company?: string;
  my_company_id?: number;
  agreement_id?: number | null;
  agreement?: string | null;
  task_id?: number | null;
  task?: string | null;
  contact_id?: number;
  contact?: any;
  currency_id?: number;
  currency?: string;
  saveFiles?: any[];
  file_ids: number[];
  responsible_id?: number | null;
  responsible_name?: string | null;
  status: string;
  companies?: any;
  agreement_ids?: any;
  deadline?: string;
  course?: string;
  delete?: boolean;
  edit?: boolean;
  containers?: any[];
}
export interface IPayment {
  id?: number;
  payment_id?: string;
  unique_id?: string;
  name_payment?: string;
  company?: string;
  company_id?: number;
  email?: string;
  phone?: string;
  expense?: string;
  expense_id?: number | null;
  dso?: string;
  dso_id?: number | null;
  edo?: string;
  total_payment?: string;
  my_company?: string;
  my_company_id?: number;
  contact_id?: number;
  contact?: any;
  currency_id?: number;
  currency?: string;
  responsible_id?: number | null;
  responsible_name?: string | null;
  paymentFileIds: number[];
  paymentSaveFiles: any[];
  saveFiles: any[];
  file_ids: number[];
  course?: string;
  companies?: any;
  num_container?: string;
  release_id?: string;
  purposeOfPurchase?: string;
  agreement_id?: number | null;
  agreement?: string | null;
  operating_director_id?: number | null;
  operating_director?: string | null;
  author_id?: number | null;
  author?: string | null;
  status: string;
  approval_director?: string | null;
  approval_fin?: string | null;
  delete?: boolean;
  edit?: boolean;
  responsible_persons: IResponsiblePersons[];
  observers: IResponsible[];
  observer?: string;
  observer_id?: number | null;

  type_bill?: string;
  id_type_bill?: number | null;
  priority?: string;
  priority_id?: number | null;
  agreement_ids?: any;
  deadline?: string;
  containers?: any[];
  date_request?: string | null;
}
interface IFinanceState {
  oneBillingData: IBilling;
  onePaymentData: IPayment;
}

export const defaultValuesBilling: IBilling = {
  id: undefined,
  billing_id: "",
  id_deal: "12",
  type_deal_id: null,
  type_deal: "",
  type_deal_char: "",
  company: "",
  company_id: undefined,
  phone: "",
  email: "",
  my_company: "",
  my_company_id: undefined,
  agreement_id: null,
  agreement: "",
  contact_id: undefined,
  contact: null,
  currency_id: undefined,
  currency: "",
  responsible_id: undefined,
  responsible_name: "",
  containers: ktkDefault,
  saveFiles: [],
  file_ids: [],
  status: NEW,
  deadline: "",
  course: "",
  priority: "",
  priority_id: null,
  delete: false,
  edit: false,
};
export const defaultValuesPayment: IPayment = {
  id: undefined,
  payment_id: "12",
  expense: "",
  expense_id: null,
  dso: "",
  dso_id: null,
  edo: "",
  company: "",
  company_id: undefined,
  phone: "",
  email: "",
  total_payment: "",
  my_company: "",
  my_company_id: undefined,
  contact_id: undefined,
  contact: null,
  currency_id: undefined,
  currency: "",
  course: "",
  responsible_id: undefined,
  responsible_name: "",
  purposeOfPurchase: "",
  paymentFileIds: [],
  paymentSaveFiles: [],
  file_ids: [],
  saveFiles: [],
  delete: false,
  edit: false,
  observers: [],
  responsible_persons: [],
  approval_director: null,
  approval_fin: null,
  status: "",
  agreement_id: null,
  agreement: "",
  deadline: "",
  priority: "",
  priority_id: null,
  date_request: null,
};

const initialState: IFinanceState = {
  oneBillingData: defaultValuesBilling,
  onePaymentData: defaultValuesPayment,
};

export interface IKtk {
  number: string;
  from: string;
  quantity: string;
  type_container: string;
  condition: string;
  remainingCost: string;
  terminal: string;
}

export type IStatus = "completed" | "partCompleted" | "cancelled" | undefined;

export const updateFinanceBilling = createAsyncThunk(
  "updateFinanceBilling",
  async (payload: IBilling) => {
    return await Rest_finance.updateFinanceBilling(payload);
  }
);
export const setFinanceBilling = createAsyncThunk(
  "setFinanceBilling",
  async (payload: IBilling) => {
    return await Rest_finance.setFinanceBilling(payload);
  }
);

export const deleteIdsBilling = createAsyncThunk(
  "deleteIdsBilling",
  async (payload: any) => {
    return await Rest_finance.deleteIdsBilling(payload);
  }
);

const financeSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {
    resetStoreFinance(state) {
      state.onePaymentData = initialState.onePaymentData;
      state.oneBillingData = initialState.oneBillingData;
    },
    resetStoreBilling(state) {
      state.oneBillingData = initialState.oneBillingData;
    },
    resetStorePayment(state) {
      state.onePaymentData = initialState.onePaymentData;
    },
    setOneBillingDate(state, actions) {
      state.oneBillingData = actions.payload;
    },
    setOnePaymentDate(state, actions) {
      state.onePaymentData = actions.payload;
    },
  },
  extraReducers: (builder) => {},
});
export default financeSlice.reducer;
export const {
  resetStoreBilling,
  resetStoreFinance,
  resetStorePayment,
  setOneBillingDate,
  setOnePaymentDate,
} = financeSlice.actions;
