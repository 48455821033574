import React, { useEffect } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Flex,
  Form,
  FormListFieldData,
  Input,
  Modal,
  Select,
  Tooltip,
  Typography,
} from 'antd';

import Icon, { PlusOutlined, SwapOutlined } from '@ant-design/icons';

import style from '../../style.module.scss';
import { getEmpty, getSizeKtk } from '../utils';
import IconButton from '../../../../components/ui/IconButton';
import { RootState, useStoreDispatch } from '../../../../store';
import { disableDateBefore } from '../../../../helpers/date-helpers';
import { Checkbox } from '../../../../components/ui/Checkbox/Checkbox';
import {
  FormDatePicker,
  FormInputNumber,
} from '../../../../components/ui/Form';
import {
  generateIdReleaseId,
  IContainer,
  ITab,
} from '../../../../store/releases';
import {
  handlePasteInputNumber,
  handlePasteInputText,
  keyHandlerInputNumber,
  keyHandlerInputText,
} from '../../../../helpers/input-helpers';
import TooltipButton from '../../../../components/ui/TooltipButton/TooltipButton';
import { errorImportIcon } from '../../../../assets/image/svg';
import socket from '../../../../socket';
import { GENERATE_ID_RELEASES } from '../../../../services/rest_releases';
import { SelectSearch } from '../../../../components/ui/SelectSearch/SelectSearch';
import { Rest_location } from '../../../../services/rest_location';
import { IDataCityChildren } from '../../../../store/terminals';

const defaultTransporter = {
  fio: '',
  phone: '',
  gos_number: '',
  series_passport: '',
  number_passport: '',
};

export const BaseTab: React.FC<ITab> = () => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  const [cityData, setCityData] = React.useState<any[] | []>([]);

  const [confirmation, setConfirmation] = React.useState<boolean>(false);

  const dataTypeContainers = useSelector(
    (state: RootState) => state.types.dataTypeContainers,
  );

  const terminal_parent_id: number = Form.useWatch('terminal_parent_id', {
    form,
    preserve: true,
  });

  const terminal_id: number = Form.useWatch('terminal_id', {
    form,
    preserve: true,
  });

  const terminal = Form.useWatch('terminal', {
    form,
    preserve: true,
  });

  const id: number = Form.useWatch('id', {
    form,
    preserve: true,
  });

  const type: string = Form.useWatch('type', {
    form,
    preserve: true,
  });

  const release_id: string = Form.useWatch('release_id', {
    form,
    preserve: true,
  });
  const release_generate_id: string = Form.useWatch('release_generate_id', {
    form,
    preserve: true,
  });

  const user = Form.useWatch('user_release_id', {
    form,
    preserve: true,
  });

  const is_transporter = Form.useWatch('is_transporter', {
    form,
    preserve: true,
  });

  const no_number = Form.useWatch('no_number', {
    form,
    preserve: true,
  });

  const date_action_start = Form.useWatch('date_action_start', {
    form,
    preserve: true,
  });

  const date_action_end = Form.useWatch('date_action_end', {
    form,
    preserve: true,
  });

  const containers = Form.useWatch('containers', {
    form,
    preserve: true,
  }) as IContainer[];

  const containers_count = Form.useWatch('containers_count') as number;

  const isDisabled = !!id;

  useEffect(() => {
    if (!terminal) {
      form.setFieldValue('terminal_id', null);
      form.setFieldValue('terminal_parent_id', null);
    }
    return () => {
      setCityData([]);
    };
  }, [terminal]);

  const handleBlurTerminal = () => {
    if (terminal && !terminal_id) {
      form.setFieldValue('terminal', '');
      form.setFieldValue('terminal_parent_id', null);
    }
  };

  const onTerminalNameChange = async (
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const text = evt.target.value;
    if (text?.length > 1) {
      Rest_location.searchLocationName({ text })
        .then((res) => {
          console.log(res.data);

          setCityData(res.data);
        })
        .catch(console.error);
    }
  };

  const handleSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const input = evt.target;
    const inn = input.value.replace(/[^0-9]/g, '');
    form.setFieldValue('inn', inn);
    // handleSearchCompany(inn);
  };

  const handlerSelectTerminals = (
    event: any,
    option: {item?: IDataCityChildren},
    parent: { id: number; value: number; label: string },
  ) => {
    const terminalItem =
      option?.item || null;
    const terminalSelectName = terminalItem?.label || '';

    form.setFieldValue('terminal_id', Number(terminalItem?.id));
    form.setFieldValue('terminal_parent_id', Number(parent?.value));
    form.setFieldValue('terminal', terminalSelectName);
  };

  const generateId = () => {
    dispatch(generateIdReleaseId({ char: user }))
      .then((res) => {
        form.setFieldValue('release_generate_id', res.payload);
      })
      .catch(() => {
        form.setFieldValue('release_generate_id', 'Ошибка генерации id');
      })
      .finally(() => {
        socket.off(GENERATE_ID_RELEASES);
      });
  };

  const handlerChecked =
    (name: 'is_transporter' | 'no_number') => (bol: boolean) => {
      const result = Object.create(null);
      if (name === 'no_number') {
        addZeroContainers(0);
      }
      if (name === 'is_transporter') {
        result.transporter = [];
        result.inn = '';
        result.company = '';
      }
      form.setFieldsValue({
        ...result,
        [name]: bol,
      });
    };

  const closeConfirmation = () => {
    setConfirmation(false);
    form.setFieldsValue({
      containers_count: containers?.length
        ? containers.reduce((acc, cur) => acc + cur?.count || 0, 0)
        : 0,
    });
  };

  const handlerInput = (evt: React.SyntheticEvent<HTMLInputElement>) => {
    const input = evt.target as HTMLInputElement;
    const value = input.value.replace(/\D/g, '');
    const count = Number(value) || 0;

    if (containers?.length) {
      setConfirmation(true);
      return;
    }
    addZeroContainers(count);
  };

  const addZeroContainers = (count: number) => {
    const zeroContainers = [
      getEmpty({
        release_id,
        type,
        count,
        terminal_id: terminal_id,
        location_id: terminal_parent_id,
        location: { id: terminal_parent_id },
        terminal: {
          value: terminal_id,
          id: terminal_id,
          name: terminal,
          label: terminal,
        },
      }),
    ];
    form.setFieldsValue({
      containers: count ? zeroContainers : [],
    });
    setConfirmation(false);
  };

  const selectedType = (type?: string) => {
    const result = containers
      .filter((cont) => (no_number ? true : cont?.type_container_id === type))
      .map((item) => ({
        ...item,
        size: getSizeKtk(type),
        type_container: type,
        type_container_id: type,
      }));
    form.setFieldsValue({
      containers: result,
    });
  };

  const footerConfirmContainer = ({
    success,
    cancel,
  }: {
    success: () => void;
    cancel: () => void;
  }) => (
    <div className={style.modal__footer} style={{ paddingLeft: '20px' }}>
      <div className={style['modal__buttons']}>
        <TooltipButton
          propsTooltip={{
            title: t('Да'),
          }}
          propsButton={{
            type: 'default',
            onClick: success,
          }}
        >
          {t('Да')}
        </TooltipButton>
        <TooltipButton
          propsTooltip={{
            title: t('Нет'),
          }}
          propsButton={{
            type: 'default',
            style: {},
            onClick: cancel,
          }}
        >
          {t('Нет')}
        </TooltipButton>
      </div>
    </div>
  );

  const fillSwap = () => {
    form.setFields([
      {
        name: 'release_id',
        value: release_generate_id,
        errors: undefined,
      },
    ]);
  };

  return (
    <div className={style.tab}>
      <Form.Item name="responsible_id" noStyle />
      <Form.Item name="status" noStyle />
      <Form.Item name="terminal" noStyle />
      <Form.Item name="terminal_id" noStyle />
      <Form.Item name="terminal_parent_id" noStyle />
      <Form.Item name="responsible" noStyle />
      <Form.Item name="user_release_id" noStyle />
      <Form.Item name="file_ids" noStyle />
      <Form.Item name="history_ids" noStyle />
      <Form.Item name="date_action_start_string" noStyle />
      <Form.Item name="date_action_end_string" noStyle />
      <Form.Item name="is_transporter" noStyle />
      <Form.Item name="no_number" noStyle />
      <Flex justify="space-between" align="start">
        <Col span={12}>
          <Flex gap={10} justify="space-between" align="flex-start">
            <Form.Item
              label="Номер релиза"
              name="release_id"
              rules={[
                {
                  whitespace: true,
                  required: !isDisabled,
                  message: t('Обязательное поле'),
                },
              ]}
              required={!isDisabled}
            >
              <Input allowClear disabled={isDisabled} />
            </Form.Item>
            {!isDisabled && (
              <TooltipButton
                propsTooltip={{
                  title: release_generate_id ? t('Заполнить') : undefined,
                }}
                propsButton={{
                  className: style['swap'],
                  type: 'text',
                  onClick: fillSwap,
                  icon: <SwapOutlined />,
                  disabled: !release_generate_id,
                }}
              />
            )}
            <Form.Item
              hidden={isDisabled}
              label={
                <span className={style.labelLink} onClick={generateId}>
                  {t('Сгенерировать')}
                </span>
              }
              name="release_generate_id"
            >
              <Input
                placeholder={t('Номер релиза')}
                disabled
                style={{ cursor: 'default' }}
              />
            </Form.Item>
          </Flex>
        </Col>
        <Col span={11}>
          <div className={style['date-block']}>
            <div className={style['date-block__item']}>
              <Form.Item
                label={t('Дата действия релиза')}
                className={style['form__label']}
                name="date_action_start"
                rules={[{ required: true, message: t('Обязательное поле') }]}
                required
              >
                <FormDatePicker
                  isString={false}
                  handlerChange={(str, data) => {
                    form.setFieldValue('date_action_start_string', str);
                    if (data > date_action_end) {
                      form.setFieldsValue({
                        date_action_end_string: '',
                        date_action_end: null,
                      });
                    }
                  }}
                />
              </Form.Item>
            </div>
            <div className={style['date-block__separator']} />
            <div className={style['date-block__item']}>
              <Form.Item
                className={classnames(
                  style['form__label'],
                  style['label__hidden'],
                )}
                label="Дата действия релиза"
                name="date_action_end"
                rules={[{ required: true, message: t('Обязательное поле') }]}
                required
              >
                <FormDatePicker
                  isString={false}
                  disabledDate={disableDateBefore(date_action_start)}
                  handlerChange={(str) =>
                    form.setFieldValue('date_action_end_string', str)
                  }
                  disabled={!date_action_start}
                />
              </Form.Item>
            </div>
          </div>
        </Col>
      </Flex>
      <Flex justify="space-between" align="start" gap={10}>
        <Col span={12}>
          <Checkbox
            isChoice={is_transporter}
            customChange={handlerChecked('is_transporter')}
          >
            <Typography.Text className={style.checkboxes__label}>
              {t('Выдача физическому лицу')}
            </Typography.Text>
          </Checkbox>
        </Col>
        <Col span={11}>
          <Checkbox
            isChoice={no_number}
            customChange={handlerChecked('no_number')}
          >
            <Typography.Text className={style.checkboxes__label}>
              {t('Безномерная выдача')}
            </Typography.Text>
          </Checkbox>
        </Col>
      </Flex>

      <Flex justify="space-between" align="start">
        <Col span={12}>
          <Flex gap={10} justify="space-between" align="flex-start">
            <Form.Item
              label="Кол-во КТК"
              name="containers_count"
              style={{ width: '100%' }}
              rules={[
                {
                  message: t('Добавьте КТК'),
                  validator: (_, val) => {
                    if (val) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  },
                },
              ]}
              required
            >
              <FormInputNumber
                allowClear={false}
                min={1}
                placeholder={t('Добавьте КТК')}
                disabled={!no_number}
                onBlur={handlerInput}
              />
            </Form.Item>
            <Form.Item
              label={t('Тип КТК')}
              name="type"
              style={{ width: '100%' }}
            >
              {
                <Select
                  allowClear
                  placeholder={t('Выберите тип')}
                  style={{ width: '100%' }}
                  options={dataTypeContainers}
                  onSelect={selectedType}
                />
              }
            </Form.Item>
          </Flex>
        </Col>
        <Col span={11}>
          <Form.Item label={t('Подрядчик')} name="contractor">
            <Input allowClear />
          </Form.Item>
        </Col>
      </Flex>
      <Flex justify="space-between" align="start">
        <Col span={12}>
          <Typography.Text className={style.label}>
            {t('Терминал')}
          </Typography.Text>
          <SelectSearch
            type
            defaults={terminal}
            dataSource={cityData?.map((el) => ({
              ...el,
              selectable: true,
            }))}
            onChangeSelect={handlerSelectTerminals}
            onBlur={handleBlurTerminal}
            dropdownRender={onTerminalNameChange}
            placeholder={t('Терминал')}
            style={{ width: '100%' }}
            hiddenBtn={false}
          />
        </Col>
        <Col span={11}></Col>
      </Flex>
      <Flex justify="space-between" align="start" hidden={!is_transporter}>
        <Col span={12}>
          <Flex gap={10} justify="space-between" align="start">
            <Form.Item
              label={t('ИНН Физического лица')}
              name="inn"
              required={is_transporter}
              rules={[
                {
                  required: is_transporter,
                  message: t('Обязательное поле'),
                  whitespace: true,
                },
              ]}
            >
              <Input
                allowClear
                placeholder={'ИНН'}
                onBlur={handleSearchBlur}
                onKeyDown={keyHandlerInputNumber}
                onKeyUp={keyHandlerInputNumber}
                onPaste={handlePasteInputNumber}
              />
            </Form.Item>
            <Tooltip
              title={form.getFieldValue('company')}
              style={form.getFieldValue('company') ? {} : { opacity: 0 }}
            >
              <Form.Item
                label={t('Наименование')}
                name={'company'}
                style={{ paddingBottom: '22px' }}
                required={is_transporter}
                rules={[
                  {
                    required: is_transporter,
                    message: t('Обязательное поле'),
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Tooltip>
          </Flex>
        </Col>
        <Col span={10} />
      </Flex>
      <div className={style.transporter} hidden={is_transporter}>
        <Form.List name="transporter">
          {(fields: FormListFieldData[], operation, meta: { errors: any }) => (
            <Flex vertical gap={20}>
              {fields?.map(({ ...field }, index: number) => (
                <Flex vertical gap={0} key={field.key}>
                  <Flex justify="space-between" align="start">
                    <Col span={12}>
                      <Flex
                        gap={10}
                        justify="space-between"
                        align="flex-start"
                        style={{ width: '100%' }}
                      >
                        <Form.Item
                          label="Данные о водителе"
                          name={[field.name, 'fio']}
                          required={!is_transporter}
                          rules={[
                            {
                              required: !is_transporter,
                              message: t('Обязательное поле'),
                              whitespace: true,
                            },
                          ]}
                          style={{ width: '100%' }}
                        >
                          <Input
                            allowClear
                            placeholder={t('Введите ФИО')}
                            onKeyUp={keyHandlerInputText}
                            onKeyDown={keyHandlerInputText}
                            onPaste={handlePasteInputText}
                          />
                        </Form.Item>

                        <Form.Item
                          className={style['label__hidden']}
                          label="Данные телефона"
                          name={[field.name, 'phone']}
                          style={{ width: '100%' }}
                        >
                          <Input
                            allowClear
                            placeholder={t('Введите номер телефона')}
                          />
                        </Form.Item>
                      </Flex>
                    </Col>
                    <Col span={11}>
                      <Flex gap={5} align="center">
                        <Form.Item
                          className={style['form__label']}
                          label="Данные об автомобиле"
                          name={[field.name, 'gos_number']}
                          style={{ width: '50%' }}
                        >
                          <Input
                            allowClear
                            placeholder={t('Введите номер авто')}
                          />
                        </Form.Item>
                        <IconButton
                          className={style.transporter__delete}
                          iType="delete"
                          onClick={() => operation.remove(index)}
                        />
                      </Flex>
                    </Col>
                  </Flex>
                  <Flex justify="space-between" align="start">
                    <Col span={12}>
                      <Flex gap={10} justify="space-between" align="end">
                        <Form.Item
                          label="Данные паспорта"
                          name={[field.name, 'series_passport']}
                          style={{ width: '100%' }}
                        >
                          <Input
                            allowClear
                            maxLength={4}
                            placeholder={t('Серия')}
                            onKeyDown={keyHandlerInputNumber}
                            onKeyUp={keyHandlerInputNumber}
                            onPaste={handlePasteInputNumber}
                          />
                        </Form.Item>

                        <Form.Item
                          className={style['label__hidden']}
                          label="Данные паспорта"
                          name={[field.name, 'number_passport']}
                          style={{ width: '100%' }}
                        >
                          <Input
                            allowClear
                            maxLength={6}
                            placeholder={t('Номер')}
                          />
                        </Form.Item>
                      </Flex>
                    </Col>
                  </Flex>
                </Flex>
              ))}

              <Button
                type="text"
                className={style['button__primary']}
                onClick={() => operation.add(defaultTransporter)}
                icon={<PlusOutlined />}
              >
                {t('Добавить водителя')}
              </Button>
            </Flex>
          )}
        </Form.List>
      </div>
      <Modal
        className={style.modal}
        style={{ maxWidth: '400px' }}
        open={confirmation}
        centered
        footer={() =>
          footerConfirmContainer({
            success: () => addZeroContainers(containers_count),
            cancel: closeConfirmation,
          })
        }
        onCancel={(e: any) => setConfirmation(false)}
      >
        <div className={style.modal}>
          <div className={classnames(style['modal__content'])}>
            <Flex
              justify="space-between"
              align="center"
              style={{ marginBottom: '20px', maxWidth: '100%' }}
            >
              <Icon component={errorImportIcon} />
              <Flex style={{ padding: '15px', width: '100%' }}>
                <Typography.Text className={style['modal__title']}>
                  {t(
                    'Это действие приведет к замене всех контейнеров, продолжить?',
                  )}
                </Typography.Text>
              </Flex>
            </Flex>
          </div>
        </div>
      </Modal>
    </div>
  );
};
