import {io} from "socket.io-client";
import {Utils} from "../utils";

const URL: any = process.env.REACT_APP_API_URL || window.config?.REACT_APP_API_URL;

const socket = io(URL, {
    transports: ['websocket', 'polling'],
    forceNew: true,
    upgrade: false,
    auth: {id: Utils.getUserInfo().id}
});

export default socket;