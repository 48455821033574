import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { RootState } from '../store';
import Container from './Layouts/Container';
import { privateRoutes, publicRoutes } from '../router';

const AppRouter = () => {
  const isAuth = useSelector((state: RootState) => state.users.auth);
  return isAuth ? (
    <Container>
      <Switch>
        {privateRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
    </Container>
  ) : (
    <Switch>
      {publicRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
};

export default AppRouter;
