import React, { useEffect, useState } from 'react';
import TooltipButton from '../TooltipButton/TooltipButton';
import classnames from 'classnames';
import style from './style.module.scss';
import Icon, {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  HomeOutlined,
  PrinterOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  BusinessTripIconDefault,
  CopyBufferIconDefault,
  CopyIconDefault,
  DeleteIconDefault,
  EditViewIconDefault,
  MessageIconDefault,
  moreIcon,
  openEyeIcon,
  PlusIconDefault,
  uploadIcon,
  VacationIconDefault,
} from '../../../assets/image/svg';
import { useTranslation } from 'react-i18next';
import { ButtonProps } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

export type iconButtonType =
  | 'go-back'
  | 'view-form'
  | 'edit-form'
  | 'edit'
  | 'delete'
  | 'copy'
  | 'buffer'
  | 'pluse'
  | 'excel'
  | 'check'
  | 'close'
  | 'menu'
  | 'print'
  | 'depo'
  | 'chat'
  | 'vacation'
  | 'business_trip';
interface IButton extends ButtonProps {
  iType: iconButtonType;
  title?: string;
  placement?: TooltipPlacement;
  className?: string;
  onClick?: () => void;
}

// компонент для интерактивных иконок
export const IconButton: React.FC<IButton> = ({
  iType,
  onClick,
  className = '',
  title = '',
  placement = 'topLeft',
  ...props
}) => {
  const { t } = useTranslation();
  const [currentTitle, setCurrentTitle] = useState('');
  const [currentIcon, setCurrentIcon] = useState<any>(null);
  useEffect(() => {
    switch (iType) {
      case 'vacation':
        setCurrentTitle(title || 'Отпуск');
        setCurrentIcon(<VacationIconDefault />);
        return;
      case 'business_trip':
        setCurrentTitle(title || 'Поездка');
        setCurrentIcon(<BusinessTripIconDefault />);
        return;
      case 'chat':
        setCurrentTitle(title || 'Отправить');
        setCurrentIcon(<MessageIconDefault />);
        return;
      case 'go-back':
        setCurrentTitle(title || 'Вернуться');
        setCurrentIcon(<RollbackOutlined />);
        return;
      case 'print':
        setCurrentTitle(title || 'Печать');
        setCurrentIcon(<PrinterOutlined />);
        return;
      case 'depo':
        setCurrentTitle(title || 'Сохранить');
        setCurrentIcon(<HomeOutlined />);
        return;
      case 'menu':
        setCurrentTitle(title || 'Меню');
        setCurrentIcon(<Icon component={moreIcon} />);
        return;
      case 'close':
        setCurrentTitle(title || 'Закрыть');
        setCurrentIcon(<CloseOutlined />);
        return;
      case 'check':
        setCurrentTitle(title || 'Сохранить');
        setCurrentIcon(<CheckOutlined />);
        return;
      case 'edit':
        setCurrentTitle(title || 'Редактировать');
        setCurrentIcon(<EditOutlined />);
        return;
      case 'edit-form':
        setCurrentTitle(title || 'Редактировать');
        setCurrentIcon(<Icon component={EditViewIconDefault} />);
        return;
      case 'view-form':
        setCurrentTitle(title || 'Просмотр');
        setCurrentIcon(<Icon component={openEyeIcon} />);
        return;
      case 'delete':
        setCurrentTitle(title || 'Удалить');
        return setCurrentIcon(<Icon component={DeleteIconDefault} />);
      case 'copy':
        setCurrentTitle(title || 'Копировать');
        return setCurrentIcon(<Icon component={CopyIconDefault} />);
      case 'buffer':
        setCurrentTitle(title || 'Копировать');
        return setCurrentIcon(<Icon component={CopyBufferIconDefault} />);
      case 'pluse':
        setCurrentTitle(title || 'Добавить');
        return setCurrentIcon(<Icon component={PlusIconDefault} />);
      case 'excel':
        setCurrentTitle(title || 'Выгрузить');
        return setCurrentIcon(<Icon component={uploadIcon} />);
    }
  }, [iType]);

  return iType ? (
    <TooltipButton
      propsButton={{
        ...props,
        className: classnames(style.button, {[style.icon] : !className, [className]: className }),
        icon: props?.icon ? props.icon : currentIcon,
        onClick,
      }}
      propsTooltip={{ title: t(currentTitle), placement }}
    />
  ) : null;
};
