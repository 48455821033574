import React from 'react';
import { Flex, Tag, Typography } from 'antd';
import classnames from 'classnames';
import style from '../style.module.scss';

interface IHistoryLabel {
  label?: string;
  value?: any;
  className?: string;
  selected?: boolean;
}

export const HistoryLabel: React.FC<IHistoryLabel> = ({
  label = '',
  value = '',
  selected = false,
  className = '',
}) => (
  <Flex className={className} wrap gap={5}>
    {!!label && <Typography.Paragraph
      className={classnames(style.label, {
        [style['label_selected']]: selected,
      })}
    >
      {selected ? (
        <Tag bordered={false} color="green" style={{ fontSize: '16px' }}>
          {label ?? ''}
        </Tag>
      ) : (
        label ?? ''
      )}
    </Typography.Paragraph>}
    <Typography.Text className={style.label}>{value ?? ''}</Typography.Text>
  </Flex>
);
