import { Typography } from 'antd';
import { IColumnType } from '../../../components/ui/Table/Table';

export const columns: IColumnType<any>[] = [
  {
    dataIndex: 'id',
    filterIndex: 'id',
    checkbox: true,
    menu: true,
    type: 'string',
    key: '0',
  },
  {
    key: '1',
    title: 'Номер договора',
    dataIndex: 'agreement_id',
    filterIndex: 'agreement_id',
    link: true,
    type: 'string',
  },
  {
    key: '2',
    title: 'Дата подписания',
    dataIndex: 'date_signing',
    filterIndex: 'date_signing',
    type: 'date',
  },

  {
    key: '3',
    title: 'Тип договора',
    dataIndex: 'type',
    filterIndex: 'type',
    type: 'string',
  },
  {
    key: '4',
    title: 'Исполнитель',
    dataIndex: 'executor',
    filterIndex: 'executor',
    type: 'string',
    render: (col, item) =>
      item?.executor ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: { placement: 'top', title: item.executor },
          }}
          style={{ maxWidth: '250px', margin: 0 }}
        >
          {item.executor}
        </Typography.Paragraph>
      ) : null,
  },
  {
    key: '5',
    title: 'Заказчик',
    dataIndex: 'client',
    filterIndex: 'client',
    type: 'string',
    render: (col, item) =>
      item?.client ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: { placement: 'top', title: item.client },
          }}
          style={{ maxWidth: '250px', margin: 0 }}
        >
          {item.client}
        </Typography.Paragraph>
      ) : null,
  },
  {
    title: '',
    dataIndex: 'hidden',
    filterIndex: 'hidden',
    key: '6',
    width: '60px',
  },
];
