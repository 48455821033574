import { apiAxios } from "../api/config";
import socket from "../socket";

export interface IGetUserId {
  userId: number;
  current: number;
  pageSize: number;
  order: string;
  nameSort?: string;
}
export interface IGetUsers {
  current?: number;
  pageSize?: number;
}

export class Rest_User {
  static registration(payload: []): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit("registration", payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static userInfo(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit("user-info", payload, (response: any) => {
        try {
          resolve(response[0]);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getUserPermissions(
    userId: number,
    entityId?: number | number[]
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const handler = (response: any) => {
        socket.off("get-user-permissions", handler);
        try {
          if (typeof response === "boolean") {
            console.error("Ошибка: сервер вернул true, а не объект с правами!");
            reject("Ошибка: некорректный ответ от сервера");
          } else {
            resolve(response);
          }
        } catch (e) {
          reject(e);
        }
      };
      socket.on("get-user-permissions", handler);

      socket.emit("get-user-permissions", { userId, entityId });
    });
  }

  static entitiesInfo(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit("info-entities", payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static refUserInfo(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit("update-user", payload, (response: any) => {
        try {
          if (response.success === 1) {
            resolve(response.data[0]);
          } else {
            resolve(JSON.parse(response));
          }
        } catch (e) {
          reject(e);
        }
      });
    });
  }
  static loginAxios(data: any): Promise<any> {
    return apiAxios.post("/users/login", data);
  }
  static registrationAxios(data: any): Promise<any> {
    return apiAxios.post("/users/registration", data);
  }
  static resetPasswordAxios(data: { email: string }): Promise<any> {
    return apiAxios.post("/users/reset-password", data);
  }
  static findUserById(id: number): Promise<any> {
    return apiAxios.get(`/users/${id}`);
  }
  static getUsers(data: IGetUsers): Promise<any> {
    return apiAxios.post("/users/get-users", data);
  }
  static getAllUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log("emit get-all-users"); // отладка
      socket.once("return-all-users", (response: any) => {
        console.log("received return-all-users", response); // отладка
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
      socket.emit("get-all-users", {});
    });
  }
  static setUserActive(payload: { id: number; active: boolean }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit("set-users-active", payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
  static checkUsersActive(payload: { ids: number[]; }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit("check-users-active", payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static updateUser(payload: {
    id: number;
    avatar?: string | null;
    vacation?: boolean;
    travel?: boolean;
  }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit("update-user", payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
}
