import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { PdfSigningRU, PdfSigningEN } from "../pdf/pdfRent";
import UploaderDocs from "../../../../../components/ui/UploaderDocs";
import CancelReasonModal from "./CancelReasonModal";

import { getDateString } from "../../../../../helpers/date-helpers";
import { FormDatePicker } from "../../../../../components/ui/Form";
import { fileTypes } from "../../../../../helpers/input-helpers";
import {
  disableDateBefore,
  disableDateAfter,
} from "../../../../../helpers/date-helpers";
import { Rest_rent } from "../../../../../services/rest_rent";

import {
  Form,
  Input,
  Col,
  Row,
  Button,
  Checkbox,
  Modal,
  Typography,
} from "antd";
import { SideArrows } from "../../../../../assets/image/svg";
import style from "../style.module.scss";

interface SigningTabProps {
  initialValue: any;
  form?: any;
  isOpen?: any;
  isViewMode: boolean;
  rightsSigningTabEdit: boolean;
  // handlerSaveFiles: (files: any[]) => void;
}

type PdfOption = "standard" | "editable" | "signature";

const SigningTab: React.FC<SigningTabProps> = ({
  initialValue,
  form,
  isOpen,
  isViewMode,
  rightsSigningTabEdit,
  // handlerSaveFiles
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [requestId, setRequestId] = useState<string | null>(null);
  const [pdfType, setPdfType] = useState<"ru" | "en" | null>(null);
  const [isPdfModalVisible, setPdfModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);
  const locationReturnList = form.getFieldValue("location_return") || [];
  const locationReleaseList = form.getFieldValue("location_release") || [];

  const [selectedOption, setSelectedOption] = useState<PdfOption>("standard");

  const handleCheckboxChange = (option: PdfOption) => {
    setSelectedOption(option);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const generateTemporaryId = () => `${Math.floor(Math.random() * 1000)}`;

  const onPdf = (type: "ru" | "en") => {
    setPdfType(type);
    setPdfModalVisible(true);
  };

  const handlePdfModalClose = () => {
    setPdfModalVisible(false);
    setPdfType(null);
  };

  const formPdfData = form.getFieldsValue();

  const file_ids = Form.useWatch("file_ids", {
    form,
    preserve: true,
  });

  const handleOpenCancelReasonModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleSaveReasons = (reasons: string[]) => {
    setSelectedReasons(reasons);
  };

  const date_action_start = Form.useWatch("date_action_start", {
    form,
    preserve: true,
  });
  const date_action_end = Form.useWatch("date_action_end", {
    form,
    preserve: true,
  });

  return (
    <Form
      layout="vertical"
      form={form}
      className={style.requestTab}
      initialValues={initialValue}
      style={{
        pointerEvents: !rightsSigningTabEdit ? "none" : "auto",
      }}
    >
      <Form.Item
        label="Название"
        name="name_request"
        className={
          isViewMode ? style.approvalInputsDisabled : style.approvalInputs
        }
      >
        <Input
          style={{
            opacity: !rightsSigningTabEdit ? 0.6 : 1,
          }}
          disabled
          className={style.inputName}
          // style={{
          //   color: "#0E1117",
          //   backgroundColor: "#0e111700",
          // }}
        />
      </Form.Item>
      <Row gutter={24}>
        <Col
          span={isCollapsed ? 1 : 9}
          className={`${style.approvalInputs} ${
            isCollapsed ? style.collapsed : ""
          } ${isViewMode ? style.approvalInputsDisabled : style.approvalInputs}
          `}
          style={{
            marginTop: "10px",
            marginBottom: "55px",
            opacity: !rightsSigningTabEdit ? 0.6 : 1,
          }}
        >
          {!isCollapsed && (
            <>
              <Form.Item label="Наше юридическое лицо" name="my_company">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Клиент" name="company">
                <Input disabled />
              </Form.Item>
              <Form.Item label="№ Договора" name="agreement">
                <Input disabled />
              </Form.Item>

              {locationReleaseList.map((rel: any, indexRelease: number) => (
                <Form.Item label={`Локация релиза`} key={indexRelease}>
                  <Input value={rel.location_release} disabled />
                </Form.Item>
              ))}

              <Row
                gutter={16}
                align="middle"
                style={{ paddingLeft: "15px", width: "345px" }}
              >
                {locationReturnList.map((loc: any, index: number) => (
                  <Row
                    key={index}
                    gutter={16}
                    align="middle"
                    className={style.locationReturnApprooval}
                  >
                    <Form.Item label={`Локация возврата`}>
                      <Input
                        value={loc.location}
                        disabled
                        style={{ width: "335px" }}
                      />
                    </Form.Item>
                    <Form.Item label="Ставка">
                      <Input
                        value={loc.bet}
                        disabled
                        style={{ width: "335px" }}
                      />
                    </Form.Item>
                  </Row>
                ))}
              </Row>

              <Form.Item label="Тип КТК" name="type_container">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Состояние" name="condition">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Количество КТК" name="count_ktk">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Валюта" name="currency">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Количество дней" name="count_day">
                <Input disabled />
              </Form.Item>
              <Form.Item label="СНП" name="snp">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Подписант" name="signer">
                <Input disabled={!rightsSigningTabEdit} />
              </Form.Item>
              <Form.Item label="Штраф за отмену" name="fine">
                <Input disabled={!rightsSigningTabEdit} />
              </Form.Item>
            </>
          )}
        </Col>

        <div className={style.divider} onClick={toggleCollapse}>
          <div
            className={`${style.icon} ${
              isCollapsed ? style.iconRight : style.iconLeft
            }`}
          >
            <SideArrows />
          </div>
        </div>

        <Col span={isCollapsed ? 23 : 14} className={style["right-column"]}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginBottom: "20px",
            }}
          >
            <div className={style["date-block"]}>
              <div className={style["date-block__item"]}>
                <Form.Item
                  label={t("Валидность заявки")}
                  className={style["form__label"]}
                  name="date_action_start"
                >
                  <FormDatePicker
                    disabled={!rightsSigningTabEdit}
                    isString={false}
                    handlerChange={(str, data) => {
                      form.setFieldValue("date_action_start_string", str);
                      if (data > date_action_end) {
                        form.setFieldsValue({
                          date_action_end_string: "",
                          date_action_end: null,
                        });
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <div className={style["date-block__separator"]} />
              <div className={style["date-block__item"]}>
                <Form.Item
                  className={classnames(
                    style["form__label"],
                    style["label__hidden"]
                  )}
                  label="Валидность заявки"
                  name="date_action_end"
                >
                  <FormDatePicker
                    isString={false}
                    disabledDate={disableDateBefore(date_action_start)}
                    handlerChange={(str, dataString) =>
                      form.setFieldValue("date_action_end_string", str)
                    }
                    disabled={
                      !date_action_start || isViewMode || !rightsSigningTabEdit
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className={style["request-info"]}>
              <Form.Item label="ID заявки" name="id_request">
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Дата заявки"
                name="date_request"
                className={style["form__label"]}
              >
                <FormDatePicker
                  disabled={!rightsSigningTabEdit}
                  isString={true}
                  handlerChange={(str, data) => {
                    form.setFieldValue("date_request", str);
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <div className={style["pdf"]}>
            <div className={style["pdf__buttons"]}>
              <Button
                disabled={!rightsSigningTabEdit}
                type="default"
                style={{
                  marginRight: "10px",
                  borderColor:
                    isViewMode || !rightsSigningTabEdit ? "#d9d9d9" : "#05F",
                }}
                onClick={() => onPdf("ru")}
                className={style["pdf__buttons__btn"]}
              >
                Сгенерировать заявку РФ
              </Button>
              <Button
                disabled={!rightsSigningTabEdit}
                type="default"
                onClick={() => onPdf("en")}
                style={{
                  borderColor:
                    isViewMode || !rightsSigningTabEdit ? "#d9d9d9" : "#05F",
                }}
                className={style["pdf__buttons__btn"]}
              >
                Сгенерировать заявку EN
              </Button>
            </div>

            <div className={style["pdf__options"]}>
              <Checkbox
                disabled={!rightsSigningTabEdit}
                className={style["pdf__options__check"]}
                checked={selectedOption === "standard"}
                onChange={() => handleCheckboxChange("standard")}
              >
                Стандартные условия
              </Checkbox>
              <Checkbox
                disabled={!rightsSigningTabEdit}
                className={style["pdf__options__check"]}
                checked={selectedOption === "editable"}
                onChange={() => handleCheckboxChange("editable")}
              >
                Условия по заявке
              </Checkbox>
              <Checkbox
                disabled={!rightsSigningTabEdit}
                className={style["pdf__options__check"]}
                checked={selectedOption === "signature"}
                onChange={() => handleCheckboxChange("signature")}
              >
                Подпись/печать
              </Checkbox>
            </div>
          </div>
          <Form.Item name="file_ids" noStyle />
          <Typography.Text className={style.label}>
            {t("Файлы")}
          </Typography.Text>
          <div
            className={classnames(style["file-box"], "all-custom-v-scroll")}
            style={{
              pointerEvents: isViewMode ? "none" : "auto",
              opacity: isViewMode ? 0.6 : 1,
            }}
          >
            <UploaderDocs
              disabled={!rightsSigningTabEdit}
              isOpen={isOpen}
              ids={file_ids}
              buttonDescription={t("Загрузить файл")}
              multiple
              accept={[
                fileTypes.doc,
                fileTypes.xls,
                fileTypes.pdf,
                fileTypes.jpeg,
              ].join(",")}
              setDataIds={(files) => {
                form.setFieldValue("file_ids", files);
              }}
              // handlerSaveFiles={handlerSaveFiles}
            />
          </div>
          <Typography.Text
            className={style["pdf__text"]}
            style={{
              marginLeft: "5px",
              fontSize: "15px",
              fontWeight: "500",
              pointerEvents: isViewMode ? "none" : "auto",
              opacity: isViewMode || !rightsSigningTabEdit ? 0.6 : 1,
            }}
          >
            Сделка
          </Typography.Text>
          <div
            className={style["deals"]}
            style={{
              pointerEvents: isViewMode ? "none" : "auto",
              opacity: isViewMode || !rightsSigningTabEdit ? 0.6 : 1,
            }}
          >
            Сделка с поставщиком сформирована:
          </div>
        </Col>
      </Row>
      <Modal
        open={isPdfModalVisible}
        footer={null}
        onCancel={handlePdfModalClose}
        width="fit-content"
      >
        {pdfType === "ru" && (
          <PdfSigningRU
            formPdfData={formPdfData}
            onClose={handlePdfModalClose}
            selectedOption={selectedOption}
            defaultValues={initialValue}
          />
        )}
        {pdfType === "en" && (
          <PdfSigningEN
            formPdfData={formPdfData}
            onClose={handlePdfModalClose}
            selectedOption={selectedOption}
            defaultValues={initialValue}
          />
        )}
      </Modal>
    </Form>
  );
};

export default SigningTab;
