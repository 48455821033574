import React, { useState } from "react";
import { Modal, Checkbox, Button } from "antd";
import Icon from "@ant-design/icons";
import styles from "../style.module.scss";

interface CancelReasonModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave?: (selectedReasons: string[]) => void;
  reasonList: string[];
  onUpdateReasons: (newList: string[]) => void;
}

const CancelReasonModal: React.FC<CancelReasonModalProps> = ({
  isOpen,
  onClose,
  // onSave,
  reasonList,
  onUpdateReasons,
}) => {
  const reasons = [
    { id: 1, reason: "Не прошли по ставке" },
    { id: 2, reason: "Отменился букинг" },
    { id: 3, reason: "Нет закупа/субаренды" },
    { id: 4, reason: "Нет ОС от клиента" },
    { id: 5, reason: "Стоп локация" },
  ];

  // При клике по чекбоксу сразу обновляем родительское поле через onUpdateReasons
  const handleCheckboxChange = (itemReason: string) => {
    let newList: string[];
    if (reasonList.includes(itemReason)) {
      newList = reasonList.filter((r) => r !== itemReason);
    } else {
      newList = [...reasonList, itemReason];
    }
    onUpdateReasons(newList);
  };

  const handleSave = () => {
    onClose();
  };

  return (
    <Modal
      style={{ maxWidth: "500px" }}
      className={styles.CancelReasonModal}
      title="Пожалуйста, выберите причину отмены запроса"
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button
          key="save"
          type="primary"
          onClick={handleSave}
          //   disabled={selectedReasons.length === 0}
        >
          Сохранить
        </Button>,
        <Button key="cancel" type="link" danger onClick={onClose}>
          Отменить
        </Button>,
      ]}
    >
      <div className={styles.checkboxGroup}>
        {reasons.map((item) => (
          <Checkbox
            key={item.id}
            onChange={() => handleCheckboxChange(item.reason)}
            checked={reasonList.includes(item.reason)}
          >
            {item.reason}
          </Checkbox>
        ))}
      </div>
    </Modal>
  );
};

export default CancelReasonModal;
