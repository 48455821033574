import React from 'react';
import { Avatar, Badge, Tooltip } from 'antd';
import classnames from 'classnames';
import style from './style.module.scss';
import { UserOutlined } from '@ant-design/icons';
import { TooltipPlacement } from 'antd/es/tooltip';

interface IAvatarProfile {
  title?: string;
  avatar?: string;
  tooltipInfo?: any;
  isHiddenInfo?: boolean;
  className?: string;
  size: number;
  placement?: TooltipPlacement;
  isActive?: boolean;
  isShowActive?: boolean;
}

export const AvatarProfile: React.FC<IAvatarProfile> = ({
  avatar,
  title,
  tooltipInfo,
  isHiddenInfo,
  size = 32,
  className = '',
  placement = 'bottomRight',
  isShowActive = false,
  isActive = false,
}) => {
  const result = typeof title === 'string' ? title.split(' ') : [];
  const alt = (
    result?.length > 1
      ? (result[0]?.[0] ?? '') + (result?.[1]?.[0] ?? '')
      : result?.[0]?.[0] ?? ''
  ).toUpperCase();

  const fixStyle = {
    maxWidth: `${size}px`,
    minWidth: `${size}px`,
    maxHeight: `${size}px`,
    minHeight: `${size}px`,
  };
  return (
    <Tooltip
      title={tooltipInfo}
      arrow={false}
      placement={placement}
      overlayStyle={
        isHiddenInfo ? { visibility: 'visible' } : { visibility: 'hidden' }
      }
      overlayInnerStyle={
        isHiddenInfo ? { background: 'transparent', padding: 0 } : {}
      }
    >
      <Badge size='small' offset={[-6, 6]} color={isActive ? '#00B288' : 'red'} dot showZero={false} style={{opacity: isShowActive ? 1 : 0, cursor: 'default'}}>
        <Avatar
          className={classnames(style['avatar'], {
            [className]: className,
          })}
          style={fixStyle}
          size={size}
          src={avatar}
          icon={alt ? null : <UserOutlined />}
          alt={alt || 'icon'}
        >
          {alt}
        </Avatar>
      </Badge>
    </Tooltip>
  );
};
