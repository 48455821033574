import { Flex, Select } from 'antd';
import { IColumnType } from './Table';
import { SessionStorageManager } from './sessionStorageManager';
import { useLocation } from 'react-router-dom';
import {StorageData} from "../../../types";


interface ColumnStringProps {
  columnFilters?: any,
  column: IColumnType<any>,
  searchFilter: (row: string, column: any) => void,
  handleChange: (option: string[], dates: [Date | null | string, Date | null | string],
                 dataInt: [number | '' | null | undefined, number | '' | null | undefined],
                 checked: boolean, columnIndex: number) => void,
  columnIndex: number,
  startDate: Date | null,
  endDate: Date | null,
  optionsFilter?: any,
  startIntRange?: any,
  endIntRange?: any,
  dataType?: any
}

const ColumnDropdown = ({
                          columnFilters,
                          column,
                          searchFilter,
                          handleChange,
                          columnIndex,
                          startDate,
                          endDate,
                          startIntRange,
                          endIntRange,
                          dataType,
                        }: ColumnStringProps) => {
  const location = useLocation();
  const storageManager = new SessionStorageManager(location.pathname);
  const storageLocal: StorageData | null = storageManager.getItem();

  return (
    <Flex vertical gap={7}>
      <Select
        allowClear
        mode="multiple"
        value={columnFilters || storageLocal?.fiters?.[0]?.[column?.filterIndex] || null}
        onSearch={(value) => searchFilter(value, column)}
        style={{ width: 200 }}
        placeholder={column.title}
        notFoundContent={null}
        defaultActiveFirstOption={false}
        onChange={(option) => handleChange(option, [startDate, endDate],
          [startIntRange, endIntRange], false, columnIndex)}
        options={dataType}
      />
    </Flex>
  );
};

export default ColumnDropdown;
