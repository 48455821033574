import { apiAxios } from "../api/config";
import socket from "../socket";
import { IParamRestDelete } from "../types";


export class Rest_contacts {

    static getFiltersContact(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('get-filters-contact', payload, (response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static getSourceContact(): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('source-contact',(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static getFindExecutor(payload: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-executor',payload,(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static async getFindCity(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-city',payload,(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static setContacts(payload: {}): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('save-contact', payload,(response: any) => {
                try {
                    resolve(response)
                } catch (e) {reject(e)}
            })
        })
    }

    static updateContacts(payload: {}): Promise<any> {
        return new Promise( () => {
            socket.emit('update-contact', payload)
        })
    }

    static async findContact(payload: string): Promise<any>{
        return new Promise(async (resolve, reject) => {
            socket.emit('find-contact',payload,(response: any) => {
                try {
                    resolve(response)
                }catch (e) {reject(e)}
            })
        })
    }

    static getContact(payload: {}): Promise<any>{
        return apiAxios.post('contacts/get-contact', payload)
    }

    static findContactById(id: string): Promise<any>{
        return apiAxios.get(`contacts/${id}`)
    }

    static deleteIds(payload: IParamRestDelete): Promise<any> {
        return new Promise(async (resolve, reject) => {
          socket.emit('delete-contact', payload, (response: any) => {
            try {
              resolve(response);
            } catch (e) {
              reject(e);
            }
          });
        });
      }
}
