import React, { useEffect, useRef, useState } from "react";
import Icon from "@ant-design/icons";
import {
  Flex,
  Form,
  Input,
  Select,
  Row,
  Col,
  Typography,
  DatePicker,
  AutoComplete,
  Button,
  DatePickerProps,
  notification,
  FormInstance,
  Modal,
} from "antd";
import { CopyBufferIconDefault } from "../../assets/image/svg";
import moment from "moment";
import { ICurrency, ITypeContainers } from "../../types";
import { RootState, useStoreDispatch } from "../../store";
import { findCompany } from "../../store/contractors";
import { SelectSearch } from "../../components/ui/SelectSearch/SelectSearch";
import { Utils } from "../../utils";
import ModalConfirm from "../../components/ui/ModalConfirm/ModalConfirm";
import { saveContractors } from "../../store/contractors";
import { CONTAINER, messagesModal, MIN_CURRENCY_PRICE } from "../../constants";
import {
  disableDateAfterCurrent,
  disableDateBefore,
  getDateString,
  getDateSubmitString,
} from "../../helpers/date-helpers";
import { CREATE } from "../../helpers/string-helpers";
import { useSelector } from "react-redux";
import socket from "../../socket";
import { findContainers } from "../../store/containers";
import CustomDrawer from "../../components/CustomDrawer/CustomDrawer";
import { regNumberKTK } from "../../helpers/input-helpers";
import { useLink } from "../../hooks";
import IconButton from "../../components/ui/IconButton";
import CopyButton from "../../components/ui/CopyButton/CopyButton";
import { useTranslation } from "react-i18next";
import { Rest_files } from "../../services/rest_files";
import UploaderPreview from "../../components/ui/UploaderPreview";
import useModalMessage from "../../hooks/useModalMessage";
import { IContainers } from "../../types/containers";
import FormEditContractors from "../contractors/formEditContractors";
import { defaultValueContractor } from "../contractors/constants";
import { IDataCity, IDataCityChildren } from "../../store/terminals";
import dayjs from "dayjs";
import { FormInputNumber } from "../../components/ui/Form";
import { Rest_terminal } from "../../services/rest_terminal";
import FormModalImport from "./formModalImport";
import { IColumnsImport, TerminalService } from "./service";
import { isValidHttpUrl } from "../../helpers/link-helper";
import {
  filterSelectSearch,
  mapperSelect,
} from "../../helpers/mappers-helpers";
import { Rest_location } from "../../services/rest_location";

const { title: warningTitle, subtitle } = messagesModal.warning;

const { Text, Link } = Typography;

const columns: IColumnsImport[] = [
  {
    key: "0",
    title: "Номер контейнера",
    dataIndex: "num_container",
    type: "num_container",
  },
  {
    key: "1",
    title: "Родитель",
    dataIndex: "location",
    type: "parent",
  },
  {
    key: "2",
    title: "Терминал",
    dataIndex: "terminal",
    type: "terminal",
  },
  {
    key: "3",
    title: "Сток",
    dataIndex: "stock",
    type: "stock",
  },
  {
    key: "4",
    title: "Тип контейнера",
    dataIndex: "type_container",
    type: "type_container",
  },
  {
    key: "5",
    title: "Собственник",
    dataIndex: "owner",
    type: "company",
  },
  {
    key: "6",
    title: "Состояние",
    dataIndex: "quality",
    type: "quality",
  },
  {
    key: "7",
    title: "Входящая ставка",
    dataIndex: "currency_price",
    type: "string",
  },
  {
    key: "8",
    title: "Валюта",
    dataIndex: "currency",
    type: "currency",
  },

  {
    key: "9",
    title: "Комментарий",
    dataIndex: "comment",
    type: "string",
  },
  {
    key: "10",
    title: "Дата прибытия",
    dataIndex: "arrival_date",
    type: "date",
  },
  {
    key: "11",
    title: "Дата убытия",
    dataIndex: "departure_date",
    type: "date",
  },
  {
    key: "12",
    title: "Фото",
    dataIndex: "photo_bool",
    type: "string",
  },
];

interface Props {
  isOpen?: boolean;
  isCopy?: boolean;
  onView?: () => void;
  title?: string;
  onClose?: () => void;
  dataRowContainer: IContainers;
  form: FormInstance;
  typeForm?: string;
  onFinishForm?: (data: any) => void;
}

const createSvgIcon = (svgString: string) => () =>
  <div dangerouslySetInnerHTML={{ __html: svgString }} />;

const FormContainer: React.FC<Props> = ({
  isOpen,
  isCopy,
  title,
  onClose,
  onView,
  dataRowContainer,
  form,
  typeForm,
  onFinishForm,
}) => {
  const [formContractors] = Form.useForm();
  const message = useModalMessage(true);
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const link = useLink({ key: CONTAINER, id: dataRowContainer?.id as number });

  const [api, contextHolder] = notification.useNotification();

  const inputRef = useRef<HTMLInputElement>(null);

  const [loadings, setLoadings] = useState<boolean>(false);

  const [cityData, setCityData] = useState<IDataCity[]>([]);

  const dataCurrency = useSelector(
    (state: RootState) => state.types.dataTypeCurrency
  );

  const dataTypeQuality = useSelector(
    (state: RootState) => state.types.dataTypeQuality
  );

  const dataTypeContainers: ITypeContainers[] = useSelector(
    (state: RootState) => state.types.dataTypeContainers
  );

  const dataStatusContainer = useSelector(
    (state: RootState) => state.containers.dataStatusContainer
  );

  const [confirmation, setConfirmation] = React.useState<boolean>(false);

  const [dateMemory, setMemory] = useState<any>(null);

  const [dateCvv, setDateCvv] = useState<any>(null);

  const [open, setOpen] = useState<boolean>(false);

  const [openAddCompany, setOpenAddCompany] = useState<boolean>(false);

  const [openCvv, setOpenCvv] = useState(false);

  const [options, setOptions] = useState<{ value: string; id: number }[]>([]);

  const [optionsStock, setOptionsStock] = useState<
    { label: string; value: number; terminal_id: number }[]
  >([]);

  const [icons, setIcons] = useState<{ [key: string]: JSX.Element }>({});

  const [terminal, setTerminal] = useState<any>([]);

  const [terminalName, setTerminalName] = useState<string | null>(null);

  const [numContainer, setNumContainer] = useState<string>("");

  const [disabledSave, setDisabledSave] = useState<boolean>(false);

  const [ownerId, setOwnerId] = useState<number | null>(null);

  const [arHistory, setArHistory] = useState<any[]>([
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
    [
      { id: 0, meaning: null },
      { id: 1, meaning: null },
    ],
  ]);

  const [saveFiles, setSaveFiles] = useState<any[]>([]);

  const [isModalOpenImport, setIsModalOpenImport] = useState<boolean>(false);

  const ids_photo: number[] = Form.useWatch("ids_photo", {
    form,
    preserve: true,
  });

  const arrival_date = Form.useWatch("arrival_date", {
    form,
    preserve: true,
  });

  const departure_date = Form.useWatch("departure_date", {
    form,
    preserve: true,
  });

  const photo_bool: string = Form.useWatch("photo_bool", {
    form,
    preserve: true,
  });

  useEffect(() => {
    if (isOpen) {
      dataCurrency?.forEach((currency: ICurrency) => {
        const SvgIcon = createSvgIcon(currency.svg);
        setIcons((prev) => ({ ...prev, [currency.id]: <SvgIcon /> }));
      });
      if (dataRowContainer?.id) {
        const dateCvv =
          dataRowContainer?.date_cvv
            ? getDateString({
                date: dataRowContainer?.date_cvv,
              })
            : setDateCvv("Нет даты");
        const dateMemory =
          dataRowContainer?.date_memory
            ? getDateString({
                date: dataRowContainer?.date_memory,
              })
            : null;

        setDateCvv(dateCvv);
        setMemory(dateMemory);
        setTerminalName(dataRowContainer?.terminal?.name);
        form.setFieldsValue({
          num_container: dataRowContainer?.num_container,
          type_container: dataRowContainer?.type_container,
          quality: dataRowContainer?.quality,
          owner: dataRowContainer?.contractors?.name_company
            ? dataRowContainer.contractors.name_company
            : dataRowContainer?.["contractors.name_company"]
            ? dataRowContainer["contractors.name_company"]
            : "",
          owner_id: dataRowContainer?.owner_id,
          comment: dataRowContainer.comment,
          currency: dataRowContainer?.currency,
          currency_id: dataRowContainer?.currency_id,
          price: dataRowContainer?.price,
          currency_price: dataRowContainer?.currency_price,
          arrival_date: dataRowContainer?.arrival_date
            ? dataRowContainer.arrival_date
            : null,
          departure_date: dataRowContainer?.departure_date
            ? dataRowContainer.departure_date
            : null,
          ids_photo: dataRowContainer?.ids_photo,
          photo_bool: dataRowContainer?.photo_bool,
          location_id: dataRowContainer?.location?.id,
          terminal_id: dataRowContainer?.terminal?.id,
          stock: null,
          stock_id: null,
        });
        if (dataRowContainer?.terminal?.id) {
          const id = dataRowContainer.terminal?.id as number;
          onSearchStock({ id, isNull: false });
        }
        if (dataRowContainer?.terminalStock) {
          form.setFieldsValue({
            stock: dataRowContainer.terminalStock?.label,
            stock_id: dataRowContainer.terminalStock?.value,
          });
          setOptionsStock([dataRowContainer.terminalStock]);
        }
      } else {
        setDateCvv("Нет даты");
      }

      socket.on("return-create-contractors", handleCreateContractors);
    } else {
      socket.off("return-create-contractors", handleCreateContractors);
      setOptions([]);
      setMemory(null);
      setDateCvv(null);
      setOpen(false);
      setOpenAddCompany(false);
      setOpenCvv(false);
      setOptions([]);
      setOptionsStock([]);
      setIcons({});
      setTerminal([]);
      setTerminalName(null);
      setNumContainer("");
      setDisabledSave(false);
      setOwnerId(null);
      setArHistory([
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
        [
          { id: 0, meaning: null },
          { id: 1, meaning: null },
        ],
      ]);
      setSaveFiles([]);
      form.resetFields();
    }
  }, [isOpen, typeForm]);

  const createObj = (
    val: string | number | string[],
    found: boolean,
    name: string,
    arIndex: number,
    propName: string
  ) => {
    if (found) {
      return {
        id: arIndex,
        status_id: null,
        user_id: null,
        container_id: null,
        meaning: null,
      };
    } else {
      return {
        id: arIndex,
        status_id: dataStatusContainer.find((item) => item.name === name)?.id,
        user_id: Utils.getUserInfo().id,
        container_id: dataRowContainer?.id,
        meaning: val,
      };
    }
  };

  // HACK: импорт
  const onFinishImport = (newContainers: IContainers[]) => {
    const addResult = newContainers.map((item) => {
      const arrival_date = item?.arrival_date
        ? dayjs(item?.arrival_date)?.isValid()
          ? item?.arrival_date
          : ""
        : "";

      const departure_date = item?.departure_date
        ? dayjs(item?.departure_date)?.isValid()
          ? dayjs(item?.departure_date)
          : ""
        : "";

      const result = {
        ...item,
        departure_date,
        arrival_date,
      };

      return result;
    });

    const result =
      addResult.filter(({ num_container }) =>
        regNumberKTK.test(num_container)
      )?.[0] || {};
    const numberKtk = result?.num_container || "";
    const ids = numberKtk ? [numberKtk] : [];

    dispatch(findContainers({ data: ids, key: "num_container" }))
      .then((res) => {
        const resultContainers = res?.payload?.length ? res.payload : [];

        if (resultContainers?.length) {
          message.error(
            `${t(`Контейнер с номером ${numberKtk} уже создан`)} ${
              resultContainers.length
            } ктк`
          );
          return;
        }

        if (result?.owner && result?.owner_id) {
          setOwnerId(Number(result.owner_id));
          setOptions([{ value: result.owner, id: result.owner_id }]);
        }
        form.setFieldsValue({
          owner_id: result.owner_id ?? null,
          owner: result.owner ?? null,
          departure_date: result.departure_date,
          arrival_date: result.arrival_date,
          quality: result?.quality ?? "",
          currency: result?.currency ?? "",
          type_container: result?.type_container ?? "",
          currency_price: result?.currency_price ?? "",
          comment: result?.comment ?? "",
          photo_bool: result?.photo_bool ?? "",
          num_container: result.num_container,
        });

        if (result?.location_id && result?.terminal_id) {
          form.setFieldValue("terminal", result?.terminal);
          form.setFieldValue("location_id", result?.location_id);
          form.setFieldValue("terminal_id", result.terminal_id);
          if (result.stock_id && result.stock) {
            form.setFieldsValue({
              stock: result.stock,
              stock_id: result.stock_id,
            });
            setOptionsStock([
              {
                label: result.stock,
                value: result.stock_id,
                terminal_id: result.terminal_id,
              },
            ]);
          }
          setTerminalName(result?.terminal);
          onSearchStock({ id: result.terminal_id, isNull: false });
        }
      })
      .catch((err) => {
        message.error(`${t("Произошла ошибка")}, добавлено 0 ктк`);
        console.error(t("Произошла ошибка"), err);
      });
  };

  const closeModalImport = () => {
    setIsModalOpenImport(false);
  };

  const handleShowModal = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChangeImport = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    TerminalService.handleFile({ event, limit: 1 })
      .then((response) => {
        if (response.length > 0) {
          setIsModalOpenImport(true);
          if (inputRef.current) {
            inputRef.current.value = "";
          }
        }
      })
      .catch(console.error);
  };

  const handleCreateContractors = (response: any) => {
    if (response.success === 1) {
      setOwnerId(response.data.id);
    } else {
      setOwnerId(response.data.id);
      message.error(response.message);
    }
  };

  const getFindCompany = (searchText: any) => {
    if (searchText.length > 2) {
      dispatch(findCompany(searchText.toLowerCase()))
        .then((response) => {
          setOptions(response.payload);
        })
        .catch(console.error);
    }
  };

  const onFinish = async (data: IContainers) => {
    try {
      Object.assign(data, { type_form: typeForm });
      if (saveFiles?.length) {
        // надо метод загрузки пачкой
        for (const file of saveFiles) {
          const res = await Rest_files.submitImageFiles(file);
          if (res?.data) {
            if (!Array.isArray(data?.ids_photo)) {
              data.ids_photo = [];
            }
            data.ids_photo.push(res.data);
          }
        }
      }
      data.owner_id = ownerId !== null ? ownerId : dataRowContainer?.owner_id;

      if (!data.owner_id) {
        form.setFieldValue("owner_id", null);
        form.setFields([
          {
            name: "owner",
            value: null,
            errors: [t("Обязательное поле!")],
          },
        ]);
        setOwnerId(null);
        return;
      }
      data.date_entry =
        dataRowContainer?.date_entry || moment().format("YYYY-MM-DD");

      data.responsible_id = Utils.getUserInfo().id;
      data.status_id = typeForm === "create" ? 1 : 10;
      data.price = null;

      data.user_id = Utils.getUserInfo().id;
      data.id = dataRowContainer?.id;

      if (data.ids_photo?.length === 0) {
        data.ids_photo = null;
      }
      data.history = arHistory;

      data.date_cvv = getDateSubmitString({ date: data.date_cvv });
      data.date_memory = getDateSubmitString({ date: data.date_memory });
      data.departure_date = getDateSubmitString({ date: data.departure_date });
      data.arrival_date = getDateSubmitString({ date: data.arrival_date });
      data.date_entry = getDateSubmitString({ date: data.date_entry });

      if (onFinishForm) {
        onFinishForm(data);
        form.resetFields();
        setCityData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onFinishCompany = (data: any) => {
    data.short_address = data?.requisites?.requisites?.length
      ? data?.requisites?.requisites?.[10]?.meaning || null
      : null;
    data.contractors_id = data?.id;

    form.setFieldsValue({
      owner: data.name_company,
      owner_id: ownerId,
    });
    dispatch(saveContractors(data));
    setOpenAddCompany(false);
  };

  const handleCloseCvv = () => {
    setOpenCvv(false);
  };

  const handleCloseMemory = () => {
    setOpen(false);
  };

  const onChangeNumContainer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const convertToEng = Utils.convertToEnglishUppercase(event.target.value);
    setNumContainer(convertToEng);
    form.setFieldsValue({ num_container: convertToEng });
  };

  const handleAddOwner = () => {
    setOpenAddCompany(true);
  };

  const handleCompany = () => {
    setOptions([]);
  };

  const onCloseDrawerCompany = () => {
    setOpenAddCompany(false);
  };

  const onTerminalNameChange = async (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const text = evt.target.value?.toLocaleLowerCase();
    if (text?.length > 1) {
      Rest_location.searchLocationName({ text })
        .then((res) => {
          setCityData(res.data);
        })
        .catch(console.error);
    }
  };

  const onBlurNumContainer = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const isValid = Utils.isValidContainerNumber(event.target.value);
    if (!isValid && event.target.value.length !== 11) {
      message.warning("Неверный формат номера контейнера!");
      setDisabledSave(true);
    } else {
      dispatch(findContainers(event.target.value))
        .then((response) => {
          if (typeForm === "update") {
            const obj =
              response.payload.length === 0
                ? arHistory[0].forEach((item: any, index: number) => {
                    if (index === 0) {
                      item.meaning = dataRowContainer?.num_container;
                      item.status_id = dataStatusContainer.find(
                        (item: any) => item.name === "Номер контейнера"
                      )?.id;
                      item.user_id = Utils.getUserInfo().id;
                      item.container_id = dataRowContainer?.id;
                    } else {
                      item.meaning = event.target.value;
                      item.status_id = dataStatusContainer.find(
                        (item: any) => item.name === "Номер контейнера"
                      )?.id;
                      item.user_id = Utils.getUserInfo().id;
                      item.container_id = dataRowContainer?.id;
                    }
                  })
                : arHistory[0].forEach((item: any, index: number) => {
                    if (index === 0) {
                      item.meaning = null;
                    } else {
                      item.meaning = null;
                    }
                  });
            if (response.payload.length > 0) {
              Utils.InfoOpenNotification(
                "topRight",
                "Номер такого контейнера уже есть в базе!",
                5,
                api
              );
            }
            setDisabledSave(false);
          }
          setDisabledSave(false);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const selectTypeContainer = (val: string) => {
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) => item["type_container"] === val
      );
      if (!isFind) {
        arHistory[1].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = dataRowContainer?.type_container;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Тип контейнера"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = val;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Тип контейнера"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[1].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const selectQualityContainer = (val: string) => {
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) => item["quality"] === val
      );
      if (!isFind) {
        arHistory[2].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = dataRowContainer?.quality;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Состояние"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = val;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Состояние"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[2].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const onSelectCompany = (_: string, record: any) => {
    setOwnerId(Number(record.id));
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) => item["owner_id"] === Number(record.id)
      );
      if (!isFind) {
        arHistory[3].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = dataRowContainer?.contractors.name_company;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Собственник"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = record.value;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Собственник"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[3].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const onBlurComment = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) => item["comment"] === event.target.value
      );
      if (!isFind) {
        arHistory[4].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = dataRowContainer?.comment;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Собственник"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning =
              event.target.value === "" ? null : event.target.value;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Собственник"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[4].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const onChangeSelectTerminal = (
    event: any,
    option: { item?: IDataCityChildren },
    parent: IDataCity
  ) => {
    const terminalItem = option?.item || null;
    const terminalSelectName = terminalItem?.label || "";

    form.setFieldsValue({
      location_id: Number(parent?.value),
      terminal_id: Number(terminalItem?.id),
      stock_id: null,
    });

    setTerminal(terminalItem ? [{ ...terminalItem }] : null);
    setTerminalName(terminalSelectName);
    onSearchStock({ id: Number(terminalItem?.id), isNull: !terminalItem?.id });

    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) => item["terminal"] === terminalSelectName
      );
      if (!isFind) {
        arHistory[5].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = dataRowContainer?.child_terminal.name;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Терминал"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = terminalSelectName;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Терминал"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[5].map((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const handleCurrency = (val: string, record: any) => {
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) => item["currency"] === val
      );
      if (!isFind) {
        arHistory[6].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = dataRowContainer?.currency;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Валюта"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = val;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Валюта"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[6].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const onBlurPrice = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;

    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) => item["currency_price"] === val
      );
      if (!isFind) {
        arHistory[7].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = dataRowContainer?.currency_price;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Входящая ставка"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = val;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Входящая ставка"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[7].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const onSelectArrivalDate: DatePickerProps["onChange"] = (
    _,
    dateString: string | string[]
  ) => {
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) =>
          getDateString({
            date: item["arrival_date"],
            formatDate: "DD.MM.YYYY",
          }) === dateString
      );
      if (!isFind) {
        arHistory[8].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = getDateString({
              date: dataRowContainer?.arrival_date,
              formatDate: "DD.MM.YYYY",
            });
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Дата прибытия"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = dateString === "" ? null : dateString;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Дата прибытия"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[8].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const onSelectDepartureDate: DatePickerProps["onChange"] = (
    _,
    dateString: string | string[]
  ) => {
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) =>
          getDateString({
            date: item["departure_date"],
            formatDate: "DD.MM.YYYY",
          }) === dateString
      );
      if (!isFind) {
        arHistory?.[9]?.forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = getDateString({
              date: dataRowContainer?.departure_date,
              formatDate: "DD.MM.YYYY",
            });
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Дата убытия"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = dateString === "" ? null : dateString;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Дата убытия"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[9].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const onSelectDateChangeCvv: DatePickerProps["onChange"] = (_, dateStr) => {
    setDateCvv(dateStr);
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) =>
          getDateString({
            date: item["date_cvv"],
            formatDate: "DD.MM.YYYY",
          }) === dateStr
      );
      if (!isFind) {
        arHistory[10].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = getDateString({
              date: dataRowContainer?.date_cvv,
              formatDate: "DD.MM.YYYY",
            });
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Дата свв"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = dateStr === "" ? null : dateStr;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Дата свв"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[10].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const onSelectDateChange: DatePickerProps["onChange"] = (_, dateStr) => {
    setMemory(dateStr);
    if (typeForm === "update") {
      const isFind = [dataRowContainer].some(
        (item: any) =>
          getDateString({
            date: item["date_memory"],
            formatDate: "DD.MM.YYYY",
          }) === dateStr
      );
      if (!isFind) {
        arHistory[11].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = getDateString({
              date: dataRowContainer?.date_memory,
              formatDate: "DD.MM.YYYY",
            });
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Дата напоминания"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          } else {
            item.meaning = dateStr === "" ? null : dateStr;
            item.status_id = dataStatusContainer.find(
              (item: any) => item.name === "Дата напоминания"
            )?.id;
            item.user_id = Utils.getUserInfo().id;
            item.container_id = dataRowContainer?.id;
          }
        });
      } else {
        arHistory[11].forEach((item: any, index: number) => {
          if (index === 0) {
            item.meaning = null;
          } else {
            item.meaning = null;
          }
        });
      }
    }
  };

  const onSearchStock = ({
    text,
    id,
    isNull,
  }: {
    text?: string;
    id?: number;
    isNull: boolean;
  }) => {
    if (!isNull) {
      Rest_terminal.findStock({ text, id })
        .then((res) => {
          const data = res?.data;
          setOptionsStock(
            mapperSelect({ items: data, keys: { label: "label", value: "id" } })
          );
        })
        .catch((err) => {
          console.error(err);
          setOptionsStock([]);
        });
    } else {
      form.setFieldValue("stock", null);
      form.setFieldValue("stock_id", null);
      setOptionsStock([]);
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        className="page-container"
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        form={form}
        name="getValueProps"
      >
        <Flex justify={"space-between"} className={"drawer-header"}>
          <Flex vertical style={{ width: "100%" }} gap={16}>
            <Flex justify="space-between" style={{ width: "100%" }}>
              <div className={"drawer-title"}>{title}</div>
              <Flex gap={5} align={"center"}>
                <IconButton
                  hidden={!onView || !dataRowContainer?.id}
                  disabled={!dataRowContainer?.id}
                  iType="view-form"
                  onClick={onView}
                />
                {!!dataRowContainer?.id && isCopy && (
                  <CopyButton
                    text={link}
                    textSuccess={t("Ссылка скопирована")}
                    icon={<Icon component={CopyBufferIconDefault} />}
                  />
                )}
              </Flex>
            </Flex>
            <Flex gap={24} vertical>
              <Flex
                className={"container-photo all-custom-v-scroll"}
                style={{ padding: "12px" }}
              >
                <Form.Item name="ids_photo" noStyle />
                <UploaderPreview
                  isOpen={isOpen}
                  ids={ids_photo}
                  multiple
                  setDataIds={(files) => {
                    form.setFieldValue("ids_photo", files);
                  }}
                  handlerSaveFiles={(files) => {
                    setSaveFiles(files);
                  }}
                />
              </Flex>
              <Row gutter={[14, 10]} align="middle">
                <Col span={16}>
                  <Form.Item name={"photo_bool"}>
                    <Input placeholder={t("Ссылка на фото")} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  {photo_bool && isValidHttpUrl(photo_bool) && (
                    <Typography.Link href={photo_bool} target="_blank">
                      {t("Ссылка на фото")}
                    </Typography.Link>
                  )}
                </Col>
              </Row>
              <Row gutter={[12, 8]}>
                <Col span={8}>
                  <Form.Item
                    name={"num_container"}
                    rules={[
                      {
                        required: !openAddCompany,
                        message: t("Обязательное поле!"),
                        whitespace: true,
                      },
                      {
                        required: !openAddCompany,
                        message: t("Неверный формат номера контейнера"),
                        whitespace: true,
                        validator: (_, val) => {
                          if (!val?.trim()) {
                            return Promise.resolve();
                          }
                          if (regNumberKTK.test(val)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject();
                          }
                        },
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("Номер контейнера")}
                      style={{ width: "100%" }}
                      allowClear
                      maxLength={11}
                      onBlur={onBlurNumContainer}
                      value={numContainer}
                      onChange={onChangeNumContainer}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={"type_container"}
                    rules={[
                      {
                        required: !openAddCompany,
                        message: t("Обязательное поле!"),
                      },
                    ]}
                  >
                    <Select
                      disabled={!!dataRowContainer?.release}
                      allowClear
                      options={dataTypeContainers}
                      onSelect={selectTypeContainer}
                      placeholder={t("Тип контейнера")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={"quality"}
                    rules={[
                      {
                        required: !openAddCompany,
                        message: t("Обязательное поле!"),
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      options={dataTypeQuality}
                      onSelect={selectQualityContainer}
                      placeholder={t("Состояние")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item
                    name={"owner"}
                    rules={[
                      {
                        required: !openAddCompany,
                        message: t("Обязательное поле!"),
                      },
                    ]}
                  >
                    <AutoComplete
                      allowClear
                      options={options}
                      style={{ width: "100%" }}
                      onChange={handleCompany}
                      onSelect={onSelectCompany}
                      onSearch={(text: string) => getFindCompany(text)}
                      placeholder={t("Собственник")}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Button
                    type={"text"}
                    style={{
                      border: "2px solid #05F",
                      width: "100%",
                    }}
                    onClick={handleAddOwner}
                  >
                    Добавить
                  </Button>
                </Col>
              </Row>
            </Flex>
            <Form.Item name="comment">
              <Input.TextArea
                allowClear
                placeholder={t("Комментарий")}
                autoSize={{ minRows: 4, maxRows: 20 }}
                onBlur={onBlurComment}
              />
            </Form.Item>
            <Row>
              <Col span={24}>
                <Form.Item name={"terminal"} noStyle />
                <Form.Item name={"terminal_id"} noStyle />
                <Form.Item name={"location_id"} noStyle />
                <SelectSearch
                  type
                  disabled={!!dataRowContainer?.release}
                  defaults={terminalName}
                  dataSource={cityData?.map((el) => ({
                    ...el,
                    selectable: true,
                  }))}
                  onChangeSelect={onChangeSelectTerminal}
                  dropdownRender={onTerminalNameChange}
                  placeholder={t("Терминал")}
                  style={{ width: "100%" }}
                  hiddenBtn={false}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item name={"currency"}>
                  <Select
                    style={{ width: "100%" }}
                    onChange={handleCurrency}
                    placeholder={t("Валюта")}
                    allowClear
                  >
                    {dataCurrency?.map((item: ICurrency) => (
                      <Select.Option key={item.id} value={item.symbol}>
                        <Flex align={"center"} gap={5}>
                          {icons[item.id]}
                          {item.symbol} {item.value}
                        </Flex>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={"currency_price"}>
                  <FormInputNumber
                    min={MIN_CURRENCY_PRICE}
                    style={{ width: "100%" }}
                    allowClear
                    placeholder={t("Входящая ставка")}
                    onBlur={onBlurPrice}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  name={"arrival_date"}
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : null,
                  })}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="DD.MM.YYYY"
                    placeholder={t("Дата прибытия")}
                    disabledDate={
                      departure_date
                        ? disableDateAfterCurrent(departure_date)
                        : undefined
                    }
                    onChange={onSelectArrivalDate}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={"departure_date"}
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : null,
                  })}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format="DD.MM.YYYY"
                    placeholder={t("Дата убытия")}
                    disabledDate={
                      arrival_date ? disableDateBefore(arrival_date) : undefined
                    }
                    onChange={onSelectDepartureDate}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className={"cvv-box"} gutter={16}>
              <Col span={12} className={"cvv-container"}>
                <Flex gap={6}>
                  <Text className={"cvv-text"}>СВВ</Text>
                  <IconButton
                    iType="delete"
                    style={{ border: "none" }}
                    onClick={() => {
                      setDateCvv(null);
                      setMemory(null);
                      form.setFieldsValue({
                        date_cvv: null,
                        date_memory: null,
                      });
                    }}
                  />
                </Flex>
                <Flex style={{ width: "100%" }} gap={8}>
                  <div style={{ width: "25%" }}>
                    <Link onClick={() => setOpenCvv(!openCvv)}>
                      <div className={"cvv-text--1"}>
                        {dateCvv || t("Нет даты")}
                      </div>
                    </Link>
                    <Form.Item
                      className={"cvv-text--1"}
                      name={"date_cvv"}
                      getValueProps={(value) => ({
                        value: value ? dayjs(value) : null,
                      })}
                    >
                      <DatePicker
                        open={openCvv}
                        format="DD.MM.YYYY"
                        onOpenChange={handleCloseCvv}
                        style={{ visibility: "hidden" }}
                        onChange={onSelectDateChangeCvv}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ width: "75%" }}>
                    <Link onClick={() => setOpen(!open)}>
                      <div className={"cvv-text--2"}>
                        Напомнить {dateMemory}
                      </div>
                    </Link>
                    <Form.Item
                      className={"cvv-text--2"}
                      name={"date_memory"}
                      getValueProps={(value) => ({
                        value: value ? dayjs(value) : null,
                      })}
                    >
                      <DatePicker
                        open={open}
                        format="DD.MM.YYYY"
                        onOpenChange={handleCloseMemory}
                        style={{ visibility: "hidden" }}
                        onChange={onSelectDateChange}
                      />
                    </Form.Item>
                  </div>
                </Flex>
              </Col>
              <Col span={12}>
                <Form.Item name={"stock"} noStyle />
                <Form.Item name={"stock_id"} className={"cvv-text--2"}>
                  <Select
                    showSearch
                    disabled={!terminalName || !optionsStock?.length}
                    allowClear
                    options={optionsStock}
                    filterOption={filterSelectSearch}
                    onSelect={(val, opt) => {
                      form.setFieldValue("stock", opt?.label ?? null);
                    }}
                    optionLabelProp="label"
                    placeholder={"Сток"}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {!dataRowContainer?.id && (
                <Typography.Link
                  className={"text-import"}
                  onClick={handleShowModal}
                >
                  {t("Загрузить из Excel")}
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    type="file"
                    id="fileSelect"
                    accept=".xlsx, .xls, .csv"
                    onChange={handleFileChangeImport}
                  />
                </Typography.Link>
              )}
            </Row>
            <Flex gap={8} align={"flex-end"}>
              <Button
                type={"primary"}
                htmlType={"submit"}
                style={{ width: "70%" }}
                disabled={disabledSave}
              >
                {typeForm === CREATE ? "Добавить" : "Сохранить изменения"}
              </Button>
              <Button
                type={"text"}
                style={{ width: "30%", color: "#E14453" }}
                onClick={() => setConfirmation(true)}
              >
                Отменить
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Form>

      <CustomDrawer open={openAddCompany} onClose={onCloseDrawerCompany}>
        <FormEditContractors
          isOpen={openAddCompany}
          form={formContractors}
          initialValue={defaultValueContractor}
          onCloseEdit={onCloseDrawerCompany}
          title={"Новая компания"}
          onFinishEdit={onFinishCompany}
        />
      </CustomDrawer>
      <Modal
        className="page-container"
        closable={false}
        footer={null}
        width={"94%"}
        open={isModalOpenImport}
      >
        <FormModalImport
          closeModalImport={closeModalImport}
          loadings={loadings}
          setLoadings={setLoadings}
          onFinish={onFinishImport}
          crmColumns={columns}
        />
      </Modal>
      <ModalConfirm
        title={warningTitle}
        subtitle={subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={openAddCompany ? onCloseDrawerCompany : onClose}
      />
    </>
  );
};

export default FormContainer;
