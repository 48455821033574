import React, { useEffect, useState } from 'react';
import { Divider, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import classnames from 'classnames';
import styles from './style.module.scss';

interface Props {
  className?: string;
  children: any;
  setLastItemReached?: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  lastItemReached?: boolean;
  totalTableActivity: number;
  data: any;
  paginationActivity: () => void;
  tableRef: any;
  height?: number;
  isTable?: boolean;
  endMessage?: string | React.ReactNode,
  isCustomScrollY?: boolean
}

const Index: React.FC<Props> = ({
  children,
  setLastItemReached,
  lastItemReached,
  totalTableActivity,
  data,
  tableRef,
  paginationActivity,
  height,
  isCustomScrollY,
  isTable = true,
  endMessage = 'Конец списка',
  className = '',
}) => {
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    if (lastItemReached) {
      paginationActivity();
    }
  }, [lastItemReached]);

  const handleScroll = (e: any) => {
    const { scrollTop, scrollHeight, clientHeight } =
      e.target as HTMLDivElement;
    if (scrollHeight - scrollTop <= clientHeight + 1) {
      if (!lastItemReached && setLastItemReached) {
        // Проверяем, не был ли уже вызван
        setLastItemReached(true); // Устанавливаем флаг, когда достигнут конец
      }
      const lastItem = scrollHeight - clientHeight - 1; // учитываем скрытые элементы

      // Проверяем направление прокрутки
      if (scrollTop > lastScrollTop) {
        // Прокрутка вниз
        if (setLastItemReached) {
          setLastItemReached(scrollTop + clientHeight >= lastItem);
        }
      } else {
        // Прокрутка вверх
        if (setLastItemReached) {
          setLastItemReached(false); // Сбрасываем флаг при прокрутке вверх
        }
      }

      setLastScrollTop(scrollTop); // Обновляем предыдущую позицию скролла
    }
  };

  useEffect(() => {
    if (isCustomScrollY) {
      setLastScrollTop(0)
    }
  }, [isCustomScrollY])

  return isTable ? (
    <div className="box-container-active">
      <div className="table-title label-card-20">Активность</div>
      <div className="table-header">
        <div className={'label-card-16'}>Дата</div>
        <div className={'label-card-16'}>Статус</div>
        <div className={'label-card-16'}>Ответственный</div>
      </div>
      <div
        id="scrollableDiv"
        ref={tableRef}
        className={classnames(styles.scrollableDiv, 'scrollableDiv')}
        style={{ height: height + 'px' }}
        onScroll={(e) => handleScroll(e)}>
        <InfiniteScroll
          dataLength={data.length}
          next={paginationActivity}
          hasMore={data.length < totalTableActivity} // Замените на общее количество элементов
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={
            data.length > 0 && (
              <Divider style={{ marginTop: 5 }} plain>
                {endMessage}
              </Divider>
            )
          }>
          {children}
        </InfiniteScroll>
      </div>
    </div>
  ) : (
    <div
      id="scrollableDiv"
      ref={tableRef}
      className={classnames(styles.scrollableDiv, 'scrollableDiv', {[className]: className})}
      style={{ height: height + 'px', overflow: 'auto', }}
      onScroll={(e) => handleScroll(e)}>
      <InfiniteScroll
        dataLength={data.length}
        next={paginationActivity}
        hasMore={data.length < totalTableActivity} // Замените на общее количество элементов
        loader={<Skeleton paragraph={{ rows: 1 }} active />}
        endMessage={
          data.length > 0 && (endMessage)
        }>
        {children}
      </InfiniteScroll>
    </div>
  );
};

export default Index;
