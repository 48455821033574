import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AutoComplete, Button, Flex, Input, Space } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import { RouteNames } from "../../../router/names";
import { RootState, useStoreDispatch } from "../../../store";
import { useBreadcrumb, useMenu, useParamsHistory } from "../../../hooks";
import { Table } from "../../../components/ui/Table/Table";
import {
  IDeal,
  setOneDeal,
  defaultValuesDeal,
  deleteIdsDeal,
  resetStoreRent,
} from "../../../store/rent";
import { columns } from "./columns";
import { Rest_rent } from "../../../services/rest_rent";
import CustomDrawer from "../../../components/CustomDrawer/CustomDrawer";
import { FormDeal } from "./components";
import { CREATE, EDIT, VIEW } from "../../../helpers/string-helpers";
import { filterResultKtk, mapperDealsRent } from "./utils";
import { RELEASE, RENT_DEAL } from "../../../constants";
import { useGettingDataForTables } from "../../../hooks/useGettingDataForTables/useGettingDataForTables";
import {
  hasViewPermission,
  hasEditPermission,
  hasDeletePermission,
} from "../../../components/Menu/permissions";

const Deals = () => {
  const { t } = useTranslation();
  const [activeButtons, setActiveButtons] = useState<any>({});
  const [searchColumn, setSearchColumn] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const { location, history } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const dealOneData = useSelector<RootState>(
    (state) => state.rent.dealOneData
  ) as IDeal;
  const [typeForm, setTypeForm] = useState<string>(CREATE);
  const [openDrawerDeal, setOpenDrawerDeal] = useState<boolean>(false);

  //==============================================
  // подключаемся к хранилищу данных с правами
  //==============================================
  const permissionsData: any = useSelector(
    (state: RootState) => state.users.permissionsData
  );

  /// получаем данные только по запросу (не все)
  // через утилиту hasView/Edit/DeletePermission
  const canView = hasViewPermission(permissionsData, "rentDeals");
  const canEdit = hasEditPermission(permissionsData, "rentDeals");
  const canDelete = hasDeletePermission(permissionsData, "rentDeals");

  const { dataTable, optionsTable, updateEffectDate, setLoadingSubmit } =
    useGettingDataForTables({
      titlePage: "сделка аренды",
      keyItemName: "id_deal",
      subscribeStringCreateForm: "return-set-deal-rent",
      subscribeStringUpdateForm: "return-update-deal-rent",
      subscribeStringDelete: "return-delete-deal-rent",
      subscribeError: "error",
      table: RENT_DEAL,
      location,
      columns,
      mapperData: (arr) => arr.map(mapperDealsRent),
      fetchTables: Rest_rent.getDeals,
      fetchDelete: (arg: any) => dispatch(deleteIdsDeal(arg)),
    });

  useBreadcrumb(["Аренда", "Сделки"]);
  useMenu({
    openKey: RouteNames.RENT,
    activeKeys: [RouteNames.RENT, RouteNames.RENT_DEAL],
  });

  useEffect(() => {
    if (pathname === RouteNames.RENT_DEAL) {
      dispatch(resetStoreRent());
      updateEffectDate();
      const rent = location?.state?.[RENT_DEAL];
      if (rent) {
        window.history.replaceState({ state: null }, document.title);
        if (rent?.edit) {
          dispatch(setOneDeal(rent.edit));
          setTypeForm(EDIT);
          setOpenDrawerDeal(true);
          return;
        }
        setTypeForm(CREATE);
        dispatch(
          setOneDeal({
            ...defaultValuesDeal,
            ...rent,
            containers: filterResultKtk(rent?.containers),
            delete: false,
            edit: true,
          })
        );

        setOpenDrawerDeal(true);
        return;
      }
    }
    return history.replace(RouteNames.RENT_DEAL);
  }, [pathname]);

  const onCloseFormDeal = (res?: boolean) => {
    setTypeForm(CREATE);
    dispatch(resetStoreRent());
    setOpenDrawerDeal(false);
    setLoadingSubmit(!!res);
  };
  const onClose = () => {
    setTypeForm(CREATE);
    onCloseFormDeal(false);
  };

  const onRelease = () => {
    history.push(RouteNames.RELEASES, { [RELEASE]: {} });
  };

  const handleButtonClickSearch = (option: any) => {
    setActiveButtons((prevActiveButtons: any) => {
      setSearchColumn((prev) =>
        prevActiveButtons[option.key]
          ? prev.filter((item: string) => item !== option.dataIndex)
          : [...prev, option.dataIndex]
      );
      return {
        ...prevActiveButtons,
        [option.key]: !prevActiveButtons[option.key],
      };
    });
  };

  const handleSearch = (val: string) => {
    setSearchValue(val);
  };

  const handleShowDrawerDeal = () => {
    setTypeForm(CREATE);
    dispatch(setOneDeal(defaultValuesDeal));
    setOpenDrawerDeal(true);
  };

  const onRow = (data: IDeal) => {
    const row = data;
    if (row?.id) {
      setTypeForm(VIEW);
      Rest_rent.findOneDeal(row.id)
        .then((res) => {
          dispatch(setOneDeal(mapperDealsRent(res.data)));
          setOpenDrawerDeal(true);
        })
        .catch(() => {
          dispatch(setOneDeal(row));
          setOpenDrawerDeal(true);
        });
    }
  };

  const handleShowEditForm = (data: IDeal) => {
    const row = data;
    if (row?.id) {
      setTypeForm(EDIT);
      Rest_rent.findOneDeal(row.id)
        .then((res) => {
          dispatch(setOneDeal(mapperDealsRent(res.data)));
          setOpenDrawerDeal(true);
        })
        .catch(() => {
          dispatch(setOneDeal(row));
          setOpenDrawerDeal(true);
        });
    }
  };

  if (!canView) {
    return (
      <div style={{ padding: 20, fontSize: 16, textAlign: "center" }}>
        {t("У вас нет прав на просмотр данной страницы")}
      </div>
    );
  }

  return (
    <>
      <Flex
        gap={20}
        justify={"space-between"}
        align={"flex-start"}
        style={{ width: "100%" }}
        className={"container"}
      >
        <Flex align={"center"} gap={10}>
          <div className={"drawer-title"}>{t("Сделки")}</div>
        </Flex>
        <Flex gap={16}>
          {canEdit && (
            <Button
              icon={<PlusOutlined />}
              style={{ background: "#05F", color: "#fff", minWidth: "32px" }}
              onClick={handleShowDrawerDeal}
            />
          )}
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            options={columns}
            defaultValue={searchValue}
            popupMatchSelectWidth={false}
            onSearch={handleSearch}
            dropdownRender={() => (
              <>
                <div
                  className={"text-card-12"}
                  style={{ padding: "10px 10px" }}
                >
                  {t("Выбор колонок по которым будет производиться поиск")}
                </div>
                <Flex
                  gap={5}
                  style={{ width: 300, flexWrap: "wrap", padding: 10 }}
                >
                  {columns.map(
                    (option) =>
                      !!option.title && (
                        <Button
                          size={"small"}
                          key={option.key}
                          type={
                            activeButtons[Number(option.key)]
                              ? "default"
                              : "primary"
                          }
                          onClick={() => handleButtonClickSearch(option)}
                        >
                          {option.title}
                        </Button>
                      )
                  )}
                </Flex>
              </>
            )}
          >
            <Input placeholder={t("Введите текст")} />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Table
          {...optionsTable}
          editRow={handleShowEditForm}
          dataSource={dataTable}
          height={"calc(-162px + 100vh)"}
          onRow={onRow}
          onClickLink={onRow}
          canView={canView}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      </Space>
      <CustomDrawer
        open={openDrawerDeal}
        onClose={onClose}
        isHeight
        minWidth={1000}
      >
        <FormDeal
          // typeForm={typeForm}
          isCopy
          title={
            typeForm === CREATE ? "Создать сделку" : "Редактировать сделку"
          }
          initialValue={dealOneData}
          isOpen={openDrawerDeal}
          onClose={onCloseFormDeal}
          onRelease={onRelease}
          onEdit={() => setTypeForm(EDIT)}
          onView={() => setTypeForm(VIEW)}
          disabled={
            typeForm === VIEW || !!dealOneData?.delete || !dealOneData.edit
          }
          canEdit={canEdit}
        />
      </CustomDrawer>
    </>
  );
};

export default Deals;
