import React, { ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  TableProps,
  Table,
  FormListFieldData,
  Form,
  Input,
  Button,
  Typography,
  Select,
  TablePaginationConfig,
  FormListOperation,
  AutoComplete,
  TreeSelect,
} from 'antd';
import style from './style.module.scss';
import { EditIcon } from '../../../../assets/image/svg';
import { ColumnType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import FormDatePicker from '../FormDatePicker/FormDatePicker';
import IconButton from '../../IconButton';
import { delay } from 'lodash';
import dayjs from 'dayjs';
import { getDateString } from '../../../../helpers/date-helpers';
import FormInputNumber from '../FomInputNumber/FormInputNumber';
import { SelectSearch } from '../../SelectSearch/SelectSearch';

type ICellComponentForm =
  | 'select'
  | 'input'
  | 'number'
  | 'date'
  | 'search'
  | 'select-search'
  | 'search-tree';

export interface IColumnsEditTable extends ColumnType<any> {
  title: React.ReactNode;
  dataIndex: any;
  type?: ICellComponentForm;
  source?: any[]; // список
  placeholder?: string;
  addKey?: string; // дополнительный ключ
  selectKey?: string; // дополнительный ключ
  keyDefault?: string; // дефолтное значение для selectSearch
  connectionKey?: string;
  onHandler?: (evt: any, index: any) => void;
  isField: boolean; // чтобы убрать отслеживание
  noFocus?: boolean; // открыть сразу
  isDelete?: boolean; // удалять
  propsOptions?: any; // какие то атрибуты в компонент
  apiSearch?: (text: string, index: any) => Promise<any>; // запрос для списка
  mapperList?: (prom: any[]) => any[]; // для списка
  mapperRes?: (res: any) => any; // для разных запросов
  noStyleList?: string[]; // доп поля
}

interface IFormTable extends TableProps {
  label?: string;
  minHeight?: string;
  className?: string;
  classTitle?: string;
  name: string;
  defaultColumns: IColumnsEditTable[];
  pagination?: false | TablePaginationConfig;
  isLoading: boolean;
  noStyleList?: string[];
}

interface EditTableCellProps {
  title: React.ReactNode;
  dataIndex: any;
  record: any;
  type?: ICellComponentForm;
  index: any;
  source?: any[];
  noFocus?: boolean;
  isField: boolean;
  isDelete?: boolean;
  placeholder?: string;
  addKey?: string;
  selectKey?: string;
  keyDefault?: string;
  connectionKey?: string;
  nameList: string;
  propsOptions?: any; // какие то атрибуты в компонент
  apiSearch?: (text: string, index: any) => Promise<any>; // запрос для списка
  mapperList?: (prom: any[]) => any[]; // для списка
  mapperRes?: (res: any) => any; // для разных запросов
  operation: FormListOperation;
  onHandler?: (evt: any, index: any) => void;
  setLoading: (bol: any) => void;
  fields: FormListFieldData[];
  data: any[];
  render?: (value: any, record: any, index: number) => ReactNode;
  noStyleList?: string[];
}

const EditableCell: React.FC<React.PropsWithChildren<EditTableCellProps>> = ({
  title,
  children,
  dataIndex,
  record,
  type,
  index,
  source,
  placeholder,
  noFocus,
  isDelete,
  nameList,
  apiSearch,
  mapperList,
  onHandler,
  isField,
  addKey,
  selectKey,
  connectionKey,
  fields,
  data,
  render,
  setLoading,
  operation,
  noStyleList,
  keyDefault,
  mapperRes = (res) => res?.payload,
  propsOptions = {},
  ...restProps
}) => {
  const form = Form.useFormInstance();
  const addItem: any = Form.useWatch([nameList, fields?.[index]?.name], {
    form,
    preserve: true,
  });

  const [dataSearch, setDataSearch] = useState<any>([]);
  const [editCell, setEditCell] = useState<any>(null);
  const [isFind, setIsFind] = useState<boolean>(false);
  const [searchName, setSearchName] = useState<string | null>(null);

  useEffect(() => {
    if (index + 1 === data?.length && setLoading) {
      setLoading(false);
    }
  }, [index]);

  useEffect(() => {
    if (addKey) {
      if (!addItem?.[dataIndex] && dataSearch?.length) {
        const result = {
          ...addItem,
        };
        if (addKey) {
          result[addKey] = null;
        }
        form.setFieldValue([nameList, fields[index].name], result);
      }
    }
  }, [addItem?.[dataIndex], index]);
  useEffect(() => {
    if (connectionKey) {
      if (addItem?.[connectionKey] && !dataSearch?.length) {
        setDataSearch(addItem[connectionKey]);
      }
    }
  }, [index, connectionKey]);

  useEffect(() => {
    if (type === 'select-search' && keyDefault && addItem) {

      if (addItem?.[keyDefault]) {
        setSearchName(addItem[keyDefault] ?? '');
      }
    }
  }, [index, type, keyDefault, addItem]);

  if (!fields) return null;

  const toggleEditContainer = (index: any) => {
    setEditCell(index);
  };

  const isType =
    typeof type === 'string'
      ? [
          'input',
          'number',
          'select',
          'date',
          'search',
          'search-tree',
        ].includes(type)
      : null;


   const onChangeSelectSearch = (event: any, option: {item?: {id: number}}, parent: {value: string | number}) => {
    const item = option?.item || null;
    const result = { ...addItem };

    result[dataIndex] = Number(item?.id);
    if (addKey) {
      result[addKey] = Number(parent?.value);
    }
    form.setFieldValue([nameList, fields[index].name], result);
   }

   const onSelectSearchNameChange = (evt: any) => {
    const text = evt.target.value?.toLocaleLowerCase();
    if (apiSearch) {
      if (text?.length) {
        delay(() => {
          apiSearch(text.toLocaleLowerCase(), index)
            .then((res: any) => {
              const response = mapperRes(res);
              setDataSearch(mapperList ? mapperList(response) : response);
            })
            .catch(console.error);
        }, 1000);
      }
    }
   }

  const onSelectSearch = (value: string, record: any) => {
    const result = { ...addItem };

    result[dataIndex] = value;
    if (addKey) {
      result[addKey] = record?.id;
    }
    form.setFieldValue([nameList, fields[index].name], result);
  };

  const handleSearch = (text: string) => {
    setIsFind(true);
    if (apiSearch) {
      if (text?.length) {
        delay(() => {
          apiSearch(text.toLocaleLowerCase(), index)
            .then((res: any) => {
              const response = mapperRes(res);
              setDataSearch(mapperList ? mapperList(response) : response);
            })
            .catch(console.error);
        }, 1000);
      }
    }
  };

  const handleSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    if (addItem?.[dataIndex] && !dataSearch?.length && !isFind) return;
    const item = dataSearch?.find(
      (data: any) => data?.value === evt.target.value,
    );
    if (!item) {
      const result = { ...addItem };
      result[dataIndex] = null;
      if (addKey) {
        result[addKey] = null;
      }
      form.setFieldValue([nameList, fields[index].name], result);
    }
    setIsFind(false);
  };

  const onSelectTreeSearch = (value: string, record: any) => {
    const result = { ...addItem };

    result[dataIndex] = value;
    if (addKey) {
      result[addKey] = record?.title;
    }
    form.setFieldValue([nameList, fields[index].name], result);
  };

  const handleTreeSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    if (addItem?.[dataIndex] && !source?.length && !isFind) return;
    const item = source?.find((data: any) =>
      data?.children.find((el: any) => el.value === addItem?.[dataIndex]),
    );
    if (!item) {
      const result = { ...addItem };
      result[dataIndex] = null;
      if (addKey) {
        result[addKey] = null;
      }
      form.setFieldValue([nameList, fields[index].name], result);
    }
    setIsFind(false);
  };

  const onSelect = (value: any, opt: any) => {
    const result = { ...addItem };
    if (selectKey) {
      result[dataIndex] = opt?.[selectKey];
    }
    form.setFieldValue([nameList, fields[index].name], result);
  }

  return (
    <td {...restProps}>
      {addKey && <Form.Item name={[fields[index].name, addKey]} noStyle />}
      {!!noStyleList?.length &&
        noStyleList.map((el: string, i: number) => (
          <Form.Item key={i} name={[fields[index].name, el]} noStyle />
        ))}
      {dataIndex === 'index' && (
        <Typography.Text style={{ whiteSpace: 'nowrap' }}>
          {index + 1}
        </Typography.Text>
      )}
      {isDelete && (
        <IconButton
          className={style['action-delete']}
          iType="delete"
          onClick={() => operation.remove(fields[index].name)}
          style={{ padding: '4px' }}
        />
      )}
      {isType ? (
        editCell === `${fields[index].name}${dataIndex}` || !!noFocus ? (
          <Form.Item
            name={[fields[index].name, dataIndex]}
            getValueProps={(value) => ({
              value: value ? (type === 'date' ? dayjs(value) : value) : null,
            })}
          >
            {type === 'date' && (
              <FormDatePicker
                placeholder={placeholder}
                {...propsOptions}
                handlerChange={(str, data) => {
                  const result = { ...addItem };
                  result[dataIndex] = data;
                  form.setFieldValue([nameList, fields[index].name], result);
                  toggleEditContainer(null);
                }}
              />
            )}
            {type === 'input' && (
              <Input
                autoFocus
                allowClear
                placeholder={placeholder}
                style={{ width: '100%' }}
                {...propsOptions}
                onBlur={() => toggleEditContainer(null)}
              />
            )}
            {type === 'number' && (
              <FormInputNumber
                allowClear
                autoFocus
                placeholder={placeholder}
                style={{ width: '100%' }}
                {...propsOptions}
                onBlur={() => toggleEditContainer(null)}
              />
            )}
            {type === 'select' && (
              <Select
                allowClear
                optionLabelProp="label"
                style={{ width: '100%' }}
                {...propsOptions}
                placeholder={placeholder}
                options={source}
                onSelect={(...props) => {
                  const [id, opt] = props;
                  onSelect(id, opt);
                  if (onHandler) {
                    onHandler(props, index);
                  }
                }}
                onBlur={() => toggleEditContainer(null)}
              />
            )}
            {type === 'search-tree' && (
              <TreeSelect
                allowClear
                showSearch
                style={{ width: '100%' }}
                treeNodeFilterProp="title"
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                {...propsOptions}
                placeholder={placeholder}
                treeLine
                treeData={source}
                treeDefaultExpandAll
                onBlur={(evt: any) => {
                  handleTreeSearchBlur(evt);
                  toggleEditContainer(null);
                }}
                onSelect={(...props) => {
                  onSelectTreeSearch(...props);
                  if (onHandler) {
                    onHandler(props, index);
                  }
                }}
              />
            )}
            {type === 'search' && (
              <AutoComplete
                popupClassName="certain-category-search-dropdown"
                allowClear
                showSearch
                options={dataSearch}
                style={{ width: '100%' }}
                {...propsOptions}
                onSearch={handleSearch}
                onBlur={(evt: any) => {
                  handleSearchBlur(evt);
                  toggleEditContainer(null);
                }}
                onSelect={(...props) => {
                  onSelectSearch(...props);
                  if (onHandler) {
                    onHandler(props, index);
                  }
                }}
                placeholder={placeholder}
              />
            )}
          </Form.Item>
        ) : (
          <Button
            style={{
              paddingLeft: '10px',
              paddingRight: '5px',
              width: '100%',
              minWidth: '120px',
              justifyContent: 'space-between',
            }}
            icon={<EditIcon />}
            iconPosition="end"
            onClick={() =>
              toggleEditContainer(`${fields[index].name}${dataIndex}`)
            }
          >
            <Typography.Text
              ellipsis={{
                tooltip: {
                  placement: 'top',
                  title:
                    type === 'date'
                      ? getDateString({ date: data?.[index]?.[dataIndex] })
                      : data?.[index]?.[dataIndex],
                },
              }}
            >
              {type === 'date'
                ? getDateString({ date: data?.[index]?.[dataIndex] })
                : data?.[index]?.[dataIndex]}
            </Typography.Text>
          </Button>
        )
      ) : render ? (
        render(data?.[index]?.[dataIndex], data?.[index], index)
      ) : type === 'select-search' ? (
          <SelectSearch
            type
            defaults={searchName}
            dataSource={dataSearch}
            onChangeSelect={onChangeSelectSearch}
            dropdownRender={onSelectSearchNameChange}
            onBlur={(evt: any) => {
              toggleEditContainer(null);
            }}
            placeholder={placeholder}
            style={{ width: '100%' }}
            hiddenBtn={false}
            {...propsOptions}
          />
        )
       : (
        <Typography.Text
          ellipsis={{
            tooltip: {
              placement: 'top',
              title: data?.[index]?.[dataIndex],
            },
          }}
        >
          {data?.[index]?.[dataIndex]}
        </Typography.Text>
      )}
    </td>
  );
};

const EditTableRow: React.FC = (props: { [key: string]: any }) => (
  <tr {...props} key={props?.className} />
);

const FormTable: React.FC<IFormTable> = ({
  name,
  defaultColumns,
  label = '',
  minHeight = 'none',
  className = '',
  classTitle = '',
  pagination = false,
  isLoading = false,
  noStyleList = [],
  ...props
}) => {

  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const data = Form.useWatch(name, { form });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(isLoading);
    delay(() => {
      if (!data?.length) {
        setLoading(false);
      }
    }, 3000);
  }, [isLoading]);

 // console.log(defaultColumns)

  return (
    <Form.List name={name}>
      {(fields: FormListFieldData[], operation, meta: { errors: any }) => (
        <div
          className={classnames(style.container, { [className]: className })}
          style={{ minHeight }}
        >
          {label && (
            <Typography.Text
              className={classnames(style.label, { [classTitle]: classTitle })}
            >
              {t(label)}
            </Typography.Text>
          )}
          <Table
            {...props}
            loading={loading}
            dataSource={fields}
            columns={defaultColumns?.map((col: any) => {
              return {
                ...col,
                onCell: (record: any, index: any) => ({
                  record,
                  dataIndex: col?.dataIndex,
                  type: col.type,
                  source: col?.source,
                  placeholder: col?.placeholder,
                  link: col?.link,
                  onHandler: col?.onHandler,
                  noFocus: col?.noFocus,
                  propsOptions: col?.propsOptions,
                  isDelete: col?.isDelete,
                  isField: col.isDelete,
                  apiSearch: col?.apiSearch,
                  mapperList: col?.mapperList,
                  mapperRes: col?.mapperRes,
                  addKey: col?.addKey,
                  selectKey: col?.selectKey,
                  keyDefault: col?.keyDefault,
                  connectionKey: col?.connectionKey,
                  noStyleList,
                  nameList: name,
                  operation,
                  index,
                  fields,
                  data,
                  setLoading,
                  render: col?.render,
                }),
              };
            })}
            components={{
              body: {
                row: EditTableRow,
                cell: EditableCell,
              },
            }}
            pagination={pagination}
          />
        </div>
      )}
    </Form.List>
  );
};

export default FormTable;
