import { IPermissionsData } from "../../store/users";

// export function hasViewPermission(
//   perms: IPermissionsData | null,
//   entity: string
// ): boolean {
//   if (!perms) return false;
//   return Boolean(
//     perms[`${entity}.status_view`] || perms[`${entity}.group_status_view`]
//   );
// }

// // Аналогичные вспомогательные функции можно сделать для edit / delete
// export function hasEditPermission(
//   perms: IPermissionsData | null,
//   entity: string
// ): boolean {
//   if (!perms) return false;
//   return Boolean(
//     perms[`${entity}.status_edit`] || perms[`${entity}.group_status_edit`]
//   );
// }

// export function hasDeletePermission(
//   perms: IPermissionsData | null,
//   entity: string
// ): boolean {
//   if (!perms) return false;
//   return Boolean(
//     perms[`${entity}.status_delete`] || perms[`${entity}.group_status_delete`]
//   );
// }

export function hasViewPermission(
  perms: IPermissionsData,
  entity: string
): boolean {
  return !!(
    perms?.[`${entity}.status_view`] || perms?.[`${entity}.group_status_view`]
  );
}

export function hasEditPermission(
  perms: IPermissionsData,
  entity: string
): boolean {
  return !!(
    perms?.[`${entity}.status_edit`] || perms?.[`${entity}.group_status_edit`]
  );
}

export function hasDeletePermission(
  perms: IPermissionsData,
  entity: string
): boolean {
  return !!(
    perms?.[`${entity}.status_delete`] ||
    perms?.[`${entity}.group_status_delete`]
  );
}
