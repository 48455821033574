import { CloseOutlined } from '@ant-design/icons';
import { App, List, Row, Typography } from 'antd';
import { MessageType } from 'antd/es/message/interface';

import style from './style.module.scss';
import IconButton from '../../components/ui/IconButton';

interface IMessage {
  success: (text: string | string[] | React.ReactNode, duration?: number) => MessageType,
  error: (text: string | string[] | React.ReactNode, duration?: number) => MessageType,
  warning: (text: string | string[] | React.ReactNode, duration?: number) => MessageType,
  info: (text: string | string[] | React.ReactNode, duration?: number) => MessageType,
  destroy: () => void,
}

export const useModalMessage = (isClose = false) : IMessage => {
  const { message } = App.useApp();
  const keyClose = Date.now();

  const onClose = () => {
    message.destroy(keyClose);
  };
    const list = (text: string | string[] | React.ReactNode) => (
      <List
        className={style.modal__list}
        header={
          isClose ?
            <IconButton iType='close' className={style['modal__close']} onClick={onClose} />
          : null
        }
        dataSource={Array.isArray(text) ? text : [text]}
        renderItem={(item, i) => (
          <List.Item key={i}>
            <Typography.Paragraph style={{ margin: 0 }}>
              {item}
            </Typography.Paragraph>
          </List.Item>
        )}
      />
    );
    const config = (text: string | string[] | React.ReactNode, duration = 3) => ({
      className: style.modal,
      top: 0,
      content: list(text),
      key: keyClose,
      duration,
      isClose
    });
    return {
      success: (text: string | string[] | React.ReactNode, duration?: number) => message.success(config(text, duration)),
      error: (text: string | string[] | React.ReactNode, duration?: number) => message.error(config(text, duration)),
      warning: (text: string | string[] | React.ReactNode, duration?: number) => message.warning(config(text, duration)),
      info: (text: string | string[] | React.ReactNode, duration?: number) => message.info(config(text, duration)),
      destroy: onClose,
    };

};
