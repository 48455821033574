import React, { useEffect } from 'react';
import { App, Button, DatePicker, Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { getDateSubmitString } from '../../../helpers/date-helpers';

interface ColumnDateProps {
  columnIndex: number;
  handleDateChange: (type: 'start' | 'end', date: string | string[]) => void;
  startDate: Date | null;
  endDate: Date | null;
  handleChange: (
    option: string[],
    dates: [Date | null | string, Date | null | string],
    dataInt: [number | '' | null | undefined, number | '' | null | undefined],
    checked: boolean,
    columnIndex: number,
  ) => void;
  startIntRange?: any;
  endIntRange?: any;
  columnFilters?: any;
  column: any;
}

const ColumnDate: React.FC<ColumnDateProps> = ({
  columnIndex,
  handleDateChange,
  handleChange,
  columnFilters,
  column,
  startIntRange,
  endIntRange,
}) => {
  const [form] = Form.useForm();
  const { message } = App.useApp();

  useEffect(() => {
    if (columnFilters && columnFilters.length > 0) {
      form.setFieldsValue({
        start: columnFilters[0] || null,
        end: columnFilters[1] || null,
      });
    } else {
      form.setFieldsValue({
        start: null,
        end: null,
      });
    }
  }, [form, columnFilters]);

  const clearDate = () => {
    handleChange(
      [],
      [null, null],
      [startIntRange, endIntRange],
      false,
      columnIndex,
    );
  };

  const dateChange = (type: 'start' | 'end', date: string | string[]) => {
    handleDateChange(type, date);
  };

  const onFinish = (data: any) => {
    const start = getDateSubmitString({date: data.start});
    const end = getDateSubmitString({date: data.end});

    if (start && end) {
      handleChange(
        ['date'],
        [start, end],
        [startIntRange, endIntRange],
        false,
        columnIndex,
      );
    } else {
      message.info('Не указана дата!');
    }
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', gap: 7 }}>
          <Form.Item
            name="start"
            getValueProps={(value) => ({
              value: value ? dayjs(value) : null,
            })}
          >
            <DatePicker
              onChange={(date,dateString) => dateChange('start', dateString)}
              style={{ width: 120 }}
              format="DD.MM.YYYY"
            />
          </Form.Item>
          <Form.Item
            name="end"
            getValueProps={(value) => ({
              value: value ? dayjs(value) : null,
            })}
          >
            <DatePicker
              onChange={(date,dateString) => dateChange('end', dateString)}
              style={{ width: 120 }}
              format="DD.MM.YYYY"
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 7 }}>
          <Button size="small" onClick={clearDate}>
            Сбросить
          </Button>
          <Button type="primary" size="small" htmlType="submit">
            ОК
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ColumnDate;
