import Icon from "@ant-design/icons";
import { IConfigList } from "../../../components/CustomHistory/CustomHistory";
import { DeleteRed20 } from "../../../assets/image/svg";

export const defaultContainer = {
  id: null,
  deal: null,
  rent: null,
  price: null,
  stock: null,
  active: false,
  delete: false,
  repair: false,
  status: null,
  comment: null,
  quality: null,
  release: null,
  currency: null,
  date_cvv: null,
  location: null,
  owner_id: null,
  stock_id: null,
  terminal: null,
  ids_photo: null,
  status_id: null,
  date_entry: "",
  photo_bool: null,
  contractors: null,
  date_memory: null,
  location_id: null,
  responsible: null,
  terminal_id: null,
  arrival_date: null,
  num_container: "",
  sales_deal_id: null,
  terminalStock: null,
  booking_before: null,
  currency_price: null,
  departure_date: null,
  responsible_id: null,
  type_container: null,
  type_container_id: null
}

export const configList: IConfigList[] = [
  {
    typeRow: 'delete',
    titleRow: 'Отмечен на удаление',
    renderValue: <Icon component={DeleteRed20} />,
    isCheckShow: true,
    keyRowPrevDataShow: 'delete',
    keyRowCurrentDataShow: 'delete',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevData: 'delete',
    keyRowCurrentData: 'delete',
    keyValueRowPrevData: 'delete',
    keyValueRowCurrentData: 'delete',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Текущий статус',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'status',
    keyRowCurrentData: 'status',
    keyValueRowPrevData: 'status',
    keyValueRowCurrentData: 'status',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Родитель',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'location',
    keyRowCurrentData: 'location',
    keyValueRowPrevData: 'location',
    keyValueRowCurrentData: 'location',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Терминал',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'terminal',
    keyRowCurrentData: 'terminal',
    keyValueRowPrevData: 'terminal',
    keyValueRowCurrentData: 'terminal',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Сток',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'stock',
    keyRowCurrentData: 'stock',
    keyValueRowPrevData: 'stock',
    keyValueRowCurrentData: 'stock',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Собственник',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'company',
    keyRowCurrentData: 'company',
    keyValueRowPrevData: 'company',
    keyValueRowCurrentData: 'company',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Тип контейнера',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'type_container',
    keyRowCurrentData: 'type_container',
    keyValueRowPrevData: 'type_container',
    keyValueRowCurrentData: 'type_container',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Состояние',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'quality',
    keyRowCurrentData: 'quality',
    keyValueRowPrevData: 'quality',
    keyValueRowCurrentData: 'quality',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Входящая ставка',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'currency_price',
    keyRowCurrentData: 'currency_price',
    keyValueRowPrevData: 'currency_price',
    keyValueRowCurrentData: 'currency_price',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Валюта',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'currency',
    keyRowCurrentData: 'currency',
    keyValueRowPrevData: 'currency',
    keyValueRowCurrentData: 'currency',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Статус оплаты',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: '',
    keyRowCurrentData: '',
    keyValueRowPrevData: '',
    keyValueRowCurrentData: '',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Дата входа',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'date_entry',
    keyRowCurrentData: 'date_entry',
    keyValueRowPrevData: 'date_entry',
    keyValueRowCurrentData: 'date_entry',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Дата прибытия',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'arrival_date',
    keyRowCurrentData: 'arrival_date',
    keyValueRowPrevData: 'arrival_date',
    keyValueRowCurrentData: 'arrival_date',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Дата убытия',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'departure_date',
    keyRowCurrentData: 'departure_date',
    keyValueRowPrevData: 'departure_date',
    keyValueRowCurrentData: 'departure_date',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Дата СВВ',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'date_cvv',
    keyRowCurrentData: 'date_cvv',
    keyValueRowPrevData: 'date_cvv',
    keyValueRowCurrentData: 'date_cvv',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Напоминание СВВ',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: 'date_cvv',
    keyRowCurrentDataShow: 'date_cvv',
    keyRowPrevData: 'date_memory',
    keyRowCurrentData: 'date_memory',
    keyValueRowPrevData: 'date_memory',
    keyValueRowCurrentData: 'date_memory',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Бронь до',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'booking_before',
    keyRowCurrentData: 'booking_before',
    keyValueRowPrevData: 'booking_before',
    keyValueRowCurrentData: 'booking_before',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Комментарий',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'comment',
    keyRowCurrentData: 'comment',
    keyValueRowPrevData: 'comment',
    keyValueRowCurrentData: 'comment',
    columnsTable: []
  },
  {
    typeRow: 'length',
    titleRow: 'Фото',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'ids_photo',
    keyRowCurrentData: 'ids_photo',
    keyValueRowPrevData: 'ids_photo',
    keyValueRowCurrentData: 'ids_photo',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Ссылка на фото',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'photo_bool',
    keyRowCurrentData: 'photo_bool',
    keyValueRowPrevData: 'photo_bool',
    keyValueRowCurrentData: 'photo_bool',
    columnsTable: []
  },
];
