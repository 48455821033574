import React, { useEffect, useRef } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown, Flex, MenuProps, Select, Typography } from 'antd';

import Icon from '@ant-design/icons/lib/components/Icon';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { CONTAINER, messagesModal, RELEASE } from '../../../../../../constants';
import { moreIcon } from '../../../../../../assets/image/svg';
import { fileTypes } from '../../../../../../helpers/input-helpers';
import {
  getSearchLink,
  openInNewTab,
} from '../../../../../../helpers/link-helper';
import {
  FORMAT_DATE_KTK,
  nameColumns,
  nameColumnsKtk,
} from '../../../constants';
import { RootState, useStoreDispatch } from '../../../../../../store';
import { handleDownloadXlsx } from '../../../../../../helpers/xlsx-helper';
import ModalConfirm from '../../../../../../components/ui/ModalConfirm/ModalConfirm';
import {
  FormDatePicker,
  FormInputNumber,
} from '../../../../../../components/ui/Form';
import TooltipButton from '../../../../../../components/ui/TooltipButton/TooltipButton';
import { Checkbox } from '../../../../../../components/ui/Checkbox/Checkbox';
import {
  IContainer,
  ITabView,
  IValues,
  setDeals,
} from '../../../../../../store/sales';
import ViewRow from '../../../../../../components/ui/List/ViewRow/ViewRow';

import styles from '../../../style.module.scss';
import table from '../../../table.module.scss';
import UploaderDocsUnique from '../../../../../../components/ui/UploaderDocsUnique';

const { title, subtitle } = messagesModal.delete;

const TabTable: React.FC<ITabView> = ({ deal, isOpen }) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const refMenu = useRef<HTMLDivElement>(null);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [deleteContainer, setDeleteContainer] = React.useState<IContainer>();
  const [containers, setContainers] = React.useState<IContainer[]>([]);
  const [values, setValues] = React.useState<IValues>({});

  useEffect(() => {
    if (isOpen) {
      setContainers(deal?.containers || []);
    }
  }, [isOpen])

  const containerDealStatuses = useSelector<RootState>(
    (state) => state.types.dataTypeDealContainerStatus,
  ) as { id: number; value: string }[];

  const comments = deal?.comments || [];

  const isDisabled = !!deal?.delete || !deal?.edit;

  const onScrollTable = (evt: any) => {
    if (refMenu?.current && evt?.target?.scrollTop >= 0) {
      refMenu.current.scrollTo({ top: evt.target.scrollTop });
    }
  };

  const handlerUpload = () => {
    const rows = containers.map(
      ({
        release,
        num_container,
        type_container,
        status,
        bet,
        price,
        payment,
        issue_date,
        departure_date,
        gtd,
      }) => ({
        release: release?.release_id || '',
        num_container: num_container ?? '',
        type_container: type_container || '',
        status: status || '',
        bet: bet ?? '',
        price: price ?? '',
        payment: payment ?? '',
        issue_date: issue_date ?? '',
        departure_date: departure_date ?? '',
        gtd: gtd ? 'Да' : 'Нет',
      }),
    );
    handleDownloadXlsx({
      nameFile: `${t('ктк сделки')} ${deal?.id_deal ?? ''}`,
      nameColumns: nameColumnsKtk,
      nameList: `${t('Лист')} 1`,
      rows,
    });
  };

  const editContainer = (index: number) => {
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        ...containers[index],
      },
    }));
    const result = [...containers];
    result[index] = { ...result[index], edit: true };
    setContainers(result);
  };
  const deletedContainer = () => {
    const result = containers.map((item) =>
      item.id === deleteContainer?.id
        ? { ...item, delete: true, edit: false }
        : item,
    );
    dispatch(setDeals({ deal, comments, containers: result })).finally(() => {
      setContainers(result);
    });
  };
  const getItems = (
    remove: () => void,
    edit: (defaultValue?: any, insertIndex?: number) => void,
    index: number,
  ): MenuProps['items'] => [
    {
      key: '1',
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'left', title: t('Редактировать') }}
          propsButton={{
            onClick: () => editContainer(index),
            icon: <EditOutlined />,
            shape: 'circle',
            disabled: isDisabled,
          }}
        />
      ),
    },
    {
      key: '2',
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'left', title: t('Удалить') }}
          propsButton={{
            onClick: () => remove(),
            icon: <DeleteOutlined />,
            shape: 'circle',
            disabled: isDisabled,
          }}
        />
      ),
    },
  ];

  const submitContainer = (index: number) => {
    const result = [...containers];
    result[index] = { ...values[index], edit: false };
    dispatch(setDeals({ deal, containers: result, comments })).finally(() => {
      setContainers(result);
    });
  };
  const closeEditContainer = (index: number) => {
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        ...containers[index],
      },
    }));
    const result = [...containers];
    result[index] = { ...result[index], edit: false };
    setContainers(result);
  };

  const handlerChange = ({
    name,
    value,
    index,
  }: {
    name: string;
    value: any;
    index: number;
  }) => {
    setValues((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        [name]: value,
      },
    }));
  };

  return (
    <Flex gap={30} vertical align="flex-start">
      <div className={styles['file-box']}>
        <UploaderDocsUnique
          isView
          isOpen={isOpen}
          files={deal?.files}
          accept={[
            fileTypes.doc,
            fileTypes.xls,
            fileTypes.pdf,
            fileTypes.jpeg,
          ].join(',')}
        />
      </div>
      <Flex justify="end" style={{ width: '100%', paddingRight: '10px' }}>
        <Checkbox
          className={table['checkbox']}
          isChoice={deal?.include_bill}
          disabled
        >
          {t('Включаем в счет? За доставку КТК')}
        </Checkbox>
      </Flex>
      <div className={table['two-columns']} style={{ marginBottom: '20px' }}>
        <div onScroll={onScrollTable} className={table['overlap-group']}>
          <table className={table['table']}>
            <thead className={table['table__header-fixed']}>
              <tr className={table['table__header-fixed-row']}>
                <th
                  key={title}
                  className={classnames(
                    table['table__header-fixed-index'],
                    table['label-card-16'],
                  )}
                >
                  №
                </th>
                {nameColumns.map(({ title, style }) => (
                  <th
                    key={title}
                    className={classnames(
                      table['table__header-fixed-cell'],
                      table['label-card-16'],
                    )}
                    style={style}
                  >
                    {t(title)}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className={table['table__body']}>
              {containers.map((field, index) => (
                <tr key={field.num_container} hidden={field?.delete} className={table['table__row']}>
                  <td
                    className={classnames(
                      table['table__index'],
                      table['text-card-14'],
                    )}
                  >
                    <Typography.Text
                      ellipsis={{
                        tooltip: {
                          placement: 'topLeft',
                          title: index + 1,
                        },
                      }}
                    >
                      {index + 1}
                    </Typography.Text>
                  </td>
                  <td className={classnames(table['table__cell'])}>
                    <Typography.Link
                      className={classnames(table['text-card-14'], {
                        [table['default-link']]: !field?.['release']?.id,
                      })}
                      ellipsis
                      onClick={() => {
                        openInNewTab(
                          getSearchLink({
                            key: RELEASE,
                            id: field?.['release']?.id,
                          }),
                        );
                      }}
                    >
                      {field?.['release']?.release_id}
                    </Typography.Link>
                  </td>

                  <td className={classnames(table['table__cell'])}>
                    <Typography.Link
                      className={classnames(table['text-card-14'], {
                        [table['default-link']]: !field?.['id'],
                      })}
                      ellipsis
                      onClick={() => {
                        openInNewTab(
                          getSearchLink({ key: CONTAINER, id: field?.['id'] }),
                        );
                      }}
                    >
                      {field?.['num_container']}
                    </Typography.Link>
                  </td>

                  <td
                    className={classnames(
                      table['table__cell'],
                      table['text-card-14'],
                    )}
                  >
                    <Typography.Text
                      ellipsis={{
                        tooltip: {
                          placement: 'topLeft',
                          title: field?.['type_container'] || '',
                        },
                      }}
                    >
                      {field?.['type_container'] || ''}
                    </Typography.Text>
                  </td>

                  <td
                    className={classnames(
                      table['table__cell'],
                      table['text-card-14'],
                    )}
                  >
                    {field?.['edit'] ? (
                      <Select
                        placeholder={t('Добавьте статус')}
                        style={{ width: '100%' }}
                        defaultValue={field.status_id}
                        onChange={(value) => {
                          const status =
                            containerDealStatuses.find(({ id }) => id === value)
                              ?.value || null;
                          handlerChange({
                            name: 'status',
                            value: status,
                            index,
                          });
                          handlerChange({ name: 'status_id', value, index });
                        }}
                        options={containerDealStatuses?.map(
                          ({ id, value }) => ({
                            value: id,
                            label: value,
                          }),
                        )}
                      />
                    ) : (
                      <Typography.Text
                        ellipsis={{
                          tooltip: {
                            placement: 'topLeft',
                            title: field?.['status'] || t('Добавьте статус'),
                          },
                        }}
                      >
                        {field?.['status'] || t('Добавьте статус')}
                      </Typography.Text>
                    )}
                  </td>

                  <td className={classnames(table['table__cell'])}>
                    <Typography.Text
                      className={table['text-card-14']}
                      ellipsis={{
                        tooltip: {
                          placement: 'topLeft',
                          title: field?.['bet'] ?? '',
                        },
                      }}
                    >
                      {field?.['bet'] ?? ''}
                    </Typography.Text>
                  </td>

                  <td className={classnames(table['table__cell'])}>
                    {field?.['edit'] ? (
                      <FormInputNumber
                        name={'price'}
                        placeholder={t('Добавьте цену')}
                        value={values[index]['price']}
                        min={0}
                        onChange={(val) =>
                          handlerChange({
                            name: 'price',
                            value: val,
                            index,
                          })
                        }
                      />
                    ) : (
                      <Typography.Text
                        className={table['text-card-14']}
                        ellipsis={{
                          tooltip: {
                            placement: 'topLeft',
                            title: field?.['price'] || t('Добавьте цену'),
                          },
                        }}
                      >
                        {field?.['price'] || t('Добавьте цену')}
                      </Typography.Text>
                    )}
                  </td>

                  <td className={classnames(table['table__cell'])}>
                    {field?.['edit'] ? (
                      <FormInputNumber
                        name={'payment'}
                        placeholder={t('Добавьте данные')}
                        value={values[index]['payment']}
                        maxLength={15}
                        onChange={(val) =>
                          handlerChange({
                            name: 'payment',
                            value: val,
                            index,
                          })
                        }
                      />
                    ) : (
                      <Typography.Text
                        className={table['text-card-14']}
                        ellipsis={{
                          tooltip: {
                            placement: 'topLeft',
                            title: field?.['payment'] || t('Добавьте данные'),
                          },
                        }}
                      >
                        {field?.['payment'] || t('Добавьте данные')}
                      </Typography.Text>
                    )}
                  </td>

                  <td
                    className={classnames(
                      table['table__cell'],
                      table['text-card-14'],
                    )}
                  >
                    {field?.['edit'] ? (
                      <FormDatePicker
                        format={FORMAT_DATE_KTK}
                        value={field?.['issue_date'] || null}
                        handlerChange={(value: string | null) => {
                          handlerChange({
                            name: 'issue_date',
                            value,
                            index,
                          });
                        }}
                      />
                    ) : (
                      <Typography.Text
                        ellipsis={{
                          tooltip: {
                            placement: 'topLeft',
                            title: field?.['issue_date'] || t('Добавьте дату'),
                          },
                        }}
                      >
                        {field?.['issue_date'] || t('Добавьте дату')}
                      </Typography.Text>
                    )}
                  </td>
                  <td
                    className={classnames(
                      table['table__cell'],
                      table['text-card-14'],
                    )}
                  >
                    {field?.['edit'] ? (
                      <FormDatePicker
                        format={FORMAT_DATE_KTK}
                        value={field?.['departure_date'] || null}
                        handlerChange={(value: string | null) => {
                          handlerChange({
                            name: 'departure_date',
                            value,
                            index,
                          });
                        }}
                      />
                    ) : (
                      <Typography.Text
                        ellipsis={{
                          tooltip: {
                            placement: 'topLeft',
                            title:
                              field?.['departure_date'] || t('Добавьте дату'),
                          },
                        }}
                      >
                        {field?.['departure_date'] || t('Добавьте дату')}
                      </Typography.Text>
                    )}
                  </td>

                  <td
                    className={classnames(table['table__cell'])}
                    style={{ minWidth: 'auto' }}
                  >
                    <Checkbox
                      isChoice={field?.['gtd']}
                      disabled={!field?.['edit']}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          ref={refMenu}
          className={classnames(
            table['overlap-group'],
            table['overlap-group_last'],
          )}
        >
          <table className={table['table']}>
            <thead className={table['table__header-fixed']}>
              <tr className={table['table__header-fixed-row']}>
                <th
                  className={classnames(
                    table['table__header-fixed-cell'],
                    table['table__header-fixed-menu-icon'],
                  )}
                  style={{ padding: '15px 5px' }}
                >
                  <div className={table['label-card-16']}>
                    <Dropdown
                      overlayClassName={table.drop}
                      menu={{
                        items: [
                          {
                            key: 'link2',
                            label: (
                              <Typography.Text
                                data-id="link"
                                className={classnames(table['menu-icon__link'])}
                                onClick={handlerUpload}
                                data-disabled={!containers?.length}
                                disabled={!containers?.length}
                              >
                                {t('Выгрузить в Excel')}
                              </Typography.Text>
                            ),
                          },
                        ],
                      }}
                      placement="bottomLeft"
                      trigger={['click']}
                    >
                      <Typography.Link
                        className=""
                        onClick={(e) => e.preventDefault()}
                      >
                        <Icon component={moreIcon} />
                      </Typography.Link>
                    </Dropdown>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody className={table['table__body']}>
              {containers.map((field, index) => (
                <tr key={field.num_container} hidden={field?.delete} className={table['table__row']}>
                  <td
                    className={classnames(
                      table['table__cell'],
                      table['table__menu'],
                    )}
                  >
                    <div className={table['text-card-14']}>
                      {field?.['edit'] ? (
                        <Dropdown
                          overlayClassName={table.drop}
                          open
                          menu={{
                            items: [
                              {
                                key: '1',
                                label: (
                                  <TooltipButton
                                    propsTooltip={{ title: t('Сохранить') }}
                                    propsButton={{
                                      className: table['table__button'],
                                      shape: 'circle',
                                      icon: <CheckOutlined />,
                                      onClick: () => submitContainer(index),
                                    }}
                                  />
                                ),
                              },
                              {
                                key: '2',
                                label: (
                                  <TooltipButton
                                    propsTooltip={{ title: t('Закрыть') }}
                                    propsButton={{
                                      className: table['table__button'],
                                      shape: 'circle',
                                      icon: <CloseOutlined />,
                                      onClick: () => closeEditContainer(index),
                                    }}
                                  />
                                ),
                              },
                            ],
                            style: {
                              background: 'transparent',
                              boxShadow: 'none',
                            },
                          }}
                          placement="bottom"
                          trigger={['click']}
                        >
                          <Typography.Link
                            className=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <Icon component={moreIcon} />
                          </Typography.Link>
                        </Dropdown>
                      ) : (
                        <Dropdown
                          overlayClassName={table.drop}
                          disabled={isDisabled}
                          menu={{
                            items: getItems(
                              () => {
                                setConfirmation(true);
                                setDeleteContainer(field);
                              },
                              editContainer,
                              index,
                            ),
                            style: {
                              background: 'transparent',
                              boxShadow: 'none',
                            },
                          }}
                          placement="bottom"
                          trigger={['click']}
                        >
                          <Typography.Link onClick={(e) => e.preventDefault()}>
                            <Icon component={moreIcon} />
                          </Typography.Link>
                        </Dropdown>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
              <ModalConfirm
                title={title}
                subtitle={subtitle}
                isOpen={confirmation}
                closeModal={() => setConfirmation(false)}
                actionAfterConsent={deletedContainer}
              />
            </tbody>
          </table>
        </div>
      </div>

      <ViewRow title={t('Итого')} subtitle={deal?.total_ktk} isDivider />
      <ViewRow
        title={t('Остаток долга')}
        subtitle={deal?.balance_ktk}
        isDivider
      />
    </Flex>
  );
};

export default TabTable;
