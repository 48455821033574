import { Typography } from 'antd';
import { IColumnType } from '../../../components/ui/Table/Table';

export const columns: IColumnType<any>[] = [
  {
    dataIndex: 'id',
    filterIndex: 'id',
    checkbox: true,
    menu: true,
    type: 'string',
    key: '0',
  },
  {
    key: '1',
    title: 'Номер релиза',
    dataIndex: 'release_id',
    filterIndex: 'release_id',
    type: 'string',
    link: true
  },
  {
    key: '2',
    title: 'Количество КТК',
    dataIndex: 'containers_count',
    filterIndex: 'containers_count',
    type: 'range_integer',
  },

  {
    key: '3',
    title: 'Дата с',
    dataIndex: 'date_action_start',
    filterIndex: 'date_action_start',
    type: 'date',
  },
  {
    key: '4',
    title: 'Дата по',
    dataIndex: 'date_action_end',
    filterIndex: 'date_action_end',
    type: 'date',
  },
  {
    key: '5',
    title: 'Подрядчик',
    dataIndex: 'contractor',
    filterIndex: 'contractor',
    type: 'string',
    render: (col, item) =>
      item?.contractor ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: { placement: 'top', title: item.contractor },
          }}
          style={{ maxWidth: '250px', margin: 0 }}
        >
          {item.contractor}
        </Typography.Paragraph>
      ) : null,
  },
  {
    title: '',
    dataIndex: 'hidden',
    filterIndex: 'hidden',
    key: '6',
    width: '2%'
  },
];
