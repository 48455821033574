export const COMPLETED = 'completed';
export const PART_COMPLETED = 'partCompleted';
export const CANCELLED = 'cancelled';
export const NEW = 'new';
export const CREATED = 'created';
export const SENT = 'sent';

export const ktkDefault = [
  {
    deal_id: '333',
    num_container: 'ASSQ1131314',
    type_container: '20DC',
    bet: '100',
    count_day_snp: '20',
    checked: false,
  },
];

export const statusColorsInvoice = (status: string) => {
  switch (status) {
    case CREATED:
      return {
        title: status,
        style: {
          color: '#00B288',
          background: '#E6F6EC',
        },
      };
    case SENT:
      return {
        title: status,
        style: {
          color: '#a3a9b1',
          background: '#E6F6EC',
        },
      };
    case PART_COMPLETED:
      return {
        title: status,
        style: {
          color: '#a3a9b1',
          background: '#E6F6EC',
        },
      };
    case COMPLETED:
      return {
        title: status,
        style: {
          color: '#a3a9b1',
          background: '#E6F6EC',
        },
      };
    case NEW:
      return {
        title: status,
        style: {
          color: '#a3a9b1',
          background: '#E6F6EC',
        },
      };
    case CANCELLED:
      return {
        title: status,
        style: {
          color: '#E14453',
          background: '#FCE8EA',
        },
      };
    default:
      return {
        title: 'Нет статуса',
        style: {
          color: '#a3a9b1',
          background: '#E6F6EC',
        },
      };
  }
};

export const noStyleField = [
  'id',
  'type_deal',
  'type_deal_char',
  'email',
  'phone',
  'my_company_id',
  'company_id',
  'contact_id',
  'currency_id',
  'responsible_id',
  'responsible_name',
  'saveFiles',
  'executor_id',
  'agreement_id',
  'related_deals_ids',
  'account_type_id',
];
