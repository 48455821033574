import React, { useEffect, useState } from 'react';
import { AGREEMENT, COMPANY, CONTACT, CONTAINER, RELEASE, RENT_DEAL, RENT_REQUEST, SALES_DEAL, TERMINAL } from '../../../../constants';
import { Flex } from 'antd';
import { getNamePage } from '../../../../helpers/string-helpers';
import { ProfileTask } from '../ProfileTask/ProfileTask';
import { IProfileTab } from '../../../../types/users';
import ITabs from '../../../ui/Tabs';
import { ITab } from '../../../ui/Tabs/Tabs';
import style from '../../style.module.scss';
import { TAB_TASKS } from '../../constants';

export const ProfileTasks: React.FC<IProfileTab> = ({ activeKey, initialValue, width }) => {

  const [activeTabKey, setActiveTabKey] = useState('');
  const changeClickTab = (key: string) => {
    setActiveTabKey(key);
  };

  useEffect(() => {
    if (activeKey === TAB_TASKS) {
      setActiveTabKey(AGREEMENT);
    }
  }, [])

  const tabs: ITab[]= [
    {
      key: AGREEMENT,
      forceRender: false,
      label: getNamePage({ table: AGREEMENT }),
      content: [<ProfileTask key={1} table={AGREEMENT} activeTab={activeTabKey} initialValue={initialValue} width={width} />],
    },
    {
      key: COMPANY,
      forceRender: false,
      label: getNamePage({ table: COMPANY }),
      content: [<ProfileTask key={1} table={COMPANY} activeTab={activeTabKey} initialValue={initialValue} width={width} />],
    },
    {
      key: CONTACT,
      forceRender: false,
      label: getNamePage({ table: CONTACT }),
      content: [<ProfileTask key={1} table={AGREEMENT} activeTab={activeTabKey} initialValue={initialValue} width={width} />],
    },
    {
      key: CONTAINER,
      forceRender: false,
      label: getNamePage({ table: CONTAINER }),
      content: [<ProfileTask key={1} table={CONTAINER} activeTab={activeTabKey} initialValue={initialValue} width={width} />],
    },
    {
      key: TERMINAL,
      forceRender: false,
      label: getNamePage({ table: TERMINAL }),
      content: [<ProfileTask key={1} table={TERMINAL} activeTab={activeTabKey} initialValue={initialValue} width={width} />],
    },
    {
      key: RELEASE,
      forceRender: false,
      label: getNamePage({ table: RELEASE }),
      content: [<ProfileTask key={1} table={RELEASE} activeTab={activeTabKey} initialValue={initialValue} width={width} />],
    },
    {
      key: SALES_DEAL,
      forceRender: false,
      label: getNamePage({ table: SALES_DEAL }),
      content: [<ProfileTask key={1} table={SALES_DEAL} activeTab={activeTabKey} initialValue={initialValue} width={width} />],
    },
    {
      key: RENT_DEAL,
      forceRender: false,
      label: getNamePage({ table: RENT_DEAL }),
      content: [<ProfileTask key={1} table={RENT_DEAL} activeTab={activeTabKey} initialValue={initialValue} width={width} />],
    },
    {
      key: RENT_REQUEST,
      forceRender: false,
      label: getNamePage({ table: RENT_REQUEST }),
      content: [<ProfileTask key={1} table={RENT_REQUEST} activeTab={activeTabKey} initialValue={initialValue} width={width} />],
    },
  ];
  return (
    <Flex vertical>
         <ITabs
          className={style['form__tab']}
          isErrorTabs
          activeKey={activeTabKey}
          tabs={tabs}
          onChange={changeClickTab}
        />
    </Flex>
  );
};
