import { apiAxios } from '../api/config';

export interface ISearch {
  pageSize?: number;
  current?: number;
  order?: string;
  text: string;
}

export class Rest_location {

  static searchLocationName = (payload: {name?: string, text?: string}): Promise<any> => {
    return apiAxios.post('location/search-terminals', payload);
  };

  static checkImport = (payload: {location: string, terminal: string, stock: string, index: number}[]): Promise<any> => {
    return apiAxios.post('location/check-import', payload);
  };
}
