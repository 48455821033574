import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AutoComplete, Button, Flex, Input, Space } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import { RouteNames } from "../../../router/names";
import { RootState, useStoreDispatch } from "../../../store";
import { useBreadcrumb, useMenu, useParamsHistory } from "../../../hooks";
import { Table } from "../../../components/ui/Table/Table";
import {
  defaultValuesDeal,
  deleteIds,
  IDeal,
  resetStoreSale,
  setOneDeal,
} from "../../../store/sales";
import { columns } from "./columns";
import { Rest_deal } from "../../../services/rest_deal";
import CustomDrawer from "../../../components/CustomDrawer/CustomDrawer";
import { FormDeal, ViewDeal } from "./components";
import { CREATE, EDIT, VIEW } from "../../../helpers/string-helpers";
import { filterResultKtk, mapperDealSales } from "./utils";
import { SALES_DEAL } from "../../../constants";
import { useGettingDataForTables } from "../../../hooks/useGettingDataForTables/useGettingDataForTables";
import {
  hasViewPermission,
  hasEditPermission,
  hasDeletePermission,
} from "../../../components/Menu/permissions";

const Deals = () => {
  const { t } = useTranslation();
  const [activeButtons, setActiveButtons] = useState<any>({});
  const [searchColumn, setSearchColumn] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();
  const [typeForm, setTypeForm] = useState<string>(CREATE);
  const dealOneData = useSelector<RootState>(
    (state) => state.sales.dealOneData
  ) as IDeal;
  const [openDrawerDeal, setOpenDrawerDeal] = useState<boolean>(false);

  //==============================================
  // подключаемся к хранилищу данных с правами
  //==============================================
  const permissionsData: any = useSelector(
    (state: RootState) => state.users.permissionsData
  );

  /// получаем данные только по запросу (не все)
  // через утилиту hasView/Edit/DeletePermission
  const canView = hasViewPermission(permissionsData, "salesDeals");
  const canEdit = hasEditPermission(permissionsData, "salesDeals");
  const canDelete = hasDeletePermission(permissionsData, "salesDeals");

  const { dataTable, optionsTable, setLoadingSubmit, updateEffectDate } =
    useGettingDataForTables({
      titlePage: "сделка продажи",
      keyItemName: "id_deal",
      subscribeStringCreateForm: `return-set-deal-sales`,
      subscribeStringDelete: "return-delete-deal-sales",
      subscribeError: "error",
      table: SALES_DEAL,
      location,
      columns,
      fetchTables: Rest_deal.getDeals,
      fetchDelete: (arg: any) => dispatch(deleteIds(arg)),
      mapperData: mapperDealSales,
    });

  useBreadcrumb(["Продажи", "Сделки"]);
  useMenu({
    openKey: RouteNames.SALES,
    activeKeys: [RouteNames.SALES, RouteNames.SALES_DEAL],
  });

  useEffect(() => {
    if (pathname === RouteNames.SALES_DEAL) {
      dispatch(resetStoreSale());
      updateEffectDate();
      const sales = location?.state?.[SALES_DEAL];
      if (sales) {
        window.history.replaceState({ state: null }, document.title);
        if (sales?.[EDIT]) {
          dispatch(setOneDeal(sales[EDIT]));
          setTypeForm(EDIT);
          setOpenDrawerDeal(true);
          return;
        }
        setTypeForm(CREATE);
        dispatch(
          setOneDeal({
            ...defaultValuesDeal,
            ...location.state.sales,
            containers: filterResultKtk(sales?.containers),
          })
        );
        setOpenDrawerDeal(true);
        return;
      }
    }
    return history.replace(RouteNames.SALES_DEAL);
  }, [pathname]);

  const handleButtonClickSearch = (option: any) => {
    setActiveButtons((prevActiveButtons: any) => {
      setSearchColumn((prev) =>
        prevActiveButtons[option.key]
          ? prev.filter((item: string) => item !== option.dataIndex)
          : [...prev, option.dataIndex]
      );
      return {
        ...prevActiveButtons,
        [option.key]: !prevActiveButtons[option.key],
      };
    });
  };

  const onCloseFormDeal = (res?: boolean) => {
    dispatch(resetStoreSale());
    dispatch(setOneDeal(defaultValuesDeal));
    setOpenDrawerDeal(false);
    setLoadingSubmit(!!res);
  };

  const onClose = () => {
    onCloseFormDeal(false);
  };

  const handleSearch = (val: string) => {
    setSearchValue(val);
  };

  const onCreate = () => {
    setTypeForm(CREATE);
    dispatch(setOneDeal(defaultValuesDeal));
    setOpenDrawerDeal(true);
  };

  const onRow = (data: IDeal) => {
    const row = data;
    if (row?.id) {
      setTypeForm(VIEW);
      dispatch(setOneDeal(row));
      setOpenDrawerDeal(true);
    }
  };

  const handleShowEditForm = (data: IDeal) => {
    const row = data;
    if (row?.id) {
      setTypeForm(EDIT);
      dispatch(setOneDeal(row));
      setOpenDrawerDeal(true);
    }
  };

  if (!canView) {
    return (
      <div style={{ padding: 20, fontSize: 16, textAlign: "center" }}>
        {t("У вас нет прав на просмотр данной страницы")}
      </div>
    );
  }

  return (
    <>
      <Flex
        gap={20}
        justify={"space-between"}
        align={"flex-start"}
        style={{ width: "100%" }}
        className={"container"}
      >
        <Flex align={"center"} gap={10}>
          <div className={"drawer-title"}>{t("Сделки")}</div>
        </Flex>
        <Flex gap={16}>
          {canEdit && (
            <Button
              icon={<PlusOutlined />}
              style={{ background: "#05F", color: "#fff", minWidth: "32px" }}
              onClick={onCreate}
            />
          )}
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            options={columns}
            defaultValue={searchValue}
            popupMatchSelectWidth={false}
            onSearch={handleSearch}
            dropdownRender={() => (
              <>
                <div
                  className={"text-card-12"}
                  style={{ padding: "10px 10px" }}
                >
                  {t("Выбор колонок по которым будет производиться поиск")}
                </div>
                <Flex
                  gap={5}
                  style={{ width: 300, flexWrap: "wrap", padding: 10 }}
                >
                  {columns.map(
                    (option) =>
                      !!option.title && (
                        <Button
                          size={"small"}
                          key={option.key}
                          type={
                            activeButtons[Number(option.key)]
                              ? "default"
                              : "primary"
                          }
                          onClick={() => handleButtonClickSearch(option)}
                        >
                          {option.title}
                        </Button>
                      )
                  )}
                </Flex>
              </>
            )}
          >
            <Input placeholder={t("Введите текст")} />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Table
          {...optionsTable}
          editRow={handleShowEditForm}
          dataSource={dataTable}
          height={"calc(-162px + 100vh)"}
          onRow={onRow}
          onClickLink={onRow}
          canView={canView}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      </Space>
      <CustomDrawer open={openDrawerDeal} onClose={onClose} isHeight>
        {typeForm === VIEW || dealOneData?.delete || !dealOneData?.edit ? (
          <ViewDeal
            typeForm={typeForm}
            initialValue={dealOneData}
            onClose={onCloseFormDeal}
            isOpen={openDrawerDeal}
            isCopy
            onEdit={() => setTypeForm(EDIT)}
            canEdit={canEdit}
          />
        ) : (
          <FormDeal
            typeForm={typeForm}
            initialValue={dealOneData}
            title={
              typeForm === CREATE ? "Создать сделку" : "Редактировать сделку"
            }
            onClose={onCloseFormDeal}
            onView={() => setTypeForm(VIEW)}
            isOpen={openDrawerDeal}
            isCopy
            canEdit={canEdit}
          />
        )}
      </CustomDrawer>
    </>
  );
};

export default Deals;
