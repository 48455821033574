import dayjs, {Dayjs} from 'dayjs';
import {IContainer} from '../../../store/rent';
import {uniqBy} from 'lodash';

export const mapperSearchYandexLocation = ({ response }: any) => {
  return uniqBy(
    response?.GeoObjectCollection?.featureMember?.map(
      (item: any) => {
        const text =
          item?.GeoObject?.metaDataProperty?.GeocoderMetaData?.AddressDetails
            ?.Country?.AddressLine;
        return { value: text, label: text };
      },
    ),
    (item: { value: string; label: string }) => item?.value,
  );
};

// для сделок
//const RENT = 3; // id в продаже
// мапер для таблицы ктк в сделках
export const getterKtk = (container: IContainer): IContainer => ({
  id: container?.id || undefined,
  num_container: container.num_container,
  container_id: container?.id,
  type_container: container?.type_container,
  status: null,
  status_id: null,
  bet: container?.bet,
  price: null,
  payment: container?.payment,
  gtd: false,
  issue_date: null,
  departure_date: null,
  release: container?.release ?? null,
  deal: container?.deal ?? null,
  delete: false,
  edit: true,
});
export const mapperKtk = (
  containers: IContainer[],
  defaultValue?: IContainer,
): IContainer[] | [] => {
  if (Array.isArray(containers) && containers?.length) {
    return containers.map((item) =>
      defaultValue ? getterKtk({ ...item, ...defaultValue }) : getterKtk(item),
    );
  }
  return [];
};
export const filterResultKtk = (containers: any[]): IContainer[] | [] => {
  if (Array.isArray(containers) && containers?.length) {
    return containers.filter((item) => !item?.deal).map(getterKtk);
  }
  return [];
};

export const getContainersAndDeals = ({
  containers,
  currentContainers,
}: {
  containers: IContainer[];
  currentContainers: IContainer[];
}) => {
  const result = [...containers, ...currentContainers].filter(
    (item) => item?.num_container,
  );
  const resultContainers = [...result];

  const ids = resultContainers
    .filter((cont) => !!cont?.deal)
    .map((item) => ({ ...item.deal }))
    .map((item) => item.id);

  return { resultContainers, ids };
};

export const filterContainers = ({
  containers,
  releases,
  release_id,
}: {
  containers: IContainer[];
  releases: { release_id: string }[];
  release_id: string;
}) => {
  return containers.filter(
      (item) => item?.release?.release_id !== release_id,
  );
};

export const mapperCompany = (payload: any[]) => {
  return payload.map((company: any) => ({
    ...company,
    phone: company?.['phone_first'] ?? '',
  }));
};

// кол-во дней снп (Дата сдачи-дата сдачи по заявке +1)
export const getCountDaySnp = ({
  delivery_date,
  delivery_request_date,
}: {
  delivery_request_date?: Dayjs | null;
  delivery_date?: Dayjs | null;
}) => {
  const diffDate = dayjs(delivery_request_date).add(1, 'day');
  const count = dayjs(delivery_date).diff(diffDate, 'day');
  return count + 1 || '';
};
// Дата сдачи по заявке - формула (Дата выдачи+кол-во дней с первого листа)
export const getDeliveryRequestDate = ({
  departure_date,
  count_day,
}: {
  departure_date?: Dayjs | null;
  count_day: number;
}) => {
  return dayjs(departure_date)?.isValid()
    ? dayjs(departure_date).add(Number(count_day), 'day')
    : null;
};
// Сумма СНП - формула (кол-во дней СНП* СНП)
export const getTotalSnp = ({
  count_day_snp,
  count_day,
}: {
  count_day_snp: number;
  count_day: number | string;
}) => {
  return count_day_snp * Number(count_day) || '';
};

export const calculationContainer = ({
  container,
  count_day,
}: {
  container: IContainer;
  count_day: number;
}): IContainer => {
  const delivery_request_date = getDeliveryRequestDate({
    departure_date: container.departure_date,
    count_day,
  });
  const count_day_snp = getCountDaySnp({
    delivery_date: container.delivery_date,
    delivery_request_date,
  }) as number;
  const total_snp = getTotalSnp({
    count_day,
    count_day_snp,
  });
  container.total_snp = total_snp + '';
  container.delivery_request_date = delivery_request_date;
  container.count_day_snp = count_day_snp + '';
  return container;
};

export const getContainerSubmit = (container: IContainer) => {
  const {
    id,
    num_container,
    container_id,
    payment,
    bet,
    departure_date,
    delivery_request_date,
    delivery_date,
    count_day_snp,
    total_snp,
  } = container;

  return {
    id,
    num_container,
    container_id,
    rent: {
      payment,
      bet,
      departure_date,
      delivery_request_date,
      delivery_date,
      count_day_snp,
      total_snp,
    },
  };
};
export const getContainerRent = (container: IContainer) => {
  return {
    id: container?.id,
    num_container: container?.num_container,
    container_id: container?.container_id,
    release: container?.release,
    payment: container?.rent?.payment,
    bet: container?.rent?.bet,
    departure_date: container?.rent?.departure_date,
    delivery_request_date: container?.rent?.delivery_request_date,
    delivery_date: container?.rent?.delivery_date,
    count_day_snp: container?.rent?.count_day_snp,
    total_snp: container?.rent?.total_snp,
  };
};
export const calculationTotalContainer = (containers: IContainer[]) => {
  return containers?.reduce((acc, curr) => acc + Number(curr?.payment || 0),0) || 0
};

export const mapperDealsRent = (deal: any) => ({
    ...deal,
    agreement: deal?.agreement
      ? [{label: deal.agreement?.agreement_id, value: deal.agreement?.id}]
      : [],
    companies: deal?.companies
      ?
      [{label: deal.companies?.name_company, value: deal.companies?.name_company, id: deal.companies?.id,
        phone_first: deal.companies?.phone_first}]
      : [],
    company: deal?.companies
      ?
      deal.companies?.name_company
      : '',
    releases: deal?.releases_model
      ?
      deal.releases_model
      : [],
  });
