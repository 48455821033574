import React, { CSSProperties, MouseEvent, useEffect, useState } from 'react';
import { Input, Select, Space } from 'antd';
import Button from '../Button/Button';
import '../../../assets/scss/select-search.scss';
import { SearchOutlined } from '@ant-design/icons';

export interface Props<T> {
  style?: CSSProperties;
  size?: string;
  dataSource: IOption[] | [];
  onClick?: (e: MouseEvent) => void;
  onChangeSelect?: (e: number, option: any, parent?: any) => void;
  dropdownRender?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => Promise<void>;
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  onClose?: () => void;
  placeholder?: string;
  defaults?: string | null;
  hiddenBtn?: boolean;
  type?: boolean;
  notFoundContent?: any;
  disabled?: boolean;
  value?: any;
}

interface IOption {
  label?: string | undefined;
  value?: string | number | undefined;
  children?: any[];
  child_terminal?: { name: string } | undefined;
  avatar?: string;
  lat?: string | number | undefined;
  lon?: string | number | undefined;
  selectable?: boolean;
}

export function SelectSearch<T>({
  dataSource,
  onChangeSelect,
  dropdownRender,
  onClick,
  onClose,
  placeholder,
  defaults,
  style,
  hiddenBtn,
  type,
  notFoundContent,
  disabled,
  onBlur
}: Props<T>): JSX.Element {
  const [defValue, setDefValue] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState('');
  const stateTasksOptions = type
    ? dataSource.map((parent: {value?: number | string | undefined, label?: string, selectable?: boolean, avatar?: any, children?: any[]}, parentIndex: number) => (
        <React.Fragment key={`parent-${parent?.value ?? ""}-${parentIndex}`}>
          <Select.Option disabled={parent?.selectable} value={parent?.value} data-avatar={parent?.avatar}>
            {parent?.label ?? '-'}
          </Select.Option>
          {parent?.children?.map((item: {value?: string | number, label: string}, childIndex: number) => (
            <Select.Option
              key={`${item.label}-${parentIndex}-${childIndex}`}
              value={item?.value}
              data-parent={parent}
              data-item={item}
            >
              <span style={{ float: 'right' }}>{item?.label}</span>
            </Select.Option>
          ))}
        </React.Fragment>
      ))
    : dataSource.map(
        (item: any) =>
          !item.child && (
            <Select.Option
              key={item.value}
              value={item.value}
              label={item.label}
              data-parent={item}
              data-avatar={item?.avatar}
            >
              {<span>{item.label}</span>}
            </Select.Option>
          ),
      );
  const handlerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (dropdownRender) {
      dropdownRender(e);
    }
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (defaults) {
      setDefValue(defaults);
    } else {
      setDefValue(null);
    }
  }, [defaults]);

  const handleChange = (e: any, option: any) => {
    const avatar = option?.['data-avatar'] || null;
    const parent = option?.['data-parent'] || null;
    const item = option?.['data-item'] || null;

    if (onChangeSelect) {
      onChangeSelect(e, { ...option, avatar, item }, parent);
    }
    if (item) {
      setDefValue(item.label);
    } else {
      setDefValue(option?.label);
    }
    setInputValue('');
  };

  return (
    <>
      <Select
        style={style}
        allowClear
        onChange={(e: any, option: any) => handleChange(e, option)}
        onBlur={onBlur}
        defaultValue={defValue}
        value={defValue}
        placeholder={placeholder}
        notFoundContent={notFoundContent}
        dropdownRender={(menu) => (
          <Space className={'space'} direction={'vertical'}>
            <Input
              value={inputValue}
              allowClear
              prefix={<SearchOutlined />}
              className={'input'}
              placeholder="Поиск"
              onChange={handlerChange}
            />
            {menu}
            {hiddenBtn && (
              <Button
                type="primary"
                style={{ width: '100%' }}
                onClick={onClick}
              >
                Добавить терминал
              </Button>
            )}
          </Space>
        )}
        disabled={disabled}
      >
        {stateTasksOptions}
      </Select>
    </>
  );
}
