import { apiAxios } from '../api/config';
import socket from '../socket';
import { IParamRestDelete } from '../types';

export interface ISearchTerminals {
  pageSize?: number;
  current?: number;
  order?: string;
  text: string;
}
export class Rest_terminal {
  static searchTerminal(payload: {
    text: string;
    all?: boolean;
  }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('search-terminal', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static setTerminal(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('set-terminal', payload);
    });
  }

  static getFiltersTerminals(payload: {}): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('get-filters-terminals', payload, (response: any) => {
        try {
          console.log(response);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }

  static getTerminal(param?: any): Promise<any> {
    return apiAxios.post("terminals/get-terminal", param);
  }

  static findTerminalOne(id: string | number): Promise<any> {
    return apiAxios.get(`terminals/${id}`);
  }

  static deleteIds(payload: IParamRestDelete): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('delete-terminal', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }


  static findStock = (payload: {text?: string, id?: number, isEmpty?: boolean}): Promise<any> => {
    return apiAxios.post('terminals/find-stock', payload);
  };
}
