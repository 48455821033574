import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CompanyRequest } from '../services/rest_companies';
import {
  ITypeCompany,
  ITypeContact,
  ITypeContainers,
  ITypeEmail,
  ITypeMessenger,
  ITypePhone,
  ITypeQuality,
  ITypeSite,
  ITypePayment,
  ITypeDeal,
  ITypeDealStatus,
  ITypeDealContainerStatus,
  ICurrency,
  ITerminalStorage,
  IPercent,
  ITypeSelectList,
  ITypeExpenseModel
} from '../types';

interface ITypeState {
  dataTypePhone: Array<ITypePhone>;
  dataTypeMessenger: Array<ITypeMessenger>;
  dataTypeEmail: Array<ITypeEmail>;
  dataTypeContact: Array<ITypeContact>;
  dataTypeCompany: Array<ITypeCompany>;
  dataTypeContainers: Array<ITypeContainers>;
  dataTypeQuality: Array<ITypeQuality>;
  dataTypeSite: Array<ITypeSite>;
  dataTypePayment: Array<ITypePayment>;
  dataTypeDepartment: [];
  dataTypeDeal: ITypeDeal[];
  dataTypeDealStatus: ITypeDealStatus[];
  dataTypeDealContainerStatus: ITypeDealContainerStatus[];
  dataTypeTerminalStorage: ITerminalStorage[];
  dataTypeCurrency: ICurrency[];
  dataTypePercent: IPercent[];
  currencyCurrent?: ICurrency | null;
  dataTypeVidExpense: ITypeSelectList[];
  dataTypePriority: ITypeSelectList[];
  dataTypeExpenseModel: ITypeExpenseModel[];
}

const initialState: ITypeState = {
  dataTypePhone: [],
  dataTypeMessenger: [],
  dataTypeEmail: [],
  dataTypeContact: [],
  dataTypeCompany: [],
  dataTypeContainers: [],
  dataTypeQuality: [],
  dataTypePayment: [],
  dataTypeSite: [],

  dataTypeDepartment: [],
  dataTypeDeal: [], // тип сделок
  dataTypeDealStatus: [], // статус сделок в продажах
  dataTypeDealContainerStatus: [], // статус ктк в сделках
  dataTypeTerminalStorage: [], // терминалы
  dataTypeCurrency: [], // валюты
  currencyCurrent: null, // объект курса валют
  dataTypePercent: [],
  dataTypeVidExpense: [], // вид расхода
  dataTypePriority: [], // приоритет
  dataTypeExpenseModel: [], // тип расхода
};

export const typeSite = createAsyncThunk('typeSite', async () => {
  return await CompanyRequest.getTypeSite();
});


const typeSlice = createSlice({
  name: 'types',
  initialState,
  reducers: {
    resetStoreTypes(state) {
      state.dataTypePhone = initialState.dataTypePhone;
      state.dataTypeMessenger= initialState.dataTypeMessenger;
      state.dataTypeEmail= initialState.dataTypeEmail;
      state.dataTypeContact= initialState.dataTypeContact;
      state.dataTypeCompany= initialState.dataTypeCompany;
      state.dataTypeContainers= initialState.dataTypeContainers;
      state.dataTypeQuality= initialState.dataTypeQuality;
      state.dataTypePayment= initialState.dataTypePayment;
      state.dataTypeSite= initialState.dataTypeSite;
      state.dataTypeDepartment= initialState.dataTypeDepartment;
      state.dataTypeDeal= initialState.dataTypeDeal;
      state.dataTypeDealStatus= initialState.dataTypeDealStatus;
      state.dataTypeDealContainerStatus= initialState.dataTypeDealContainerStatus;
      state.dataTypeTerminalStorage= initialState.dataTypeTerminalStorage;
      state.dataTypeCurrency= initialState.dataTypeCurrency;
      state.currencyCurrent= initialState.currencyCurrent;
      state.dataTypePercent= initialState.dataTypePercent;
      state.dataTypeVidExpense= initialState.dataTypeVidExpense;
      state.dataTypePriority= initialState.dataTypePriority;
      state.dataTypeExpenseModel = initialState.dataTypeExpenseModel;
    },
    setTypeContainers(state, action) {
      state.dataTypeContainers = action.payload;
    },
    setTypeQuality(state, action) {
      state.dataTypeQuality = action.payload;
    },
    setTypePhone(state, action) {
      state.dataTypePhone = action.payload;
    },
    setTypeMessenger(state, action) {
      state.dataTypeMessenger = action.payload;
    },
    setTypeEmail(state, action) {
      state.dataTypeEmail = action.payload;
    },
    setTypeCompany(state, action) {
      state.dataTypeCompany = action.payload;
    },
    setTypePayment(state, action) {
      state.dataTypePayment = action.payload;
    },
    setTypeContact(state, action) {
      state.dataTypeContact = action.payload;
    },
    setTypeDeal(state, action) {
      state.dataTypeDeal = action.payload;
    },
    setTypeDealStatus(state, action) {
      state.dataTypeDealStatus = action.payload;
    },
    setTypeDealContainerStatus(state, action) {
      state.dataTypeDealContainerStatus = action.payload;
    },
    setTypeTerminalStorage(state, action) {
      state.dataTypeTerminalStorage = action.payload;
    },
    setTypeCurrency(state, action) {
      state.dataTypeCurrency = action.payload;
    },
    setTypeUpdateCurrency(state, action) {
      state.dataTypeCurrency = action.payload?.data;
      state.currencyCurrent = action.payload?.current;
    },
    setTypePercent(state, action) {
      state.dataTypePercent = action.payload;
    },
    setTypeVidExpense(state, action) {
      state.dataTypeVidExpense = action.payload?.map((opt: ITypeSelectList) => ({...opt, value: opt?.id}));
    },
    setTypeExpenseModel(state, action) {
      state.dataTypeExpenseModel = action.payload?.map((el: any) => ({...el, selectable: !el?.children?.length,}));
    },
    setTypePriority(state, action) {
      state.dataTypePriority = action.payload?.map((opt: ITypeSelectList) => ({...opt, value: opt?.id}));;
    },
    setTypes(state, action) {
      state.dataTypeContainers = action.payload?.arContainers;
      state.dataTypeQuality = action.payload?.arQuality;
      state.dataTypePhone = action.payload?.arPhone;
      state.dataTypeMessenger = action.payload?.arMessenger;
      state.dataTypeEmail = action.payload?.arEmail;
      state.dataTypeCompany = action.payload?.arCompany;
      state.dataTypePayment = action.payload?.arPayment;
      state.dataTypeContact = action.payload?.arTypeContact;
      state.dataTypeDeal = action.payload?.arDealTypeService;
      state.dataTypeDealStatus = action.payload?.arDealStatuses;
      state.dataTypeDealContainerStatus = action.payload?.arDealContainerStatusService;
      state.dataTypeTerminalStorage = action.payload?.arTerminalStorage;
      state.dataTypeCurrency = action.payload?.arCurrency;
      state.dataTypePercent = action.payload?.arPercent;
      state.dataTypeVidExpense = action.payload?.arTypeVidExpense?.map((opt: ITypeSelectList) => ({...opt, value: opt?.id}));
      state.dataTypeExpenseModel = action.payload?.arTypeExpenseModel?.map((el: any) => ({...el, selectable: !el?.children?.length,}));
      state.dataTypePriority = action.payload?.arTypePriority?.map((opt: ITypeSelectList) => ({...opt, value: opt?.id}));;
    }
  },
  extraReducers: (builder) => {},
});
export default typeSlice.reducer;
export const {
  setTypeContainers,
  setTypeQuality,
  setTypePhone,
  setTypeMessenger,
  setTypeEmail,
  setTypeCompany,
  setTypePayment,
  setTypeContact,
  setTypeDeal,
  setTypeDealStatus,
  setTypeDealContainerStatus,
  setTypeTerminalStorage,
  setTypeCurrency,
  setTypePercent,
  setTypeUpdateCurrency,
  setTypeVidExpense,
  setTypePriority,
  setTypeExpenseModel,
  setTypes,
  resetStoreTypes
} = typeSlice.actions;
