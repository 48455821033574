import React from "react";
import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Button, Flex, Typography } from "antd";

import { paths } from "./constants";
import style from "./style.module.scss";
import { useStoreDispatch } from "../../../../store";
import { CREATE, DELETE, VIEW } from "../../../../helpers/string-helpers";
import { TableGuide } from "./components/TableGuide";

interface IGuide {
  title: string;
  activeKey: string;
}

export const Guide: React.FC<IGuide> = ({
  activeKey,
  title,
}) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  const [typeForm, setTypeForm] = useState(VIEW);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);


  const onCreate = () => {
    setTypeForm(CREATE);
    setIsOpenDrawer(true);
  };
  const onDelete = () => {
    setTypeForm(DELETE);
    setIsOpenDrawer(true);
  };

  const editTypeForm = (val: string) => setTypeForm(val);
  const toggleDrawer = (val: boolean) => setIsOpenDrawer(val);


  return (
    <div className={style.container}>
      <Flex
        justify={"space-between"}
        align={"flex-start"}
        style={{ margin: "20px 28px 0px" }}
      >
        <Typography.Title level={4}>
          {t(title)}
        </Typography.Title>
        <Flex gap={16}>
          <Button className={style['button']} onClick={onCreate} type='primary'>
            {t("Добавить компанию в справочник")}
          </Button>
          <Button className={style['button']} onClick={onDelete} type='default'>
            {t("Удалить компанию из справочника")}
          </Button>
        </Flex>
      </Flex>
      <TableGuide
        isOpenDrawer={isOpenDrawer}
        typeForm={typeForm}
        toggleDrawer={toggleDrawer}
        editTypeForm={editTypeForm}
        paths={paths}
        activeKey={activeKey}
      />
    </div>
  );
};
