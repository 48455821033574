import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Rest_agreement } from '../services/rest_agreement';
import { CREATE } from '../helpers/string-helpers';

export interface ITypeFindAgreements {
  client_id: number;
  executor_id: number;
}

export interface IAgreement {
  readonly id?: number;
  date_signing: Date | null | string;
  readonly date_string: string;
  readonly agreement_id: string;
  readonly type_id: number | null;
  readonly executor_id?: number | null;
  readonly executor?: string;
  readonly client_id?: number | null;
  readonly client?: string;
  readonly approve_to_id: number | null;
  readonly agreed?: boolean;
  readonly date_issue: string | null;
  readonly date_course: string | null;
  readonly payment_term: string;
  readonly conversion: number | null;
  responsible_id?: number;
  type?: any;
  approving?: any;
  refused?: boolean;
  delete?: boolean;
  files?: any[];
}

export interface IForm {
  title?: string;
  initialValue: IAgreement;
  isOpen: boolean;
  isCopy?: boolean;
  typeForm: string;
  setSaveFiles?: (files: File[]) => void;
  onClose: (res?: boolean) => void;
  onEdit?: () => void;
}

interface IAgreementState {
  typeForm: string;
  isOpenDrawer: boolean;
  agreements: IAgreement[];
  agreement?: IAgreement;
  loadingSave: boolean;
  types: { id: number; value: string }[];
  saveFiles: any[];
}
export const defaultAgreement: IAgreement = {
  id: undefined,
  date_signing: null,
  date_issue: '',
  date_course: '',
  conversion: null,
  payment_term: '',
  date_string: '',
  agreement_id: '',
  type_id: null,
  executor_id: null,
  executor: '',
  client_id: null,
  client: '',
  approve_to_id: null,
  agreed: false,
  refused: undefined,
  responsible_id: undefined,
  files: []
};
const initialState: IAgreementState = {
  isOpenDrawer: false,
  typeForm: CREATE,
  agreements: [],
  agreement: defaultAgreement,
  saveFiles: [],
  types: [],
  loadingSave: false,
};

export const getAgreementTypes = createAsyncThunk(
  'getAgreementTypes',
  async () => {
    return await Rest_agreement.getAgreementTypes();
  },
);

export const setAgreement = createAsyncThunk(
  'setAgreement',
  async (payload: any) => {
    return await Rest_agreement.setAgreement(payload);
  },
);
export const deleteIds = createAsyncThunk(
  'deleteIds',
  async (payload: any) => {
    return await Rest_agreement.deleteIds(payload);
  },
);

const agreementSlice = createSlice({
  name: 'agreement',
  initialState,
  reducers: {
    resetStoreAgreement(state) {
      state.isOpenDrawer = initialState.isOpenDrawer
      state.typeForm = initialState.typeForm
      state.agreements = initialState.agreements
      state.agreement = initialState.agreement
      state.saveFiles = initialState.saveFiles
      state.types = initialState.types
      state.loadingSave = initialState.loadingSave
    },
    setSaveFiles(state, action) {
      state.saveFiles = action.payload;
    },
    setOneAgreement(state, action) {
      state.agreement = action.payload;
    },
    setTypeForm(state, action) {
      state.typeForm = action.payload;
    },
    setIsOpenDrawer(state, action) {
      state.isOpenDrawer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAgreementTypes.fulfilled, (state, action) => {
      state.types = action.payload;
    });
    builder.addCase(setAgreement.fulfilled, (state, action) => {
      state.agreement = action.payload;
      state.loadingSave = false;
    });
    builder.addCase(setAgreement.pending, (state, action) => {
      state.loadingSave = true;
    });
    builder.addCase(setAgreement.rejected, (state, action) => {
      state.loadingSave = false;
    });
  },
});
export default agreementSlice.reducer;
export const {
  setSaveFiles,
  setOneAgreement,
  setIsOpenDrawer,
  setTypeForm,
  resetStoreAgreement
} = agreementSlice.actions;
