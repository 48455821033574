import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { App, AutoComplete, Button, Flex, Input, Space } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import { RouteNames } from "../../../router/names";
import { RootState, useStoreDispatch } from "../../../store";
import { SessionStorageManager } from "../../../components/ui/Table/sessionStorageManager";
import { StorageData } from "../../../types";
import { useBreadcrumb, useMenu, useParamsHistory } from "../../../hooks";
import {
  IColumnType,
  IPagination,
  Table,
} from "../../../components/ui/Table/Table";
import {
  setTableRequests,
  IRequest,
  defaultValuesRequest,
  findOneRequest,
  deleteIdsRequest,
  resetStoreRent,
} from "../../../store/rent";
import { columns } from "./columns";
import socket from "../../../socket";
import {
  DELETE_REQUESTS_RENT,
  GET_REQUESTS_RENT,
  Rest_rent,
  SET_RENT_REQUESTS,
  UPDATE_RENT_REQUESTS,
} from "../../../services/rest_rent";
import {
  TABLE_RENT_REQUESTS,
  ROW_RENT_REQUESTS,
  COUNT_RENT_REQUESTS,
} from "../deals/constants";
import {
  CREATE,
  EDIT,
  getTypeForm,
  VIEW,
} from "../../../helpers/string-helpers";
import { RENT_REQUEST, REQUESTS } from "../../../constants";
import { setOneRequest } from "../../../store/rent";
import CustomDrawer from "../../../components/CustomDrawer/CustomDrawer";
import FormModalRentRequest from "./components/formModalRentRequest";
import { useGettingDataForTables } from "../../../hooks/useGettingDataForTables/useGettingDataForTables";
import { mapperRentRequests } from "./utils";
import { Utils } from "../../../utils";
import { getUserPermissions } from "../../../store/users";
import {
  hasViewPermission,
  hasEditPermission,
  hasDeletePermission,
} from "../../../components/Menu/permissions";

const Requests = () => {
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(Utils.getUserInfo().id);
  const [activeButtons, setActiveButtons] = useState<any>({});
  const [searchColumn, setSearchColumn] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();

  const [openDrawerRequest, setOpenDrawerRequest] = useState<boolean>(false);
  const requestOneData = useSelector<RootState>(
    (state) => state.rent.requestOneData
  ) as IRequest;

  const {
    dataTable,
    current,
    pageSize,
    optionsTable,
    setDataTable,
    updateDate,
    updateEffectDate,
  } = useGettingDataForTables({
    titlePage: "запрос аренды",
    keyItemName: "id_request",

    // subscribeStringCreateForm: "res-set-rent-requests",
    // subscribeStringUpdateForm: "res-update-rent-requests",
    subscribeStringDelete: "return-delete-rent-requests",
    subscribeError: "error",
    table: RENT_REQUEST,
    location,
    columns,
    fetchTables: Rest_rent.getRentRequests,
    fetchDelete: (arg: any) => dispatch(deleteIdsRequest(arg)),
  });

  //==============================================
  // подключаемся к хранилищу данных с правами
  //==============================================
  const permissionsData: any = useSelector(
    (state: RootState) => state.users.permissionsData
  );

  /// получаем данные только по запросу (не все)
  // через утилиту hasView/Edit/DeletePermission
  const canView = hasViewPermission(permissionsData, "rentRequests");
  const canEdit = hasEditPermission(permissionsData, "rentRequests");
  const canDelete = hasDeletePermission(permissionsData, "rentRequests");

  // ---------------------------------------------------------------------
  // ПОДКЛЮЧЕНИЕ К СОКЕТАМ: добавление, обновление, удаление
  // ---------------------------------------------------------------------
  useEffect(() => {
    // 1) При успешном создании новой записи сервер шлёт "res-set-rent-requests"
    socket.on("res-set-rent-requests", (newRequest) => {
      // console.log("Новая запись со всеми связями:", newRequest);
      // newRequest — новый объект, который вернулся из сокета при создании
      // Мы добавляем его в существующий стейт, чтобы отобразить без перезагрузки
      setDataTable((prev) => [newRequest, ...prev]);
    });

    // 2) При успешном обновлении записи приходит "res-update-rent-requests"
    socket.on("res-update-rent-requests", (updatedRequest) => {
      // updatedRequest — обновлённый объект
      // Находим в массиве элемент с тем же id и заменяем его
      setDataTable((prev) =>
        prev.map((item) =>
          item.id === updatedRequest.id ? updatedRequest : item
        )
      );
    });

    // 3) При успешном удалении сервер возвращает "return-delete-rent-requests"
    socket.on("return-delete-rent-requests", (res) => {
      // В res обычно передаётся массив ids (res.ids) — то, что было удалено
      const { ids } = res;
      setDataTable((prev) => prev.filter((item) => !ids.includes(item.id)));
    });

    // В конце отписываемся от событий, чтобы не плодить слушатели
    return () => {
      socket.off("res-set-rent-requests");
      socket.off("res-update-rent-requests");
      socket.off("return-delete-rent-requests");
    };
  }, [setDataTable]);

  useBreadcrumb(["Аренда", "Запросы аренды"]);
  useMenu({
    openKey: RouteNames.RENT,
    activeKeys: [RouteNames.RENT, RouteNames.RENT_REQUESTS],
  });
  const [typeForm, setTypeForm] = useState<string>(
    requestOneData?.id ? EDIT : CREATE
  );

  useEffect(() => {
    if (requestOneData?.id) {
      setTypeForm(EDIT);
    } else {
      setTypeForm(CREATE);
    }
  }, [requestOneData]);

  useEffect(() => {
    if (pathname === RouteNames.RENT_REQUESTS) {
      dispatch(resetStoreRent());
      updateEffectDate();
      const rent = location?.state?.[REQUESTS];
      if (rent) {
        if (rent?.edit) {
          dispatch(setOneRequest(rent.edit));
          setTypeForm(EDIT);
          setOpenDrawerRequest(true);
          return;
        }
        setTypeForm(CREATE);
        dispatch(
          setOneRequest({
            // ...defaultValuesRequest,
            ...rent,
          })
        );

        setOpenDrawerRequest(true);
        return;
      }
    }
    return history.replace(RouteNames.RENT_REQUESTS);
  }, [pathname]);

  const handleButtonClickSearch = (option: any) => {
    setActiveButtons((prevActiveButtons: any) => {
      setSearchColumn((prev) =>
        prevActiveButtons[option.key]
          ? prev.filter((item: string) => item !== option.dataIndex)
          : [...prev, option.dataIndex]
      );
      return {
        ...prevActiveButtons,
        [option.key]: !prevActiveButtons[option.key],
      };
    });
  };

  const onCloseFormDeal = () => {
    dispatch(resetStoreRent());
    setOpenDrawerRequest(false);
  };

  const handleSearch = (val: string) => {};

  const onCreate = () => {
    setTypeForm(CREATE);
    dispatch(setOneRequest(defaultValuesRequest));
    setOpenDrawerRequest(true);
  };

  const onClickLink = (data: any) => {
    const row = data;
    if (row?.id) {
      setTypeForm(EDIT);
      dispatch(setOneRequest(row));
      setOpenDrawerRequest(true);
    }
  };

  const onRow = (data: IRequest) => {
    const row = data;
    if (row?.id) {
      setTypeForm(EDIT);
      dispatch(setOneRequest(row));
      setOpenDrawerRequest(true);
    }
  };

  const handleShowEditForm = async (data: IRequest) => {
    const row = data;
    if (row?.id) {
      setTypeForm(EDIT);
      await dispatch(findOneRequest(Number(row.id)));
      setOpenDrawerRequest(true);
    }
  };

  const handleShowDrawerRequest = (data: IRequest) => {
    setTypeForm(CREATE);
    dispatch(setOneRequest(defaultValuesRequest));
    setOpenDrawerRequest(true);
    console.log("тип handleShowDrawerRequest", typeForm);
  };

  const onCloseFormRequest = () => {
    dispatch(resetStoreRent());
    setOpenDrawerRequest(false);
  };

  if (!canView) {
    return (
      <div style={{ padding: 20, fontSize: 16, textAlign: "center" }}>
        {t("У вас нет прав на просмотр данной страницы")}
      </div>
    );
  }

  return (
    <>
      <Flex
        gap={10}
        justify={"space-between"}
        align={"flex-start"}
        style={{ width: "100%" }}
        className={"container"}
      >
        <Flex align={"center"} gap={10}>
          <div className={"drawer-title"}>{t("Запросы аренды")}</div>
        </Flex>
        <Flex gap={16}>
          {canEdit && (
            <Button
              icon={<PlusOutlined />}
              style={{ background: "#05F", color: "#fff", minWidth: "32px" }}
              onClick={onCreate}
            />
          )}
          <AutoComplete
            allowClear
            style={{ width: 300 }}
            options={columns}
            defaultValue={searchValue}
            popupMatchSelectWidth={false}
            onSearch={handleSearch}
            dropdownRender={() => (
              <>
                <div
                  className={"text-card-12"}
                  style={{ padding: "10px 10px" }}
                >
                  {t("Выбор колонок по которым будет производиться поиск")}
                </div>
                <Flex
                  gap={5}
                  style={{ width: 300, flexWrap: "wrap", padding: 10 }}
                >
                  {columns.map(
                    (option) =>
                      option.title !== "" &&
                      option.dataIndex !== "date_entry" &&
                      option.dataIndex !== "arrival_date" &&
                      option.dataIndex !== "departure_date" &&
                      option.dataIndex !== "booking_before" && (
                        <Button
                          size={"small"}
                          key={option.key}
                          type="primary"
                          className={
                            activeButtons[Number(option.key)]
                              ? "active-search-btn"
                              : ""
                          }
                          onClick={() => handleButtonClickSearch(option)}
                        >
                          {option.title}
                        </Button>
                      )
                  )}
                </Flex>
              </>
            )}
          >
            <Input placeholder={t("Введите текст")} />
          </AutoComplete>
        </Flex>
      </Flex>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Table
          {...optionsTable}
          // onClickLink={onClickLink}
          // editRow={handleShowEditForm}
          onClickLink={onRow}
          editRow={onRow}
          onRow={onRow}
          dataSource={dataTable}
          height={"calc(-162px + 100vh)"}
          style={{ overflow: "auto hidden" }}
          canView={false}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      </Space>
      <CustomDrawer
        open={openDrawerRequest}
        onClose={onCloseFormRequest}
        isHeight
        minWidth={1000}
      >
        <FormModalRentRequest
          title={
            typeForm === CREATE ? "Создать сделку" : "Редактировать сделку"
          }
          isOpen={openDrawerRequest}
          onClose={onCloseFormDeal}
          initialValue={requestOneData || defaultValuesRequest}
          onEdit={() => setTypeForm(EDIT)}
          typeForm={typeForm}
        />
      </CustomDrawer>
    </>
  );
};

export default Requests;
