import { RouteNames } from "../../router/names";

export const optionsBoolean = [
  {value: 1, label: 'Да'},
  {value: 0, label: 'Нет'},
];

export const pathsCrm = {
  parent: RouteNames.CRM_AGREEMENT,
}

export const TABLE_AGREEMENT = 'tableAgreement';
export const ROW_AGREEMENT = 'rowAgreement';
export const COUNT_AGREEMENT = 'countAgreement';