import { apiAxios } from "../api/config";
import socket from "../socket";
import { IBilling } from "../store/finance";
import { IParamRestDelete } from "../types";

export class Rest_finance {
    static getFinanceBilling(param?: any): Promise<any> {
      return apiAxios.post("finance/get-finance-invoice", param);
    }

    static findOneFinanceBilling(id: string | number): Promise<any> {
      return apiAxios.get(`finance/${id}`);
    }

  static setFinanceBilling(payload: IBilling): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('set-finance-invoice', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
  static updateFinanceBilling(payload: IBilling): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('update-finance-invoice', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
  static deleteIdsBilling(payload: IParamRestDelete): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('delete-finance-invoice', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
}