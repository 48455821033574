import { Link } from "react-router-dom";
import { Typography, Flex } from "antd";
import IRate from "../../../components/ui/Rate";
import { IColumnType } from "../../../components/ui/Table/Table";
import RouteList from "./components/list/RouteList";
import { RouteNames } from "../../../router/names";
import { IDeal, ILocation } from "../../../store/rent";

import { IRelease } from "../../../store/releases";

export const columns: IColumnType<any>[] = [
  {
    dataIndex: "id",
    filterIndex: "id",
    checkbox: true,
    menu: true,
    type: "string",
    key: "0",
  },
  {
    title: "ID",
    dataIndex: "id_request",
    filterIndex: "id_request",
    link: true,
    width: "5%",
    type: "string",
    key: "1",
  },
  {
    title: "Название запроса",
    dataIndex: "name_request",
    filterIndex: "name_request",
    key: "2",
    type: "string",
    render: (data, item) => (
      <Typography.Text>{item?.name_request || ""}</Typography.Text>
    ),
  },
  {
    title: "Юр. лицо",
    dataIndex: "my_company",
    filterIndex: "my_company",
    key: "3",
    type: "string",
    render: (_, item) => (
      <Typography.Text>{item?.my_company || ""}</Typography.Text>
    ),
  },
  {
    title: "Клиент",
    dataIndex: "company",
    filterIndex: "company",
    key: "4",
    type: "string",
    render: (_, item) => (
      <Typography.Text>{item?.company || ""}</Typography.Text>
    ),
  },
  {
    title: "№ Договора",
    dataIndex: "company",
    filterIndex: "company",
    key: "5",
    type: "string",
    render: (_, item) => (
      <Typography.Text>{item?.agreement || ""}</Typography.Text>
    ),
  },
  // {
  //   title: "Локация релиза",
  //   dataIndex: "location_release",
  //   filterIndex: "location_release",
  //   key: "6",
  //   type: "string",
  //   render: (_, item) =>
  //     item?.location_release ? (
  //       <Typography.Paragraph
  //         ellipsis={{
  //           rows: 2,
  //           tooltip: { placement: 'left', title: item.location_release },
  //         }}
  //         style={{ maxWidth: '200px', margin: 0 }}
  //       >
  //         {item.location_release}
  //       </Typography.Paragraph>
  //     ) : null,
  // },
  {
    title: "Локация релиза",
    dataIndex: "location_release",
    filterIndex: "location_release",
    key: "7",
    type: "string",
    render: (_, item) => (
      <Typography.Text>
        {item?.location_release?.[0]?.location_release || ""}
      </Typography.Text>
    ),
  },
  {
    title: "Локация возврата",
    dataIndex: "request_location",
    filterIndex: "request_location",
    key: "7",
    type: "string",
    render: (_, item) => (
      <Typography.Text>
        {item?.request_location?.[0]?.location_return || ""}
      </Typography.Text>
    ),
  },
  {
    title: "Ставка",
    dataIndex: "request_location",
    filterIndex: "request_location",
    key: "8",
    type: "string",
    render: (_, item) => (
      <Typography.Text>
        {item?.request_location?.[0]?.bet || ""}&ensp;
        {item?.currency || ""}
      </Typography.Text>
    ),
  },
  {
    title: "Тип КТК",
    dataIndex: "type_container",
    filterIndex: "type_container",
    key: "9",
    type: "string",
    render: (_, item) => (
      <Typography.Text>{item?.type_container || ""}</Typography.Text>
    ),
  },
  {
    title: "Состояние",
    dataIndex: "condition",
    filterIndex: "condition",
    key: "10",
    type: "string",
    render: (_, item) => (
      <Typography.Text>{item?.condition || ""}</Typography.Text>
    ),
  },
  {
    title: "Кол-во КТК",
    dataIndex: "count_ktk",
    filterIndex: "count_ktk",
    key: "11",
    type: "number",
    render: (_, item) => (
      <Typography.Text>{item?.count_ktk || ""}</Typography.Text>
    ),
  },
  {
    title: "Валюта",
    dataIndex: "currency",
    filterIndex: "currency",
    key: "12",
    type: "string",
    render: (_, item) => (
      <Typography.Text>{item?.currency || ""}</Typography.Text>
    ),
  },
  {
    title: "Кол-во дней",
    dataIndex: "count_day",
    filterIndex: "count_day",
    key: "13",
    type: "number",
    render: (_, item) => (
      <Typography.Text>{item?.count_day || ""}</Typography.Text>
    ),
  },
  {
    title: "СНП",
    dataIndex: "snp",
    filterIndex: "snp",
    key: "14",
    type: "number",
    render: (_, item) => <Typography.Text>{item?.snp || ""}</Typography.Text>,
  },
  {
    title: "",
    dataIndex: "hidden",
    filterIndex: "hidden",
    width: "2%",
    key: "8",
  },
];
