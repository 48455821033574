import { URL_USER } from '../api/config';
import { Utils } from '../utils';
import { VIEW_SEARCH } from './string-helpers';

// открывает ссылку в новом окне
export const openInNewTab = (url?: string, focus = true) => {
  if (!url) return;

  const win = window.open(url, '_blank');
  if (win && focus) {
    win.focus();
  }
};
// создает строку для страницы просмотра
export const getSearchLink = ({
  key,
  id,
  noUrl,
  noSearch,
}: {
  key: string;
  id?: number | string | null;
  noUrl?: boolean;
  noSearch?: boolean;
}) => {
  if (!id) return '';
  const stringId = Utils.encrypt(`${id}`);
  if (!noSearch) {
    const searchParams = new URLSearchParams();
    searchParams.set('id', stringId);
    return `${
      noUrl ? '' : URL_USER
    }${VIEW_SEARCH}${key}?${searchParams.toString()}`;
  }
  return stringId;
};

export const base64ToArrayBuffer = (base64: any) => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const getFileString = ({
  file,
  type,
}: {
  file: string;
  type: string;
}) => {
  try {
    return new Blob([base64ToArrayBuffer(file)], { type });
  } catch {
    return null;
  }
};

export const dataURLtoFile = ({
  file,
  filename,
  type,
}: {
  file: string;
  filename: string;
  type: string;
}) => {
  try {
    const arr = file.split(',');
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type });
  } catch {
    return null;
  }
};

export function downloadLinkContentBuffer({
  content,
  fileName = 'text.txt',
  contentType = 'text/plain',
}: {
  content: any;
  fileName?: string;
  contentType?: string;
}) {
  const a = document.createElement('a');
  const file = new Blob([base64ToArrayBuffer(content)], { type: contentType });
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  a.click();
}
export function downloadLinkContent({
  content,
  fileName,
}: {
  content: any;
  fileName: string;
}) {
  const url = window.URL.createObjectURL(new Blob([content]));

  // Создаем временную ссылку для скачивания
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName); // Указываем имя файла

  // Добавляем ссылку на страницу и инициируем клик
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
}

export const isValidHttpUrl = (link: string) => {
  try {
    const url = new URL(link);
    if (url) {
      return url.protocol === 'http:' || url.protocol === 'https:';
    }
    return false;
  } catch (_) {
    return false;
  }
};
