import { useEffect, useMemo, useState } from "react";

import { Flex, Tabs, TabsProps } from "antd";

import Contacts from "../contacts/Contacts";
import { RouteNames } from "../../router/names";
import Contractors from "../contractors/Contractors";
import { useBreadcrumb, useMenu, useParamsHistory } from "../../hooks";
import {
  COMPANY,
  CONTACT,
  crmNames,
  SALES_DEAL,
  REQUESTS,
  INTEGRATION,
  AGREEMENT,
  GUIDE,
  NOT_FOUND,
} from "../../constants";

import style from "./style.module.scss";
import { useTranslation } from "react-i18next";
import AgreementsCRM from "../agreements";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { IUsers } from "../../types/users";
import { setUserPermissions } from "../../store/users";
import { getUserPermissions } from "../../store/users";
import { useStoreDispatch } from "../../store";
import { Utils } from "../../utils";
import socket from "../../socket";
import { hasViewPermission } from "../../components/Menu/permissions";


export const CrmContainers = () => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const { history, location, id } = useParamsHistory();
  const [activeKey, setActiveKey] = useState("");
  const [activeTitle, setActiveTitle] = useState("");
  const permissionsData: any = useSelector(
    (state: RootState) => state.users.permissionsData
  );

  const profile: IUsers | undefined = useSelector(
    (state: RootState) => state.users.user
  );

  useBreadcrumb(["CRM", activeTitle]);
  useMenu({ activeKeys: [RouteNames.CRM] });

  const canViewAgreements = hasViewPermission(permissionsData, "agreements");
  const canViewContractors = hasViewPermission(permissionsData, "contractors");
  const canViewContacts = hasViewPermission(permissionsData, "contacts");

  const items: TabsProps["items"] = useMemo(
    () => [
      ...(canViewAgreements
        ? [
            {
              key: AGREEMENT,
              label: t(crmNames[AGREEMENT]),
              children: (
                <AgreementsCRM
                  title={t(crmNames[AGREEMENT])}
                  activeKey={activeKey}
                />
              ),
            },
          ]
        : []),
      // // { key: SALES_DEAL, label: t(crmNames[SALES_DEAL]), children: "Сделки" },
      ...(canViewContractors
        ? [
            {
              key: COMPANY,
              label: t(crmNames[COMPANY]),
              children: (
                <Contractors
                  title={t(crmNames[COMPANY])}
                  activeKey={activeKey}
                />
              ),
            },
          ]
        : []),
      ...(canViewContacts
        ? [
            {
              key: CONTACT,
              label: t(crmNames[CONTACT]),
              children: (
                <Contacts title={t(crmNames[CONTACT])} activeKey={activeKey} />
              ),
            },
          ]
        : []),
      // {
      //   key: INTEGRATION,
      //   label: t(crmNames[INTEGRATION]),
      //   children: "Интеграции",
      // },
    ],
    [activeKey, t, canViewAgreements, canViewContractors, canViewContacts]
  );

  const handleClickTab = (key: string) => {
    setActiveKey(key);
    setActiveTitle(crmNames[key]);
    history.replace(`${RouteNames.CRM}/${key}`);
  };

  useEffect(() => {
    switch (id) {
      case AGREEMENT:
        setActiveKey(AGREEMENT);
        setActiveTitle(crmNames[AGREEMENT]);
        return;
      // case SALES_DEAL:
      //   setActiveKey(SALES_DEAL);
      //   setActiveTitle(crmNames[SALES_DEAL]);
      //   return;
      case REQUESTS:
        setActiveKey(REQUESTS);
        setActiveTitle(crmNames[REQUESTS]);
        return;
      case CONTACT:
        setActiveKey(CONTACT);
        setActiveTitle(crmNames[CONTACT]);
        return;
      case COMPANY:
        setActiveKey(COMPANY);
        setActiveTitle(crmNames[COMPANY]);
        return;
      // case INTEGRATION:
      //   setActiveKey(INTEGRATION);
      //   setActiveTitle(crmNames[INTEGRATION]);
      //   return;
      default:
        setActiveKey(COMPANY);
        setActiveTitle(crmNames[COMPANY]);
    }
  }, [id]);

  return (
    <Flex style={{ width: "100%" }} vertical className={style["crm"]}>
      {/* <Title level={2} style={{ padding: '20px 18px' }}>
      {profile?.name ? `${t('Доброе утро')} ${profile.name}!` : ''}
      </Title> */}
      <Tabs
        defaultActiveKey={activeKey}
        activeKey={activeKey}
        onChange={handleClickTab}
        items={items}
        style={{ width: "100%", padding: "20px 0" }}
        indicator={{ size: (origin: any) => origin - 100, align: "center" }}
      />
    </Flex>
  );
};

// import { useEffect, useMemo, useState } from "react";

// import { Flex, Tabs, TabsProps, Typography } from "antd";

// import Contacts from "../contacts/Contacts";
// import { RouteNames } from "../../router/names";
// import Contractors from "../contractors/Contractors";
// import { useBreadcrumb, useMenu, useParamsHistory } from "../../hooks";
// import {
//   COMPANY,
//   CONTACT,
//   crmNames,
//   SALES_DEAL,
//   REQUESTS,
//   INTEGRATION,
//   AGREEMENT,
//   GUIDE,
//   NOT_FOUND,
// } from "../../constants";

// import style from "./style.module.scss";
// import { useTranslation } from "react-i18next";
// import AgreementsCRM from "../agreements";
// import { useSelector } from "react-redux";
// import { RootState } from "../../store";
// import { IUsers } from "../../types/users";
// import { setUserPermissions } from "../../store/users";
// import { getUserPermissions } from "../../store/users";
// import { useStoreDispatch } from "../../store";
// import { Utils } from "../../utils";
// import socket from "../../socket";
// import { hasViewPermission } from "../../components/Menu/permissions";

// const { Title } = Typography;

// export const CrmContainers = () => {
//   const { t } = useTranslation();
//   const dispatch = useStoreDispatch();
//   const { history, location, id } = useParamsHistory();
//   const [activeKey, setActiveKey] = useState("");
//   const [activeTitle, setActiveTitle] = useState("");
//   const permissionsData: any = useSelector(
//     (state: RootState) => state.users.permissionsData
//   );

//   const profile: IUsers | undefined = useSelector(
//     (state: RootState) => state.users.user
//   );

//   useBreadcrumb(["CRM", activeTitle]);
//   useMenu({ activeKeys: [RouteNames.CRM] });

//   const canViewAgreements = hasViewPermission(permissionsData, "agreements");
//   const canViewContractors = hasViewPermission(permissionsData, "contractors");
//   const canViewContacts = hasViewPermission(permissionsData, "contacts");

//   // 1) Формируем массив «настоящих» вкладок
//   const realTabs: TabsProps["items"] = useMemo(() => {
//     const arr: TabsProps["items"] = [];
//     if (canViewAgreements) {
//       arr.push({
//         key: AGREEMENT,
//         label: t(crmNames[AGREEMENT]),
//         children: (
//           <AgreementsCRM title={t(crmNames[AGREEMENT])} activeKey={activeKey} />
//         ),
//       });
//     }
//     if (canViewContractors) {
//       arr.push({
//         key: COMPANY,
//         label: t(crmNames[COMPANY]),
//         children: (
//           <Contractors title={t(crmNames[COMPANY])} activeKey={activeKey} />
//         ),
//       });
//     }
//     if (canViewContacts) {
//       arr.push({
//         key: CONTACT,
//         label: t(crmNames[CONTACT]),
//         children: (
//           <Contacts title={t(crmNames[CONTACT])} activeKey={activeKey} />
//         ),
//       });
//     }
//     return arr;
//   }, [canViewAgreements, canViewContractors, canViewContacts, activeKey, t]);

//   // 2) Определяем, существует ли текущий activeKey среди «реальных» вкладок
//   const tabExists = realTabs.some((tab) => tab.key === activeKey);

//   // 3) Если вкладка не существует (значит, нет прав или неправильный урл),
//   //    добавляем «фейковую» вкладку, чтобы Ant не переключался автоматически.
//   let extendedTabs = realTabs;
//   if (!tabExists && activeKey) {
//     extendedTabs = [
//       ...realTabs,
//       {
//         key: activeKey,
//         label: "",
//         children: (
//           <div style={{ padding: 16, fontSize: 16 }}>
//             {t("У вас нет прав на просмотр раздела")}{" "}
//             <strong>{t(crmNames[id] || id)}</strong>! 😝
//           </div>
//         ),
//       },
//     ];
//   }

//   const handleClickTab = (key: string) => {
//     setActiveKey(key);
//     setActiveTitle(crmNames[key]);
//     history.replace(`${RouteNames.CRM}/${key}`);
//   };

//   useEffect(() => {
//     switch (id) {
//       case AGREEMENT:
//         setActiveKey(AGREEMENT);
//         setActiveTitle(crmNames[AGREEMENT]);
//         return;
//       // case SALES_DEAL:
//       //   setActiveKey(SALES_DEAL);
//       //   setActiveTitle(crmNames[SALES_DEAL]);
//       //   return;
//       case REQUESTS:
//         setActiveKey(REQUESTS);
//         setActiveTitle(crmNames[REQUESTS]);
//         return;
//       case CONTACT:
//         setActiveKey(CONTACT);
//         setActiveTitle(crmNames[CONTACT]);
//         return;
//       case COMPANY:
//         setActiveKey(COMPANY);
//         setActiveTitle(crmNames[COMPANY]);
//         return;
//       // case INTEGRATION:
//       //   setActiveKey(INTEGRATION);
//       //   setActiveTitle(crmNames[INTEGRATION]);
//       //   return;
//       default:
//         setActiveKey(COMPANY);
//         setActiveTitle(crmNames[COMPANY]);
//     }
//   }, [id]);

//   return (
//     <Flex style={{ width: "100%" }} vertical className={style["crm"]}>
//       <Tabs
//         // Если таб не существует, всё равно держим activeKey => AntD не переключается
//         activeKey={activeKey || extendedTabs[0]?.key}
//         onChange={handleClickTab}
//         items={extendedTabs}
//         style={{ width: "100%", padding: "20px 0" }}
//       />
//     </Flex>
//   );
// };
