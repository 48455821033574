import { RouteNames } from "../../../../router/names";

export const optionsBoolean = [
  {value: 1, label: 'Да'},
  {value: 0, label: 'Нет'},
];

export const paths = {
  parent: RouteNames.STORAGE_CONDITIONS_GUIDE,
}
