import React, { useEffect, useState } from "react";
import { Form, Input, Col, Row, Divider, Button } from "antd";
import { purchaseColumns, subleaseColumns, ktkColumns } from "./columns";
import EditableTable from "../table/Table";
import Icon from "@ant-design/icons";
import { ReloadOutlined } from "@ant-design/icons";
import { successIcon } from "../../../../../assets/image/svg";
import { errorImportIcon } from "../../../../../assets/image/svg";
import style from "../style.module.scss";
import FormList from "antd/es/form/FormList";
import { IPurchase, ISublease } from "../../../../../store/rent";

interface ApprovalTabProps {
  form?: any;
  initialValue: any;
  isAgreed: boolean;
  toggleApproval: () => void;
  returnToRevision: () => void;
  tableName: string;
  data: any;
  setApprovalState: (value: boolean) => void;
  isViewMode: boolean;
  rightsApprovalTabEdit?: boolean;
}

const ApprovalTab: React.FC<ApprovalTabProps> = ({
  initialValue,
  isAgreed,
  toggleApproval,
  returnToRevision,
  form,
  tableName,
  data,
  setApprovalState,
  isViewMode,
  rightsApprovalTabEdit,
}) => {
  const [formData, setFormData] = useState(initialValue);

  const locationReturnList = form.getFieldValue("location_return") || [];
  const locationReleaseList = form.getFieldValue("location_release") || [];

  useEffect(() => {
    if (initialValue) {
      setFormData({ ...initialValue, ...initialValue });
    }
  }, [initialValue]);

  useEffect(() => {
    if (form) {
      const approvedValue = form.getFieldValue("approved");
      if (approvedValue !== undefined) {
        setApprovalState(!!approvedValue);
      }
      // console.log("filteredPurchase", filteredPurchase);
      // console.log("filteredSublease", filteredSublease);
      // console.log("filteredKtk", filteredKtk);
    }
  }, [form, setApprovalState]);

  const filteredPurchase =
    initialValue?.purchase?.filter((row: any) => row?.is_selected === true) ||
    [];

  const filteredSublease =
    initialValue?.sublease?.filter((row: any) => row?.is_selected === true) ||
    [];

  const filteredKtk =
    initialValue?.ktk?.filter((row: any) => row?.is_selected === true) || [];

  return (
    <Form layout="vertical" form={form} className={style.requestTab}>
      <Form.Item
        label="Название"
        name="name_request"
        className={
          isViewMode ? style.approvalInputsDisabled : style.approvalInputs
        }
      >
        <Input
          disabled
          value={data}
          className={style.inputName}
          // style={{ color: "#0E1117", backgroundColor: "#0e111700" }}
        />
      </Form.Item>
      <Row gutter={24}>
        <Col
          span={9}
          // className={style.approvalInputs}
          className={
            isViewMode ? style.approvalInputsDisabled : style.approvalInputs
          }
        >
          <Form.Item label="Наше юридическое лицо" name="my_company">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Клиент" name="company">
            <Input disabled />
          </Form.Item>

          <Form.Item label="№ Договора" name="agreement">
            <Input disabled />
          </Form.Item>

          {locationReleaseList.map((rel: any, indexRelease: number) => (
            <Form.Item label={`Локация релиза`} key={indexRelease}>
              <Input value={rel.location_release} disabled />
            </Form.Item>
          ))}

          <Row
            gutter={16}
            align="middle"
            style={{ paddingLeft: "15px", width: "345px" }}
          >
            {locationReturnList.map((loc: any, indexReturn: number) => (
              <Row
                key={indexReturn}
                gutter={16}
                align="middle"
                className={style.locationReturnApprooval}
              >
                <Form.Item label={`Локация возврата`}>
                  <Input
                    value={loc.location}
                    disabled
                    style={{ width: "335px" }}
                  />
                </Form.Item>
                <Form.Item label="Ставка">
                  <Input value={loc.bet} disabled style={{ width: "335px" }} />
                </Form.Item>
              </Row>
            ))}
          </Row>

          <Form.Item label="Тип КТК" name="type_container">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Состояние" name="condition">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Количество КТК" name="count_ktk">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Валюта" name="currency">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Количество дней" name="count_day">
            <Input disabled />
          </Form.Item>

          <Form.Item label="СНП" name="snp">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col
          span={15}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            marginTop: "10px",
            pointerEvents: isViewMode ? "none" : "auto",
            opacity: isViewMode ? 0.6 : 1,
          }}
        >
          <div className={style.ApprovalTableContainer}>
            <div className={style.ApprovalTable}>
              {filteredPurchase.length > 0 && (
                <>
                  <Divider className={style.RequestTablesDivider}>
                    Закуп
                  </Divider>
                  <EditableTable
                    columns={purchaseColumns}
                    fields={filteredPurchase.map((row: IPurchase, id: any) => ({
                      name: id.company,
                      key: id,
                      ...row,
                    }))}
                    rowCount={filteredPurchase.length}
                    columnWidths="15% 11% 9% 13% 15% 17% 20%"
                    isEditable={false}
                  />
                </>
              )}
            </div>

            <div className={style.ApprovalTable}>
              {filteredSublease.length > 0 && (
                <>
                  <Divider className={style.RequestTablesDivider}>
                    {tableName}
                  </Divider>
                  <EditableTable
                    columns={subleaseColumns}
                    fields={filteredSublease.map((row: ISublease, id: any) => ({
                      name: id.company,
                      key: id,
                      ...row,
                    }))}
                    rowCount={filteredSublease.length}
                    columnWidths="15% 11% 8% 13% 11% 16% 11% 7% 8%"
                    isEditable={false}
                  />
                </>
              )}
            </div>

            <div className={style.ApprovalTable}>
              {filteredKtk.length > 0 && (
                <>
                  <Divider className={style.RequestTablesDivider}>
                    КТК МК
                  </Divider>
                  <EditableTable
                    columns={ktkColumns}
                    fields={filteredKtk.map((row: IPurchase, id: any) => ({
                      name: id.company,
                      key: id,
                      ...row,
                    }))}
                    rowCount={filteredKtk.length}
                    columnWidths="14% 11% 11% 13% 15% 19% 17%"
                    isEditable={false}
                  />
                </>
              )}
            </div>
          </div>
          <div
            className={style["additional"]}
            style={{
              pointerEvents: rightsApprovalTabEdit ? "auto" : "none",
            }}
            // style={{
            //   pointerEvents: isViewMode ? "none" : "auto",
            // }}
          >
            <Form.Item
              name="approved"
              label="Название"
              rules={[{ required: false }]}
            >
              <div
                // onClick={toggleApproval}
                // style={{ cursor: "pointer" }}
                onClick={rightsApprovalTabEdit ? toggleApproval : undefined}
                style={{
                  cursor: rightsApprovalTabEdit ? "pointer" : "not-allowed",
                }}
              >
                {isAgreed ? (
                  <Form.Item className={style["agreed"]} name="approved">
                    <div className={style["icon-container"]}>
                      <Icon
                        component={successIcon}
                        className={style["success-icon"]}
                      />
                    </div>
                    Согласовано
                  </Form.Item>
                ) : (
                  <Form.Item className={style["not-agreed"]} name="approved">
                    <div className={style["error-icon-container"]}>
                      <Icon
                        component={errorImportIcon}
                        className={style["error-icon"]}
                      />
                    </div>
                    Не согласовано
                  </Form.Item>
                )}
              </div>
            </Form.Item>

            <div className={style.Return}>
              <div className="returnContainer">
                <p>Вернуть на доработку</p>
                <Button
                  className="reset"
                  icon={<ReloadOutlined />}
                  // onClick={rightsApprovalTabEdit ? returnToRevision : undefined}
                  onClick={
                    rightsApprovalTabEdit
                      ? () => {
                          toggleApproval();
                          returnToRevision();
                        }
                      : undefined
                  }
                  style={{
                    cursor: rightsApprovalTabEdit ? "pointer" : "not-allowed",
                    marginRight: "16px",
                  }}
                  disabled={!rightsApprovalTabEdit}
                ></Button>
              </div>
              <Form.Item
                name="return_to_revision"
                label="Причина"
                rules={[
                  {
                    // required: true,
                    // message: "Обязательное поле!",
                    whitespace: true,
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Введите причину"
                  disabled={!rightsApprovalTabEdit}
                />
              </Form.Item>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ApprovalTab;
