import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Rest_Chat } from "../services/rest_chat";
import { IUsers } from "../types/users";
import { message } from "antd";

export interface IChat {
  id?: number;
  isDelete?: boolean;
  message_text?: string;
  recipient_id?: number;
  recipient_tag?: string;
  row_id?: number;
  sender_id?: number;
  table?: string;
}

export interface IComment extends IChat {
  createdAt?: string;
  sender_avatar?: string;
  sender_fio?: string;
  sender_email?: string;
  sender_name?: string;
  sender_last_name?: string;

  recipient_avatar?: string;
  recipient_fio?: string;
  recipient_email?: string;
  recipient_name?: string;
  recipient_last_name?: string;

  updatedAt?: string;
}
export interface IMessage extends IChat {
  createdAt?: string;
  ['user_recipient.avatar']?: string;
  ['user_recipient.email']?: string;
  ['user_recipient.fio']?: string;
  ['user_recipient.last_name']?: string;
  ['user_recipient.name']?: string;

  ['user_sender.avatar']?: string;
  ['user_sender.email']?: string;
  ['user_sender.fio']?: string;
  ['user_sender.last_name']?: string;
  ['user_sender.name']?: string;
}


interface ChatState {
  messages: IChat[];
  loading: boolean;
  error: string | null;
}

const initialState: ChatState = {
  messages: [],
  loading: false,
  error: null,
};

export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  async (payload: IChat, { rejectWithValue }) => {
    try {
      return await Rest_Chat.setMessage(payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMessages = createAsyncThunk(
  "chat/fetchMessages",
  async (row_id: number, { rejectWithValue }) => {
    try {
      const response = await Rest_Chat.getMessage({ row_id });
      return response; // response уже преобразован в IComment[]
    } catch (error) {
      return rejectWithValue(
        typeof error === "string" ? error : "Неизвестная ошибка"
      );
    }
  }
);

export const deleteMessage = createAsyncThunk(
  "chat/deleteMessage",
  async (id: number, { rejectWithValue }) => {
    try {
      return await Rest_Chat.deleteMessage({ id });
    } catch (error) {
      return error;
      // return rejectWithValue(error);
    }
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetStoreChat(state) {
      state.messages = initialState.messages
      state.loading = initialState.loading
      state.error = initialState.error
    },
    // addMessage: (state, action) => {
    //   state.messages.unshift(action.payload);
    // },
    updateMessage: (state, action) => {
      const index = state.messages.findIndex(m => m.id === action.payload.id);
      if (index !== -1) state.messages[index] = action.payload;
    },
    // removeMessage: (state, action) => {
    //   state.messages = state.messages.filter(m => m.id !== action.payload);
    // }
    addMessage: (state, action) => {
      if (!state.messages.some(msg => msg.id === action.payload.id)) {
        state.messages.unshift(action.payload);
      }
    },
    removeMessage: (state, action) => {
      state.messages = state.messages.filter(m => m.id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.messages.unshift(action.payload);
      })
      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.messages = action.payload;
        state.loading = false;
      })
      .addCase(fetchMessages.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
      })
  }
});

export const { addMessage, updateMessage, removeMessage, resetStoreChat } = chatSlice.actions;
export default chatSlice.reducer;