import { IColumnType } from '../../components/ui/Table/Table';
import { Link } from 'react-router-dom';
import { RouteNames } from '../../router/names';
import { Flex, Typography } from 'antd';
import Scroll from '../../components/ui/List/Scroll';
import { VIEW } from '../../helpers/string-helpers';
import { TERMINAL } from '../../constants';

export const columns: IColumnType<any>[] = [
  {
    dataIndex: 'id',
    filterIndex: 'id',
    checkbox: true,
    menu: true,
    key: '0',
  },
  {
    title: 'Наименование терминала',
    dataIndex: 'name',
    filterIndex: 'name',
    type: 'string',
    width: '20%',
    key: '1',
    render: (col, item) => {
      const id = item?.id;
      const name = item?.name;
      return (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: { placement: 'top', title: item.contractor },
          }}
          style={{ maxWidth: '250px', margin: 0 }}
        >
          {id && name ? (
            <Link
              to={`${RouteNames.CONTAINERS}/?terminal_id=${encodeURIComponent(
                id,
              )}&name=${encodeURIComponent(' - ' + name)}`}
            >
              {name ?? ''}
            </Link>
          ) : (
            name || null
          )}
        </Typography.Paragraph>
      );
    },
  },
  {
    title: 'Родитель',
    dataIndex: 'parent',
    filterIndex: 'parent.name',
    width: '20%',
    type: 'string',
    key: '2',
    render: (col, item) => {
      const parent = item?.parent;
      return parent ? (
        <Link
          to={`${RouteNames.CONTAINERS}/?&location_id=${encodeURIComponent(
            parent?.id,
          )}&name=${encodeURIComponent(' - ' + parent?.name)}`}
        >
          {parent?.name ?? ''}
        </Link>
      ) : null;
    },
  },
  {
    title: 'Сток',
    dataIndex: 'stocks',
    filterIndex: 'stocks.label',
    type: 'string',
    width: '120px',
    key: '3',
    render: (col, item) => {
      const stockItem = item?.stocks;
      return Array.isArray(stockItem) && !!stockItem?.length ? (
        <Link to={{ pathname: RouteNames.TERMINALS, state: {[TERMINAL]: {[VIEW]: item } } }}>
          Подробнее
        </Link>
      ) : null;
    },
  },
  {
    title: 'Адрес',
    dataIndex: 'address_terminal',
    filterIndex: 'address_terminal',
    type: 'string',
    width: '60%',
    key: '4',
  },
  {
    title: 'Контакты',
    dataIndex: 'phone_director',
    filterIndex: 'phone_director',
    type: 'string',
    width: '20%',
    key: '5',
  },
  {
    title: 'Входящая ставка',
    dataIndex: 'currency_price',
    filterIndex: 'currency_price',
    type: 'string',
    width: '20%',
    key: '6',
    render: (col, item) => {
      const price = item?.currency_price;
      const currency = item?.currency?.name || '';
      return price ? (
        <Flex gap={5}>
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              tooltip: { placement: 'left', title: price },
            }}
            style={{ maxWidth: '80px', margin: 0 }}
          >
            {price}
          </Typography.Paragraph>
          <Typography.Paragraph style={{ maxWidth: '40px', margin: 0 }}>
            {currency}
          </Typography.Paragraph>
        </Flex>
      ) : null;
    },
  },
  {
    title: 'Вместимость',
    dataIndex: 'capacity',
    filterIndex: 'capacity',
    type: 'string',
    width: '20%',
    key: '7',
  },
  {
    isHidden: true,
    title: 'Краткая инструкция',
    dataIndex: 'text',
    filterIndex: 'text',
    type: 'string',
    width: '20%',
    key: '8',
    render: (_, item) =>
      item?.stock_model?.text ? (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: {
              placement: 'left',
              title: <Scroll>{item.stock_model.text}</Scroll>,
            },
          }}
          style={{ maxWidth: '200px', margin: 0 }}
        >
          {item.stock_model.text}
        </Typography.Paragraph>
      ) : null,
  },
  {
    title: '',
    dataIndex: 'hidden',
    filterIndex: 'hidden',
    width: '2%',
    key: '9',
  },
];
