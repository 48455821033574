import { IContacts } from "../../types/contacts";

export const defaultValueContact: IContacts = {
  last_phone: [],
  last_email: [],
  last_messenger: [],
  phone_first: '',
  email_first: '',
  fio: '',
  executor: '',
  messenger_first: '',
  source: null,
  position: '',
  type_phone_first: null,
  type_email_first: null,
  type_messenger_first: null,
  type_contact: null,
  company: null,
  comment: '',
  responsible_id: null,
  name: '',
  last_name: '',
  first_name: '',
  delete: false,
};

export const defaultItemMessengerList = {
  add_messenger: '',
  add_type_messenger: null
};

export const defaultItemLastEmailList = {
  add_email: '',
  add_type_email: null
};
export const defaultItemLastPhoneList = {
  add_phone: '',
  add_type_phone: null
};