import React, { ReactNode, useRef, useState } from 'react';
import { Button, Flex, Modal, Typography } from 'antd';
import classnames from 'classnames';
import ScrollList from '../ui/ScrollList';
import { useTranslation } from 'react-i18next';
import Responsible from './components/Responsible';
import style from './style.module.scss';
import { SelectSearch } from '../ui/SelectSearch/SelectSearch';
import { useStoreDispatch } from '../../store';
import { findExecutor } from '../../store/contact';
import ModalConfirm from '../ui/ModalConfirm/ModalConfirm';
import { messagesModal } from '../../constants';
import { mapperResultResponsible } from './utils';

interface IResponsible {
  avatar: string;
  name: string;
  role?: string;
  id: number;
  isResponsible: boolean;
}

interface IResponsibleList {
  title?: ReactNode;
  textButtonAdd?: string;
  textButtonEdit?: string;
  descriptionItem?: string;
  descriptionItemModal?: string;
  height?: number;
  isEmpty?: boolean;
  responsible: IResponsible[];
  isHiddenModal?: boolean;
  handlerSubmit: (responsible: IResponsible) => void;
  handlerSubmitList: (responsible: IResponsible[]) => void;
}

export const ResponsibleList: React.FC<IResponsibleList> = ({
  title,
  responsible,
  isHiddenModal,
  handlerSubmit,
  handlerSubmitList,
  isEmpty = false,
  height = 150,
  descriptionItemModal = 'ответственного',
  descriptionItem = 'Ответственный',
  textButtonAdd = 'Добавить ответственного',
  textButtonEdit = 'Сменить ответственного',
}) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const refResponsible = useRef(null);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string | null>(null);
  const [isModalOpenResponsible, setIsModalOpenResponsible] =
    useState<boolean>(false);
  const [isEdit, setIsEdit] = useState(() => false);
  const [dataExecutor, setDataExecutor] = useState<any[]>([]);
  const [responsibleChange, setResponsibleChange] = useState<any>(() => null);

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length >= 2) {
      const result: any = await dispatch(findExecutor(event.target.value));
      const currentResult = result?.payload?.filter(mapperResultResponsible({responsible, isEdit}));

      if (currentResult.length > 0) {
        setDataExecutor(currentResult);
      }
    }
  };

  const addFormResponsible = () => {
    const respUser = { ...responsibleChange };
    respUser.isResponsible =  responsible?.length ? isEdit : true;
    if (isEdit || !responsible?.length) {
      handlerSubmit(respUser);
    }
    const filter = responsible
      ?.filter((item: any) => item?.id !== respUser?.id)
      .map((item: any) => {
        const current = { ...item, isResponsible: false };
        return current;
      });
    handlerSubmitList(
      isEdit ? [respUser, ...filter] : [...responsible, respUser],
    );
    setIsModalOpenResponsible(false);
    setIsEdit(false);
    setResponsibleChange(null);
    setSearchValue(null);
  };

  const handleAddResponsible = (e: any, option: {label: string, value: number, avatar: string}) => {
    const resp = {
      avatar: option.avatar,
      name: option.label,
      id: option.value,
    };
    setResponsibleChange(resp);
    setDataExecutor([]);
    setSearchValue(option.label);
  };

  const handleCancelResponsible = () => {
    setSearchValue(null);
    setIsModalOpenResponsible(false);
    setIsEdit(false);
  };

  const showModalResponsible = () => {
    setIsModalOpenResponsible(true);
  };
  return (
    <Flex vertical gap={6}>
      {!!title && <div>{title}</div>}
      {!!responsible?.length ? (
        <ScrollList
          tableRef={refResponsible}
          setLastItemReached={(v: any) => v}
          height={height}
          totalTableActivity={1}
          data={responsible}
          paginationActivity={() => {}}
          isTable={false}
          endMessage={null}
        >
          {responsible.map((item, index, items) =>
            item?.name ? (
              <Flex
                key={index}
                justify={'space-between'}
                align={'baseline'}
                style={{ paddingRight: 7, minWidth: '400px' }}
              >
                <Responsible
                  title={item?.name}
                  description={
                    item?.isResponsible && descriptionItem
                      ? t(descriptionItem)
                      : item?.role || ''
                  }
                  avatar={item?.avatar}
                />
                <Flex vertical gap={6}>
                  {!index && (
                    <>
                      <Typography.Text
                        style={{
                          marginTop: 0,
                          color: '#05F',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setIsEdit(true);
                          showModalResponsible();
                        }}
                      >
                        {t(textButtonEdit)}
                      </Typography.Text>
                      <Typography.Text
                        style={{
                          marginTop: 0,
                          color: '#05F',
                          cursor: 'pointer',
                        }}
                        onClick={showModalResponsible}
                      >
                        {t(textButtonAdd)}
                      </Typography.Text>
                    </>
                  )}
                </Flex>
              </Flex>
            ) : null,
          )}
        </ScrollList>
      ) : (
        <Flex vertical gap={6}>
          <Typography.Text
            style={{
              marginTop: 0,
              color: '#05F',
              cursor: 'pointer',
            }}
            onClick={showModalResponsible}
          >
            {t(textButtonAdd)}
          </Typography.Text>
        </Flex>
      )}
      <Modal
        className={classnames(style.modal, {
          [style.modal_hidden]: confirmation,
        })}
        title={`${isEdit ? t('Сменить') : t('Добавить')} ${t(
          descriptionItemModal,
        )}`}
        open={isModalOpenResponsible}
        onCancel={handleCancelResponsible}
        footer={null}
      >
        <Flex vertical style={{ height: '50vh', padding: 20 }}>
          <SelectSearch
            defaults={searchValue}
            dataSource={dataExecutor}
            notFoundContent={t('Пользователь не найден')}
            style={{ width: '100%' }}
            dropdownRender={onNameChange}
            onChangeSelect={handleAddResponsible}
          />
          <Flex
            gap={8}
            style={{ position: 'absolute', bottom: 28, width: '100%' }}
          >
            <Button
              type={'primary'}
              style={{ width: '56%' }}
              onClick={addFormResponsible}
              disabled={!responsibleChange}
            >
              {t('Добавить')}
            </Button>
            <Button
              type={'text'}
              style={{ width: '30%', color: '#E14453' }}
              onClick={() => setConfirmation(true)}
            >
              {t('Отменить')}
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleCancelResponsible}
      />
    </Flex>
  );
};
