import Icon from "@ant-design/icons";
import { IConfigList } from "../../../../components/CustomHistory/CustomHistory";
import { DeleteRed20 } from "../../../../assets/image/svg";
import { columnsKtk, columnsTransporter } from "../../constants";

export const historyConfigList: IConfigList[] = [
  {
    typeRow: 'delete',
    titleRow: 'Отмечен на удаление',
    renderValue: <Icon component={DeleteRed20} />,
    isCheckShow: true,
    keyRowPrevDataShow: 'delete',
    keyRowCurrentDataShow: 'delete',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevData: 'delete',
    keyRowCurrentData: 'delete',
    keyValueRowPrevData: 'delete',
    keyValueRowCurrentData: 'delete',
    columnsTable: []
  },
  {
    typeRow: 'checkbox',
    titleRow: 'Выдача физическому лицу',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'is_transporter',
    keyRowCurrentData: 'is_transporter',
    keyValueRowPrevData: 'is_transporter',
    keyValueRowCurrentData: 'is_transporter',
    columnsTable: []
  },
  {
    typeRow: 'checkbox',
    titleRow: 'Безномерная выдача',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'no_number',
    keyRowCurrentData: 'no_number',
    keyValueRowPrevData: 'no_number',
    keyValueRowCurrentData: 'no_number',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Дата действия релиза',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'date_action',
    keyRowCurrentData: 'date_action',
    keyValueRowPrevData: 'date_action',
    keyValueRowCurrentData: 'date_action',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Номер релиза',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'release_id',
    keyRowCurrentData: 'release_id',
    keyValueRowPrevData: 'release_id',
    keyValueRowCurrentData: 'release_id',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Кол-во КТК',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'containers_count',
    keyRowCurrentData: 'containers_count',
    keyValueRowPrevData: 'containers_count',
    keyValueRowCurrentData: 'containers_count',
    columnsTable: []
  },
  {
    typeRow: 'string',
    titleRow: 'Подрядчик',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'contractor',
    keyRowCurrentData: 'contractor',
    keyValueRowPrevData: 'contractor',
    keyValueRowCurrentData: 'contractor',
    columnsTable: []
  },
  {
    typeRow: 'length',
    titleRow: 'Файлы',
    suffixTitleRow: '',
    defaultValue: '0',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'file_ids',
    keyRowCurrentData: 'file_ids',
    keyValueRowPrevData: 'file_ids',
    keyValueRowCurrentData: 'file_ids',
    columnsTable: []
  },
  {
    typeRow: 'table',
    titleRow: 'Данные перевозчика',
    suffixTitleRow: ':',
    isCheckShow: true,
    isCheckOpposite: true,
    keyRowPrevDataShow: 'is_transporter',
    keyRowCurrentDataShow: 'is_transporter',
    defaultValue: [],
    keyRowPrevData: 'transporter',
    keyRowCurrentData: 'transporter',
    keyValueRowPrevData: '',
    keyValueRowCurrentData: '',
    columnsTable: columnsTransporter
  },
  {
    typeRow: 'string',
    titleRow: 'Терминал',
    suffixTitleRow: '',
    defaultValue: '',
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'terminal',
    keyRowCurrentData: 'terminal',
    keyValueRowPrevData: 'terminal',
    keyValueRowCurrentData: 'terminal',
    columnsTable: []
  },
  {
    typeRow: 'table',
    titleRow: 'Таблица ктк',
    suffixTitleRow: ':',
    defaultValue: [],
    keyRowPrevDataShow: '',
    keyRowCurrentDataShow: '',
    keyRowPrevData: 'containers',
    keyRowCurrentData: 'containers',
    keyValueRowPrevData: 'containers',
    keyValueRowCurrentData: 'containers',
    columnsTable: columnsKtk
  },
];