import { ComponentType } from "react";
import CrmContainers from "../pages/crm";
import ForgetPassword from '../pages/auth/ForgetPassword';
import Login from "../pages/auth/Login";
import RegistrationForm from "../pages/auth/Registration";
import { RouteNames } from "./names";
import Terminals from "../pages/terminals/Terminals";
import Page404 from "../pages/Page404";
import SalesDeals  from "../pages/sales/deals/Deals";
import Containers from "../pages/containers/Containers";
import RentDeals  from "../pages/rent/deals/Deals";
import RentRequests from "../pages/rent/requests/Requests";
import Releases from "../pages/releases";
import ViewPage from "../pages/view";
import { FinanceBillingTable, FinancePaymentTable } from "../pages/finance";
import StorageConditions from "../pages/conditions";



export interface IRoute {
    path: string;
    component: ComponentType;
    exact?: boolean;
}
export interface IRoutes extends IRoute {
    children?: IRoute[],
}

export const publicRoutes: IRoutes[] = [
    { path: RouteNames.LOGIN, exact: false, component: Login },
    { path: RouteNames.REGISTRATION, exact: false, component: RegistrationForm },
    { path: RouteNames.FORGET_PASSWORD, exact: false, component: ForgetPassword },
    { path: RouteNames.NOT_FOUND, exact: true, component: Login },
]

export const privateRoutes: IRoutes[] = [
    { path: RouteNames.MAIN, exact: true, component: CrmContainers},
    { path: RouteNames.CRM, exact: true, component: CrmContainers},
    { path: RouteNames.CRM_ID, exact: true, component: CrmContainers},
    { path: RouteNames.PAGE_VIEW_ID, exact: true, component: ViewPage },
    { path: RouteNames.CONTAINERS, exact: true, component: Containers },
    { path: RouteNames.TERMINALS, exact: true, component: Terminals },
    { path: RouteNames.SALES_DEAL, exact: true, component: SalesDeals },
    { path: RouteNames.RELEASES, exact: true, component: Releases },
    { path: RouteNames.RENT_DEAL, exact: true, component: RentDeals },
    { path: RouteNames.RENT_REQUESTS, component: RentRequests, exact: true, },
    { path: RouteNames.FINANCE_BILLING, component: FinanceBillingTable, exact: true, },
    { path: RouteNames.FINANCE_PAYMENT, component: FinancePaymentTable, exact: true, },
    { path: RouteNames.STORAGE_CONDITIONS, component: StorageConditions, exact: true },
    { path: RouteNames.STORAGE_CONDITIONS_ID, component: StorageConditions, exact: true },
    { path: RouteNames.NOT_FOUND, exact: true, component: Page404 },
]
