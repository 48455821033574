import axios from 'axios';
import { TOK_EN, Utils } from '../utils';

export const URL_API_USER = process.env.REACT_APP_API_URL || window.config?.REACT_APP_API_URL;
export const URL_USER = window.location.origin;

export function authConfigAxios(apiURL?: string) {
  const requestBase = axios.create({
    baseURL: apiURL,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
  });

  requestBase.interceptors.request.use(
    (config) => {
      config.params = { ...config.params };
      // проверка токена
      const token = localStorage.getItem(TOK_EN);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  requestBase.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const errorMessage = error?.response?.data?.message ? error?.response?.data : '';
      if (error?.response?.status === 401) {
        // возможем такой сценарий какие то действия;
        Utils.clearStorage();
        return Promise.reject(errorMessage || {message: 'Ошибка доступа 401' });
      }
      if (error?.response?.status === 404) {
        // возможем такой сценарий какие то действия;
        return Promise.reject(errorMessage || {message: 'Ошибка запроса 404'});
      }
      return Promise.reject(errorMessage || {message: 'Ошибка сервера'});
    },
  );

  return requestBase;
}


export const apiAxios = authConfigAxios(URL_API_USER);
