import React, { useCallback, useEffect, useState } from "react";

import { delay } from "lodash";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  AutoComplete,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
} from "antd";

import socket from "../../../../../../socket";
import style from "../../../style.module.scss";
import { Utils } from "../../../../../../utils";
import { mapperSearchYandexLocation } from "./utils";
import { findCompany } from "../../../../../../store/contractors";
import { RootState, useStoreDispatch } from "../../../../../../store";
import { findReleaseId, IContainer } from "../../../../../../store/rent";
import { GET_DEALS_SALES_IDS } from "../../../../../../services/rest_deal";
import { FormList } from "../../../../../../components/ui/Form/FormList/FormList";
import {
  filterContainers,
  getContainersAndDeals,
  mapperCompany,
} from "../../../utils";
import {
  findAgreementsCompany,
  getDealsIds,
  IDeal,
} from "../../../../../../store/sales";
import {
  FIND_RELEASES_ID,
  Rest_releases,
} from "../../../../../../services/rest_releases";
import {
  defaultLocationReturn,
  defaultRelease,
  ITabForm,
  IRequest,
} from "../../../../../../store/rent";
import {
  FormInputNumber,
  FormSelectSearch,
  FormTable,
} from "../../../../../../components/ui/Form";
import {
  columnsAccounts,
  columnsDeals,
  dataCondition,
  minContractorId,
  minLimitBet,
} from "../../../constants";
import { mapperSelect } from "../../../../../../helpers/mappers-helpers";
import { useParamsHistory } from "../../../../../../hooks";
import { RouteNames } from "../../../../../../router/names";
import { FINANCE_BILLING, FINANCE_PAYMENT } from "../../../../../../constants";
import { EDIT } from "../../../../../../helpers/string-helpers";

const TabDeal: React.FC<ITabForm> = ({
  initialValue,
  isOpen,
  isDisabled,
  isFromRequest,
}) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const form = Form.useFormInstance();
  const { history } = useParamsHistory();

  const [dataAgreements, setDataAgreements] = useState<any>([]);

  const [currentPageDeal, setCurrentPageDeal] = useState(1);

  const [pageDeal, setPageDeal] = useState(20);

  const [isLoadingDeals, setIsLoadingDeals] = useState(false);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);

  const [dataCompany, setDataCompany] = useState<
    { id: number; value: string }[]
  >([]);

  const dataTypeContainers = useSelector(
    (state: RootState) => state.types.dataTypeContainers
  );

  const dataCurrency = useSelector(
    (state: RootState) => state.types.dataTypeCurrency
  );
  const dataPercent = useSelector(
    (state: RootState) => state.types.dataTypePercent
  );
  const dataMyCompany = useSelector(
    (state: RootState) => state.companies.dataMyCompany
  ) as any;

  const requestOneData = useSelector(
    (state: RootState) => state.rent.requestOneData
  ) as IRequest;

  const related_deals: IDeal[] = Form.useWatch("related_deals", {
    form,
    preserve: true,
  });

  const releases: { release_id: string; ids: any[] }[] = Form.useWatch(
    "releases",
    {
      form,
      preserve: true,
    }
  );

  const related_accounts: any[] = Form.useWatch("related_accounts", {
    form,
    preserve: true,
  });

  const containers: IContainer[] = Form.useWatch("containers", {
    form,
    preserve: true,
  });

  const my_company_id: number = Form.useWatch("my_company_id", {
    form,
    preserve: true,
  });

  const company_id = Form.useWatch("company_id", { form, preserve: true });

  const typeId = Form.useWatch("type_deal_id", { form, preserve: true });

  const type_deal_id = Form.useWatch("type_deal_id", { form, preserve: true });

  useEffect(() => {
    setDataCompany([]);
    setDataAgreements([]);
    if (Array.isArray(initialValue.agreement)) {
      setDataAgreements(initialValue.agreement);
    }
    if (initialValue.companies) {
      setDataCompany(initialValue.companies);
    }
  }, []);

  useEffect(() => {
    // нужно учитывать откуда данные
    if (!type_deal_id || !isFromRequest) return;

    // В зависимости от type_deal_id берём соответствующий штраф
    if (type_deal_id === 6) {
      // Закуп
      form.setFieldsValue({
        fine: requestOneData?.purchase_fine ?? "",
        message_subject: requestOneData?.purchase_mail_theme ?? "",
        additionally: requestOneData?.purchase_add_info ?? "",
      });
    } else if (type_deal_id === 5) {
      // Аренда
      form.setFieldsValue({
        fine: requestOneData?.ktk_fine ?? "",
        message_subject: requestOneData?.ktk_mail_theme ?? "",
        additionally: requestOneData?.ktk_add_info ?? "",
      });
    } else if (type_deal_id === 9 || type_deal_id === 10) {
      // Эвакуация или Субаренда
      form.setFieldsValue({
        fine: requestOneData?.sublease_fine ?? "",
        message_subject: requestOneData?.sublease_mail_theme ?? "",
        additionally: requestOneData?.sublease_add_info ?? "",
      });
    }
  }, [type_deal_id, requestOneData, form]);

  useEffect(() => {
    if (!company_id) {
      form.setFieldsValue({
        containers: [],
        related_deals: [],
        related_deals_ids: [],
      });
    }
  }, [company_id]);

  const getAgreements = ({
    client,
    company,
  }: {
    client: number;
    company: number;
  }) => {
    if (client && company) {
      const payload = { executor_id: company, client_id: client };
      dispatch(findAgreementsCompany(payload))
        .then((res) => {
          console.log(res.payload);

          setDataAgreements(mapperSelect({ items: res.payload }));
        }).catch((e) => {
          console.error(e);
        })
        .finally(() => {
          socket.off("find-agreements-company");
        });
    } else {
      form.setFieldsValue({
        agreement_id: null,
      });
      setDataAgreements([]);
    }
  };

  const onDeleteRelease = (
    item: { release_id: string; ids: any[] },
    index: string | number
  ) => {
    const findContainer = containers.find(
      (cont) => cont?.release?.release_id === item?.release_id
    );
    const deals = findContainer
      ? related_deals?.filter(
          (deal) => deal?.id_deal !== findContainer?.deal?.id_deal
        )
      : related_deals;
    form.setFieldValue(
      "containers",
      containers.filter(
        (cont) => cont?.release?.release_id !== item?.release_id
      )
    );
    form.setFieldValue("related_deals", deals);
    form.setFieldValue(
      "related_deals_ids",
      deals.map((deal) => deal?.id)
    );
  };

  const handleSelectCompany = useCallback((id: string, record: any) => {
    const fields = form.getFieldsValue();
    form.setFieldsValue({
      ...fields,
      phone: record?.phone,
      company: record?.value,
      company_id: record?.id,
      contact_id: record?.contact_id,
    });
  }, []);

  const handleSelectMyCompany = useCallback((id: string, record: any) => {
    form.setFieldValue("account_num", record?.score);
    form.setFieldValue("my_company", record?.value);
    form.setFieldValue("my_company_id", record?.value);
  }, []);

  const handleSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    if (company_id && !dataCompany?.length) return;
    const item = dataCompany?.find(
      (comp: any) => comp?.value === evt.target.value
    );
    if (item && my_company_id) {
      getAgreements({ company: my_company_id, client: item?.id });
      return;
    }
    if (!item) {
      form.setFieldValue("company", "");
      form.setFieldValue("company_id", null);
      form.setFieldValue("my_company_id", null);
      form.setFieldValue("phone", null);
      form.setFieldValue("contact_id", null);
    }
  };

  const handleSearchBlurMyCompany = (
    evt: React.FocusEvent<HTMLInputElement>
  ) => {
    if (my_company_id && company_id) {
      getAgreements({ client: company_id, company: my_company_id });
      return;
    }
  };

  const handleSearchCompany = (text: string) => {
    if (text?.length >= 2) {
      delay(() => {
        dispatch(findCompany(text.toLocaleLowerCase()))
          .then((res) => {
            form.setFieldValue("company_id", null);
            form.setFieldValue("phone", null);
            form.setFieldValue("contact_id", null);
            setDataCompany(mapperCompany(res.payload));
          })
          .catch(console.error);
      }, 1000);
    }
  };

  const onSelectRelease = (val: any, opt: any) => {
    if (opt?.id) {
      Rest_releases.findOneRelease(opt.id).then((res) => {
        if (res?.data?.currentContainers?.length) {
          const { resultContainers, ids } = getContainersAndDeals({
            containers: filterContainers({
              containers,
              releases,
              release_id: opt.id,
            }),
            currentContainers: res.data.currentContainers,
          });
          form.setFieldValue("containers", resultContainers);
          dispatch(
            getDealsIds({
              current: currentPageDeal,
              page: pageDeal,
              clear: "",
              order: "DESC",
              ids,
            })
          )
            .then((res) => {
              const deals = res.payload?.rows?.length ? res.payload?.rows : [];
              form.setFieldValue("related_deals", [...deals, ...related_deals]);
              form.setFieldValue("related_deals_ids", ids);
            }).catch((e) => {
              console.error(e);
            })
            .finally(() => {
              socket.off(GET_DEALS_SALES_IDS);
              setIsLoadingDeals(false);
            });
        }
      }).catch((e) => {
        console.error(e);
      });
    }
  };

  const getBilling = () => {
    const { id, ...rent } = form.getFieldsValue(); // дополнить поля для счета
    history.push(RouteNames.FINANCE_BILLING, {
      [FINANCE_BILLING]: { [EDIT]: { ...rent, account_type: "Аренда" } },
    });
  };

  return (
    <Flex vertical>
      <Flex vertical gap={10} style={{ marginBottom: "24px" }}>
        <Row gutter={[12, 11]} justify="space-between">
          <Col span={11}>
            <Flex vertical gap={10}>
              <Form.Item
                label={t(typeId < minContractorId ? "Клиент" : "Поставщик")}
                name={"company"}
                className={classnames(style.label, {
                  [style.label__disabled]: !!form.getFieldValue("id"),
                })}
                rules={[{ required: true, message: t("Обязательное поле") }]}
              >
                <AutoComplete
                  popupClassName="certain-category-search-dropdown"
                  style={{ width: "100%" }}
                  allowClear
                  placeholder={t("Выберите клиента")}
                  options={dataCompany}
                  onSelect={handleSelectCompany}
                  onSearch={handleSearchCompany}
                  onBlur={handleSearchBlur}
                  disabled={isDisabled}
                />
              </Form.Item>
              <Flex vertical gap={10}>
                <Form.Item
                  label={t("Договор №")}
                  name={"agreement"}
                >
                  <Select
                    optionLabelProp="label"
                    options={dataAgreements}
                    disabled={!dataAgreements?.length || isDisabled}
                    onSelect={(id) => {
                      form.setFieldValue('agreement_id', id);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={t("Наше юридическое лицо")}
                  name={"my_company_id"}
                  className={classnames(style.label, {
                    [style.label__disabled]: !!form.getFieldValue("id"),
                  })}
                  rules={[{ required: true, message: t("Обязательное поле") }]}
                >
                  <Select
                    popupClassName="certain-category-search-dropdown"
                    style={{ width: "100%" }}
                    allowClear
                    optionLabelProp="label"
                    placeholder={t("Выберите компанию")}
                    options={mapperSelect({ items: dataMyCompany })}
                    onSelect={handleSelectMyCompany}
                    onBlur={handleSearchBlurMyCompany}
                    disabled={isDisabled}
                  />
                </Form.Item>
              </Flex>
              <Row gutter={12} style={{ marginLeft: 0 }}>
                <FormList
                  nameList="releases"
                  title="№ Релиза"
                  titleName="release_id"
                  titleNames={["id"]}
                  file="Релиз и заявка с поставщиком"
                  fileName="ids"
                  defaultItem={defaultRelease}
                  onDelete={onDeleteRelease}
                  disabled={!company_id || isDisabled}
                  type="search"
                  filterSelect={(id: any, index: any) => {
                    if (releases?.[index]?.release_id === id) {
                      return true;
                    }
                    return !releases?.find(
                      ({ release_id }) => release_id === id
                    );
                  }}
                  typeOption={{
                    style: { maxWidth: "195px" },
                    allowClear: false,
                    showSearch: true,
                    placeholder: "Поиск",
                    suffixIcon: null,
                    notFoundContent: null,
                    onSelect: onSelectRelease,
                    api: (val: any) =>
                      dispatch(findReleaseId(val)).finally(() => {
                        socket.off(FIND_RELEASES_ID);
                      }),
                    mapResponse: (res: any) => res?.payload,
                  }}
                />
              </Row>
              <Row gutter={12} style={{ marginLeft: 0 }}>
                <Form.Item
                  name="location_release"
                  label="Локация релиза"
                  style={{ flex: 1, maxWidth: "100%" }}
                >
                  <FormSelectSearch
                    allowClear
                    showSearch
                    placeholder={"Поиск"}
                    suffixIcon={null}
                    filterOption={false}
                    notFoundContent={null}
                    api={Utils.yandexCity}
                    mapResponse={mapperSearchYandexLocation}
                  />
                </Form.Item>
              </Row>
              <Row gutter={12} style={{ marginLeft: 0 }}>
                <FormList
                  nameList="location_return"
                  title="Локация возврата"
                  titleName="location"
                  type="search"
                  defaultItem={defaultLocationReturn}
                  disabled={isDisabled}
                  typeOption={{
                    style: { maxWidth: "360px" },
                    allowClear: true,
                    showSearch: true,
                    placeholder: "Поиск",
                    suffixIcon: null,
                    filterOption: false,
                    notFoundContent: null,
                    api: Utils.yandexCity,
                    mapResponse: mapperSearchYandexLocation,
                  }}
                />
              </Row>
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item label={t("Ставка")} name={"bet"}>
                    <FormInputNumber min={minLimitBet} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t("Валюта")} name={"currency_id"}>
                    <Select
                      optionLabelProp="label"
                      options={mapperSelect({ items: dataCurrency })}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t("НДС")} name={"nds"}>
                    <Select optionLabelProp="label" options={dataPercent} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item label={t("Тип КТК")} name={"type_container"}>
                    <Select
                      optionLabelProp="label"
                      options={dataTypeContainers}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t("Состояние")} name={"condition"}>
                    <Select optionLabelProp="label" options={dataCondition} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t("Кол-во КТК")} name={"count_ktk"}>
                    <FormInputNumber min={0} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                <Col span={8}>
                  <Form.Item
                    label={t("Кол-во дней")}
                    rules={[
                      { required: true, message: t("Обязательное поле") },
                    ]}
                    name={"count_day"}
                  >
                    <FormInputNumber min={0} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t("СНП")} name={"snp"}>
                    <Input allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={12}>
                {/* <Form.Item
                  label={t('Штраф за отмену')}
                  style={{ flex: 1 }}
                  name={'fine'}
                  rules={[
                    {
                      required: true,
                      message: t('Обязательное поле'),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item> */}
                <Form.Item
                  label={t("Штраф за отмену")}
                  style={{ flex: 1 }}
                  name={"fine"}
                  rules={[
                    {
                      required: true,
                      message: t("Обязательное поле"),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>
              </Row>
              <Row gutter={12}>
                <Form.Item
                  label={t(
                    `Тема письма с ${
                      typeId < minContractorId ? "клиентом" : "подрядчиком"
                    }`
                  )}
                  name={"message_subject"}
                  style={{ flex: 1 }}
                  rules={[
                    {
                      required: true,
                      message: t("Обязательное поле"),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>
              </Row>
              <Row gutter={12}>
                <Form.Item
                  label={t("Дополнительно")}
                  name={"additionally"}
                  style={{ flex: 1 }}
                >
                  <Input allowClear />
                </Form.Item>
              </Row>
            </Flex>
          </Col>
          <Col span={12}>
            <Flex vertical style={{ minHeight: "100%" }}>
              <Row gutter={12} style={{ maxHeight: "450px" }}>
                <FormTable
                  isLoading={isLoadingDeals}
                  label="Связанные сделки"
                  tableLayout="fixed"
                  scroll={related_deals?.length > 1 ? { y: 250 } : undefined}
                  defaultColumns={columnsDeals}
                  name="related_deals"
                />
              </Row>
              <Divider />
              <Row gutter={12} style={{ maxHeight: "450px" }}>
                <FormTable
                  isLoading={isLoadingAccounts}
                  label="Счет"
                  tableLayout="fixed"
                  scroll={related_accounts?.length ? { y: 250 } : undefined}
                  defaultColumns={columnsAccounts}
                  name="related_accounts"
                />
              </Row>
              <Flex
                wrap
                justify="space-between"
                gap={20}
                style={{ marginTop: "auto" }}
              >
                <Button
                  className={style["text-card-14"]}
                  type="default"
                  onClick={getBilling}
                  disabled={isDisabled || !initialValue?.id}
                >
                  {t("Отправить запрос на выставление счета")}
                </Button>
                <Button
                  className={style["text-card-14"]}
                  type="default"
                  disabled={isDisabled || !initialValue?.id}
                >
                  {t("Отправить запрос на оплату счета")}
                </Button>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Flex>
    </Flex>
  );
};

export default TabDeal;
