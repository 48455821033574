export enum RouteNames {
    LOGIN = '/login',
    REGISTRATION = '/registration',
    FORGET_PASSWORD = '/forget_password',
    CONTRACTORS = '/contractors',
    CONTACT = '/contact',
    STAFF = '/staff',
    MAIN = '/',
    CRM = '/crm',
    CRM_ID = '/crm/:id',
    CRM_AGREEMENT = '/crm/agreement',
    CRM_COMPANY = '/crm/company',
    CRM_CONTACTS = '/crm/contact',
    CONTAINERS = '/containers',
    TERMINALS = '/terminals',
    DEPOT = '/depot',
    SALES = '/sales',
    SALES_DEAL = '/sales/deal',
    RELEASES = '/releases',

    RENT = '/rent',
    RENT_DEAL = '/rent/deal',

    RENT_REQUESTS = '/rent/requests',
    RENT_REQUEST_CREATE = '/rent/requests/0',
    RENT_REQUESTS_EDIT = '/rent/requests/',
    RENT_REQUESTS_VIEW = '/rent/requests/view/',
    RENT_REQUESTS_VIEW_ID = '/rent/requests/view/:id',
    RENT_REQUEST_ID = '/rent/requests/:id',


    FINANCE = '/finance',
    FINANCE_PAYMENT = '/finance/payment',
    FINANCE_BILLING = '/finance/billing',

    STORAGE_CONDITIONS = '/storage_conditions',
    STORAGE_CONDITIONS_ID = '/storage_conditions/:id',
    STORAGE_CONDITIONS_CALCULATION_FORMATION = '/storage_conditions/calculation_formation',
    STORAGE_CONDITIONS_GUIDE = '/storage_conditions/guide',

    PAGE_VIEW = '/view/',
    PAGE_VIEW_ID = '/view/:id',
    PAGE_ERROR = '/error',
    NOT_FOUND = '*',
}
