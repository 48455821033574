import { MenuProps } from "antd";
import { IColumnType } from "./Table";
import { TableRowCell } from "./TableRowCell";
import TableRowItem from "./TableRowItem";

interface Props<T> {
  t: any;
  data: T[];
  columns: IColumnType<T>[];
  onRow?: (record: any, rowIndex: number) => void;
  subMenu?: {
    label: string;
    getSubMenu: ({
      item,
      rows,
      data,
      onClose,
    }: {
      item: any;
      rows: Set<any>;
      data: T[];
      onClose: () => void;
    }) => MenuProps["items"];
  };
  canView?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  editRow?: (item: any) => void;
  deleteRow?: (row: any) => void;
  onClickLink?: (record: T, rowIndex: number) => void;
  setAllSelected?: (value: ((prevState: boolean) => boolean) | boolean) => void;
  selectedRows?: any;
  handleRowSelect: (id: number, checked: boolean) => void;
  handleCopyAll: (row?: any) => void;
  handleDeleteCopyAll?: () => void | any[];
  onUploadFileAll?: () => void;
  handlerAllUpdate?: () => void;
}

export function TableRow<T>({
  t,
  data,
  subMenu,
  columns,
  selectedRows,
  canView,
  canEdit,
  canDelete,
  onRow,
  editRow,
  deleteRow,
  onClickLink,
  handleCopyAll,
  onUploadFileAll,
  handleRowSelect,
  handlerAllUpdate,
  handleDeleteCopyAll,
}: Props<T>): JSX.Element {
  return (
    <>
      {data?.length
        ? data?.map((item: any, itemIndex) => (
            <TableRowItem
              key={`table-body-${itemIndex}`}
              onRow={onRow}
              item={item}
              status={item.status}
              itemIndex={itemIndex}
              isChecked={selectedRows && selectedRows.has(item.id)}
              handleRowSelect={handleRowSelect}
            >
              {columns
                .filter((col) => !col?.isHidden)
                .map((column, columnIndex) => (
                  <TableRowCell
                    t={t}
                    key={`table-row-cell-${columnIndex}`}
                    item={item}
                    column={column}
                    data={data}
                    selectedRows={selectedRows}
                    onClickLink={onClickLink}
                    itemIndex={itemIndex}
                    onRow={onRow}
                    editRow={editRow}
                    deleteRow={deleteRow}
                    handleCopyAll={handleCopyAll}
                    handleDeleteCopyAll={handleDeleteCopyAll}
                    subMenu={subMenu}
                    columnIndex={columnIndex}
                    onUploadFileAll={onUploadFileAll}
                    handlerAllUpdate={handlerAllUpdate}
                    canView={canView}
                    canEdit={canEdit}
                    canDelete={canDelete}
                  />
                ))}
            </TableRowItem>
          ))
        : null}
    </>
  );
}
