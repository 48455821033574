import { apiAxios } from '../api/config';
import socket from '../socket';
import { IParamRestDelete } from '../types';

export class Rest_conditions {

  static getGuide(payload: {}): Promise<any> {
    return apiAxios.post('conditions/get-guide', payload);
  }

  static deleteIds(payload: IParamRestDelete): Promise<any> {
    return new Promise(async (resolve, reject) => {
      socket.emit('delete-guide', payload, (response: any) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    });
  }
}
