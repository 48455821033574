import { IFile } from "../components/ui/UploaderDocsUnique/UploaderDocsUnique";

const CHUNK_SIZE = 500 * 500;

type saveFile = IFile | Blob

interface ISubmitFormAndFiles {
  id: number | undefined;
  saveFiles: saveFile[]; // файлы
  initialFiles: IFile[]; // файлы при
  submit: any; // данные формы
  fetchSubmitForm: (arg: any) => Promise<any>; // запрос на сохранение формы
}

// сохранение формы с файлами
export const submitFilesForm = ({
  id,
  submit,
  saveFiles,
  initialFiles,
  fetchSubmitForm,
}: ISubmitFormAndFiles) => {
  if (!saveFiles?.length || !id) {
    fetchSubmitForm({
      files: !id ? [] : initialFiles,
      size: 0,
      type: 'old',
      name: undefined,
      origin_name: undefined,
      submit,
      return: true
    });
    return;
  }

  saveFiles?.forEach((file: saveFile, index: number, arr: any[]) => {
    const isReturn =  arr?.length === index + 1;
    if ((file instanceof File || file instanceof Blob)) {

      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      for (let i = 0; i < totalChunks; i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, +file.size);
        const chunk = file.slice(start, end);
        const reader = new FileReader();
        reader.onload = () => {
          const arrayBuffer = reader.result;
          fetchSubmitForm({
            chunk: arrayBuffer,
            chunkIndex: i,
            totalChunks,
            type: 'new',
            files: initialFiles,
            size: file.size,
            name: file instanceof File ? file.name : undefined,
            origin_name: file instanceof File ? file.name : undefined,
            submit,
            return: isReturn
          });
        };
        reader.readAsArrayBuffer(chunk);
      }
    } else {
      const currentFile = file as IFile;
      fetchSubmitForm({
        files: initialFiles,
        size: currentFile.size,
        type: 'old',
        name: currentFile.name,
        origin_name: currentFile?.origin_name,
        submit,
        return: isReturn
      });
    }
  });
};
