import { IColumnType } from "../../../components/ui/Table/Table";

export const columns: IColumnType<any>[] = [
  {
    dataIndex: 'id',
    filterIndex: 'id',
    checkbox: true,
    menu: true,
    type: 'string',
    key: '0',
    width: '70px'
  },
  {
    title: 'Дата запроса',
    dataIndex: 'date',
    filterIndex: 'date',
    width: '20%',
    type: 'string',
    key: '1',
  },
  {
    title: 'Тип расхода',
    dataIndex: 'type',
    filterIndex: 'type',
    width: '20%',
    type: 'string',
    key: '2',
  },
  {
    title: '№ заявки',
    dataIndex: 'payment_id',
    filterIndex: 'payment_id',
    width: '25%',
    key: '3',
    link: true,
    type: 'string',
  },
  {
    title: 'Контрагент',
    dataIndex: 'company',
    filterIndex: 'company',
    width: '25%',
    key: '4',
    type: 'string',
  },
  {
    title: 'Сумма',
    dataIndex: 'total',
    filterIndex: 'total',
    width: '20%',
    type: 'string',
    key: '5',
  },
  {
    title: '',
    dataIndex: 'hidden',
    filterIndex: 'hidden',
    width: '60px',
    key: '9',
  },
];
