import { IColumnType } from "../../../components/ui/Table/Table";

export const columns: IColumnType<any>[] = [
  {
    dataIndex: 'id',
    filterIndex: 'id',
    checkbox: true,
    menu: true,
    type: 'string',
    key: '0',
    width: '70px'
  },
  {
    title: 'Дата запроса',
    dataIndex: 'createdAt',
    filterIndex: 'createdAt',
    width: '20%',
    type: 'date',
    key: '1',
  },
  {
    title: 'Тип счета',
    dataIndex: 'account_type.title',
    filterIndex: 'account_type.title',
    width: '20%',
    type: 'string',
    key: '2',
  },
  {
    title: '№ заявки',
    dataIndex: 'num_deal',
    filterIndex: 'num_deal',
    width: '25%',
    link: true,
    key: '3',
    type: 'string',
  },
  {
    title: 'Контрагент',
    dataIndex: 'company.name_company',
    filterIndex: 'company.name_company',
    width: '25%',
    key: '4',
    type: 'string',
  },
  {
    title: 'Сумма платежа',
    dataIndex: 'total_payment',
    filterIndex: 'total_payment',
    width: '20%',
    type: 'string',
    key: '5',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    filterIndex: 'status',
    width: '20%',
    type: 'string',
    key: '6',
  },
  {
    title: '',
    dataIndex: 'hidden',
    filterIndex: 'hidden',
    width: '60px',
    key: '9',
  },
];
