import { useEffect, useState } from "react";

import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import socket from "../../socket";
import { useCsvExport } from "..";
import { Utils } from "../../utils";
import useModalMessage from "../useModalMessage";
import { Rest_files } from "../../services/rest_files";
import { addListNotification } from "../../store/store";
import { Rest_filters } from "../../services/rest_filters";
import { IParamRestDelete, StorageData } from "../../types";
import { downloadLinkContent } from "../../helpers/link-helper";
import { IColumnType, IPagination } from "../../components/ui/Table/Table";
import { SessionStorageManager } from "../../components/ui/Table/sessionStorageManager";
import { RootState } from "../../store";
import {
  addCheckedData,
  resetStateData,
  setCheckedData,
  setCheckedRows,
  setFiltersFetch,
} from "../../store/filters";

interface IGetTable {
  duration?: number;
  subscribeStringDelete?: string; // Строка подписки ответа запроса удаления
  subscribeStringCreateForm?: string; // Строка подписки ответа сохранения формы
  subscribeStringUpdateForm?: string; // Строка подписки ответа обновления формы
  subscribeStringAllUpdateForm?: string; // Строка подписки ответа массового обновления формы
  subscribeError?: string; // Строка подписки ответа ошибок
  table: string; // обозначение страницы
  columns: IColumnType<any>[]; // колонки таблицы
  titlePage: string; // название страницы
  keyItemName: string; // на какое поле с названием ссылаться в объекте для перехода по ссылке
  performsAdditionalActions?: (row?: any) => void; // дополнительные действия после submit
  fetchTables: (params: any) => Promise<any>; // метод получения данных для таблицы
  fetchDelete: (params: IParamRestDelete) => Promise<any>; // метод удаления
  checkDeletionCancellation?: (row: any) => {
    isDelete: boolean;
    message: string;
  }; // проверка отмены удаления
  mapperData?: (arr: any[]) => any[] | any; // дополнительный обработчик данных таблицы если требуется
  mapperDataServer?: (arr: any) => any; // дополнительный обработчик данных таблицы если требуется
  location: {
    pathname: string;
    state: any;
    search: any;
    key: any;
  };
}

// общий хук таблиц
export const useGettingDataForTables = ({
  table,
  columns,
  location,
  titlePage,
  keyItemName,
  subscribeError,
  subscribeStringDelete,
  subscribeStringUpdateForm,
  subscribeStringCreateForm,
  subscribeStringAllUpdateForm,
  mapperData,
  fetchTables,
  fetchDelete,
  mapperDataServer,
  performsAdditionalActions,
  checkDeletionCancellation,
  duration = 6,
}: IGetTable) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customMessage = useModalMessage(true);
  const { pathname } = location;
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [dataFilters, setDataFilters] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [current, setCurrent] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [currentOrder, setCurrentOrder] = useState<string>("DESC");
  const [columnsTable, setColumnsTable] = useState(() => columns);
  const [columnFilters, setColumnFilters] = useState({});
  const [selectedRows, setSelected] = useState<Set<number>>(new Set());
  const [timerCount, setTimerCount] = useState(0);

  const storageManager = new SessionStorageManager(pathname);
  const storageFormManager = new SessionStorageManager(`${table}-form`);
  const storageLocal: any = storageManager.getItem();

  const checkedData = useSelector(
    (state: RootState) => state.filters.checkedData
  );
  const checkedRows = useSelector(
    (state: RootState) => state.filters.checkedRows
  );

  useEffect(() => {
    let timer: any = undefined;
    if (loadingSubmit) {
      setStorageKey("submit", "submit");
      let count = 0;
      timer = setInterval(() => {
        count++;
        setTimerCount(count);
        if (count > duration) {
          clearInterval(timer);
        }
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
        setStorageKey("submit", "");
      }
      storageFormManager.removeItem(`${table}-form`);
      dispatch(resetStateData());
    };
  }, [loadingSubmit, duration]);

  useEffect(() => {
    if (timerCount > duration && getStorageKey("submit")) {
      customMessage.error("Возникла ошибка сервера при сохранении");
      setLoadingSubmit(false);
      setTimerCount(0);
      setStorageKey("submit", "");
    }
  }, [timerCount]);

  useEffect(() => {
    if (!subscribeError || !loadingSubmit) return;

    socket.on(subscribeError, handlerError);

    return () => {
      socket.off(subscribeError, handlerError);
    };
  }, [subscribeError, loadingSubmit]);

  useEffect(() => {
    if (!subscribeStringCreateForm) return;

    socket.on(subscribeStringCreateForm, handlerSubscribeSubmit);

    return () => {
      socket.off(subscribeStringCreateForm, handlerSubscribeSubmit);
    };
  }, [subscribeStringCreateForm]);

  useEffect(() => {
    if (!subscribeStringAllUpdateForm) return;

    socket.on(subscribeStringAllUpdateForm, handlerSubscribeAllSubmit);

    return () => {
      socket.off(subscribeStringAllUpdateForm, handlerSubscribeAllSubmit);
    };
  }, [subscribeStringAllUpdateForm]);

  useEffect(() => {
    if (!subscribeStringUpdateForm) return;

    socket.on(subscribeStringUpdateForm, handlerSubscribeSubmit);

    return () => {
      socket.off(subscribeStringUpdateForm, handlerSubscribeSubmit);
    };
  }, [subscribeStringUpdateForm]);

  useEffect(() => {
    if (!subscribeStringDelete) return;

    socket.on(subscribeStringDelete, handlerSubscribeDelete);

    return () => {
      socket.off(subscribeStringDelete, handlerSubscribeDelete);
    };
  }, [subscribeStringDelete]);

  const { generateCSV, copyToClipboard } = useCsvExport({
    fields: columns
      .filter((item: any) => item.dataIndex !== "hidden") // Исключаем элементы с dataIndex равным 'hidden'
      .map((item: any) => item.dataIndex), // Создаем массив из dataIndex
  });

  const getStorageForm = (): { [key: string]: any } =>
    storageFormManager.getItem() || {};

  const getStorageKey = (key: string) => getStorageForm()?.[key];

  const setStorageKey = (key: string, value: any) => {
    const storageLocalForm = getStorageForm();
    storageLocalForm[key] = value;
    storageFormManager.setItem(storageLocalForm);
  };

  const setSelectedRows = (rows: any[], bol: boolean) => {
    dispatch(addCheckedData(dataTable));

    if (checkedRows) {
      const result = new Set([...checkedRows, ...rows]);
      [...rows]?.forEach((el) => {
        if (!bol) {
          result.delete(el);
        }
      });
      setSelected(result);
      dispatch(setCheckedRows([...result]));
      return;
    }

    dispatch(setCheckedRows([...rows]));
    setSelected(new Set([...rows]));
  };

  const clearFiltersFetch = () => {
    setFiltersFetch([]);
  };

  const filtersFetch = (text: string, column: string) =>
    Rest_filters.filtersSearch({ text, column: column, table }).then((res) => {
      setDataFilters([]);
      if (res.data?.rows) {
        setDataFilters(res.data?.rows);
      }
    });

  const handlerError = (response: any) => {
    setTimerCount(0);
    const res = Utils.checkIsJson(response)
      ? (Utils.checkIsJson(response) as {
          success: number;
          message?: string;
          data: any[];
        })
      : response;
    if (res?.message) {
      if (getStorageKey("submit")) {
        customMessage.error(res.message);
      }
    }
    setStorageKey("submit", "");
    setLoadingSubmit(false);
  };

  const handlerSubscribeDelete = (response: any) => {
    setLoading(true);
    const result: IParamRestDelete = Utils.checkIsJson(response)
      ? Utils.checkIsJson(response)
      : response;

    const ids = result?.ids;
    const isDelete = result?.isDelete;

    if (!ids?.length) {
      customMessage.error(t("Ошибка сервера"));
      setLoading(false);
      return;
    }

    setDataTable((prev) =>
      prev?.map((item) => {
        const findItem = ids.find((id?: number) => id === item?.id);
        if (findItem) {
          return { ...item, delete: isDelete };
        }
        return item;
      })
    );

    setLoading(false);

    dispatch(
      addListNotification({
        title: t("Страница ") + t(titlePage),
        subtitle:
          t(isDelete ? "Удалено" : "Восстановлено") + `  ${ids?.length}`,
      })
    );
  };

  const handlerSubscribeSubmit = (response: any) => {
    let isSubmit = getStorageKey("submit");
    try {
      setStorageKey("submit", "");
      setTimerCount(0);
      setLoadingSubmit(false);
      const res = Utils.checkIsJson(response)
        ? (Utils.checkIsJson(response) as {
            success: number;
            message?: string;
            data: any[];
          })
        : response;
      const data = Array.isArray(res?.data) ? res?.data : [res?.data];
      const itemRes = mapperData ? mapperData(data)?.[0] : data?.[0];
      const item = mapperDataServer ? mapperDataServer(itemRes) : itemRes;
      if (!res?.success && isSubmit) {
        customMessage.error(res?.message);
        return;
      }
      // console.log(item);

      if (!item && isSubmit) {
        customMessage.error(t("Ошибка сервера"));
        return;
      } else if (!item || !item?.id) {
        return;
      } else {
        const submitMessage = "Обновлен";

        setDataTable((prev) => {
          const result = [...prev];
          const index = result?.findIndex((cont) => cont?.id === item?.id);
          if (index < 0) {
            return [{ ...item }, ...result];
          }
          result[index] = { ...result[index], ...item };
          return [...result];
        });
        // выполнение функций после сохранения
        if (performsAdditionalActions) {
          performsAdditionalActions(item);
        }
        // отправка уведомлений
        dispatch(
          addListNotification({
            title: t("Страница") + " " + t(titlePage),
            subtitle: `${t(submitMessage)} ${get(data?.[0], keyItemName, "")}`,
            link: { id: data?.[0]?.id, key: table },
          })
        );
      }
    } catch (err) {
      console.log(err);
    } finally {
      isSubmit = false;
    }
  };

  const handlerSubscribeAllSubmit = (response: any) => {
    try {
      const res = Utils.checkIsJson(response)
        ? (Utils.checkIsJson(response) as {
            success: number;
            message?: string;
            count?: number;
          })
        : response;
      if (res?.count) {
        setSelected(new Set());
        dispatch(resetStateData());
        dispatch(
          addListNotification({
            title: t("Страница ") + t(titlePage),
            subtitle: t("Обновлено") + ` ${res.count}`,
            link: null,
          })
        );
      }
      updateDate();
    } catch (err) {
      console.log(err);
    }
  };

  const clearFilters = () => {
    clearFiltersFetch();
    storageManager.removeItem(pathname);
    setCurrent(0);
    setPageSize(10);
    setTotal(1);

    setColumnFilters({});
  };

  const fetchData = ({
    current,
    pageSize,
    order = "DESC",
    options = {},
  }: {
    current: number;
    pageSize: number;
    order?: string;
    options?: { [key: string]: string };
  }) => {
    setLoading(true);

    fetchTables({
      order,
      ...options,
      current,
      pageSize,
    })
      .then((res) => {
        const response = res.data;
        const rows = mapperData ? mapperData(response?.rows) : response?.rows;
        setDataTable(rows || []);
        setTotal(response?.count || 0);
      })
      .catch((err) => {
        if (err?.message) {
          customMessage.error(err.message);
          return;
        }
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateDate = (param?: { [key: string]: any }) => {
    clearFilters();
    const options = param || {};
    fetchData({ current, pageSize, options });
  };

  const handlerOrder = (order: string) => {
    setCurrentOrder(order);
    updateEffectDate({ current, pageSize, order });
  };

  const handlerDeleteRow = (row: any) => {
    const { id, isDelete } = { id: row?.id, isDelete: !row?.delete };
    if (checkDeletionCancellation) {
      const { isDelete, message } = checkDeletionCancellation(row);
      if (!isDelete) {
        customMessage.info(message);
        return;
      }
    }
    fetchDelete({ ids: [id], isDelete });
  };

  const handlerDeleteCopyAll = (selected: Set<number | undefined>) => {
    fetchDelete({ ids: checkedRows, isDelete: true });
  };

  const handlerCopyAll = (selected: Set<number | undefined>) => {
    const rowsData = generateCSV(checkedRows, checkedData);
    copyToClipboard(rowsData);
  };

  // выгрузка в файл
  const onUploadFile = (selected: Set<number>) => {
    Rest_files.uploadExcel({ table, ids: checkedRows })
      .then((res) => {
        downloadLinkContent({
          content: res.data,
          fileName: table + ".xlsx",
        });
      })
      .catch((err) => {
        customMessage.error(t("Ошибка загрузки файла"));
      })
      .finally(() => {
        setSelected(new Set());
        dispatch(resetStateData());
      });
  };

  const filtersColumnTable = ({
    data,
    current,
    pageSize,
    order = "DESC",
    options = {},
  }: {
    data: any;
    current: number;
    pageSize: number;
    order?: string;
    options?: { [key: string]: any };
  }) => {
    setSelected(new Set());
    dispatch(resetStateData());
    filtersColumn({data, current, order, pageSize, options});
  };
  const filtersColumn = ({
    data,
    current,
    pageSize,
    order = "DESC",
    options = {},
  }: {
    data: any;
    current: number;
    pageSize: number;
    order?: string;
    options?: { [key: string]: any };
  }) => {
    if (data && Object.keys(data).length > 0) {
      setLoading(true);
      Rest_filters.fetchFilters({
        order,
        ...options,
        data,
        current,
        pageSize,
        table,
      })
        .then((res) => {
          const response = res.data;
          const rows = mapperData ? mapperData(response?.rows) : response?.rows;
          setDataTable(rows ?? []);
          setTotal(response?.count ?? 0);
        })
        .catch((err) => {
          if (err?.message) {
            customMessage.error(err.message);
            return;
          }
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      fetchData({ current, pageSize, order, ...options });
    }
    setCurrent(current);
  };

  const updateEffectDate = (param?: { [key: string]: any }) => {
    const options = param || {};

    if (storageLocal) {
      setCurrent(storageLocal.pagination.current || 1);
      setColumnFilters(storageLocal.arrFilters);
      setPageSize(storageLocal.pagination.page || 10);
      filtersColumn({
        data: storageLocal.fiters,
        current: storageLocal.pagination.current || 1,
        pageSize: storageLocal.pagination.page || 10,
        options,
      });
      return;
    }
    fetchData({ current, pageSize, options });
  };

  const onChangePagination = (page: IPagination) => {
    try {
      if (storageLocal) {
        const storageData: StorageData = {
          ...storageLocal,
          bull: storageLocal?.bull,
          pagination: {
            page: page.pageSize,
            current: page.current,
            total: page.total,
          },
        };
        filtersColumn({
          data: storageLocal.fiters,
          current: page.current,
          pageSize: page.pageSize,
          order: currentOrder,
        });
        setCurrent(page.current);
        setPageSize(page.pageSize);
        storageManager.updateItem(storageData);
        return;
      }
      if (page.current != null && page.pageSize != null) {
        setCurrent(page.current);
        setPageSize(page.pageSize);
        fetchData({
          current: page.current,
          pageSize: page.pageSize,
          order: currentOrder,
        });
      }
    } catch (err) {
      customMessage.error("Ошибка пагинации");
    }
  };
  // для смены в колонке атрибутов
  const setColumns = (col: IColumnType<any>) => {
    setColumnsTable((prev) =>
      prev.map((item) => {
        if (col?.key === item.key) {
          return { ...item, ...col };
        }
        return item;
      })
    );
  };

  return {
    customMessage,
    current,
    pageSize,
    dataTable,
    loadingSubmit, // переменная для блокировки кнопки в процессе сохранения
    optionsTable: {
      columns: columnsTable,
      loading,
      columnFilters,
      selectedRows,
      pagination: {
        total: Number(total),
        current,
        pageSize,
        showSizeChanger: true,
      },
      dataFilters,
      clearFilters, //
      clearFiltersFetch,
      handlerCopyAll,
      handlerOrder,
      filtersFetch,
      setDataTable,
      filtersColumn: filtersColumnTable,
      setSelectedRows,
      handlerDeleteRow,
      setColumnFilters,
      onChangePagination,
      handlerDeleteCopyAll,
      onUploadFile,
      setColumns,
    },
    setTotal,
    setLoading,
    checkedData,
    checkedRows,
    setSelected,
    updateDate, // запрос табличных данных без фильтров
    setDataTable, // ручное добавление данных в таблицу
    updateEffectDate, // запрос табличных данных с проверкой фильтров
    setLoadingSubmit, // ручное изменения переменной процесса сохранения
  };
};
