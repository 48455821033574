import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import classnames from "classnames";
import styles from "./style.module.scss";
import { delay, get, result } from "lodash";
import {
  AutoComplete,
  Flex,
  Form,
  Input,
  Modal,
  Typography,
  message as popMessage,
} from "antd";
import { useTranslation } from "react-i18next";
import IconButton from "../ui/IconButton";
import { useStoreDispatch } from "../../store";
import { UseSelector } from "react-redux";
import { findExecutor } from "../../store/contact";
import { IUsers } from "../../types/users";
import { ChatItem } from "./components/ChatItem";
import { Utils } from "../../utils";
import {
  sendMessage,
  fetchMessages,
  deleteMessage,
  addMessage,
  removeMessage,
} from "../../store/chat";
import socket from "../../socket";
import { IComment, IChat, IMessage } from "../../store/chat";
import { getMessage } from "@reduxjs/toolkit/dist/actionCreatorInvariantMiddleware";
import { error } from "console";
import { Rest_Chat } from "../../services/rest_chat";
import { useForm } from "antd/es/form/Form";

interface IChatComponent {
  user: IUsers;
  table?: string;
  row_id?: number;
}

export const Chat: React.FC<IChatComponent> = ({ user, table, row_id }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  // const form = Form.useForm()
  const dispatch = useStoreDispatch();
  const [dataUsers, setDataUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [sender, setSender] = useState(Utils.getUserInfo());
  const [messages, setMessages] = useState<IMessage[]>([]);
  const message: string = Form.useWatch("message", { form, preserve: true });
  const searchValue: string = Form.useWatch("searchValue", {
    form,
    preserve: true,
  });
  const recipient: any = Form.useWatch("toUser", {
    form,
    preserve: true,
  });

  useEffect(() => {
    form.resetFields();
  }, []);

  const initialValue = {
    message: null,
    messages: [],
    searchValue: null,
    recipient: null,
  };

  const loadMessages = async () => {
    try {
      const result = await Rest_Chat.getMessage({ row_id });
      setMessages(result);
      form.setFieldValue("messages", result);
    } catch (error) {
      console.error("Ошибка загрузки сообщений:", error);
    }
  };

  const handleError = (response: any) => {
    popMessage.error(response.message);
  };

  useEffect(() => {
    if (row_id) {
      loadMessages();
    }
  }, [row_id]);

  useEffect(() => {
    if (!row_id) return;
    const handleAddMessage = (newMessage: IMessage) => {
      setMessages((prev) => [newMessage, ...prev]);
      form.setFieldValue("messages", [newMessage, ...messages]);
      loadMessages();
    };

    const handleDeleteMessage = () => {
      loadMessages(); // Перезагружаем сообщения при удалении
    };
    socket.on("error", handleError);
    socket.on("return-set-message", handleAddMessage);
    socket.on("return-delete-message", handleDeleteMessage);
    socket.on("return-get-message", (messages: IMessage[]) => {
      setMessages(messages);
      form.setFieldValue("messages", messages);
    });

    form.setFieldValue("message", undefined);
    form.setFieldValue("searchValue", undefined);

    return () => {
      socket.off("return-set-message", handleAddMessage);
      socket.off("return-delete-message", handleDeleteMessage);
      socket.off("return-get-message");
    };
  }, [form, row_id]);

  const handleDeleteMessage = async (id: number) => {
    popMessage.loading("Удаление сообщения...");
    try {
      await Rest_Chat.deleteMessage({ id });
      setMessages((prev) => prev.filter((msg) => msg.id !== id));
      form.setFieldValue(
        "messages",
        messages.filter((msg) => msg.id !== id)
      );
    } catch (error) {
      console.error("Ошибка удаления:", error);
      loadMessages();
    }
    // popMessage.loading('Удаление сообщения...')
  };

  const handlerSubmit = async () => {
    const comment: IComment = {
      sender_avatar: sender.avatar,
      sender_fio: sender.fio,
      sender_email: sender.email,
      sender_name: sender.name,
      sender_last_name: sender.last_name,

      recipient_avatar: recipient?.avatar,
      recipient_fio: recipient?.fio,
      recipient_email: recipient?.email,
      recipient_tag: recipient?.tag,
      recipient_name: recipient?.name,
      recipient_last_name: recipient?.last_name,

      createdAt: new Date().toISOString(),
      sender_id: sender.id,
      recipient_id: recipient?.id,
      message_text: message,
      row_id: row_id,
      table: table,
    };

    try {
      await Rest_Chat.setMessage(comment);
      // form.setFieldValue(message, "");
      // form.setFieldValue(searchValue, "");
      // form.setFieldValue(recipient, null);
      setDataUsers([]);
      form.setFieldsValue({
        searchValue: undefined,
        message: undefined,
        recipient: undefined,
      });
    } catch (error) {
      console.error("Ошибка отправки:", error);
    }
  };

  const onOpenChat = () => {
    setIsOpen(true);
  };

  const onCloseChat = () => {
    setIsOpen(false);
  };

  const handleSelect = useCallback((id: string, user: any) => {
    const [tag] =
      typeof user?.email === "string" && /@/.test(user?.email)
        ? user.email.split("@")
        : [""];
    const info = {
      id,
      avatar: user?.avatar,
      tag: `@${tag}`,
      fio: user?.fio ?? "",
      email: user?.email ?? "",
      phone: user?.phone ?? "",
      name: user?.name ?? "",
      last_name: user?.last_name ?? "",
    };
    form.setFieldValue("toUser", info);
    form.setFieldValue("searchValue", user?.fio ?? "");
    // console.log("user (select):", user);
    // console.log("info (select):", info);
  }, []);

  const handleSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const item = dataUsers.find(
      (user: any) => user?.label === evt.target.value
    );

    if (!item) {
      form.setFieldValue("toUser", null);
      form.setFieldValue("searchValue", "");
    }
  };

  const handleSearch = (text: string) => {
    if (text?.length >= 2) {
      delay(() => {
        dispatch(findExecutor(text.toLocaleLowerCase()))
          .then((res) => {
            const result = res.payload?.length
              ? res.payload.map((item: any) => ({
                  ...item,
                  label: item?.label || item.fio,
                  value: item?.id,
                }))
              : [];
            setDataUsers(result);
          })
          .catch((e) => {
            console.error(e);
            setDataUsers([]);
          });
      }, 1000);
    }
  };

  return (
    <Form
      className={styles.chat}
      noValidate
      autoComplete="off"
      form={form}
      initialValues={{ ...initialValue }}
      scrollToFirstError
      component={"div"}
      disabled={!row_id}
    >
      <Flex justify="space-between">
        <Typography.Text
          className={classnames(styles["label-subtitle"], {
            [styles.rent_request_title]: table === "rent_request",
            [styles.finance_title]: table === "finance",
          })}
        >
          {t("Чат")}
        </Typography.Text>
        <Typography.Link
          onClick={onOpenChat}
          className={classnames({
            [styles.rent_request_open_modal_chat]: table === "rent_request",
            [styles.finance_open_modal_chat]: table === "finance",
          })}
        >
          {t("Развернуть")}
        </Typography.Link>
      </Flex>
      <div
        hidden={isOpen}
        className={classnames(styles["chat__control"], {
          [styles.rent_request_control]: table === "rent_request",
        })}
      >
        <div
          className={classnames("all-custom-v-scroll", styles["chat__view"], {
            [styles.rent_request_chat]: table === "rent_request",
            [styles.finance_chat]: table === "finance",
          })}
        >
          {messages?.map((item: any) => (
            <ChatItem
              key={item.id}
              isWidth
              comment={item}
              onDelete={() => handleDeleteMessage(item.id)}
            />
          ))}
        </div>
        <div
          className={classnames(styles["input-box"], {
            [styles.rent_request_select]: table === "rent_request",
          })}
        >
          <Form.Item name="searchValue" noStyle>
            <AutoComplete
              popupClassName="certain-category-search-dropdown"
              style={{ width: "100px" }}
              allowClear
              placeholder={t("Кому")}
              options={dataUsers}
              // options={[{ label: t('Все'), value: 'all' }, ...dataUsers]}
              onSearch={handleSearch}
              onSelect={handleSelect}
              // onSelect={(value, option) => {
              //   if (value === 'all') {
              //     form.setFieldValue("toUser", null);
              //     form.setFieldValue("searchValue", t('Все'));
              //     return;
              //   }
              //   handleSelect(value, option);
              // }}
              onBlur={handleSearchBlur}
              title={searchValue}
            />
          </Form.Item>
          <Form.Item name="message" noStyle>
            <Input
              className={styles["input-box__input"]}
              onPressEnter={handlerSubmit}
              suffix={
                <IconButton
                  disabled={!message}
                  onClick={handlerSubmit}
                  className={styles.button}
                  iType="chat"
                />
              }
              placeholder={t("Введите комментарий")}
              allowClear
            />
          </Form.Item>
        </div>
      </div>
      <Modal
        className={styles.modal}
        open={isOpen}
        footer={null}
        closable
        width={900}
        height={700}
        centered
        onCancel={onCloseChat}
        title={t("Чат")}
      >
        <Form
          className={styles["modal__content"]}
          noValidate
          autoComplete="off"
          form={form}
          initialValues={{ ...initialValue }}
          scrollToFirstError
          component={"div"}
        >
          <div
            className={classnames("all-custom-v-scroll", styles["modal__view"])}
          >
            {messages?.map((item: any) => (
              <ChatItem
                key={item.id}
                isWidth
                comment={item}
                onDelete={() => handleDeleteMessage(item.id)}
              />
            ))}
          </div>
          <div className={styles["input-box"]}>
            <Form.Item name="searchValue" noStyle>
              <AutoComplete
                popupClassName="certain-category-search-dropdown"
                style={{ width: "100px" }}
                allowClear
                placeholder={t("Кому")}
                options={dataUsers}
                onSearch={handleSearch}
                onSelect={handleSelect}
                onBlur={handleSearchBlur}
                title={searchValue}
              />
            </Form.Item>
            <Form.Item name="message" noStyle>
              <Input
                className={styles["input-box__input"]}
                suffix={
                  <IconButton
                    disabled={!message}
                    onClick={handlerSubmit}
                    className={styles.button}
                    iType="chat"
                  />
                }
                placeholder={t("Введите комментарий")}
                allowClear
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </Form>
  );
};
