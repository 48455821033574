import React, { useMemo, useState, useEffect } from "react";
import classnames from "classnames";
import style from "../style.module.scss";
import {
  Button,
  Dropdown,
  Flex,
  MenuProps,
  Modal,
  Tag,
  Typography,
} from "antd";
import { IForm } from "../../../store/releases";
import { BASE_TAB, releaseStatus, TABLE_TAB } from "../constants";
import { BaseTab, TableTab } from "./view";
import { useTranslation } from "react-i18next";
import { useLink } from "../../../hooks";
import { ITab, ITabs } from "../../../components/ui/Tabs/Tabs";
import { RouteNames } from "../../../router/names";
import Icon from "@ant-design/icons";
import { moreIcon } from "../../../assets/image/svg";
import CopyButton from "../../../components/ui/CopyButton/CopyButton";
import { RELEASE, RENT_DEAL, SALES_DEAL } from "../../../constants";
import UploaderDocsUnique from "../../../components/ui/UploaderDocsUnique";
import { Utils } from "../../../utils";
import { useSelector } from "react-redux";
import socket from "../../../socket";
import { RootState, useStoreDispatch } from "../../../store";
import { getUserPermissions } from "../../../store/users";
import { setUserPermissions } from "../../../store/users";
import { TableEditTab } from "./view/TableEditTab";

export const ViewRelease: React.FC<IForm> = ({
  initialValue,
  typeForm,
  isOpen,
  isCopy,
  onPdf,
  onClose,
  onEdit,
  onDeal,
  onHistory,
  canEdit,
}) => {
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const [activeKey, setActiveKey] = useState(() => BASE_TAB);
  const [isModalFiles, setIsModalFiles] = useState<boolean>(false);
  const link = useLink({ key: RELEASE, id: initialValue?.id as number });

  //==============================================
  // подключаемся к хранилищу данных с правами
  //==============================================
  const permissionsData: any = useSelector(
    (state: RootState) => state.users.permissionsData
  );

  const isDisabled = !!initialValue?.delete;

  const openModalFile = () => {
    setIsModalFiles(true);
  };
  const closeModalFile = () => {
    setIsModalFiles(false);
  };

  const openEditForm = () => {
    setIsModalFiles(false);
    if (onEdit) {
      onEdit();
    }
  };
  const openModalHistory = () => {
    setIsModalFiles(false);
    if (onHistory) {
      onHistory();
    }
  };

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: BASE_TAB,
        label: "Основное",
        forceRender: true,
        content: <BaseTab initialValue={initialValue} />,
      },
      {
        key: TABLE_TAB,
        label: "Таблица",
        forceRender: true,
        content: <TableEditTab initialValue={initialValue} />,
      },
    ],
    [isOpen, typeForm]
  );

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: t("Связанные документы"),
      disabled: true,
    },
    {
      key: 2,
      label: t("История изменений"),
      disabled: !onHistory,
      onClick: openModalHistory,
    },
    {
      key: 3,
      label: t("Создать сделку на основание релиза"),
      disabled: isDisabled,
      children: [
        {
          key: 3.1,
          label: (
            <Typography.Text
              data-id="link"
              onClick={() =>
                onDeal({
                  path: RouteNames.SALES_DEAL,
                  state: {
                    [SALES_DEAL]: {
                      containers: initialValue?.containers
                        ?.filter((item) => item?.num_container && !item?.delete)
                        ?.map((item) => ({
                          ...item,
                          id: item?.container_id,
                          release: {
                            release_id: initialValue.release_id,
                            id: initialValue.id,
                          },
                          edit: false,
                        })),
                    },
                  },
                })
              }
            >
              {t("Продажа")}
            </Typography.Text>
          ),
        },
        {
          key: 3.2,
          disabled: isDisabled,
          label: (
            <Typography.Text
              data-id="link"
              onClick={() =>
                onDeal({
                  path: RouteNames.RENT_DEAL,
                  state: {
                    [RENT_DEAL]: {
                      releases: [
                        {
                          release_id: initialValue.release_id,
                          options: [
                            {
                              label: initialValue.release_id,
                              value: initialValue.id,
                            },
                          ],
                          ids: [],
                          saveFiles: [],
                        },
                      ],
                      containers: initialValue?.containers
                        ?.filter((item) => item?.num_container && !item?.delete)
                        ?.map((item) => ({
                          ...item,
                          id: item?.container_id,
                          release: {
                            release_id: initialValue.release_id,
                            id: initialValue.id,
                          },
                          edit: false,
                        })),
                    },
                  },
                })
              }
            >
              {t("Аренда")}
            </Typography.Text>
          ),
        },
      ],
    },
    {
      key: 4,
      label: t("Выгрузить шаблон релиза PDF (РФ)"),
      onClick: () => onPdf("ru"),
    },
    {
      key: 5,
      label: t("Выгрузить шаблон релиза PDF (EN)"),
      onClick: () => onPdf("en"),
    },
    // {
    //   key: 6,
    //   label: t("Редактировать"),
    //   disabled: isDisabled,
    //   onClick: openEditForm,
    // },
    ...(canEdit
      ? [
          {
            key: 6,
            label: t("Редактировать"),
            disabled: isDisabled,
            onClick: openEditForm,
          },
        ]
      : []),
    {
      key: 7,
      label: t("Просмотр вложений"),
      onClick: openModalFile,
      disabled: !initialValue?.files?.length,
    },
    {
      key: 8,
      label: (
        <CopyButton
          text={link}
          textButton={t("Скопировать ссылку")}
          textSuccess={t("Ссылка скопирована")}
        />
      ),
      disabled: !isCopy,
    },
  ];
  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

  return (
    <div className={style["form"]}>
      <Flex vertical gap={24}>
        <Flex justify="space-between" align="center">
          <Flex gap={10} align="center" style={{ marginTop: 15 }}>
            <Typography.Text
              className={classnames(style["title"], style["title_label"])}
            >
              {initialValue.release_id}
            </Typography.Text>
          </Flex>
          <Dropdown
            overlayClassName={style["menu-drop"]}
            placement="bottomRight"
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <Typography.Link onClick={(e) => e.preventDefault()}>
              <Icon component={moreIcon} />
            </Typography.Link>
          </Dropdown>
        </Flex>
        <div className={style["relative"]}>
          <ITabs
            className={style["tabs-form__tab"]}
            isErrorTabs
            activeKey={activeKey}
            tabs={tabs}
            onChange={changeClickTab}
          />
          <div className={style["relative__block"]}>
            <Typography.Text style={{ whiteSpace: "nowrap" }}>
              {t("Статус")}
            </Typography.Text>
            <div className={classnames(style["relative__status"])}>
              {!!initialValue?.status && (
                <Tag style={releaseStatus[initialValue.status].style}>
                  {" "}
                  {t(releaseStatus?.[initialValue.status].title)}
                </Tag>
              )}
            </div>
          </div>
        </div>
      </Flex>
      <Flex
        gap={8}
        justify="space-between"
        style={{ margin: "32px 0 10px auto", maxWidth: "500px" }}
      >
        <Button
          onClick={() => onClose()}
          type="primary"
          style={{ width: "174px" }}
        >
          {t("Закрыть")}
        </Button>
      </Flex>
      <Modal
        open={isModalFiles}
        title={
          <Typography.Text className={style.label}>
            {t("Файлы")}
          </Typography.Text>
        }
        closeIcon
        centered
        footer={null}
        onCancel={closeModalFile}
      >
        <div className={style.modal}>
          <div className={classnames(style["modal__content"])}>
            <div
              className={classnames(style["file-box"], "all-custom-v-scroll")}
            >
              <UploaderDocsUnique
                isView
                isOpen={isOpen}
                files={initialValue?.files}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
