import React, { useEffect, useMemo, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  Form,
  FormListFieldData,
  Input,
  Modal,
  Tooltip,
  Typography,
} from 'antd';

import Icon from '@ant-design/icons';

import style from '../../style.module.scss';
import table from '../table.module.scss';
import { nameColumnsKtk } from '../../constants';
import { getEmpty, getSizeKtk, mapperKtk } from '../utils';
import { IContainers } from '../../../../types/containers';
import IconButton from '../../../../components/ui/IconButton';
import { getSearchLink, openInNewTab } from '../../../../helpers/link-helper';
import { RootState, useStoreDispatch } from '../../../../store';
import { FormInputNumber } from '../../../../components/ui/Form';
import { handleDownloadXlsx } from '../../../../helpers/xlsx-helper';
import { Checkbox } from '../../../../components/ui/Checkbox/Checkbox';
import TooltipButton from '../../../../components/ui/TooltipButton/TooltipButton';
import {
  IContainer,
  IRelease,
  ITab,
  resetContainer,
  setIsContainerDrawer,
  updateViewRelease,
} from '../../../../store/releases';
import { EditIcon, errorImportIcon } from '../../../../assets/image/svg';
import { regNumberKTK } from '../../../../helpers/input-helpers';
import { CONTAINER } from '../../../../constants';
import useModalMessage from '../../../../hooks/useModalMessage';
import { Rest_releases } from '../../../../services/rest_releases';

export const TableEditTab: React.FC<ITab> = () => {
  const [form] = Form.useForm();
  const { info, error } = useModalMessage(true);
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();

  const initialValue = useSelector<RootState>(
    (state) => state.releases.release,
  ) as IRelease;

  useEffect(() => {
    form.setFieldsValue(initialValue);
  }, [initialValue?.id]);

  const reasons = [
    { id: 0, reason: '' },
    { id: 1, reason: t('Отмена закупа') },
    { id: 2, reason: t('Отмена продажи') },
    { id: 3, reason: t('Замена КТК') },
    { id: 4, reason: t('Другое') },
  ];

  const id: number = Form.useWatch('id', {
    form,
    preserve: true,
  });

  const responsible_id: number = Form.useWatch('responsible_id', {
    form,
    preserve: true,
  });

  const no_number: boolean = Form.useWatch('no_number', {
    form,
    preserve: true,
  });

  const type: string = Form.useWatch('type', {
    form,
    preserve: true,
  });

  const terminal: string = Form.useWatch('terminal', {
    form,
    preserve: true,
  });

  const terminal_id: number = Form.useWatch('terminal_id', {
    form,
    preserve: true,
  });

  const terminal_parent_id: number = Form.useWatch('terminal_parent_id', {
    form,
    preserve: true,
  });

  const release_id: string = Form.useWatch('release_id', {
    form,
    preserve: true,
  });

  const containers = Form.useWatch('containers', {
    form,
    preserve: true,
  }) as IContainer[];

  const newContainer = useSelector(
    (state: RootState) => state.releases.newContainer as IContainers,
  );

  const isContainerDrawer = useSelector(
    (state: RootState) => state.releases.isContainerDrawer as boolean,
  );

  const [confirmation, setConfirmation] = React.useState<boolean>(false);

  const [isDelete, setIsDelete] = React.useState<boolean>(false);

  const [reason, setReason] = React.useState(reasons[0]);

  const [editContainer, setEditContainer] = useState();

  const [numbersKtk, setNumbersKtk] = React.useState<string[]>([]);

  const [containerNumbers, setContainerNumbers] = React.useState('');

  const [isOpenContainerNumbers, setIsOpenContainerNumbers] =
    React.useState(false);

  const [indexCurrent, setIndexCurrent] = useState<number>(0);

  const count = useMemo(() => {
    const result = containers?.length
      ? containers?.reduce((prev, cur) => prev + cur?.count || 0, 0)
      : 0;
    return result;
  }, [JSON.stringify(containers)]);

  useEffect(() => {
    if (count && !editContainer) {
      form.setFieldValue('containers_count', count);
    }
  }, [editContainer, count]);

  useEffect(() => {
    if (newContainer && !isContainerDrawer) {
      const fields = form.getFieldsValue();
      const { containers } = fields;
      const { num_container, id, type_container, type_container_id } =
        newContainer;
      const result: IContainer[] = [...containers];
      result[indexCurrent] = {
        ...containers[indexCurrent],
        num_container,
        container_id: id,
        type_container,
        type_container_id,
        size: getSizeKtk(type_container),
        count: 1,
        release_id,
        terminal_id: terminal_id,
        parent_id: terminal_parent_id,
      };
      form.setFieldsValue({
        containers: result,
      });
      dispatch(resetContainer(null));
    }
  }, [isContainerDrawer, newContainer]);

  const toggleEditContainer = (index: any) => {
    setEditContainer(index);
  };

  const filterTerminalKTK = (ktk: IContainer[]) => {
    return (
      ktk?.filter(
        (field) =>
          (terminal_id && field?.['num_container']
            ? field?.['terminal_id'] === terminal_id &&
              type === field?.['type_container']
            : type === field?.['type_container']) && !field?.release,
      ) || []
    );
  };

  const closeModalContainersNumbers = () => {
    setIsOpenContainerNumbers(false);
    setContainerNumbers('');
    setNumbersKtk([]);
  };

  const handlerUpload = () => {
    const rows = containers.map(
      ({ num_container, size, type_container, comment, count }) => ({
        size,
        type_container,
        num_container,
        comment,
        count,
      }),
    );
    handleDownloadXlsx({
      nameFile: `${t('ктк релиза')} ${release_id ?? ''}`,
      nameColumns: nameColumnsKtk,
      nameList: `${t('Лист')} 1`,
      rows,
    });
  };

  const handlerReceivingData = (newContainers: string[]) => {
    const result = newContainers.filter(
      (num_container) =>
        containers.findIndex(
          (cont) =>
            cont.num_container?.toLocaleLowerCase() ===
            num_container?.toLocaleLowerCase(),
        ) === -1,
    );

    Rest_releases.findContainers({ data: result, key: 'num_container' })
      .then((res) => {

        const filterContainers = res?.data?.length
          ? filterTerminalKTK(mapperKtk(res.data))
          : [];
        const currentResult = [...filterContainers, ...containers];
        form.setFieldsValue({
          containers: currentResult,
        });
        dispatch(
          updateViewRelease({ ...initialValue, containers: currentResult }),
        );
        const titleKtk = `${t('Из')} ${result?.length} ${t('добавлено')} ${
          filterContainers.length
        } ктк`;
        const resultMessage = (
          <Flex vertical gap={5} style={{ padding: 0, marginRight: '20px' }}>
            {result?.length !== filterContainers?.length && (
              <Typography.Text>
                {t('Проверьте терминал и тип ктк,')}
                <br />
                {t('или ктк есть в другом релизе')}
              </Typography.Text>
            )}
            <Typography.Text strong>{titleKtk}</Typography.Text>
          </Flex>
        );
        info(resultMessage, 5);
      })
      .catch((err) => {
        error(`${t('Произошла ошибка')}, добавлено 0 ктк`, 3);
        console.error(t('Произошла ошибка'), err);
      });
  };

  const removeField = () => {
    const result: IContainer[] = [...containers];
    result[indexCurrent] = {
      ...containers[indexCurrent],
      reason: reason.reason,
      delete: true,
      active: false,
    };
    form.setFieldsValue({
      containers: result,
    });
    dispatch(
      updateViewRelease({ ...initialValue, containers: result }),
    );
    setIndexCurrent(0);
    closeConfirmation();
  };

  const disabledField = () => {
    const result: IContainer[] = [...containers];
    result[indexCurrent] = {
      ...containers[indexCurrent],
      reason: reason.reason,
      delete: false,
      active: true,
    };
    form.setFieldsValue({
      containers: result,
    });
    dispatch(
      updateViewRelease({ ...initialValue, containers: result }),
    );

    setIndexCurrent(0);
    closeConfirmation();
  };

  const createContainer = (index: any) => {
    setIndexCurrent(index);
    const container = containers?.[index];
    dispatch(setIsContainerDrawer(true));
    dispatch(
      resetContainer({
        id: null,
        responsible_id,
        release: {
          id,
          release_id: release_id,
          count: 1,
        },
        type_container: container?.type_container,
        terminal_id: terminal_id,
        location_id: terminal_parent_id,
        location: { id: terminal_parent_id },
        terminal: {
          value: terminal_id,
          id: terminal_id,
          name: terminal,
          label: terminal,
        },
        terminalStock: [],
      }),
    );
  };

  const closeConfirmation = () => {
    setConfirmation(false);
    setIsDelete(false);
    setReason(reasons[0]);
  };

  const deletedContainer = (index: any, remove?: (ind: any) => void) => {
    if (remove) {
      remove(index);
      return;
    }
    setIndexCurrent(index);
    setIsDelete(true);
    setReason(reasons[0]);
    setConfirmation(true);
  };

  const submitContainer = (index: number) => {
    setIndexCurrent(index);
    setIsDelete(false);
    setConfirmation(true);
  };

  // HACK: порядок для маски нужен
  const changeHandlerAreaNumbers = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const input = e.target;
    const replaceValue = String(input.value).replace(/\n|\s|[^a-zA-Z0-9]/g, '');
    input.value = replaceValue.match(/.{0,11}/g)?.join('\n') || '';
    input.value = input.value.slice(0, -1).toUpperCase();
    setNumbersKtk(
      input.value
        .split('\n')
        .map((num_container) => num_container)
        .filter((val) => regNumberKTK.test(val)),
    );
    setContainerNumbers(input.value);
  };

  const keyUpHandlerAreaNumbers = (
    evt: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    const specialCharRegex = new RegExp('[a-zA-Z0-9]');
    if (
      !specialCharRegex.test(evt.key) &&
      !(evt.ctrlKey && /\D/.test(evt.key))
    ) {
      evt.preventDefault();
      return false;
    }
  };

  const submitInputKtk = () => {
    handlerReceivingData(numbersKtk);
    closeModalContainersNumbers();
  };

  const footerAddNumberContainer = (
    <div className={style.modal__footer}>
      <div className={style['modal__buttons']}>
        <TooltipButton
          propsTooltip={{
            title: !numbersKtk?.length
              ? t('Нет валидных номеров КТК')
              : t('Загрузить'),
          }}
          propsButton={{
            type: 'primary',
            disabled: !numbersKtk?.length,
            onClick: submitInputKtk,
          }}
        >
          {t('Загрузить')}
        </TooltipButton>
        <Button
          onClick={closeModalContainersNumbers}
          type={'text'}
          style={{ color: '#E14453' }}
        >
          {t('Отменить')}
        </Button>
      </div>
    </div>
  );

  const contentAdd = (
    <Flex
      justify="space-around"
      align="center"
      style={{ marginBottom: '20px', maxWidth: '80%' }}
    >
      <Icon component={errorImportIcon} />
      <Typography.Text className={style['modal__title']}>
        {t('Подтвердить выдачу')}
      </Typography.Text>
    </Flex>
  );

  const contentDelete = (
    <Flex vertical gap={20}>
      <Flex gap={10} align="center">
        <Icon component={errorImportIcon} />
        <Typography.Text className={style['modal__title']}>
          Выберите причину удаления
        </Typography.Text>
      </Flex>
      <Flex vertical gap={20} style={{ paddingLeft: '20px' }}>
        <Checkbox
          isChoice={reason.id === 1}
          onChange={(e: boolean) => {
            if (e) {
              setReason(reasons[1]);
            } else {
              if (reason.id === 1) {
                setReason(reasons[0]);
              }
            }
          }}
        >
          {t('Отмена закупа')}
        </Checkbox>
        <Checkbox
          isChoice={reason.id === 2}
          onChange={(e: boolean) => {
            if (e) {
              setReason(reasons[2]);
            } else {
              if (reason.id === 2) {
                setReason(reasons[0]);
              }
            }
          }}
        >
          {t('Отмена продажи')}
        </Checkbox>
        <Checkbox
          isChoice={reason.id === 3}
          onChange={(e: boolean) => {
            if (e) {
              setReason(reasons[3]);
            } else {
              if (reason.id === 3) {
                setReason(reasons[0]);
              }
            }
          }}
        >
          {t('Замена КТК')}
        </Checkbox>
        <Checkbox
          isChoice={reason.id === 4}
          onChange={(e: boolean) => {
            if (e) {
              setReason(reasons[4]);
            } else {
              if (reason.id === 4) {
                setReason(reasons[0]);
              }
            }
          }}
        >
          {t('Другое')}
        </Checkbox>
        <Input
          style={{
            opacity: reason.id === 4 ? 1 : 0,
            visibility: reason.id === 4 ? 'visible' : 'hidden',
          }}
          placeholder={t('Введите причину')}
          onChange={(e) => {
            setReason((prev) => ({
              ...prev,
              reason: e.target.value || t('Другое'),
            }));
          }}
        />
      </Flex>
    </Flex>
  );
  const footerConfirmContainer = ({
    success,
    cancel,
  }: {
    success: () => void;
    cancel: () => void;
  }) => (
    <div className={style.modal__footer} style={{ paddingLeft: '20px' }}>
      <div className={style['modal__buttons']}>
        <TooltipButton
          propsTooltip={{
            title: t(isDelete ? 'Сохранить' : 'Да'),
          }}
          propsButton={{
            type: isDelete ? 'primary' : 'default',
            onClick: success,
          }}
        >
          {t(isDelete ? 'Сохранить' : 'Да')}
        </TooltipButton>
        <TooltipButton
          propsTooltip={{
            title: t(isDelete ? 'Отменить' : 'Нет'),
          }}
          propsButton={{
            type: isDelete ? 'text' : 'default',
            style: isDelete ? { color: '#E14453' } : {},
            onClick: cancel,
          }}
        >
          {t(isDelete ? 'Отменить' : 'Нет')}
        </TooltipButton>
      </div>
    </div>
  );

  const onUpdate = (changedValues: IRelease, allValues: any) => {
    console.log('changedValues', changedValues);
    console.log('allValues', allValues);
    dispatch(
      updateViewRelease({ ...initialValue, ...allValues }),
    );
    return;
  };

  return (
    <Form
      noValidate
      autoComplete="off"
      layout="vertical"
      form={form}
      onValuesChange={onUpdate}
      initialValues={{}}
    >
      {[
        'containers_count',
        'type',
        'no_number',
        'responsible_id',
        'id',
        'terminal',
        'terminal_id',
        'terminal_parent_id',
        'release_id',
      ].map((str, i) => (
        <Form.Item key={i} name={str} noStyle />
      ))}
      <Flex vertical gap={10}>
        <Flex gap={5} style={{ marginBottom: '5px' }}>
          <Typography.Text>{t('Терминал')}:</Typography.Text>
          <Typography.Text type={terminal ? 'success' : 'danger'}>
            {terminal || 'не выбран.'}
          </Typography.Text>
        </Flex>
        <div className={style.buttons__title}>
          <Button
            type="link"
            onClick={() => setIsOpenContainerNumbers(true)}
            style={{ marginRight: 'auto', paddingLeft: 0 }}
            disabled={no_number}
          >
            {t('Заполнить контейнеры автоматически')}
          </Button>
          <IconButton
            iType="excel"
            className={style['button__excel']}
            onClick={handlerUpload}
            style={{
              opacity: !containers?.length ? 0.5 : 1,
            }}
            disabled={!containers?.length}
            title={t('Выгрузить в Excel')}
          />
        </div>

        <Form.List name="containers">
          {(fields: FormListFieldData[], operation, meta: { errors: any }) => (
            <div className={style['block-ktk']}>
              <div
                className={table['overlap-group']}
                style={{ marginBottom: '10px' }}
              >
                <table className={table['table']}>
                  <thead className={table['table__header-fixed']}>
                    <tr className={table['table__header-fixed-row']}>
                      <th
                        className={classnames(
                          table['table__header-fixed-cell'],
                          table['label-card-16'],
                        )}
                        style={{ minWidth: 'auto' }}
                      />
                      {nameColumnsKtk.map((title) => (
                        <th
                          key={title}
                          className={classnames(
                            table['table__header-fixed-cell'],
                            table['label-card-16'],
                          )}
                        >
                          {t(title)}
                        </th>
                      ))}
                      <th
                        className={classnames(
                          table['table__header-fixed-cell'],
                          table['label-card-16'],
                        )}
                        style={{ minWidth: 'auto' }}
                      />
                    </tr>
                  </thead>

                  <tbody className={table['table__body']}>
                    {fields.map(({ ...field }) => (
                      <tr
                        key={field.key}
                        className={table['table__row']}
                        data-row="row"
                      >
                        <td className={classnames(table['table__cell'])}>
                          <Flex className={table['table__menu']} align="center">
                            <div
                              className={classnames(
                                table['text-card-14'],
                                table['menu-icon'],
                              )}
                            >
                              {containers?.[field.name]?.['delete'] ? (
                                <IconButton
                                  className={table['menu-icon__button-error']}
                                  iType="delete"
                                  title={t(
                                    containers?.[field.name]?.['reason'] ||
                                      'Удален',
                                  )}
                                  style={{ cursor: 'default' }}
                                  placement="topRight"
                                />
                              ) : (
                                <>
                                  <IconButton
                                    className={table['menu-icon__button-error']}
                                    iType="close"
                                    title={t('Удалить')}
                                    placement="topRight"
                                    disabled={
                                      containers?.[field.name]?.['count'] > 1
                                    }
                                    onClick={() =>
                                      id && containers?.[field.name]?.['id']
                                        ? deletedContainer(field.name)
                                        : deletedContainer(
                                            field.name,
                                            operation.remove,
                                          )
                                    }
                                  />
                                  <IconButton
                                    className={
                                      table['menu-icon__button-success']
                                    }
                                    iType="check"
                                    title={
                                      containers?.[field.name]?.['active']
                                        ? t('Выдан')
                                        : t('Выдать')
                                    }
                                    placement="topRight"
                                    disabled={
                                      !containers?.[field.name]?.[
                                        'num_container'
                                      ] ||
                                      !id ||
                                      containers?.[field.name]?.['active']
                                    }
                                    onClick={() => submitContainer(field.name)}
                                  />
                                </>
                              )}
                            </div>
                          </Flex>
                        </td>
                        <td className={classnames(table['table__cell'])}>
                          <Form.Item name={[field.name, 'size']} noStyle />
                          <Typography.Text
                            className={classnames(
                              table['text-card-14'],
                              table['pl-10'],
                            )}
                            ellipsis
                          >
                            {containers?.[field.name]?.['size']}
                          </Typography.Text>
                        </td>
                        <td
                          className={classnames(
                            table['table__cell'],
                            table['text-card-14'],
                          )}
                        >
                          <Typography.Text
                            ellipsis={{
                              tooltip: {
                                placement: 'leftBottom',
                                title:
                                  containers?.[field.name]?.type_container ||
                                  '',
                              },
                            }}
                          >
                            {containers?.[field.name]?.['type_container'] || ''}
                          </Typography.Text>
                        </td>

                        <td className={classnames(table['table__cell'])}>
                          <Typography.Link
                            className={classnames(table['text-card-14'], {
                              [table['default-link']]:
                                containers?.[field.name]?.['container_id'],
                            })}
                            ellipsis
                            onClick={() =>
                              openInNewTab(
                                getSearchLink({
                                  key: CONTAINER,
                                  id: containers?.[field.name]?.[
                                    'container_id'
                                  ],
                                }),
                              )
                            }
                          >
                            {containers?.[field.name]?.['num_container']}
                          </Typography.Link>
                        </td>
                        <td className={classnames(table['table__cell'])}>
                          <Tooltip
                            placement={'top'}
                            title={containers?.[field.name]?.['comment']}
                          >
                            {editContainer === `${field.name}comment` ? (
                              <Form.Item name={[field.name, 'comment']}>
                                <Input
                                  autoFocus
                                  allowClear
                                  disabled={
                                    containers?.[field.name]?.['active'] ||
                                    containers?.[field.name]?.['count'] > 1
                                  }
                                  onBlur={() => {
                                    toggleEditContainer(null);
                                  }}
                                />
                              </Form.Item>
                            ) : (
                              <Button
                                style={{
                                  paddingLeft: '10px',
                                  paddingRight: '5px',
                                  maxWidth: '110px',
                                  width: '110px',
                                  justifyContent: 'space-between',
                                }}
                                disabled={
                                  containers?.[field.name]?.['active'] ||
                                  containers?.[field.name]?.['count'] > 1
                                }
                                icon={<EditIcon />}
                                iconPosition="end"
                                onClick={() =>
                                  toggleEditContainer(`${field.name}comment`)
                                }
                              >
                                <Typography.Text ellipsis>
                                  {containers?.[field.name]?.['comment'] ?? ''}
                                </Typography.Text>
                              </Button>
                            )}
                          </Tooltip>
                        </td>
                        <td
                          className={classnames(
                            table['table__cell'],
                            table['text-card-14'],
                          )}
                        >
                          {!containers?.[field.name]?.['num_container'] ? (
                            editContainer === `${field.name}count` ? (
                              <Form.Item name={[field.name, 'count']}>
                                {
                                  <FormInputNumber
                                    allowClear={false}
                                    autoFocus
                                    min={1}
                                    disabled={
                                      containers?.[field.name]?.['active']
                                    }
                                    onBlur={() => {
                                      toggleEditContainer(null);
                                    }}
                                  />
                                }
                              </Form.Item>
                            ) : (
                              <Button
                                style={{
                                  paddingLeft: '10px',
                                  paddingRight: '5px',
                                  maxWidth: '110px',
                                  width: '110px',
                                  justifyContent: 'space-between',
                                }}
                                disabled={containers?.[field.name]?.['active']}
                                icon={<EditIcon />}
                                iconPosition="end"
                                onClick={() =>
                                  toggleEditContainer(`${field.name}count`)
                                }
                              >
                                <Typography.Text
                                  ellipsis={{
                                    tooltip: {
                                      placement: 'leftBottom',
                                      title:
                                        containers?.[field.name]?.count || '',
                                    },
                                  }}
                                >
                                  {containers?.[field.name]?.['count'] || ''}
                                </Typography.Text>
                              </Button>
                            )
                          ) : (
                            <Typography.Text
                              ellipsis={{
                                tooltip: {
                                  placement: 'leftBottom',
                                  title: containers?.[field.name]?.count || '',
                                },
                              }}
                            >
                              {containers?.[field.name]?.['count'] || ''}
                            </Typography.Text>
                          )}
                        </td>
                        <td
                          className={classnames(
                            table['table__cell'],
                            table['text-card-14'],
                          )}
                        >
                          {/* {!containers?.[field.name]?.num_container && (
                            <IconButton
                              className={table['menu-icon__button-depo']}
                              iType="pluse"
                              title={t('Создать в Депо')}
                              placement="topRight"
                              disabled={
                                containers?.[field.name]?.['active'] ||
                                containers?.[field.name]?.['count'] > 1
                              }
                              onClick={() => {
                                createContainer(field.name);
                              }}
                            />
                          )} */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Modal
                className={style.modal}
                style={{ maxWidth: '400px' }}
                open={confirmation}
                centered
                footer={() =>
                  footerConfirmContainer(
                    isDelete
                      ? {
                          success: removeField,
                          cancel: closeConfirmation,
                        }
                      : {
                          success: disabledField,
                          cancel: closeConfirmation,
                        },
                  )
                }
                onCancel={(e: any) => setConfirmation(false)}
              >
                <div className={style.modal}>
                  <div className={classnames(style['modal__content'])}>
                    {isDelete ? contentDelete : contentAdd}
                  </div>
                </div>
              </Modal>
              <Flex justify="space-between" style={{ paddingRight: '10px' }}>
                <Button
                  type="default"
                  className={style['button__primary']}
                  onClick={() =>
                    operation.add(
                      getEmpty({
                        release_id,
                        type,
                        count: 1,
                        terminal_id: terminal_id,
                        location_id: terminal_parent_id,
                        location: { id: terminal_parent_id },
                        terminal: {
                          value: terminal_id,
                          id: terminal_id,
                          name: terminal,
                          label: terminal,
                        },
                      }),
                      0,
                    )
                  }
                  style={{ marginRight: 'auto' }}
                  disabled={!no_number}
                >
                  {t('Добавить контейнер')}
                </Button>
                <Flex gap={5} style={{ marginBottom: '5px' }}>
                  <Typography.Text>{t('Кол-во КТК')}:</Typography.Text>
                  <Typography.Text type={count ? 'success' : 'danger'}>
                    {count || 0}
                  </Typography.Text>
                </Flex>
              </Flex>
            </div>
          )}
        </Form.List>

        <Modal
          open={isOpenContainerNumbers}
          centered
          footer={footerAddNumberContainer}
          onCancel={closeModalContainersNumbers}
        >
          <div className={style.modal} style={{ maxWidth: 'none' }}>
            <div
              className={classnames(style['modal__content'])}
              style={{ padding: '30px' }}
            >
              <Input.TextArea
                value={containerNumbers}
                className={classnames(
                  'all-custom-v-scroll',
                  style['modal__textarea'],
                )}
                placeholder={t('Введите номера ктк')}
                autoSize={{ minRows: 15, maxRows: 18 }}
                onChange={changeHandlerAreaNumbers}
                onKeyUp={keyUpHandlerAreaNumbers}
                onKeyDown={keyUpHandlerAreaNumbers}
              />
            </div>
          </div>
        </Modal>
      </Flex>
    </Form>
  );
};
