import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  AutoComplete,
  Badge,
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tag,
  TreeSelect,
  Typography,
} from "antd";
import classnames from "classnames";
import { delay } from "lodash";
import { Utils } from "../../../utils";
import style from "../style.module.scss";
import { FINANCE_BILLING, messagesModal } from "../../../constants";
import { RootState, useStoreDispatch } from "../../../store";
import ModalConfirm from "../../../components/ui/ModalConfirm/ModalConfirm";
import TooltipButton from "../../../components/ui/TooltipButton/TooltipButton";
import UploaderDocs from "../../../components/ui/UploaderDocs";
import { useLink } from "../../../hooks";

import { CREATE, EDIT } from "../../../helpers/string-helpers";
import { Rest_files } from "../../../services/rest_files";
import {
  defaultValuesBilling,
  IBilling,
  IFormDeal,
  IStatus,
  setFinanceBilling,
  updateFinanceBilling,
} from "../../../store/finance";
import { statusColorsInvoice, noStyleField } from "./constants";
import { useSelector } from "react-redux";
import { ITypeDeal } from "../../../types";
import { findCompany } from "../../../store/contractors";
import { mapperCompany } from "../../rent/deals/utils";
import {
  FormDatePicker,
  FormInputNumber,
  FormSelectSearch,
  FormTable,
} from "../../../components/ui/Form";
import { IContainer, rentTypeId } from "../../../store/rent";
import { disableDateBefore } from "../../../helpers/date-helpers";
import { findExecutor } from "../../../store/contact";
import { findAgreementsCompany } from "../../../store/sales";
import socket from "../../../socket";
import { IColumnsEditTable } from "../../../components/ui/Form/FormTable/FormTable";
import { Checkbox } from "../../../components/ui/Checkbox/Checkbox";
import { mapperSelect } from "../../../helpers/mappers-helpers";

export const FormBilling: React.FC<IFormDeal<IBilling>> = ({
  typeForm,
  isCopy,
  onClose,
  onEdit,
  onView,
  isOpen,
  initialValue,
  title = "Запрос на выставление счета",
  canEdit,
}) => {
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const link = useLink({
    key: FINANCE_BILLING,
    id: initialValue?.id as number,
  });
  const dealType = useSelector<RootState>(
    (state) => state.types.dataTypeDeal
  ) as ITypeDeal[];
  const dataTypePriority = useSelector(
    (state: RootState) => state.types.dataTypePriority
  );
  const dataCurrency = useSelector(
    (state: RootState) => state.types.dataTypeCurrency
  );
  const dataPercent = useSelector(
    (state: RootState) => state.types.dataTypePercent
  );
  const dataMyCompany = useSelector(
    (state: RootState) => state.companies.dataMyCompany
  ) as { id: number; value: string }[];

  const dataAccountType = useSelector(
    (state: RootState) => state.types.dataTypeExpenseModel
  );

  const [isSave, setIsSave] = useState<boolean>(false);
  const [isModalFiles, setIsModalFiles] = useState<boolean>(false);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [dataCompany, setDataCompany] = useState<
    { id: number; value: string }[]
  >([]);
  const [dataAgreements, setDataAgreements] = useState<any>([]);

  const containers: IContainer[] = Form.useWatch("containers", {
    form,
    preserve: true,
  });
  const executor: string = Form.useWatch("executor", {
    form,
    preserve: true,
  });
  const email: string = Form.useWatch("email", {
    form,
    preserve: true,
  });
  const company_id: number = Form.useWatch("company_id", {
    form,
    preserve: true,
  });
  const my_company_id: number = Form.useWatch("my_company_id", {
    form,
    preserve: true,
  });

  const file_ids: number[] = Form.useWatch("file_ids", {
    form,
    preserve: true,
  });
  const saveFiles: any[] = Form.useWatch("saveFiles", {
    form,
    preserve: true,
  });
  const status = Form.useWatch("status", {
    form,
    preserve: true,
  }) as IStatus;

  const columnsKtk: IColumnsEditTable[] = [
    {
      isField: false,
      title: "№",
      dataIndex: "deal_id",
      width: "30%",
    },
    {
      isField: false,
      title: "№КТК",
      dataIndex: "num_container",
      width: "30%",
    },
    {
      isField: false,
      title: "Тип",
      dataIndex: "type_container",
      width: "30%",
    },
    {
      isField: false,
      title: "Ставка клиенту",
      dataIndex: "bet",
      width: "30%",
    },
    {
      isField: false,
      title: "Период (если СНП)",
      dataIndex: "count_day_snp",
      width: "30%",
    },
    {
      isField: false,
      noFocus: true,
      title: "",
      dataIndex: "checked",
      width: "40px",
      render: (value, record, index) => (
        <Checkbox
          isChoice={value}
          customChange={(val) => {
            const item = containers[index];
            item.checked = val;
            form.setFieldValue(["containers", index], item);
          }}
        />
      ),
    },
  ];

  const count = useMemo(() => {
    const result = (file_ids?.length ?? 0) + (saveFiles?.length ?? 0);
    return result;
  }, [JSON.stringify(file_ids), JSON.stringify(saveFiles)]);

  const isDisabled = !!initialValue?.id;

  useEffect(() => {
    if (initialValue.agreement_ids) {
      setDataAgreements(initialValue.agreement_ids);
    }
    if (initialValue.companies) {
      setDataCompany(initialValue.companies);
    }
  }, []);
  useEffect(() => {
    if (!executor) {
      form.setFieldValue("executor_id", null);
    }
  }, [executor]);

  useEffect(() => {
    if (!isOpen) return;
    form.resetFields();

    if (isDisabled && typeForm === EDIT) {
      form.setFieldsValue({ ...defaultValuesBilling, ...initialValue });
    }
    if (typeForm === CREATE) {
      const {
        email,
        id: responsible_id,
        fio: responsible,
      } = Utils.getUserInfo();
      form.setFieldsValue({
        ...defaultValuesBilling,
        responsible_id,
        responsible,
      });
    }
  }, [isDisabled, isOpen, form, dispatch, initialValue]);

  const getAgreements = ({
    client,
    company,
  }: {
    client: number;
    company: number;
  }) => {
    if (client && company) {
      const payload = { executor_id: company, client_id: client };
      dispatch(findAgreementsCompany(payload))
        .then((res) => {
          setDataAgreements(res.payload);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          socket.off("find-agreements-company");
        });
    }
  };

  const handleAddResponsible =
    ({ id, name }: { id: string; name: string }) =>
    (e: any, option: any) => {
      const resp = {
        avatar: option.avatar,
        name: option.fio,
        id: option.id,
      };
      form.setFieldValue(id, resp.id);
      form.setFieldValue(name, resp.name);
    };

  const handlerSaveFiles = (files: any[]) => {
    form.setFieldValue("saveFiles", files);
  };

  const selectedType = (val: string | null, opt: any) => {
    form.setFieldsValue({
      type_deal: opt?.label ?? "",
      type_deal_char: opt?.char || "",
    });
  };

  const openModalFile = () => {
    setConfirmation(false);
    setIsModalFiles(true);
  };
  const closeModalFile = () => {
    setIsModalFiles(false);
  };

  const onSubmit = async (values: IBilling) => {
    try {
      console.log("values", values);
      if (!Array.isArray(values.file_ids)) {
        values.file_ids = [];
      }
      if (values?.saveFiles?.length) {
        for (const file of values.saveFiles) {
          const res = await Rest_files.submitFile(file);
          if (res?.data) {
            values.file_ids.push(res.data);
          }
        }
      }

      const submit = {
        ...initialValue,
        ...values,
      };
      if (submit.saveFiles) {
        delete submit.saveFiles;
      }
      dispatch(
        initialValue?.id
          ? updateFinanceBilling(submit)
          : setFinanceBilling(submit)
      );
      console.log("submit", submit);

      setIsSave(true);
      onClose(true);
      setIsSave(false);
    } catch (err) {
      console.error("submit", err);
    }
  };
  const onUpdate = (changedValues: IBilling, allValues: any) => {
    return;
  };

  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    if (errorFields) {
      for (const errors of errorFields) {
        const title = errors?.name?.[0];
      }
    }
    return;
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleSelectCompany = useCallback((id: string, record: any) => {
    const fields = form.getFieldsValue();

    form.setFieldsValue({
      ...fields,
      email: record?.email,
      phone: record?.phone,
      company: record?.value,
      company_id: record?.id,
      contact_id: record?.contact_id,
    });
  }, []);

  const handleSelectMyCompany = useCallback((id: string, record: any) => {
    form.setFieldValue("account_num", record?.score);
    form.setFieldValue("my_company", record?.value);
  }, []);

  const handleSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const client = dataCompany.find(
      (comp: any) => comp?.value === evt.target.value
    );
    if (client) {
      getAgreements({ company: my_company_id, client: client?.id });
      return;
    }
    if (!client) {
      form.setFieldValue("company", "");
      form.setFieldValue("company_id", null);
    }
  };

  const handleSearchBlurMyCompany = (
    evt: React.FocusEvent<HTMLInputElement>
  ) => {
    if (my_company_id && company_id) {
      getAgreements({ client: company_id, company: my_company_id });
      return;
    }
  };
  const handleSearchCompany = (text: string) => {
    if (text?.length >= 2) {
      delay(() => {
        dispatch(findCompany(text.toLocaleLowerCase()))
          .then((res) => {
            setDataCompany(mapperCompany(res.payload));
          })
          .catch(console.error);
      }, 1000);
    }
  };

  //==========================================================
  // если нет права на редактирование не отображаем форму
  // вместо нее станим заглушку
  //==========================================================
  if (!canEdit) {
    return (
      <div style={{ padding: 20, fontSize: 16, textAlign: "center" }}>
        {t("У вас нет прав на редактирование данной страницы")}
      </div>
    );
  }

  return (
    <Form
      className={style["form"]}
      noValidate
      autoComplete="off"
      layout="vertical"
      form={form}
      initialValues={{ ...defaultValuesBilling, ...initialValue }}
      scrollToFirstError
      onFinish={onSubmit}
      onFinishFailed={onFinishError}
      onValuesChange={onUpdate}
    >
      {noStyleField.map((name) => (
        <Form.Item key={name} name={name} noStyle />
      ))}
      <Flex vertical gap={24}>
        <Flex justify="space-between" align="center" gap={10}>
          <Typography.Text className={style["title"]}>
            {isDisabled ? "Редактирование" : t(title)}
          </Typography.Text>
          <Form.Item
            name={"type_deal_id"}
            rules={[{ required: true, message: t("Обязательное поле") }]}
            style={{ margin: 0, width: "130px" }}
          >
            <Select
              placeholder={t("Тип сделки")}
              optionLabelProp="label"
              options={dealType?.map(
                ({ id, value, char, status_containers_id }) => ({
                  value: id,
                  label: value,
                  char,
                  status_containers_id,
                })
              )}
              disabled={isDisabled}
              onSelect={selectedType}
            />
          </Form.Item>
        </Flex>
        <Divider style={{ margin: 0 }} />
        <Flex justify="space-between" align="center">
          <Typography.Text className={classnames(style["subtitle"])}>
            Сделка № {initialValue?.id_deal ?? ""}
          </Typography.Text>
          <Flex gap={10}>
            <Typography.Text>{t("Статус")}</Typography.Text>
            {!!status && (
              <Tag style={statusColorsInvoice(status).style}>
                {t(statusColorsInvoice(status).title)}
              </Tag>
            )}
          </Flex>
        </Flex>
        <Flex vertical gap={10} style={{ marginBottom: "24px" }}>
          <Row gutter={[12, 11]} justify="space-between">
            <Col span={11}>
              <Flex vertical gap={10}>
                <Form.Item
                  label={t("Клиент")}
                  name={"company"}
                  className={classnames(style.label, {
                    [style.label__disabled]: !!form.getFieldValue("id"),
                  })}
                  rules={[{ required: true, message: t("Обязательное поле") }]}
                >
                  <AutoComplete
                    popupClassName="certain-category-search-dropdown"
                    style={{ width: "100%" }}
                    allowClear
                    placeholder={t("Выберите клиента")}
                    options={dataCompany}
                    onSelect={handleSelectCompany}
                    onSearch={handleSearchCompany}
                    onBlur={handleSearchBlur}
                    disabled={isDisabled}
                  />
                </Form.Item>
                <Flex vertical gap={10}>
                  <Form.Item
                    label={t("Наше юридическое лицо")}
                    name={"my_company_id"}
                    className={classnames(style.label, {
                      [style.label__disabled]: !!form.getFieldValue("id"),
                    })}
                    rules={[
                      { required: true, message: t("Обязательное поле") },
                    ]}
                  >
                    <Select
                      popupClassName="certain-category-search-dropdown"
                      style={{ width: "100%" }}
                      allowClear
                      optionLabelProp="label"
                      placeholder={t("Выберите компанию")}
                      options={mapperSelect({ items: dataMyCompany })}
                      onSelect={handleSelectMyCompany}
                      onBlur={handleSearchBlurMyCompany}
                      disabled={isDisabled}
                    />
                  </Form.Item>
                </Flex>

                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item label={t("Валюта")} name={"currency_id"}>
                      <Select
                        optionLabelProp="label"
                        options={mapperSelect({ items: dataCurrency })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("Договор №")} name={"agreement"}>
                      <Select
                        showSearch
                        options={dataAgreements}
                        onSelect={(_, opt) => {
                          form.setFieldValue("agreement_id", opt?.id);
                        }}
                        disabled={!dataAgreements?.length}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item label={t("Тип счета")} name={"account_type"}>
                      <TreeSelect
                        treeData={dataAccountType}
                        onChange={(value, list) => {
                          form.setFieldValue("account_type_id", value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t("Повышающий коэффициент")}
                      name={"ratio"}
                      className={style.nowrap}
                    >
                      <FormInputNumber allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item label={t("Курс")} name={"course"}>
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("Срок исполнения")} name={"deadline"}>
                      <FormDatePicker
                        isString={false}
                        disabledDate={disableDateBefore(null)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item label={t("Приоритет")} name={"priority"}>
                      <Select
                        optionLabelProp="label"
                        options={dataTypePriority}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={t("НДС")} name={"nds"}>
                      <Select optionLabelProp="label" options={dataPercent} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      className={style.nowrap}
                      label={t("Ответственный менеджер")}
                      name={"responsible"}
                    >
                      <FormSelectSearch
                        allowClear
                        showSearch
                        suffixIcon={null}
                        filterOption={false}
                        style={{ width: "100%" }}
                        api={(text) => dispatch(findExecutor(text))}
                        mapResponse={(res) => res.payload}
                        onSelect={handleAddResponsible({
                          id: "responsible_id",
                          name: "responsible",
                        })}
                        placeholder={t("Ответственный")}
                      />
                    </Form.Item>
                  </Col>
                  <Col className={style["col-vertical"]} span={12}>
                    <Badge
                      style={{ fontSize: "10px" }}
                      color="#05F"
                      showZero={false}
                      size="small"
                      overflowCount={9}
                      count={count}
                      offset={[17, 5]}
                    >
                      <Typography.Link
                        className={style["text-card-14"]}
                        onClick={openModalFile}
                      >
                        {t("Загрузить файл")}
                      </Typography.Link>
                    </Badge>
                    <Typography.Link
                      className={style["text-card-14"]}
                      disabled={!isDisabled}
                    >
                      {t("Аннулировать счет")}
                    </Typography.Link>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item label={t("Исполнитель")} name={"executor"}>
                      <FormSelectSearch
                        allowClear
                        showSearch
                        suffixIcon={null}
                        filterOption={false}
                        style={{ width: "100%" }}
                        api={(text) => dispatch(findExecutor(text))}
                        mapResponse={(res) => res.payload}
                        onSelect={handleAddResponsible({
                          id: "executor_id",
                          name: "executor",
                        })}
                        placeholder={t("Исполнитель")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} className={style["col-vertical"]}>
                    <Typography.Link className={style["text-card-14"]}>
                      {t("Счет готов")}
                    </Typography.Link>
                    <Typography.Link
                      className={style["text-card-14"]}
                      href={`mailto:${email}`}
                      disabled={!email || !isDisabled}
                    >
                      {t("Отправить счет")}
                    </Typography.Link>
                  </Col>
                </Row>
              </Flex>
            </Col>
            <Col span={12}>
              <Row gutter={12} style={{ minHeight: "20px" }} />
              <Flex vertical style={{ minHeight: "100%" }}>
                <Row gutter={12} style={{ maxHeight: "700px" }}>
                  <FormTable
                    isLoading={!!containers?.length}
                    classTitle={style["subtitle"]}
                    label="Выбор КТК"
                    tableLayout="fixed"
                    scroll={containers?.length > 1 ? { y: 700 } : undefined}
                    defaultColumns={columnsKtk}
                    name="containers"
                  />
                </Row>
              </Flex>
            </Col>
          </Row>
        </Flex>
      </Flex>

      <Form.Item>
        <Flex
          gap={8}
          justify={"space-between"}
          style={{
            maxWidth: "550px",
            width: "100%",
            margin: "auto auto 10px auto",
          }}
        >
          <TooltipButton
            propsTooltip={{
              title: isDisabled ? t("Сохранить изменения") : t("Сохранить"),
            }}
            propsButton={{
              type: "primary",
              htmlType: "submit",
              style: { maxWidth: "290px", minWidth: "290px" },
              disabled: isSave,
            }}
          >
            {isDisabled ? t("Сохранить изменения") : t("Сохранить")}
          </TooltipButton>
          <Button
            onClick={() => setConfirmation(true)}
            type={"text"}
            style={{ maxWidth: "290px", width: "100%", color: "#E14453" }}
            disabled={isSave}
          >
            {t("Отменить")}
          </Button>
        </Flex>
      </Form.Item>

      <Modal
        open={isModalFiles}
        title={
          <Typography.Text className={style.label}>
            {t("Файлы")}
          </Typography.Text>
        }
        closeIcon
        centered
        footer={null}
        onCancel={closeModalFile}
      >
        <div className={style.modal}>
          <div className={classnames(style["modal__content"])}>
            <div
              className={classnames(style["file-box"], "all-custom-v-scroll")}
            >
              <UploaderDocs
                multiple
                isOpen={isOpen}
                buttonDescription={t("Загрузить файл")}
                ids={file_ids}
                setDataIds={(files) => {
                  form.setFieldValue("file_ids", files);
                }}
                handlerSaveFiles={handlerSaveFiles}
              />
            </div>
          </div>
        </div>
      </Modal>

      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleClose}
      />
    </Form>
  );
};
