import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Modal,
  Table,
  TableColumnsType,
  Typography,
} from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { IContractors } from '../../types/contractors';
import { useTranslation } from 'react-i18next';
import { defaultValueBank } from './constants';
import { Utils } from '../../utils';



interface DataType {
  key: React.Key;
  name: string;
  meaning: string;
}

interface IFormRequisites {
  isView?: boolean;
  isOpen: boolean;
  isLoadingRequisites?: boolean;
  title: string;
  dataBankRequisites: DataType[];
  requisites: DataType[];
  setRequisites?: (val: any) => void;
  fillRequisitesInn?: () => void;
  setDataBankRequisites?: (val: any) => void;
  onClose: () => void;
}

const FormRequisites: React.FC<IFormRequisites> = ({
  isView,
  isLoadingRequisites,
  fillRequisitesInn,
  title,
  dataBankRequisites,
  requisites,
  onClose,
  setRequisites,
  setDataBankRequisites,
}) => {
  const [formReq] = Form.useForm();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditRequisites, setIsEditRequisites] = useState(false);
  const [isEditBankRequisites, setIsEditBankRequisites] = useState(false);

  const columnsRequisites: TableColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Meaning',
      dataIndex: 'meaning',
      render: (text, item, index) => (
        <Typography.Text
          editable={
            isEditRequisites &&
            !!requisites?.length &&
            setRequisites && {
              onChange: (val) => {
                console.log(val);
                console.log(requisites?.[index]);
                if (setRequisites) {
                  setRequisites((prev: any) => {
                    const result = [...prev];
                    if (result?.[index]) {
                      result[index].meaning = val;
                    }
                    return result;
                  });
                }
              },
            }
          }
          onChange={(evt: any) => {
            console.log(evt);
          }}
        >
          {text}
        </Typography.Text>
      ),
    },
  ];
  const columnsBank: TableColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Meaning',
      dataIndex: 'meaning',
      render: (text, item, index) => (
        <Typography.Text
          editable={
            isEditBankRequisites &&
            !!dataBankRequisites?.length &&
            setDataBankRequisites && {
              onChange: (val) => {
                if (setDataBankRequisites) {
                  setDataBankRequisites((prev: any) => {
                    const result = [...prev];
                    if (result?.[index]) {
                      result[index].meaning = val;
                    }
                    return result;
                  });
                }
              },
            }
          }
          onChange={(evt: any) => {
            console.log(evt);
          }}
        >
          {text}
        </Typography.Text>
      ),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    formReq.resetFields();
    setIsModalOpen(false);
  };

  const onFinishBank = (data: any) => {
    const arResult: DataType[] = [];
    [data].forEach((el: any, i) => {
      arResult.push({
        key: 'title',
        name: t('Название'),
        meaning: el?.title ?? '',
      });
      arResult.push({
        key: 'name_bank',
        name: t('Наименование банка'),
        meaning: el?.name_bank ?? '',
      });
      arResult.push({ key: 'bik', name: t('БИК'), meaning: el?.bik ?? '' });
      arResult.push({
        key: 'payment_account',
        name: t('Расчетный счёт'),
        meaning: el?.payment_account ?? '',
      });
      arResult.push({
        key: 'cor_account',
        name: 'Кор. счёт',
        meaning: el?.cor_account ?? '',
      });
      arResult.push({
        key: 'account_currency',
        name: t('Валюта счёта'),
        meaning: el?.account_currency ?? '',
      });
      arResult.push({
        key: 'address_bank',
        name: t('Адрес банка'),
        meaning: el?.address_bank ?? '',
      });
      arResult.push({
        key: 'comment',
        name: t('Комментарий'),
        meaning: el?.comment ?? '',
      });
    });
    if (setDataBankRequisites) {
      setDataBankRequisites((prev: any) => [...prev, ...arResult]);
    }
    formReq.resetFields();
    setIsModalOpen(false);
  };

  return (
    <Flex vertical>
      <Flex justify="flex-start" className="drawer-header" gap={20}>
        <LeftOutlined onClick={() => {
          setIsEditBankRequisites(false);
          setIsEditRequisites(false);
          onClose();
          }} />
        <div className="drawer-title">{title}</div>
      </Flex>
      <Flex style={{ margin: 16 }} vertical gap={10}>
        <Card
          title={t('Реквизиты')}
          style={{ margin: 0 }}
          extra={
            <Flex gap={10}>
              <Typography.Link
                hidden={isView}
                onClick={fillRequisitesInn}
              >
                {t('Заменить по ИНН')}
              </Typography.Link>
              <Typography.Link
                hidden={isView || isEditRequisites || !requisites?.length}
                onClick={() => {
                  setIsEditRequisites(true);
                }}
              >
                {t('Редактировать')}
              </Typography.Link>
            </Flex>
          }
        >
          {requisites?.length > 0 && (
            <Table
              columns={columnsRequisites}
              dataSource={requisites}
              loading={isLoadingRequisites}
              size="small"
              showHeader={false}
              pagination={false}
            />
          )}
        </Card>
        <Card
          title={t('Банковские реквизиты')}
          style={{ margin: 0 }}
          extra={
            <Flex gap={10}>
              <Typography.Link
                hidden={isView || isEditBankRequisites || !dataBankRequisites?.length}
                onClick={() => {
                  setIsEditBankRequisites(true);
                }}
              >
                {t('Редактировать')}
              </Typography.Link>
              <Typography.Link hidden={isView} onClick={showModal}>
                {t('Добавить')}
              </Typography.Link>
            </Flex>
          }
        >
          {dataBankRequisites?.length > 0 && (
            <Table
              columns={columnsBank}
              dataSource={dataBankRequisites}
              size="small"
              showHeader={false}
              pagination={false}
            />
          )}
        </Card>

        <Modal
          title={t('Реквизиты')}
          open={isModalOpen}
          onOk={formReq.submit}
          onCancel={handleCancel}
          footer={null}
          loading={isLoadingRequisites}
        >
          <Form
            style={{ margin: 20 }}
            layout="vertical"
            form={formReq}
            onFinish={onFinishBank}
            autoComplete="off"
            initialValues={defaultValueBank}
          >
            <Flex gap={10} vertical>
              <div>
                <Form.Item
                  label={t('Название')}
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: t('Введите название'),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('Наименование банка')}
                  name="name_bank"
                  rules={[
                    {
                      required: true,
                      message: t('Введите наименование банка'),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('БИК')}
                  name="bik"
                  rules={[
                    {
                      required: true,
                      message: t('Введите БИК'),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('Расчетный счёт')}
                  name="payment_account"
                  rules={[
                    {
                      required: true,
                      message: t('Введите расчётный счёт'),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('Кор. счёт')}
                  name="cor_account"
                  rules={[
                    {
                      required: true,
                      message: t('Введите корр. счёт'),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('Валюта счёта')}
                  name="account_currency"
                  rules={[
                    {
                      required: true,
                      message: t('Введите валюту счёта'),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label={t('Адрес банка')} name="address_bank">
                  <Input.TextArea autoSize />
                </Form.Item>
                <Form.Item label={t('Комментарий')} name="comment">
                  <Input.TextArea autoSize />
                </Form.Item>
              </div>
              <Flex justify="flex-end" style={{ width: '100%' }}>
                <Form.Item>
                  <Flex gap={10}>
                    <Button onClick={handleCancel}>Закрыть</Button>
                    <Button type="primary" htmlType="submit">
                      {t('Сохранить')}
                    </Button>
                  </Flex>
                </Form.Item>
              </Flex>
            </Flex>
          </Form>
        </Modal>
      </Flex>
    </Flex>
  );
};

export default FormRequisites;
