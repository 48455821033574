import { apiAxios } from '../api/config';

export class Rest_filters {

  static filtersSearch(param: {
    text: string;
    column: string;
    table: string;
  }): Promise<any> {
    return apiAxios.post('resources/search-filters', param);
  }

  static fetchFilters(param: {
    data: any;
    current: number;
    pageSize: number;
    table: string;
    [key: string]: any;
  }): Promise<any> {
    return apiAxios.post('resources/get-filters', param);
  }
}
