import { IColumnType } from "../../components/ui/Table/Table";

export const columns: IColumnType<any>[] = [
  {
    dataIndex: 'index',
    filterIndex: 'index',
    checkbox: true,
    menu: true,
    type: 'string',
    key: '0',
  },
  {
    title: '#1',
    dataIndex: 'id',
    filterIndex: 'id',
    type: 'string',
    key: '1',
    width: '20%',
  },
  {
    title: '#2',
    dataIndex: 'id',
    filterIndex: 'id',
    type: 'string',
    key: '2',
    width: '20%',
  },
  {
    title: '#3',
    dataIndex: 'id',
    filterIndex: 'id',
    type: 'string',
    key: '3',
    width: '20%',
  },
  {
    title: '#4',
    dataIndex: 'id',
    filterIndex: 'id',
    type: 'string',
    key: '4',
    width: '20%',
  },
  {
    title: '#5',
    dataIndex: 'id',
    filterIndex: 'id',
    type: 'string',
    key: '5',
    width: '10%',
  },
  {
    title: '',
    dataIndex: 'hidden',
    filterIndex: 'hidden',
    key: '10',
  },
];
