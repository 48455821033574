import { IIcon } from "./components/ProfileStatus/ProfileStatus";

export const VACATION = 'vacation';
export const BUSINESS_TRIP = 'business_trip';

export const TAB_CALENDAR = 'tab_calendar';
export const TAB_TASK = 'tab_task';
export const TAB_TASKS = 'tab_tasks';
export const TAB_TAPE = 'tab_tape';

export const statusIcons: {[key: string]: IIcon} = {
  [VACATION] : {title: 'В отпуске', typeIcon: VACATION},
  [BUSINESS_TRIP]: {title: 'В командировке', typeIcon: BUSINESS_TRIP}
};

export const noStyleField = [
  'id',
  'fio',
  'avatar',
  'avatar_id',
  'status',
  'saveFiles'
];