import React, { ReactElement, useEffect, useState } from 'react';

import { Upload, UploadProps, Typography } from 'antd';
import classnames from 'classnames';

import { UploadFile } from 'antd/lib/upload';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { messagesModal } from '../../../constants';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import UploaderDocsUniqueItem from './UploaderDocsUniqueItem/UploaderDocsUniqueItem';
import {
  checkFileTypes,
  downloadContent,
} from '../../../helpers/input-helpers';
import style from './UploaderDocs.module.scss';
import useModalMessage from '../../../hooks/useModalMessage';

const getBase64 = (file: any, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(file);
};

export interface IFile extends UploadFile {
  file_name?: string;
  origin_name?: string;
  url?: string;
  delete?: boolean;
}

interface IUploaderDocs {
  buttonDescription?: string;
  className?: string;
  disabled?: boolean;
  isText?: boolean;
  handlerSaveFiles?: (file: IFile) => void;
  handlerDeleteFiles?: (param: { file: IFile; isDelete?: boolean }) => void;
  accept?: string;
  name?: string;
  value?: any[];
  isView?: boolean;
  isOpen?: boolean;
  multiple?: boolean;
  customAddComponent?: React.ReactNode;
  limit?: number;
  size?: number;
  files?: any[];
}

export const UploaderDocsUnique: React.FC<IUploaderDocs> = ({
  size,
  handlerSaveFiles,
  handlerDeleteFiles,
  limit = 10,
  accept = '*',
  buttonDescription = '',
  className = '',
  disabled = false,
  isView = false,
  isOpen = false,
  multiple = false,
  isText = false,
  customAddComponent = <PlusOutlined />,
  files = [],
}) => {
  const message = useModalMessage(true);
  const [loading, setLoading] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [fileList, setFileList] = useState<IFile[]>([]);
  const [deleteFile, setDeleteFile] = useState<IFile | null>(null);
  useEffect(() => {
    if (!isOpen) return;
    setDeleteFile(null);
    if (Array.isArray(files)) {
      setFileList(files);
      return;
    }
    setFileList([]);
    return () => {
      setFileList([]);
      setDeleteFile(null);
    };
  }, [JSON.stringify(files), isOpen]);

  const dummyRequest: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
    onError,
  }: any) => {
    try {
      if (accept !== '*' && !checkFileTypes(accept, file?.type ?? '')) {
        message.error(`Файлы типа ${file?.type} запрещены`, 5);
        return onError(file);
      }
      const isSize = size ? file.size / 1024 / 1024 <= size : true;
      if (!isSize) {
        message.error(`Файл слишком большой`, 5);
        return onError(file);
      }
      const checkFile = fileList?.find(
        (fl) => fl?.name === file?.name && fl?.status === 'done',
      );
      if (checkFile) {
        message.error(`Файл ${file.name} уже присутствует!`, 5);
        return onError(file);
      }
      if (handlerSaveFiles) {
        handlerSaveFiles(file);
      }
      file.status = 'done';
      onSuccess(file);
    } catch (error) {
      message.error('Ошибка при загрузке файла');
      onError(error);
    }
  };

  const handleChange: UploadProps['onChange'] = ({
    fileList: newFileList,
    file,
  }) => {
    setFileList(newFileList);
    if (file.status === 'uploading') {
      setLoading(true);
      if (accept !== '*' && !checkFileTypes(accept, file?.type ?? '')) {
        message.error('Недопустимый тип файла');
        setLoading(false);
        return;
      }
      if (fileList.find(({ uid }) => uid === file.uid)) {
        message.error('Такой файл уже есть');
        setLoading(false);
        return;
      }
    }

    const result = newFileList.filter(
      (file) => !file.error && file?.status !== 'error',
    );
    setLoading(false);
    setFileList(result);
  };

  const handlePreview: UploadProps['onPreview'] = async (file: any) => {
    if (!file?.url) {
      getBase64(file.originFileObj as any, (url) => {
        downloadContent({
          content: '',
          type: file?.type,
          fileName: file?.name,
          url,
        });
      });
      return;
    }

    downloadContent({
      content: '',
      type: file?.type,
      fileName: file?.name,
      url: file.url,
    });
  };

  const openConfirmDeleteFile = (file: IFile) => {
    setDeleteFile(file);
    setIsConfirm(true);
  };

  const handleRemove = () => {
    if (!deleteFile) return;

    setLoading(true);

    const result = fileList
      .map((file) => {
        if (file.uid === deleteFile?.uid) {
          return deleteFile?.url
            ? { ...file, delete: deleteFile?.delete }
            : null;
        }
        return file;
      })
      .filter((fil) => !!fil) as IFile[];

    setFileList(result);
    setDeleteFile(null);
    setLoading(false);

    if (handlerSaveFiles) {
      if (handlerDeleteFiles) {
        handlerDeleteFiles({
          file: deleteFile,
          isDelete: deleteFile?.delete && !deleteFile?.url,
        });
        return;
      }
      handlerSaveFiles(deleteFile);
    }
  };

  const uploadButton = (
    <div className={style['loader']}>
      <button
        className={style['loader__button']}
        style={{ border: 0, background: 'none' }}
        type="button"
      >
        {loading ? <LoadingOutlined /> : customAddComponent}
      </button>
      {!!buttonDescription && (
        <div className={style['box-description']}>
          <Typography.Paragraph
            ellipsis={{
              rows: 2,
              tooltip: { placement: 'top', title: buttonDescription },
            }}
            className={style['file-description']}
            data-type="file-title"
          >
            {buttonDescription}
          </Typography.Paragraph>
        </div>
      )}
    </div>
  );

  return (
    <>
      <Upload
        className={classnames(style['container'], { [className]: className })}
        accept={accept}
        customRequest={dummyRequest}
        listType={isText ? 'text' : 'picture-card'}
        fileList={fileList}
        onChange={handleChange}
        multiple={multiple}
        disabled={disabled}
        itemRender={(
          originNode: ReactElement,
          file: UploadFile,
          fileList: any[],
          actions: {
            download: (file: UploadFile) => void;
            preview: any;
            remove: any;
          },
        ) => (
          <UploaderDocsUniqueItem
            isText={isText}
            isView={isView}
            file={file}
            handlePreview={handlePreview}
            openConfirmDeleteFile={openConfirmDeleteFile}
          />
        )}
      >
        {isView || (limit && fileList?.length >= limit) ? null : uploadButton}
      </Upload>
      <ModalConfirm
        title={messagesModal.delete.title}
        subtitle={messagesModal.delete.subtitle}
        isOpen={isConfirm}
        closeModal={() => setIsConfirm(false)}
        actionAfterConsent={handleRemove}
      />
    </>
  );
};
