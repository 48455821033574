import React, { ReactNode } from 'react';

import classnames from 'classnames';
import { Divider, Flex, Typography } from 'antd';

import { useParamsHistory } from '../../../../hooks';
import style from './style.module.scss';

interface IRow {
  title: string;
  subtitle?: string | {text: string, link: string}[] | React.ReactNode;
  isDivider?: boolean;
  link?: string;
  className?: string;
  children?: ReactNode
}

const ViewRow: React.FC<IRow> = ({ title, isDivider, children, className='', link = '', subtitle = '' }) => {
  const {history} = useParamsHistory();
  const toPathLink = () => {
    if (link) {
      history.push(link);
    }
  }
  return (
    <Flex gap={4} vertical className={classnames(style["view"], {[className]: className})}>
        <Typography.Title level={4}  ellipsis={{tooltip: {placement: "topLeft", title}}} className={style["view__title"]}>
          {title}
        </Typography.Title>
      {!!subtitle && (Array.isArray(subtitle) ? (
        subtitle?.map(({text, link}, i) => (
          <Typography.Title key={text} level={5} ellipsis={{tooltip: {placement: "topLeft", title: text}}} className={classnames(style['view__subtitle'], {[style['view__subtitle_link']] : link})} onClick={toPathLink}>
            {text}
          </Typography.Title>
        ))
      ) : (
          <Typography.Title level={5} ellipsis={{tooltip: {placement: "topLeft", title: subtitle}}} className={classnames(style['view__subtitle'], {[style['view__subtitle_link']] : link})} onClick={toPathLink}>
            {subtitle}
          </Typography.Title>
      ))}
      {children}
      {isDivider && <Divider style={{ marginTop: 8, marginBottom: 8 }} />}
    </Flex>
  );
};

export default ViewRow;
