import React, { useRef, useEffect } from "react";
import { Form, Input, Select, Checkbox } from "antd";
import styles from "./style.module.scss";
import classNames from "classnames";

interface Column {
  title: string;
  dataIndex: string;
  key: string;
}

interface EditableTableProps {
  fields?: any[];
  columns?: Column[];
  rowCount?: number;
  onAddRow?: () => void;
  columnWidths?: string;
  selectedRows?: Set<number>;
  onRowSelect?: (index: number, selected: boolean) => void;
  isEditable?: boolean;
  additionalElement?: React.ReactNode;
  type?: "purchase" | "sublease" | "ktk";
  className?: string;
  // isViewMode?: boolean;
}

const EditableTable: React.FC<EditableTableProps> = ({
  className,
  fields = [],
  columns = [],
  rowCount = 0,
  onAddRow,
  columnWidths = "1fr",
  selectedRows = new Set(),
  onRowSelect = () => {},
  isEditable = true,
  additionalElement,
  // isViewMode = false,
}) => {
  const tableBodyRef = useRef<HTMLDivElement>(null);
  const checkboxContainerRef = useRef<HTMLDivElement>(null);

  const selectOptions: Record<string, { value: string; label: string }[]> = {
    type: [
      { value: "20DC", label: "20DC" },
      { value: "40HC", label: "40HC" },
      { value: "20REF", label: "20REF" },
      { value: "40REF", label: "40REF" },
      { value: "20OT", label: "20OT" },
      { value: "40OT", label: "40OT" },
      { value: "20FR", label: "20FR" },
      { value: "40FR", label: "40FR" },
    ],
    condition: [
      { value: "NEW", label: "NEW" },
      { value: "CW", label: "CW" },
      { value: "IICL", label: "IICL" },
    ],
    payment_option: [
      { value: "Наличный", label: "Наличный" },
      { value: "Безналичный", label: "Безналичный" },
    ],
  };

  useEffect(() => {
    const syncScroll = () => {
      if (tableBodyRef.current && checkboxContainerRef.current) {
        checkboxContainerRef.current.scrollTop = tableBodyRef.current.scrollTop;
      }
    };

    tableBodyRef.current?.addEventListener("scroll", syncScroll);
    return () => {
      tableBodyRef.current?.removeEventListener("scroll", syncScroll);
    };
  }, []);

  return (
    <div
      className={classNames(
        styles["table-wrapper"],
        { [styles["non-editable"]]: !isEditable },
        className
      )}
    >
      <div
        className={styles["table"]}
        style={{ "--columns": columnWidths } as React.CSSProperties}
      >
        <div className={styles["table-header"]}>
          {columns.map((col: Column) => (
            <div key={col.key} className={styles["table-header-cell"]}>
              {col.title}
            </div>
          ))}
        </div>
        <div className={styles["table-body"]} ref={tableBodyRef}>
          {fields.slice(0, rowCount).map((field: any, index: number) => (
            <div
              key={`row-${field.key ?? index}`}
              className={styles["table-row"]}
            >
              {columns.map((col: Column) => {
                const dataIndex = col.dataIndex;
                const placeholder = col.title;

                // Если таблица в режиме редактирования, используем Form.Item
                if (isEditable) {
                  return (
                    <div key={col.key} className={styles["table-cell"]}>
                      <Form.Item name={[field.name, dataIndex]} noStyle>
                        {dataIndex in selectOptions ? (
                          <Select
                            options={selectOptions[dataIndex]}
                            placeholder="Выберите"
                            disabled={!isEditable}
                            data-outlined={false}
                            data-boxShadowSecondary={
                              "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)"
                            }
                          />
                        ) : (
                          <Input
                            placeholder={placeholder}
                            disabled={!isEditable}
                            className={styles["table-input"]}
                          />
                        )}
                      </Form.Item>
                    </div>
                  );
                } else {
                  // РЕЖИМ ПРОСТОГО ОТОБРАЖЕНИЯ (read-only):
                  // Показываем field[dataIndex], если он есть
                  return (
                    <div key={col.key} className={styles["table-cell"]}>
                      {/* Если field это объект со всеми данными, то field[dataIndex] должно содержать значение */}
                      {field[dataIndex] ?? ""}
                    </div>
                  );
                }
              })}
            </div>
          ))}
        </div>
      </div>
      {isEditable && (
        <div
          className={styles["checkbox-container"]}
          ref={checkboxContainerRef}
          style={{
            position: "absolute",
            left: -40,
            top: 0,
            height: "100%",
            overflowY: "hidden",
          }}
        >
          {fields.slice(0, rowCount).map((field: any, index: number) => (
            <Checkbox
              key={`checkbox-${field.key ?? index}`}
              checked={selectedRows.has(index)}
              onChange={(e) => onRowSelect(index, e.target.checked)}
              className={styles["checkbox-item"]}
            />
          ))}
        </div>
      )}
      {additionalElement && (
        <div className={styles["table-additional"]}>{additionalElement}</div>
      )}
    </div>
  );
};

export default EditableTable;
