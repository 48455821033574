import {
  ICustomsServices,
  IGeographyPresence,
  INotificationList,
  IPhoneMask,
  IRentKTK,
  ISaleKTK,
  ITypeCity,
  ITypeOwner,
} from '../types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Rest } from '../services/rest';

interface IStoreState {
  dataCity: Array<ITypeCity>;
  getDataCity: Array<ITypeCity>;
  dataTypeOwner: Array<ITypeOwner>;
  dataRentKTK: Array<IRentKTK>;
  dataSaleKTK: Array<ISaleKTK>;
  dataCustomsServices: Array<ICustomsServices>;
  dataGeographyPresence: Array<IGeographyPresence>;
  dataPhoneMask: Array<IPhoneMask>;
  isMenu: boolean;
  dataMenu: [];
  isDrawer: boolean;
  breadcrumb: { title: string }[];
  hasFetchedResources: boolean;
  lang: 'ru' | 'en';
  listNotification: INotificationList[];
  itemNotification: INotificationList | null;
}
const initialState: IStoreState = {
  lang: 'ru',
  dataCity: [],
  getDataCity: [],
  dataTypeOwner: [],
  dataRentKTK: [],
  dataSaleKTK: [],
  dataCustomsServices: [],
  dataGeographyPresence: [],
  dataPhoneMask: [],
  isMenu: true,
  dataMenu: [],
  isDrawer: false,
  breadcrumb: [{ title: 'CRM' }],
  hasFetchedResources: false,
  listNotification: [],
  itemNotification: null,
};

export const findOwner = createAsyncThunk(
  'findOwner',
  async (payload: string) => {
    return await Rest.getOwner(payload);
  },
);
export const findTerminalList = createAsyncThunk(
  'findTerminalList',
  async (payload: string) => {
    return await Rest.findTerminalList(payload);
  },
);
export const saveCity = createAsyncThunk('saveCity', async (payload: {}) => {
  return await Rest.saveCity(payload);
});
export const getCityTab = createAsyncThunk('getCityTab', async () => {
  return await Rest.getCityTab();
});
export const getCityInput = createAsyncThunk('getCityInput', async () => {
  return await Rest.getCityInput();
});
export const getRentKTK = createAsyncThunk('getRentKTK', async () => {
  return await Rest.getRentKTK();
});
export const getSaleKTK = createAsyncThunk('getSaleKTK', async () => {
  return await Rest.getSaleKTK();
});
export const getCustomsServices = createAsyncThunk(
  'getCustomsServices',
  async () => {
    return await Rest.getCustomsServices();
  },
);
export const getGeographyPresence = createAsyncThunk(
  'getGeographyPresence',
  async () => {
    return await Rest.getGeographyPresence();
  },
);
export const getPhoneMask = createAsyncThunk('getPhoneMask', async () => {
  return await Rest.getPhoneMask();
});
const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    resetStore(state) {
      state.lang = initialState.lang;
      state.dataCity = initialState.dataCity;
      state.getDataCity = initialState.getDataCity;
      state.dataTypeOwner = initialState.dataTypeOwner;
      state.dataRentKTK = initialState.dataRentKTK;
      state.dataSaleKTK = initialState.dataSaleKTK;
      state.dataCustomsServices = initialState.dataCustomsServices;
      state.dataGeographyPresence = initialState.dataGeographyPresence;
      state.dataPhoneMask = initialState.dataPhoneMask;
      state.isMenu = initialState.isMenu;
      state.dataMenu = initialState.dataMenu;
      state.isDrawer = initialState.isDrawer;
      state.breadcrumb = initialState.breadcrumb;
      state.hasFetchedResources = initialState.hasFetchedResources;
      state.listNotification = initialState.listNotification;
      state.itemNotification = initialState.itemNotification;
    },
    setLang(state, action) {
      state.lang = action.payload;
    },
    setMenu(state, action) {
      state.isMenu = action.payload;
    },
    setDataMenu(state, action) {
      state.dataMenu = action.payload;
    },
    setDrawer(state, action) {
      state.isDrawer = action.payload;
    },
    setBreadcrumb(state, action: { type: string; payload: string[] }) {
      state.breadcrumb = action.payload.map((title: string) => ({ title }));
    },
    setFetchedResources(state, action) {
      state.hasFetchedResources = action.payload;
    },
    setListNotification(state, action) {
      state.listNotification = action.payload;
    },
    addListNotification(state, action) {
      state.listNotification.push({...action.payload, isNew: true});
      state.itemNotification = {...action.payload, isNew: true};
    },
    setItemNotification(state, action) {
      state.itemNotification = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findOwner.fulfilled, (state, action) => {
      state.dataTypeOwner = action.payload;
    });
    builder.addCase(saveCity.fulfilled, (state, action) => {
      state.dataCity = action.payload;
    });
    builder.addCase(getCityTab.fulfilled, (state, action) => {
      state.getDataCity = action.payload;
    });
    builder.addCase(getCityInput.fulfilled, (state, action) => {
      state.getDataCity = action.payload;
    });
    builder.addCase(getRentKTK.fulfilled, (state, action) => {
      state.dataRentKTK = action.payload;
    });
    builder.addCase(getSaleKTK.fulfilled, (state, action) => {
      state.dataSaleKTK = action.payload;
    });
    builder.addCase(getCustomsServices.fulfilled, (state, action) => {
      state.dataCustomsServices = action.payload;
    });
    builder.addCase(getGeographyPresence.fulfilled, (state, action) => {
      state.dataGeographyPresence = action.payload;
    });
    builder.addCase(getPhoneMask.fulfilled, (state, action) => {
      state.dataPhoneMask = action.payload;
    });
  },
});
export default storeSlice.reducer;
export const {
  setMenu,
  setDataMenu,
  setDrawer,
  setBreadcrumb,
  setFetchedResources,
  setLang,
  setListNotification,
  addListNotification,
  setItemNotification,
  resetStore
} = storeSlice.actions;
