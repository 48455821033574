import { Component } from 'react';
import style from './style.module.scss';
import { Flex, Typography } from 'antd';

class ErrorBoundary extends Component<any> {
  state = {
    error: null,
  };
  static getDerivedStateFromError(error: any) {
    return { error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }

  onReload() {
    window.location.href = '/';
  }

  render() {
    const { error } = this.state;
    if (error) {
      return (
        <div className={style.page}>
          <Flex vertical gap={20}>
            <Typography.Title level={3} className={style.page__title}>Произошла ошибка</Typography.Title>
            <Typography.Link className={style.page__link} onClick={this.onReload}>вернуться на сайт?</Typography.Link>
          </Flex>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
