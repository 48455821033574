import React, {useEffect, useState} from 'react';
import {TableRowItem as TableRow} from './styled';
import {addPropsToChildren} from '../../../helpers/children-helpers';
import moment from "moment";

interface IRow {
    item: any,
    itemIndex: number,
    isChecked: boolean,
    onRow?: (record: any, rowIndex: number) => void,
    handleRowSelect: (id: number, checked: boolean) => void,
    children: any,
    status?: { id: number, name: string | null, name_en: string | null },
}

const TableRowItem: React.FC<IRow> = ({
                                          isChecked,
                                          itemIndex,
                                          item,
                                          handleRowSelect,
                                          onRow,
                                          children,
                                          status
                                      }) => {
    const [selectedRow, setSelectedRow] = useState(false);

    const isWithinTwoDays = (targetDate: string) => {
        const now = moment(); // Текущая дата и время
        const target = moment(targetDate); // Заданная дата

        // Вычисляем разницу в днях между текущей датой и целевой
        const differenceInDays = target.diff(now, 'days');

        // Возвращаем true, если разница два дня или меньше
        return differenceInDays <= 2 && differenceInDays >= 0;
    }

    useEffect(() => {
        if (isChecked !== selectedRow) {
            setSelectedRow(isChecked);
        }
    }, [isChecked]);

    const handleSelect = (checked: boolean) => {
        if (handleRowSelect) {
            handleRowSelect(item.id, checked);
            setSelectedRow(checked);
        }
    };

    return (
        <TableRow
            status = {
                item?.departure_date
                    ? "departure"
                    : item?.booking_before && isWithinTwoDays(item?.booking_before)
                        ? "booking"
                        : typeof item?.arrival_date !== "undefined" && !item.arrival_date
                            ? "ways"
                            : "default"
            }
            key={`table-body-${itemIndex}`}
            style={!!item?.active ? {opacity: .5, cursor: 'no-drop'} : {}}
            onDoubleClick={(e: any) => {
                if (e.target?.type === 'checkbox' || !!item?.active) {
                    return;
                }
                if (onRow) {
                    e.stopPropagation();
                    onRow(item, itemIndex);
                }
            }}
        >
            {addPropsToChildren(children, {
                isChecked: selectedRow,
                onCheck: handleSelect,
            })}
        </TableRow>
    );
};

export default TableRowItem;
