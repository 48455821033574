import { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Flex,
  Input,
  Layout,
  MenuProps,
  Typography,
} from 'antd';

import Icon, {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { setDrawer, setLang } from '../../store/store';
import { languageIcon } from '../../assets/image/svg';
import { RootState, useStoreDispatch } from '../../store';

import 'moment/locale/ru';

import style from './style.module.scss';
import CustomDate from '../CustomDate';
import { useParamsHistory } from '../../hooks';

const { Header } = Layout;



const HeaderComponent = (props: any) => {
  const { location } = useParamsHistory();
  const dispatch = useStoreDispatch();
    const { pathname } = location;
  const isDrawer = useSelector((state: RootState) => state.stores.isDrawer);
  const breadcrumb: { title: string }[] = useSelector(
    (state: RootState) => state.stores.breadcrumb,
  );
  const lang = useSelector((state: RootState) => state.stores.lang) as
    | 'ru'
    | 'en';
  const { t, i18n } = useTranslation();

  const menu: MenuProps['items'] = [
    {
      key: 'ru',
      label: 'ru',
      onClick: () => {
        dispatch(setLang('ru'));
      },
    },
    {
      key: 'en',
      label: 'en',
      onClick: () => {
        dispatch(setLang('en'));
      },
    },
  ];

  useEffect(() => {
    i18n.changeLanguage(lang.toLocaleLowerCase()).then((r) => r);
  }, [lang, pathname]);

  return (
    <Header style={{ width: '100%' }}>
      <Flex
        vertical={false}
        justify={'space-between'}
        style={{ width: 'calc(100% - 35px)', height: 57 }}
      >
        <Flex align={'center'}>
          {isDrawer && (
            <Button
              type="text"
              icon={isDrawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => dispatch(setDrawer(false))}
            />
          )}
          <Breadcrumb
            separator={<span className={style.circle} />}
            items={breadcrumb}
          />
        </Flex>
        <Flex justify="space-between" align={'center'} gap={10}>
          <Col span={4} style={{ alignSelf: 'center' }}>
            <Dropdown
              overlayClassName={style.menu}
              menu={{ items: menu }}
              trigger={['click']}
            >
              <Typography.Link onClick={(e) => e.preventDefault()}>
                <div className={style.language}>
                  <Flex gap={5} align={'center'}>
                    <Icon component={languageIcon} />
                    <Typography.Text className={style.language__item}>
                      {lang}
                    </Typography.Text>
                  </Flex>
                  <DownOutlined style={{ color: '#0E1117' }} />
                </div>
              </Typography.Link>
            </Dropdown>
          </Col>
          <div className={style.date}>
            <CustomDate />
          </div>
          <Col>
            <Input placeholder={t('Поиск')} prefix={<SearchOutlined />} />
          </Col>
        </Flex>
      </Flex>
    </Header>
  );
};

export default HeaderComponent;
