export const messagesModal = {
  warning: {
    title: "Предупреждение",
    subtitle: "Вы уверены, что хотите отменить?",
  },
  delete: {
    title: "Предупреждение",
    subtitle: "Вы уверены, что хотите удалить?",
  },
};

// не менять только добавлять
export const RELEASE = "release";
export const TERMINAL = "terminal";
export const CONTAINER = "container";
export const COMPANY = "company";
export const CONTACT = "contact";
export const INTEGRATION = "integration";
export const SALES_DEAL = "sales_deal";
export const RENT_DEAL = "rent_deal";
export const RENT_REQUEST = "rent_request";
export const REQUESTS = "requests";
export const LEADS = "leads";
export const AGREEMENT = "agreement";
export const TASK = "task";
export const FINANCE_BILLING = "finance_billing";
export const FINANCE_PAYMENT = "finance_payment";
export const PROFILE_USER = "user";
export const STORAGE_CONDITIONS = "storage_conditions";
export const CALCULATION_FORMATION = "calculation_formation";
export const GUIDE = "guide";
export const NOT_FOUND = "*";

export const crmNames: { [key: string]: string } = {
  [COMPANY]: "Компании",
  [CONTACT]: "Контакты",
  [INTEGRATION]: "Интеграции",
  [SALES_DEAL]: "Сделки",
  [REQUESTS]: "Запросы аренды",
  [LEADS]: "Лиды",
  [AGREEMENT]: "Договоры",
};
export const storageConditionsNames: { [key: string]: string } = {
  [GUIDE]: 'Справочник',
  [CALCULATION_FORMATION]: 'Формирования расчета по клиенту',
};

export const MIN_CURRENCY_PRICE = -10000;
