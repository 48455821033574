import React from 'react';

import * as XLSX from 'xlsx';
import { delay } from 'lodash';
import classnames from 'classnames';
import type { UploadProps } from 'antd';
import { Button, App, Upload } from 'antd';

import styles from './style.module.scss';
import { checkFileTypes, fileTypes } from '../../../helpers/input-helpers';
import { useTranslation } from 'react-i18next';

interface IUploadExcelFile {
  accept?: string;
  classNameLink?: string;
  nameLink?: string;
  keyNames: string[];
  onChange?: (data: any[]) => void;
  styleButton?: any
}

const UploadExcelFile: React.FC<IUploadExcelFile> = ({
  keyNames,
  onChange,
  styleButton,
  accept = fileTypes.xls,
  classNameLink = '',
  nameLink = 'Загрузить из Excel',
  ...rest
}) => {
  const {message} = App.useApp();
  const { t } = useTranslation();
  const customRequest: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
    onError,
  }: any) => {
    try {
      if (!checkFileTypes(accept, file?.type ?? '')) {
        throw new Error('ошибка типа файла');
      }

      const reader: FileReader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const array = (await reader.result as ArrayBuffer);
        const workbook = XLSX.read(array, {type: 'array'});
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const result: any[] = XLSX.utils.sheet_to_json(firstSheet, { header: 1, raw: false });
        const mapResult: any[] = [];
        for (const res of result) {
          const element: {[key: string]: any} = {};
          res.forEach((value: string, i: number) => {
            element[keyNames[i]] = value || '';
          });
          mapResult.push(element);
        }
        message.success(`${file.name} данные добавлены`, 2);
        delay(() => {
          onSuccess('ok');
          if (onChange) {
            onChange(mapResult);
          }
        }, 2000);
      };
      reader.onerror = (error) => {
        message.error('Ошибка при чтении файла');
        onError(error);
      };
    } catch (error) {
      message.error('Ошибка при загрузке файла');
      console.error(error);
    }
  };

  const props: UploadProps = {
    ...rest,
    accept,
    name: 'file',
    listType: 'text',
    fileList: [],
    className: styles.upload,
    customRequest,
    onChange(info) {
      if (info.file.status === 'error') {
        message.error(`${info.file.name} ошибка типа файла`);
        return;
      }
      if (!checkFileTypes(accept, info?.file?.type ?? '')) {
        message.error('Недопустимый тип файла');
        return;
      }
    },
  };
  return (
    <Upload {...props}>
      <Button
        className={classnames(styles.link, { [classNameLink]: classNameLink })}
        type="text"
        style={styleButton}
      >
        {t(nameLink)}
      </Button>
    </Upload>
  );
};

export default UploadExcelFile;
