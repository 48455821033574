import { IContractors } from '../../../types/contractors';

export const defaultTerminalCompany = {
  terminal: '',
  terminal_id: null,
  stock: '',
  stock_id: null,
  bet_day_20: '',
  bet_day_40: '',
  bet_crane_20: '',
  bet_crane_40: '',
  free_days: '',
};

export const defaultValueCompany: IContractors = {
  name_company: '',
  internal_company: '',
  type_company: null,
  type_source: null,
  contact_name: '',
  contact_phone: '',
  contact_email: '',
  address: null,
  inn_name: '',
  requisites_id: null,
  requisites: undefined,
  short_address: '',
  bank: [],
  contact: undefined,
  contact_id: null,
  last_phone: [],
  last_email: [],
  last_messenger: [],
  phone_first: '',
  email_first: '',
  user_fio: undefined,
  messenger_first: '',
  type_phone_first: null,
  type_email_first: null,
  type_messenger_first: null,
  comment: '',
  responsible_id: null,
  delete: false,
  terminals: [defaultTerminalCompany],
};

export const defaultItemMessengerList = {
  add_messenger: '',
  add_type_messenger: null,
};

export const defaultItemLastEmailList = {
  add_email: '',
  add_type_email: null,
};
export const defaultItemLastPhoneList = {
  add_phone: '',
  add_type_phone: null,
};

export const defaultValueBank = {
  title: '',
  name_bank: '',
  bik: '',
  payment_account: '',
  cor_account: '',
  account_currency: '',
  address_bank: '',
  comment: '',
};

export const defaultRequisites = [
  {
    key: '1',
    name: 'Компания',
    meaning: '',
  },
  {
    key: '2',
    name: 'Полное наименование организации',
    meaning: '',
  },
  {
    key: '3',
    name: 'ИНН',
    meaning: '',
  },
  {
    key: '4',
    name: 'КПП',
    meaning: '',
  },
  {
    key: '5',
    name: 'ОГРН (Дата)',
    meaning: '',
  },
  {
    key: '6',
    name: 'Адрес',
    meaning: '',
  },
  {
    key: '7',
    name: 'Дата государственной регистрации',
    meaning: '',
  },
  {
    key: '8',
    name: 'ОКПО',
    meaning: '',
  },
  {
    key: '9',
    name: 'ОКТМО',
    meaning: '',
  },
  {
    key: '10',
    name: 'Ген. директор',
    meaning: '',
  },
  {
    key: '11',
    name: 'Короткий адрес',
    meaning: '',
  },
];
