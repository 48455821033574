import get from 'lodash.get';

interface IMapperSelect {
  items: any[];
  keys?: { [key: string]: any };
}
export const mapperSelect = ({
  items,
  keys = { label: 'value', value: 'id' },
}: IMapperSelect): any[] => {
  const [label, value] = Object.keys(keys);
  return Array.isArray(items) && items?.length
    ? items.map((item: any, i) => {
        return {
          ...item,
          [label]: item?.[keys[label]],
          [value]: item?.[keys[value]],
        };
      })
    : [];
};

export const filterSelectSearch = (
  input: string,
  option: { label: string; value: number } | undefined,
) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const mapperTreeListSearchStock = (arr?: any[]) => {
  const result: any[] = [];
  const res = arr?.length
    ? arr.map((stock: any) => ({
        ...stock,
        parent: get(stock?.terminal, 'city_child.city_terminals.name', ''),
        name: get(stock?.terminal, 'city_child.name', ''),
      }))
    : [];
  for (const item of res) {
    const index = result.findIndex(
      (el) => el.title === `${item.parent}-${item.name}`,
    );
    if (index >= 0) {
      result[index].children.push({ title: item.label, value: item.id });
    } else {
      result.push({
        value: `${item.parent}-${item.name}`,
        title: `${item.parent}-${item.name}`,
        selectable: false,
        children: [
          {
            title: item.label,
            value: item.id,
          },
        ],
      });
    }
  }

  return result;
};

export const mapperListCompany = (arr?: any[]) =>
  arr?.length
    ? arr.map((company: any) => ({
        ...company,
        phone: company?.['phone_first'] ?? '',
      }))
    : [];

    export const filterListId = (id: number) => (arr?: any[]) =>
  arr?.length ? arr.filter((item: any) => item?.id !== id) : [];
