import Icon from '@ant-design/icons';
import { SortOrder } from 'antd/es/table/interface';
import { sortIconDown, sortIconUp } from '../../../../../assets/image/svg';
import { ColumnType } from 'antd/es/table';
import {
  AGREEMENT,
  COMPANY,
  CONTACT,
  CONTAINER,
  FINANCE_BILLING,
  FINANCE_PAYMENT,
  RELEASE,
  RENT_DEAL,
  RENT_REQUEST,
  SALES_DEAL,
  TERMINAL,
} from '../../../../../constants';

const getSort = ({ sortOrder }: { sortOrder: SortOrder }) => (
  <Icon component={sortOrder === 'ascend' ? sortIconUp : sortIconDown} />
);

const columnsRentRequest: ColumnType[] = [
  {
    sorter: true,
    sortIcon: getSort,
    width: 50,
    title: '',
    dataIndex: 'action',
  },
  {
    width: 50,
    title: '№',
    dataIndex: 'index', // выводит номер
  },
  {
    title: 'Название',
    dataIndex: 'name',
    ellipsis: true,
  },
  {
    title: 'Последняя активность',
    dataIndex: 'lastActive',
  },
  {
    title: 'Срок выполнения',
    dataIndex: 'timeline',
  },
  {
    title: 'Постановщик',
    dataIndex: 'responsible',
    ellipsis: true,
  },
];
const columnsDefault: ColumnType[] = [
  {
    sorter: true,
    sortIcon: getSort,
    width: 50,
    title: '',
    dataIndex: 'action',
  },
  {
    width: 50,
    title: '№',
    dataIndex: 'index', // выводит номер
  },
  {
    title: 'Название',
    dataIndex: 'name',
    ellipsis: true,
  },
  {
    title: 'Последняя активность',
    dataIndex: 'lastActive',
  },
  {
    title: 'Срок выполнения',
    dataIndex: 'timeline',
  },
  {
    title: 'Ответственный',
    dataIndex: 'responsible',
    ellipsis: true,
  },
];

export const getColumns = ({ table }: { table: string }) => {
  switch (table) {
    case RENT_REQUEST:
      return columnsRentRequest;
    case AGREEMENT:
    case COMPANY:
    case CONTACT:
    case TERMINAL:
    case CONTAINER:
    case RELEASE:
    case SALES_DEAL:
    case RENT_DEAL:
    case FINANCE_BILLING:
    case FINANCE_PAYMENT:
    default:
      return columnsDefault;
  }
};
