import { Card, Flex, Row, Typography } from 'antd';
import { FC } from 'react';
import RegistrationForm from '../../components/RegistrationForm/RegistrationForm';
import './style.scss';
import Button from '../../components/ui/Button/Button';
import { useHistory } from 'react-router-dom';
import { RouteNames } from '../../router/names';

const Registration: FC = () => {
  const router = useHistory();
  const clickLogin = () => {
    router.push(RouteNames.LOGIN);
  };
  return (
    <>
      <Flex
        align="center"
        gap={10}
        style={{ position: 'absolute', right: 20, top: 30 }}
      >
        <Typography.Paragraph
          style={{
            margin: 0,
            fontWeight: 600,
          }}
        >
          Уже зарегистрированы?
        </Typography.Paragraph>
        <Button onClick={clickLogin} type="primary" style={{ width: 200 }}>
          Войти
        </Button>
      </Flex>
      <Row justify="space-around" align="middle" className="h100">
        <Card className={'card-login'}>
          <Flex justify="center" align="center" vertical>
            <RegistrationForm clickLogin={clickLogin} />
          </Flex>
        </Card>
      </Row>
    </>
  );
};

export default Registration;
