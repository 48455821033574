import {styled} from "@stitches/react";

export const TableWrapper = styled("table", {
    fontFamily: "Manrope",
    borderCollapse: "separate",
    borderSpacing: "0 7px",
    width: "100%"
});

export const TableCell = styled("td", {
    fontSize: 14,
    padding: "2px 16px",
    // Применение закругления для первой ячейки строки
    "&:first-child": {
        borderRadius: '12px 0 0 12px',
        paddingRight: 0,
    },
    // Применение закругления для последней ячейки строки
    "&:last-child": {
        borderRadius: '0 12px 12px 0',
    },
});

export const TableRowItem = styled("tr", {
    cursor: "pointer",
    variants: {
        status: {
            departure: {
                backgroundColor: "#EDEEF1",
                color: '#445371'
            },
            booking: {
                backgroundColor: "#FCE8EA",
                color: '#bb6371'
            },
            ways: {
                backgroundColor: "#FEF4E6",
                color: '#ef7702'
            },
            default: {
                backgroundColor: "#FFFFFF",
            },
        },
    },
    height: 48,
    border: '1px solid transparent',
    transition: 'all ease 0.3s',
    padding: 5,
    "&:hover": {
        variants: {
            status: {
                ways: {
                    backgroundColor: "#FEF4E6",
                    color: '#ef7702'
                },
                booking: {
                    backgroundColor: "#FCE8EA",
                    color: '#bb6371'
                },
                departure: {
                    backgroundColor: "#EDEEF1",
                    color: '#445371'
                },
                default: {
                    backgroundColor: "#FFFFFF",
                },
            },
        },
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.10) !important',
    }
});


export const TableHeaderCell = styled("th", {
    border: '2px solid #F8F8F8',
    backgroundColor: "#FFF",
    padding: "0px 16px 0px 16px",
    fontWeight: 500,
    textAlign: "left",
    fontSize: 14,
    color: "#2c3e50",
    position: "sticky",
    top: 0,
    zIndex: 1,
    borderRadius: '10px',
    "&:hover": {
        backgroundColor: "#e1e9fa",
        color: "#0148d6",
    },
});
export const TableHeaderCellColor = styled("div", {
    flex: 1,
    backgroundColor: "inherit",
    padding: "7px 0",
    fontWeight: 500,
    textAlign: "left",
    fontSize: 14,
    color: "currentColor",
    position: "sticky",
    top: 0,
    zIndex: 1,
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
});

export const TableHeaderRow = styled("div", {
    display: "flex",
    height: 30,
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 6,
    padding: '0px 4px',
    fontSize: 14,
    fontWeight: 500,
})
export const AddHiddenColumn = styled("div", {
    fontSize: 22,
    color: '#0055FF',
    cursor: "pointer",
    minWidth: '22px'
})

export const Footer = styled('div',{
    position: "fixed",
    bottom: 0,
    display: "flex",
    width: "100%",
    height: 44,
    padding: "6px 341px 6px 16px",
    alignItems: "center",
    flexShrink: 0,
    borderTop: "1px solid #D8D8D8",
    background: "#FFF"
})
export const ColPage = styled("div", {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    gap: 8,
    flexWrap: "wrap",
    height: 30
})
export const ColPageMenu = styled("div", {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    gap: 8,
    flexWrap: "wrap",
    padding: 5,
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "#e1e9fa",
        color: "#0148d6",
        padding: 5,
        borderRadius: 4
    },
})
export const TruncatedText = styled('div',{
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    wordWrap: 'break-word',
    whiteSpace: "break-spaces"
});
