import {Flex, Typography} from 'antd';
import {IColumnType} from '../../../components/ui/Table/Table';
import {IRelease} from '../../../store/releases';
import {Link} from 'react-router-dom';
import {IDeal, ILocation} from '../../../store/rent';
import RouteList from './components/list/RouteList';
import {getSearchLink} from '../../../helpers/link-helper';
import {COMPANY, RELEASE, SALES_DEAL} from '../../../constants';

export const columns: IColumnType<any>[] = [
    {
        dataIndex: 'id',
        filterIndex: 'id',
        checkbox: true,
        menu: true,
        type: 'string',
        key: '0',
    },
    {
        title: 'ID',
        dataIndex: 'id_deal',
        filterIndex: 'id_deal',
        link: true,
        width: '20%',
        type: 'string',
        key: '1',
    },
    {
        title: 'Релизы',
        dataIndex: 'releases.release_id',
        filterIndex: 'releases_model.release_id',
        width: '20%',
        type: 'string',
        key: '2',
        render: (col, item) =>
            item?.releases?.length ? (
                <Flex vertical>
                    {item?.releases.map((rel: IRelease, index: number) => (
                        <Typography.Paragraph
                            key={index}
                            ellipsis={{
                                rows: 2,
                                tooltip: {placement: 'top', title: rel?.release_id},
                            }}
                            style={{maxWidth: '250px', margin: 0, color: '#0148D7'}}
                        >
                            <Link
                                style={{color: 'currentcolor'}}
                                to={getSearchLink({key: RELEASE, id: rel?.id, noUrl: true})}
                            >
                                {rel?.release_id}
                            </Link>
                        </Typography.Paragraph>
                    ))}
                </Flex>
            ) : null,
    },
    {
        title: 'Локация релиза',
        dataIndex: 'location_release',
        filterIndex: 'location_release',
        width: '25%',
        key: '3',
        type: 'string',
        render: (_, item) =>
            item?.location_release ? (
                <Typography.Paragraph
                    ellipsis={{
                        rows: 2,
                        tooltip: {placement: 'left', title: item.location_release},
                    }}
                    style={{maxWidth: '200px', margin: 0}}
                >
                    {item.location_release}
                </Typography.Paragraph>
            ) : null,
    },
    {
        title: 'Локация возврата',
        dataIndex: 'location_return',
        filterIndex: 'location_return.location',
        width: '25%',
        key: '3.3',
        type: 'string',
        render: (_, item) =>
            Array.isArray(item?.location_return) ? (
                <RouteList
                    array={item?.location_return?.map((el: ILocation) => el?.location)}
                />
            ) : null,
    },
    {
        title: 'Связанные сделки',
        dataIndex: 'related_deals',
        filterIndex: 'related_deals->rent_deal_sales.id_deal',
        width: '20%',
        type: 'string',
        key: '4',
        render: (col, item) =>
            item?.related_deals?.length ? (
                <Flex vertical>
                    {item?.related_deals.map((rel: IDeal, index: number) => (
                        <Typography.Paragraph
                            key={index}
                            ellipsis={{
                                rows: 2,
                                tooltip: {placement: 'top', title: rel?.id_deal},
                            }}
                            style={{maxWidth: '250px', margin: 0, color: '#0148D7'}}
                        >
                            <Link
                                style={{color: 'currentcolor'}}
                                to={getSearchLink({key: SALES_DEAL, id: rel?.id, noUrl: true})}
                            >
                                {rel?.rent_deal_sales?.id_deal}
                            </Link>
                        </Typography.Paragraph>
                    ))}
                </Flex>
            ) : null,
    },
    {
        title: 'Клиент',
        dataIndex: 'companies',
        filterIndex: 'companies.name_company',
        width: '20%',
        type: 'string',
        key: '5',
        render: (_, item) =>
            item?.companies?.[0]?.label ? (
                <Typography.Paragraph
                    ellipsis={{
                        rows: 2,
                        tooltip: {placement: 'top', title: item.companies[0].label},
                    }}
                    style={{
                        maxWidth: '250px',
                        margin: 0,
                        color: item?.companies[0]?.id ? '#0148D7' : 'currentColor',
                    }}
                >
                    {item?.companies[0]?.id ? (
                        <Link
                            style={{color: 'currentcolor'}}
                            to={getSearchLink({key: COMPANY, id: item?.companies[0]?.id, noUrl: true})}
                        >
                            {item?.companies[0].label}
                        </Link>
                    ) : (
                        item?.companies[0].label
                    )}
                </Typography.Paragraph>
            ) : null,
    },
    {
        title: 'Телефон',
        dataIndex: 'companies[0].phone_first',
        filterIndex: 'companies.phone_first',
        width: '20%',
        type: 'string',
        key: '6',
    },
    {
        title: 'Ответственный',
        dataIndex: 'users',
        filterIndex: 'users.fio',
        width: '200px',
        key: '7',
        type: 'string',
        render: (_, item) =>
            item?.users?.fio ? (
                <Typography.Paragraph
                    ellipsis={{
                        rows: 2,
                        tooltip: {placement: 'left', title: item.users.fio},
                    }}
                    style={{maxWidth: '200px', margin: 0}}
                >
                    {item.users.fio}
                </Typography.Paragraph>
            ) : null,
    },
    {
        title: 'Кол-во КТК',
        dataIndex: 'count_ktk',
        filterIndex: 'count_ktk',
        width: '20%',
        key: '8',
        type: 'range_integer',
    },
    {
        title: '',
        dataIndex: 'hidden',
        filterIndex: 'hidden',
        width: '20%',
        key: '9',
    },
];
