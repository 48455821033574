import get from 'lodash.get';
import { IContainers } from '../../types/containers';
import { getDateString } from '../../helpers/date-helpers';

export const mapperContainers = (containers: IContainers | IContainers[]) => {
  if (Array.isArray(containers)) {
    return containers?.map((cont) => {
      cont.activity = cont?.activity ? [cont.activity] : [];
      cont.comments = cont?.comments ? cont.comments : [];
      return cont;
    });
  }
  if (containers) {
    containers.activity = containers?.activity ? [containers.activity] : [];
    containers.comments = containers?.comments ? containers.comments : [];
    return containers;
  } return null
};

export const mapperHistoryContainer = (arr: {data: any}[]) => {
  return arr?.map((el) => ({...el, data: {
    ...el.data,
    status: get(el.data?.status, 'name', ''),
    company: get(el.data?.contractors, 'name_company', ''),
    location: get(el.data?.location, 'city', ''),
    terminal: get(el.data?.terminal, 'label', ''),
    stock: get(el.data?.terminalStock, 'label', ''),
    booking_before: getDateString({date: el.data?.booking_before}),
    departure_date: getDateString({date: el.data?.departure_date}),
    arrival_date: getDateString({date: el.data?.arrival_date}),
    date_entry: getDateString({date: el.data?.date_entry}),
    date_cvv: getDateString({date: el.data?.date_cvv}),
    date_memory: getDateString({date: el.data?.date_memory}),

  }}))
};
