import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ITerminal } from "../types/terminal";
import { Rest_terminal } from "../services/rest_terminal";


interface ITerminalState {
  terminalData: Array<ITerminal>;
}

export interface IDataCityChildren {
  id: number;
  label: string;
  value: number;
}
export interface IDataCity {
  id: string;
  label: string;
  value: string;
  children: IDataCityChildren[];
}

const initialState: ITerminalState = {
  terminalData: [],
};
export const setTerminal = createAsyncThunk(
  "setTerminal",
  async (payload: {}) => {
    return await Rest_terminal.setTerminal(payload);
  }
);

export const searchTerminal = createAsyncThunk(
  "searchTerminal",
  async (payload: { text: string; all?: boolean }) => {
    return await Rest_terminal.searchTerminal(payload);
  }
);

export const deleteIds = createAsyncThunk("deleteIds", async (payload: any) => {
  return await Rest_terminal.deleteIds(payload);
});
const terminalSlice = createSlice({
  name: "terminal",
  initialState,
  reducers: {
    resetStoreTerminal(state) {
      state.terminalData = initialState.terminalData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setTerminal.fulfilled, (state, action) => {
      state.terminalData = action.payload;
    });
  },
});
export default terminalSlice.reducer;
export const { resetStoreTerminal } = terminalSlice.actions;
