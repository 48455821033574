import socket from "../socket";
import { IParamRestDelete } from "../types";
import { IChat, IComment, IMessage } from "../store/chat";

export const SET_MESSAGE = "set-message";
export const GET_MESSAGE = "get-message";
export const SET_MESSAGE_ONE = "set-message-one";
export const DELETE_MESSAGE = "delete-message";

export class Rest_Chat {

  static setMessage(payload: IChat): Promise<IComment> {
    const cleanPayload = {
      sender_id: payload.sender_id,
      recipient_id: payload.recipient_id,
      recipient_tag: payload.recipient_tag,
      message_text: payload.message_text,
      table: payload.table,
      row_id: payload.row_id,
    };
  
    return new Promise((resolve, reject) => {
      socket.emit(SET_MESSAGE, cleanPayload 
      //   (response: any) => {
      //   response?.success ? resolve(response.data) : reject(response);
      // }
    );
    });
  }
  // static setMessage(payload: IChat): Promise<IComment> {
  //   return new Promise((resolve, reject) => {
  //     socket.emit(SET_MESSAGE, payload, (response: any) => {
  //       response?.success ? resolve(response.data) : reject(response);
  //     });
  //   });
  // }

  // static getMessage(payload: Pick<IMessage, 'row_id'>): Promise<IMessage[]> {
  //   return new Promise((resolve, reject) => {
  //     socket.emit(GET_MESSAGE, payload
  //       , (response: any) => {
  //       response?.success ? resolve(response.data) : reject(response);
  //       console.log("вывод в файле rest_chat:", payload)
  //     }
  //   );
  //   });
  // }
  static getMessage(payload: Pick<IMessage, 'row_id'>): Promise<IMessage[]> {
    return new Promise(() => {
      socket.emit(GET_MESSAGE, payload)
    });
  }

  static setMessageOne(payload: IChat): Promise<any> {
    return new Promise((resolve, reject) => {
      socket.emit(SET_MESSAGE_ONE, payload, (response: any) => {
        response?.success ? resolve(response) : reject(response);
      });
    });
  }

  // static deleteMessage(payload: Pick<IMessage, 'id'>): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     socket.emit(DELETE_MESSAGE, payload, (response: any) => {
  //       response?.success ? resolve(response) : reject(response);
  //     });
  //   });
  // }

  // static deleteMessage(payload: Pick<IMessage, 'id'>): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     socket.emit(DELETE_MESSAGE, payload, (response: any) => {
  //       if (response?.success) {
  //         resolve(response);
  //       } else {
  //         // Логируем ошибку для отладки
  //         console.error("Ошибка удаления сообщения:", response);
  //         reject(response?.message || "Неизвестная ошибка");
  //       }
  //     });
  //   });
  // }

  static deleteMessage(payload: Pick<IMessage, 'id'>): Promise<any> {
    return new Promise(() => {
      socket.emit(DELETE_MESSAGE, payload)
    });
  }
}