import React, { useEffect, useMemo, useState } from 'react';
import { RouteNames } from '../../router/names';
import { useBreadcrumb, useMenu, useParamsHistory } from '../../hooks';
import { useGettingDataForTables } from '../../hooks/useGettingDataForTables/useGettingDataForTables';
import {
  CALCULATION_FORMATION,
  GUIDE,
  storageConditionsNames,
} from '../../constants';
import { ITab, ITabs } from '../../components/ui/Tabs/Tabs';
import { Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import style from './style.module.scss';
import {Guide} from './components';


export const CalculationFormation = () => {
  const { location } = useParamsHistory();
  const { dataTable, optionsTable, updateEffectDate, setLoadingSubmit } =
    useGettingDataForTables({
      titlePage: 'условия хранения',
      keyItemName: 'calculation_formation_id',
      subscribeStringCreateForm: 'return-set-calculation_formation',
      subscribeStringDelete: 'return-delete-calculation_formation',
      subscribeError: 'error',
      table: CALCULATION_FORMATION,
      location,
      columns: [],
      fetchTables: (...arg) => new Promise(() => {}),
      fetchDelete: (...arg) => new Promise(() => {}),
    });
  return <div>calculation_formation</div>;
};

export const StorageConditions: React.FC = () => {
  const { t } = useTranslation();
  const { location, history, id } = useParamsHistory();
  const { pathname } = location;
  const [activeKey, setActiveKey] = useState('');
  const [activeTitle, setActiveTitle] = useState('');

  useBreadcrumb(['Депо', 'Условия хранения']);
  useMenu({
    openKey: RouteNames.DEPOT,
    activeKeys: [RouteNames.STORAGE_CONDITIONS],
  });

  useEffect(() => {
    switch (id) {
      case GUIDE:
        setActiveKey(GUIDE);
        setActiveTitle(storageConditionsNames[GUIDE]);
        return;
      case CALCULATION_FORMATION:
        setActiveKey(CALCULATION_FORMATION);
        setActiveTitle(storageConditionsNames[CALCULATION_FORMATION]);
        return;
      default:
        setActiveKey(GUIDE);
        setActiveTitle(storageConditionsNames[GUIDE]);
        history.replace(`${RouteNames.STORAGE_CONDITIONS}/${GUIDE}`);
    }
  }, [id]);

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: CALCULATION_FORMATION,
        label: t(storageConditionsNames[CALCULATION_FORMATION]),
        disabled: true,
        content: (
          <CalculationFormation />
        ),
      },
      {
        key: GUIDE,
        label: t(storageConditionsNames[GUIDE]),
        forceRender: false,
        content: (
          <Guide
          title={t('Ставки по хранению')}
          activeKey={activeKey}
        />
        ),
      },
    ],
    [],
  );
  const handleClickTab = (key: string) => {
    setActiveKey(key);
    setActiveTitle(storageConditionsNames[key]);
    history.replace(`${RouteNames.STORAGE_CONDITIONS}/${key}`);
  };
  return (
    <div className={style['container']}>
      <Flex
        justify={'space-between'}
        align={'flex-start'}
        style={{ margin: '20px 28px 0px' }}
      >
        <Typography.Title level={3}>
          {'Условия хранения'}
        </Typography.Title>
      </Flex>
      <ITabs
        defaultActiveKey={activeKey}
        className={style['container__tab']}
        activeKey={activeKey}
        tabs={tabs}
        onChange={handleClickTab}
      />
    </div>
  );
};
