import Button from '../../components/ui/Button/Button';
import {
  BitrixIcon,
  ChatIcon,
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  SkypeIcon,
  TelegramIcon,
  ViberIcon,
  VkIcon,
  WatsappIcon,
} from '../../assets/image/svg';
import React from 'react';
import Icon from '@ant-design/icons';

export class Icons {
  static arrIcon = (type: any) => {
    if (type.type === 'Facebook') {
      return <Icon component={FacebookIcon} style={{width: '20px', color: 'gray'}} />;
    } else if (type.type === 'ВКонтакте') {
      return <Icon component={VkIcon} style={{width: '20px', color: 'gray'}} />;
    } else if (type.type === 'Watsapp') {
      return <Icon component={WatsappIcon} style={{width: '20px', color: 'gray'}} />;
    } else if (type.type === 'Viber') {
      return <Icon component={ViberIcon} style={{width: '20px', color: 'gray'}} />;
    } else if (type.type === 'Skype') {
      return <Icon component={SkypeIcon} style={{width: '20px', color: 'gray'}} />;
    } else if (type.type === 'Instagram') {
      return <Icon component={InstagramIcon} style={{width: '20px', color: 'gray'}} />;
    } else if (type.type === 'Битрикс24') {
      return <Icon component={BitrixIcon} style={{width: '20px', color: 'gray'}} />;
    } else if (type.type === 'Chat') {
      return <Icon component={ChatIcon} style={{width: '20px', color: 'gray'}} />;
    } else if (type.type === 'Telegram') {
      return <Icon component={TelegramIcon} style={{width: '20px', color: 'gray'}} />;
    }
    return null;
  };
}
