import { FC, ReactNode, ReactElement } from 'react';
import { Tabs, TabsProps, Tooltip, Typography } from 'antd';
import classnames from 'classnames';

import styles from './Tabs.module.scss';
import { useTranslation } from 'react-i18next';

export interface ITab {
  key: string;
  label: ReactElement | string;
  errorText?: ReactElement | string;
  disabledText?: ReactElement | string;
  icon?: ReactNode;
  content?: ReactNode | string;
  closable?: boolean;
  closeIcon?: ReactNode;
  forceRender?: boolean;
  disabled?: boolean;
  isError?: boolean;
  [key: string]: any;
}

export interface ITabsProps extends TabsProps {
  className?: string;
  activeKey?: string;
  defaultActiveKey?: string;
  onChange?: (key: string) => void;
  onEdit?: (e: any, action: 'add' | 'remove') => void;
  onTabClick?: (key: string) => void;
  tabs: ITab[];
  editable?: boolean;
  hideAdd?: boolean;
  tabBarExtraContent?: ReactNode;
  destroyInactiveTabPane?: boolean;
  isErrorTabs?: boolean;
  isTabBarText?: boolean;
}

export const ITabs: FC<ITabsProps> = ({
  activeKey,
  defaultActiveKey,
  onChange,
  onEdit,
  onTabClick,
  tabs,
  editable,
  hideAdd,
  tabBarExtraContent,
  isErrorTabs, // включает подсветку красным табов с ошибками
  isTabBarText = true,
  className = '',
  destroyInactiveTabPane = false,
  ...props
}) => {
  const { t } = useTranslation();
  const renderTabs = tabs.map((tab) => {
    return { content: tab?.content ?? null, ...tab };
  });

  const items = renderTabs
    .map((tab) => {
      const titleLabel = typeof tab.label === 'string' ? t(tab.label) : tab.label;
      const { content, ...rest } = tab;
      const label = tab.isError ? (
        <Tooltip
          title={tab.errorText ? tab.errorText : t('Проверьте ошибки')}
          placement="top">
          <div data-error="true">
          <Typography.Text>{titleLabel}</Typography.Text>
            <span style={{ color: 'red' }}> *</span>
          </div>
        </Tooltip>
      ) : tab.disabled ? (
        <Tooltip
          title={tab.disabledText ? tab.disabledText : titleLabel}
          placement="top">
          <Typography.Text disabled>{titleLabel}</Typography.Text>
        </Tooltip>
      ) : (
        titleLabel
      );

      return { ...rest, label, children: content };
    });

  return (
    <Tabs
      {...props}
      onChange={onChange}
      onEdit={onEdit}
      onTabClick={onTabClick}
      activeKey={activeKey}
      defaultActiveKey={defaultActiveKey}
      className={classnames(styles.Tabs, {
        [className]: className,
        [styles.errorTabs]: isErrorTabs,
        [styles.TabDefault]: isTabBarText,
      })}
      type={editable ? 'editable-card' : 'card'}
      tabBarExtraContent={tabBarExtraContent}
      hideAdd={hideAdd}
      destroyInactiveTabPane={destroyInactiveTabPane}
      items={items}
    />
  );
};
