import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Table, Upload, Button, Row, Col, Divider } from "antd";
import EditableTable from "../table/Table";
import UploaderDocs from "../../../../../components/ui/UploaderDocs";
import { RootState, useStoreDispatch } from "../../../../../store";
import { Rest_rent } from "../../../../../services/rest_rent";
import { setDeals } from "../../../../../store/rent";
import { purchaseColumns, subleaseColumns, ktkColumns } from "./columns";
import { fileTypes } from "../../../../../helpers/input-helpers";
import { RouteNames } from "../../../../../router/names";
import style from "../style.module.scss";
import { create } from "domain";
import { CREATE } from "../../../../../helpers/string-helpers";
import { useTranslation } from "react-i18next";
import { RELEASE_TAB } from "../../constants";
import {
  IRequest,
  IPurchase,
  ISublease,
  IKtk,
} from "../../../../../store/rent";

interface ReleaseTabProps {
  initialValue?: any;
  form?: any;
  tableName: string;
  // меняем сигнатуру, чтобы onCreateDeal принимал
  // (tableName: string, dealTypeId: number)
  onCreateDeal: (tableName: string, dealTypeId: number) => void;
  isOpen?: any;
  isViewMode?: boolean;
  activeKey: string;
  rightsReleaseTabEdit?: boolean;
}

const ReleaseTab: React.FC<ReleaseTabProps> = ({
  initialValue,
  form,
  tableName,
  onCreateDeal,
  isOpen,
  isViewMode,
  activeKey,
  rightsReleaseTabEdit,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [hidePurchase, setHidePurchase] = useState(false);
  const [hideSublease, setHideSublease] = useState(false);
  const [hideKtk, setHideKtk] = useState(false);

  const purchase_docs = Form.useWatch("purchase_docs", {
    form,
    preserve: true,
  });

  const sublease_docs = Form.useWatch("sublease_docs", {
    form,
    preserve: true,
  });

  const ktk_docs = Form.useWatch("ktk_docs", {
    form,
    preserve: true,
  });

  const handlerSaveFiles = (files: any[]) => {
    form.setFieldsValue({ saveFiles: files });
  };

  // const handleCreateDeal = (typeId: number) => {
  //   onCreateDeal(typeId);
  // };

  const filteredPurchase =
    initialValue?.purchase?.filter((row: any) => row?.is_selected === true) ||
    [];

  const filteredSublease =
    initialValue?.sublease?.filter((row: any) => row?.is_selected === true) ||
    [];

  const filteredKtk =
    initialValue?.ktk?.filter((row: any) => row?.is_selected === true) || [];

  // // Функция для вызова onCreateDeal
  // // Можно делать либо inline, либо универсальную
  // const handleCreateDeal = (name: string) => {
  //   const dealTypeId = TABLE_NAME_TO_ID[name] || 0;
  //   onCreateDeal(name, dealTypeId);
  // };

  const handleCreateDeal = () => {
    let dealTypeId = 0;
    switch (tableName) {
      // case "Закуп":
      //   dealTypeId = 6;
      //   break;
      // case "КТК МК":
      //   dealTypeId = 5;
      //   break;
      case "Субаренда":
        dealTypeId = 10;
        break;
      case "Эвакуация":
        dealTypeId = 9;
        break;

      default:
        dealTypeId = 0;
        break;
    }
    onCreateDeal(tableName, dealTypeId);
    // handleShowDrawerDeal(tableName, dealTypeId);
  };

  // файл ReleaseTab.tsx, ПОД комментарием "добавили" или "изменили"
  function getDealTypeIdByName(name: string): number {
    switch (name) {
      case "Субаренда":
        return 10;
      case "Эвакуация":
        return 9;
      case "Закуп":
        return 6;
      case "КТК МК":
        return 5;
      default:
        return 0; // либо -1, если хотим отловить ошибку
    }
  }

  return (
    <Form
      layout="vertical"
      form={form}
      className={style.requestTab}
      style={{
        display: "flex",
        flexDirection: "column",
        pointerEvents: !rightsReleaseTabEdit ? "none" : "auto",
        opacity: !rightsReleaseTabEdit ? 0.6 : 1,
      }}
    >
      {/* <Form.Item
        label="Название"
        // style={{ width: "100%" }}
        name="name_request"
        className={
          isViewMode ? style.approvalInputsDisabled : style.approvalInputs
        }
      >
        <Input
          disabled
          className={style.inputName}
          // style={{ color: "#0E1117", backgroundColor: "#0e111700" }}
        />
      </Form.Item> */}
      <Form.Item
        label="Название"
        name="name_request"
        className={
          isViewMode ? style.approvalInputsDisabled : style.approvalInputs
        }
      >
        <Input
          disabled
          className={style.inputName}
          // style={{
          //   color: "#0E1117",
          //   backgroundColor: "#0e111700",
          // }}
        />
      </Form.Item>
      {/* {initialValue?.purchase?.length > 0 && ( */}
      {filteredPurchase.length > 0 && (
        <>
          <div
            className={style["deals"]}
            style={{
              borderBottomLeftRadius: "0",
              borderBottomRightRadius: "0",
            }}
          >
            <div className={style.ApprovalTable}>
              {filteredPurchase.length > 0 && (
                <div
                  style={{
                    opacity: isViewMode ? 0.6 : 1,
                  }}
                >
                  <Divider className={style.RequestTablesDivider}>
                    Закуп
                  </Divider>
                  <EditableTable
                    columns={purchaseColumns}
                    fields={filteredPurchase.map((row: IPurchase, id: any) => ({
                      name: id.company,
                      key: id,
                      ...row,
                    }))}
                    rowCount={filteredPurchase.length}
                    columnWidths="15% 11% 9% 13% 15% 17% 20%"
                    isEditable={false}
                    additionalElement={
                      <Button
                        type="link"
                        // onClick={() => onCreateDeal("Закуп", 6)}
                        // onClick={handleCreateDeal}
                        onClick={() =>
                          onCreateDeal("Закуп", getDealTypeIdByName("Закуп"))
                        }
                      >
                        Сформировать сделку
                      </Button>
                    }
                  />
                </div>
              )}
              {!hidePurchase && (
                <div className={style["release__info"]}>
                  <Form.Item
                    name="purchase_docs"
                    label="Релиз и заявка с поставщиком"
                  >
                    <UploaderDocs
                      isOpen={isOpen}
                      isText
                      ids={purchase_docs}
                      customAddComponent={
                        <Button
                          type="default"
                          style={{
                            borderColor: isViewMode ? "#d9d9d9" : "#05F",
                            marginBottom: "7px",
                          }}
                          className={style["pdf__buttons__btn"]}
                        >
                          Загрузить
                        </Button>
                      }
                      multiple
                      accept={[
                        fileTypes.doc,
                        fileTypes.xls,
                        fileTypes.pdf,
                        fileTypes.jpeg,
                      ].join(",")}
                      setDataIds={(files) => {
                        form.setFieldValue("purchase_docs", files);
                      }}
                      // handlerSaveFiles={handlerSaveFiles}
                    />
                  </Form.Item>
                  <Form.Item
                    name="purchase_mail_theme"
                    label="Тема письма с подрядчиком"
                    rules={[
                      {
                        required: activeKey === RELEASE_TAB,
                        message: "Обязательное поле",
                      },
                    ]}
                  >
                    <Input placeholder="Введите название" />
                  </Form.Item>
                  <Form.Item
                    name="purchase_release_number"
                    label="Номер релиза"
                    rules={[
                      {
                        required: activeKey === RELEASE_TAB,
                        message: "Обязательное поле",
                      },
                    ]}
                  >
                    <Input placeholder="Введите номер" />
                  </Form.Item>
                  <Form.Item
                    name="purchase_fine"
                    label="Штраф за отмену"
                    rules={[
                      {
                        required: activeKey === RELEASE_TAB,
                        message: "Обязательное поле",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="purchase_add_info"
                    label="Дополнительная информация"
                    rules={[{ required: false }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
          <Button
            type="link"
            onClick={() => setHidePurchase(!hidePurchase)}
            className={style["hide__btn"]}
          >
            {hidePurchase ? "Развернуть" : "Свернуть"}
          </Button>
        </>
      )}

      {filteredSublease.length > 0 && (
        <>
          <div
            className={style["deals"]}
            style={{
              borderBottomLeftRadius: "0",
              borderBottomRightRadius: "0",
            }}
          >
            <div className={style.ApprovalTable}>
              {filteredSublease.length > 0 && (
                <div
                  style={{
                    opacity: isViewMode ? 0.6 : 1,
                  }}
                >
                  <Divider className={style.RequestTablesDivider}>
                    {tableName}
                  </Divider>
                  <EditableTable
                    columns={subleaseColumns}
                    fields={filteredSublease.map((row: ISublease, id: any) => ({
                      name: id.company,
                      key: id,
                      ...row,
                    }))}
                    rowCount={filteredSublease.length}
                    columnWidths="15% 11% 8% 13% 11% 16% 11% 7% 8%"
                    isEditable={false}
                    additionalElement={
                      <Button
                        type="link"
                        // onClick={() => onCreateDeal(tableName, 10)}
                        // onClick={handleCreateDeal}
                        onClick={() =>
                          onCreateDeal(
                            tableName,
                            getDealTypeIdByName(tableName)
                          )
                        }
                      >
                        Сформировать сделку
                      </Button>
                    }
                  />
                </div>
              )}
              {!hideSublease && (
                <div className={style["release__info"]}>
                  <Form.Item
                    name="sublease_docs"
                    label="Релиз и заявка с поставщиком"
                    // rules={[{ required: true }]}
                  >
                    <UploaderDocs
                      isOpen={isOpen}
                      isText
                      ids={sublease_docs}
                      customAddComponent={
                        <Button
                          type="default"
                          style={{
                            borderColor: isViewMode ? "#d9d9d9" : "#05F",
                            marginBottom: "7px",
                          }}
                          className={style["pdf__buttons__btn"]}
                        >
                          Загрузить
                        </Button>
                      }
                      multiple
                      accept={[
                        fileTypes.doc,
                        fileTypes.xls,
                        fileTypes.pdf,
                        fileTypes.jpeg,
                      ].join(",")}
                      setDataIds={(files) => {
                        form.setFieldValue("sublease_docs", files);
                      }}
                      // handlerSaveFiles={handlerSaveFiles}
                    />
                  </Form.Item>
                  <Form.Item
                    name="sublease_mail_theme"
                    label="Тема письма с подрядчиком"
                    rules={[
                      {
                        required: activeKey === RELEASE_TAB,
                        message: "Обязательное поле",
                      },
                    ]}
                  >
                    <Input placeholder="Введите название" />
                  </Form.Item>
                  <Form.Item
                    name="sublease_release_number"
                    label="Номер релиза"
                    rules={[
                      {
                        required: activeKey === RELEASE_TAB,
                        message: "Обязательное поле",
                      },
                    ]}
                  >
                    <Input placeholder="Введите номер" />
                  </Form.Item>
                  <Form.Item
                    name="sublease_fine"
                    label="Штраф за отмену"
                    rules={[
                      {
                        required: activeKey === RELEASE_TAB,
                        message: "Обязательное поле",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="sublease_add_info"
                    label="Дополнительная информация"
                    rules={[{ required: false }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
          <Button
            type="link"
            onClick={() => setHideSublease(!hideSublease)}
            className={style["hide__btn"]}
          >
            {hideSublease ? "Развернуть" : "Свернуть"}
          </Button>
        </>
      )}
      {filteredKtk.length > 0 && (
        <>
          <div
            className={style["deals"]}
            style={{
              borderBottomLeftRadius: "0",
              borderBottomRightRadius: "0",
            }}
          >
            <div className={style.ApprovalTable}>
              {filteredKtk.length > 0 && (
                <div
                  style={{
                    opacity: isViewMode ? 0.6 : 1,
                  }}
                >
                  <Divider className={style.RequestTablesDivider}>
                    КТК МК
                  </Divider>
                  <EditableTable
                    columns={ktkColumns}
                    fields={filteredKtk.map((row: IPurchase, id: any) => ({
                      name: id.company,
                      key: id,
                      ...row,
                    }))}
                    rowCount={filteredKtk.length}
                    columnWidths="14% 11% 11% 13% 15% 19% 17%"
                    isEditable={false}
                    additionalElement={
                      <Button
                        type="link"
                        // onClick={() => onCreateDeal("КТК МК", 5)}
                        // onClick={handleCreateDeal}
                        onClick={() =>
                          onCreateDeal("КТК МК", getDealTypeIdByName("КТК МК"))
                        }
                      >
                        Сформировать сделку
                      </Button>
                    }
                  />
                </div>
              )}
              {!hideKtk && (
                <div className={style["release__info"]}>
                  <Form.Item
                    name="ktk_docs"
                    label="Релиз и заявка с поставщиком"
                    // rules={[{ required: true }]}
                  >
                    <UploaderDocs
                      isOpen={isOpen}
                      isText
                      ids={ktk_docs}
                      customAddComponent={
                        <Button
                          type="default"
                          style={{
                            borderColor: isViewMode ? "#d9d9d9" : "#05F",
                            marginBottom: "7px",
                          }}
                          className={style["pdf__buttons__btn"]}
                        >
                          Загрузить
                        </Button>
                      }
                      multiple
                      accept={[
                        fileTypes.doc,
                        fileTypes.xls,
                        fileTypes.pdf,
                        fileTypes.jpeg,
                      ].join(",")}
                      setDataIds={(files) => {
                        form.setFieldValue("ktk_docs", files);
                      }}
                      // handlerSaveFiles={handlerSaveFiles}
                    />
                  </Form.Item>
                  <Form.Item
                    name="ktk_mail_theme"
                    label="Тема письма с подрядчиком"
                    rules={[
                      {
                        required: activeKey === RELEASE_TAB,
                        message: "Обязательное поле",
                      },
                    ]}
                  >
                    <Input placeholder="Введите название" />
                  </Form.Item>
                  <Form.Item
                    name="ktk_release_number"
                    label="Номер релиза"
                    rules={[
                      {
                        required: activeKey === RELEASE_TAB,
                        message: "Обязательное поле",
                      },
                    ]}
                  >
                    <Input placeholder="Введите номер" />
                  </Form.Item>
                  <Form.Item
                    name="ktk_fine"
                    label="Штраф за отмену"
                    rules={[
                      {
                        required: activeKey === RELEASE_TAB,
                        message: "Обязательное поле",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="ktk_add_info"
                    label="Дополнительная информация"
                    rules={[{ required: false }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              )}
            </div>
          </div>
          <Button
            type="link"
            onClick={() => setHideKtk(!hideKtk)}
            className={style["hide__btn"]}
          >
            {hideKtk ? "Развернуть" : "Свернуть"}
          </Button>
        </>
      )}
    </Form>
  );
};

export default ReleaseTab;
