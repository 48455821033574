import get from "lodash.get";

import { IColumnType } from "./Table";
import { Avatar, Dropdown, Flex, MenuProps, Typography } from "antd";
import Icon, { UserOutlined } from "@ant-design/icons";
import { useState } from "react";
import { TableCell, TruncatedText } from "./styled";
import moment from "moment";
import { Checkbox } from "../Checkbox/Checkbox";
import { burgerIcon, DeleteRed20, RepairIcon } from "../../../assets/image/svg";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import style from "./style.module.scss";

interface Props<T> {
  t: any;
  item: any;
  data: T[];
  column: IColumnType<T>;
  selectedRows: any;
  itemIndex: number;
  date?: boolean;
  link?: boolean;
  onRow?: (record: any, rowIndex: number) => void;
  subMenu?: {
    label: string;
    getSubMenu: ({
      item,
      rows,
      data,
      onClose,
    }: {
      item: any;
      rows: Set<any>;
      data: T[];
      onClose: () => void;
    }) => MenuProps["items"];
  };
  canView?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  editRow?: (item: any) => void;
  deleteRow?: (row: any) => void;
  onClickLink?: (record: any, rowIndex: number) => void;
  isChecked?: boolean;
  onCheck?: (checked: boolean) => void;
  handleCopyAll: (row?: any) => void;
  handleDeleteCopyAll?: (() => void | any[]) | undefined;
  columnIndex: number;
  onUploadFileAll?: (() => void) | undefined;
  handlerAllUpdate?: (() => void) | undefined;
}

export function TableRowCell<T>({
  t,
  item,
  data,
  column,
  subMenu,
  isChecked,
  itemIndex,
  columnIndex,
  selectedRows,
  canView,
  canEdit,
  canDelete,
  onRow,
  editRow,
  onCheck,
  deleteRow,
  onClickLink,
  handleCopyAll,
  onUploadFileAll,
  handlerAllUpdate,
  handleDeleteCopyAll,
}: Props<T>): JSX.Element {
  const [openContext, setOpenContext] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const title = get(item, column?.dataIndex);
  const refTitle = !column.date ? title : moment(title).format("DD.MM.YYYY");

  const subTitle = get(
    item,
    typeof column.subTitle !== "undefined" ? column.subTitle : ""
  );
  const avatar = get(
    item,
    typeof column.avatar !== "undefined" ? column.avatar : ""
  );
  const checkbox =
    typeof column.checkbox !== "undefined" ? column.checkbox : false;
  const menu = typeof column.menu !== "undefined" ? column.menu : false;
  const isDisabled = !!item?.active;

  // Если пропс не передали, считаем, что доступ = true
  const permissionView = canView === undefined ? true : canView;
  const permissionEdit = canEdit === undefined ? true : canEdit;
  const permissionDelete = canDelete === undefined ? true : canDelete;

  const viewFunction = () => {
    setOpenPopover(false);
    if (onRow) {
      onRow(item, itemIndex);
    }
  };

  const handleEdit = (row: any) => {
    setOpenPopover(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    editRow ? editRow(row) : null;
  };

  const handleDelete = (row: any) => {
    setOpenPopover(false);
    if (!row.delete) {
      setConfirmation(true);
    } else {
      if (deleteRow) {
        deleteRow(row);
      }
    }
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (newOpen) {
      setOpenContext(false);
    }
    setOpenPopover(newOpen);
  };

  const getAllValues = (obj: any) => {
    let values: any = [];
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        values = values.concat(getAllValues(obj[key]));
      } else {
        values.push(obj[key]);
      }
    }
    return values;
  };

  const handleCopy = async (row: any, column: any) => {
    setOpenPopover(false);
    if (row?.id) {
      const set = new Set();
      set.add(row.id);
      handleCopyAll(set);
    }
  };

  const items: MenuProps["items"] = [
    handleCopyAll
      ? {
          label: (
            <div onClick={() => handleCopyAll(false)}>
              {t("Копировать выделенное")}
            </div>
          ),
          key: "1",
          disabled: isDisabled,
        }
      : null,
    handleDeleteCopyAll
      ? {
          label: (
            <div onClick={handleDeleteCopyAll}>{t("Удалить выделенное")}</div>
          ),
          key: "2",
          disabled: isDisabled,
        }
      : null,
    onUploadFileAll
      ? {
          label: (
            <div onClick={onUploadFileAll}>
              {t("Выгрузить выделенное в файл")}
            </div>
          ),
          key: "3",
          disabled: isDisabled || !onUploadFileAll,
        }
      : null,
    handlerAllUpdate
      ? {
          label: (
            <div onClick={handlerAllUpdate}>{t("Массовое изменение")}</div>
          ),
          key: "4",
          disabled: isDisabled || !handlerAllUpdate,
        }
      : null,
  ];

  const openChangeContext = (open: boolean) => {
    setOpenContext(open);
    setOpenPopover(false);
  };

  const onCloseModalConfirm = () => {
    setConfirmation(false);
  };

  const onOkModalConfirm = (row: any) => {
    setConfirmation(false);
    if (deleteRow) {
      deleteRow(row);
    }
  };

  const renderTitle = (
    type: string | undefined,
    refTitle: string | string[] | undefined,
    column?: IColumnType<any>
  ) => {
    if (type === "date" && refTitle) {
      // Parse the ISO 8601 date string
      const date = moment(refTitle, "DD.MM.YYYY");
      if (date.isValid()) {
        // Format the date according to 'DD.MM.YYYY'
        return date.format("DD.MM.YYYY");
      } else {
        return moment(refTitle).format("DD.MM.YYYY");
      }
    }
    return refTitle || "";
  };

  const menuItems: MenuProps["items"] = [];

  if (openPopover) {
    if (permissionView) {
      menuItems.push({
        key: "link1",
        label: (
          <Typography.Text className={style.menu__item} disabled={isDisabled}>
            {t("Посмотреть")}
          </Typography.Text>
        ),
        onClick: viewFunction,
        disabled: isDisabled,
      });
    }

    if (permissionEdit && !item?.delete) {
      menuItems.push({
        key: "link4",
        label: (
          <Typography.Text
            className={style.menu__item}
            disabled={!!item?.delete || isDisabled}
          >
            {t("Редактировать")}
          </Typography.Text>
        ),
        onClick: () => handleEdit(item),
        disabled: !!item?.delete || isDisabled,
      });
    }

    if (permissionView && !item?.delete) {
      menuItems.push({
        key: "link2",
        label: (
          <Typography.Text className={style.menu__item} disabled={isDisabled}>
            {t("Копировать данные")}
          </Typography.Text>
        ),
        onClick: () => handleCopy(item, column),
        disabled: isDisabled,
      });
    }

    if (permissionDelete) {
      menuItems.push({
        key: "link3",
        label: (
          <Typography.Text className={style.menu__item} disabled={isDisabled}>
            {!item.delete ? t("Удалить") : t("Снять пометку на удаление")}
          </Typography.Text>
        ),
        onClick: () => handleDelete(item),
        disabled: isDisabled,
      });
    }

    if (subMenu) {
      menuItems.push(
        subMenu
          ? {
              key: "link5",
              disabled: !!item?.delete || isDisabled,
              popupClassName: style.menu__submenu,
              popupOffset: [20, 0],
              label: (
                <Typography.Text
                  className={style.menu__item}
                  disabled={!!item?.delete || isDisabled}
                >
                  {t(subMenu.label)}
                </Typography.Text>
              ),
              children: subMenu.getSubMenu({
                item,
                rows: selectedRows,
                data,
                onClose: () => handleOpenChange(false),
              }),
            }
          : {
              key: "link5",
              label: null,
              style: { display: "none" },
            }
      );
    }
  }

  return (
    <>
      <Dropdown
        menu={{ items }}
        open={openContext}
        trigger={["contextMenu"]}
        onOpenChange={openChangeContext}
        disabled={isDisabled}
      >
        <TableCell>
          {column.render ? (
            column.render(column, item, itemIndex)
          ) : (
            <Flex align={"center"}>
              <Flex align={"center"} onClick={(e) => e.stopPropagation()}>
                {checkbox && !isDisabled ? (
                  !item.delete ? (
                    <Checkbox
                      stopChange
                      isChoice={isChecked}
                      onChange={onCheck}
                    />
                  ) : (
                    <Icon
                      component={DeleteRed20}
                      style={{ cursor: "default" }}
                    />
                  )
                ) : (
                  <div style={{ minWidth: "20px" }} />
                )}
              </Flex>
              <Flex align={"center"}>
                {menu ? (
                  <Dropdown
                    // overlayClassName={style.menu}
                    // open={openPopover}
                    // onOpenChange={handleOpenChange}
                    // menu={{
                    //   items: openPopover
                    //     ? [
                    //         {
                    //           key: "link1",
                    //           label: (
                    //             <Typography.Text
                    //               className={style.menu__item}
                    //               disabled={isDisabled}
                    //             >
                    //               {t("Посмотреть")}
                    //             </Typography.Text>
                    //           ),
                    //           onClick: viewFunction,
                    //           disabled: isDisabled,
                    //         },
                    //         {
                    //           key: "link4",
                    //           disabled: !!item?.delete || isDisabled,
                    //           label: (
                    //             <Typography.Text
                    //               className={style.menu__item}
                    //               disabled={!!item?.delete || isDisabled}
                    //             >
                    //               {t("Редактировать")}
                    //             </Typography.Text>
                    //           ),
                    //           onClick: () => handleEdit(item),
                    //         },
                    //         {
                    //           key: "link2",
                    //           label: (
                    //             <Typography.Text
                    //               className={style.menu__item}
                    //               disabled={!!item?.delete || isDisabled}
                    //             >
                    //               {t("Копировать данные")}
                    //             </Typography.Text>
                    //           ),
                    //           disabled: !!item?.delete || isDisabled,
                    //           onClick: () => handleCopy(item, column),
                    //         },
                    //         {
                    //           key: "link3",
                    //           label: (
                    //             <Typography.Text
                    //               className={style.menu__item}
                    //               disabled={isDisabled}
                    //             >
                    //               {!item.delete
                    //                 ? t("Удалить")
                    //                 : t("Снять пометку на удаление")}
                    //             </Typography.Text>
                    //           ),
                    //           onClick: () => handleDelete(item),
                    //           disabled: isDisabled,
                    //         },
                    //         subMenu
                    //           ? {
                    //               key: "link5",
                    //               disabled: !!item?.delete || isDisabled,
                    //               popupClassName: style.menu__submenu,
                    //               popupOffset: [20, 0],
                    //               label: (
                    //                 <Typography.Text
                    //                   className={style.menu__item}
                    //                   disabled={!!item?.delete || isDisabled}
                    //                 >
                    //                   {t(subMenu.label)}
                    //                 </Typography.Text>
                    //               ),
                    //               children: subMenu
                    //                 ? subMenu.getSubMenu({
                    //                     item,
                    //                     rows: selectedRows,
                    //                     data,
                    //                     onClose: () => handleOpenChange(false),
                    //                   })
                    //                 : [],
                    //             }
                    //           : {
                    //               key: "link5",
                    //               label: null,
                    //               style: { display: "none" },
                    //             },
                    //       ]
                    //     : [],
                    // }}
                    // placement="bottomLeft"
                    // trigger={["click"]}
                    menu={{ items: menuItems }}
                    open={openPopover}
                    onOpenChange={setOpenPopover}
                    placement="bottomLeft"
                    trigger={["click"]}
                    disabled={isDisabled}
                  >
                    <Typography.Link
                      className={style.menu__action}
                      style={{ padding: "4px 0 0 15px" }}
                      disabled={isDisabled}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpenPopover(true);
                      }}
                    >
                      <Icon component={burgerIcon} />
                    </Typography.Link>
                  </Dropdown>
                ) : null}
                <Flex gap={6} align={"center"}>
                  <Flex>
                    {typeof column.avatar !== "undefined" ? (
                      avatar === null || avatar === undefined ? (
                        <Avatar size={36}>
                          {title
                            ?.substring(0, 2)
                            .toUpperCase()
                            .replace(/['"]+/g, "")}
                        </Avatar>
                      ) : (
                        <Avatar
                          size={36}
                          src={avatar}
                          icon={<UserOutlined />}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </Flex>
                  <Flex vertical>
                    <TruncatedText>
                      {column.link ? (
                        <Typography.Paragraph
                          ellipsis={{
                            tooltip: { placement: "topLeft" },
                            rows: 1,
                          }}
                          style={{
                            color: "#0148D7",
                            maxWidth: "250px",
                            margin: 0,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (onClickLink) {
                              if (isDisabled) return;
                              onClickLink(item, columnIndex);
                            }
                          }}
                        >
                          {refTitle}
                        </Typography.Paragraph>
                      ) : columnIndex ? (
                        renderTitle(column.type, refTitle, column)
                      ) : null}
                      {column.dataIndex === "repair" &&
                        item.repair === true && <Icon component={RepairIcon} />}
                      <div>{subTitle}</div>
                    </TruncatedText>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )}
          <ModalConfirm
            isOpen={confirmation}
            closeModal={onCloseModalConfirm}
            actionAfterConsent={() => onOkModalConfirm(item)}
          />
        </TableCell>
      </Dropdown>
    </>
  );
}
