import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  App,
  Avatar,
  Badge,
  Divider,
  Flex,
  Popover,
  Tooltip,
  Typography,
} from 'antd';

import Icon, { CloseOutlined } from '@ant-design/icons';

import { USER_NOTIFICATIONS, Utils } from '../../utils';
import { RootState } from '../../store';
import IconButton from '../ui/IconButton';
import styles from './Notification.module.scss';
import { INotificationList } from '../../types';
import ViewRow from '../ui/List/ViewRow/ViewRow';
import { notificationIcon } from '../../assets/image/svg';
import { getSearchLink } from '../../helpers/link-helper';
import { setItemNotification, setListNotification } from '../../store/store';
import { useParamsHistory } from '../../hooks';

interface INotification {
  isShow: boolean;
}

export const Notification: React.FC<INotification> = ({ isShow }) => {
  const { t } = useTranslation();
  const {location} = useParamsHistory();
  const {pathname} = location;
  const dispatch = useDispatch();
  const { notification } = App.useApp();

  const list = useSelector((state: RootState) => state.stores.listNotification);

  const item = useSelector((state: RootState) => state.stores.itemNotification);

  const [count, setCount] = useState<number | null>(10);

  const [content, setContent] = useState<any>([]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let show = true;
    if (item && show) {
      const arr = Utils.getStorage(USER_NOTIFICATIONS) as string;
      if (JSON.stringify(list) !== arr) {
        Utils.setStorage(USER_NOTIFICATIONS, JSON.stringify(list));
      }
      show = false;
      notification.open({
        key: 'notifications-1',
        message: item?.title,
        description: item?.subtitle,
        duration: 3,
        pauseOnHover: true,
        placement: 'topLeft',
        closeIcon: <CloseOutlined />,
        onClose: () => {
          dispatch(setItemNotification(null));
        },
      });
    }
  }, [item]);

  useEffect(() => {
    const arr = Utils.getStorage(USER_NOTIFICATIONS) as string;
    if (arr) {
      const result = Utils.checkIsJson(arr) ? Utils.checkIsJson(arr) : [];
      dispatch(setListNotification(result));
    }
  }, []);

  useEffect(() => {
    const current = list?.length ? list.filter((el) => el?.isNew).length : 0;
    setCount(current);
  }, [JSON.stringify(list)]);

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [pathname])

  const onClose = () => {
    setCount(null);
    setIsOpen(false);
    dispatch(setItemNotification(null));
  };

  const resetNotification = () => {
    const clearNewItems = list?.map((el) => ({ ...el, isNew: false }));
    Utils.setStorage(USER_NOTIFICATIONS, JSON.stringify(clearNewItems));
    dispatch(setListNotification(clearNewItems));
  };

  const descriptions = (arr: INotificationList[]) => (
    <div className={styles['notification__list']}>
      <Flex justify="space-between" align="center" gap={30}>
        <Typography.Paragraph className={styles['notification__title']}>
          {t('Список уведомлений')}
        </Typography.Paragraph>
        <IconButton
          className={styles['notification__close']}
          iType="close"
          onClick={onClose}
        />
      </Flex>
      <Divider />
      <div
        className="all-custom-v-scroll"
        style={{
          maxHeight: '70vh',
          overflow: 'auto',
        }}
      >
        <Flex
          gap={10}
          style={{
            flexDirection: 'column-reverse',
            minWidth: '300px',
          }}
        >
          {arr?.length ? (
            arr?.map((el, i) => (
              <ViewRow
                key={i}
                title={el?.title}
                subtitle={el?.subtitle}
                link={
                  el?.link ? getSearchLink({ ...el.link, noUrl: true }) : ''
                }
                isDivider
              />
            ))
          ) : (
            <ViewRow title={t('список пуст')} />
          )}
        </Flex>
      </div>
    </div>
  );

  const openAllNotifications = () => {
    resetNotification();
    setContent(descriptions(list));
    setIsOpen(true);
    setCount(null);
  };

  return (
    <div className={styles.container}>
      <Tooltip title="" placement="right">
        <Badge
          className={styles.notification}
          count={count}
          showZero={false}
          overflowCount={9}
        >
          <Avatar
            shape="circle"
            size="large"
            icon={<Icon component={notificationIcon} />}
            onClick={openAllNotifications}
          />
        </Badge>
        <Popover open={isOpen} content={content} />
      </Tooltip>
      {!isShow && <div className={'text-notification'}>{t('Уведомления')}</div>}
    </div>
  );
};
