import React, {useState} from 'react';
import {Col, Flex, Row} from "antd";
import '../../../assets/scss/carousel.scss'
import HeaderScreen from "./HeaderScreen";
import Icon from "@ant-design/icons";
import {arrowLeft, arrowRight} from "../../../assets/image/svg";


interface CarouselProps {
    images?: any,
    onClick?: () => void,
    previewTitle?: string[]
    previewIndex: number;
}

const Carousel: React.FC<CarouselProps> = ({images, onClick, previewTitle, previewIndex = 0}) => {

    const [currentImageIndex, setCurrentImageIndex] = useState(() => previewIndex);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };
    return (
        <>
            <HeaderScreen onClick={onClick} previewTitle={images[currentImageIndex].name}/>
            <div className="carousel">
                <Row className="justify-content-center align-items-center">
                    <Col span={2} className={'box-arrow'}>
                        <Icon component={arrowLeft} onClick={prevImage}>{"<"}</Icon>
                    </Col>
                    <Col span={20}>
                        <Flex justify={"center"} align={"center"}>
                            <img
                                className={'active'}
                                src={images[currentImageIndex]?.url}
                                alt={images[currentImageIndex].name}
                            />
                        </Flex>
                    </Col>
                    <Col span={2} className={'box-arrow'}>
                        <Icon component={arrowRight} onClick={nextImage}>{">"}</Icon>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default Carousel;
