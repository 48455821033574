import { uniqBy } from 'lodash';
import { IContainer, IDeal } from '../../../store/sales';

// для сделок
const SALES = 3; // id в продаже
// мапер для таблицы ктк в сделках
export const getterKtk = (container: IContainer): IContainer => ({
  id: container?.id || undefined,
  num_container: container.num_container,
  container_id: container?.id,
  type_container: container?.type_container,
  status: null,
  status_id: null,
  bet: null,
  price: null,
  payment: '',
  gtd: false,
  issue_date: null,
  departure_date: null,
  delete: false,
  release: container?.release ?? null,
  deal: null,
  edit: false,
});
export const mapperKtk = (containers: any[]): IContainer[] | [] => {
  if (Array.isArray(containers) && containers?.length) {
    return containers.map(getterKtk);
  }
  return [];
};
export const filterResultKtk = (containers: any[]): IContainer[] | [] => {
  if (Array.isArray(containers) && containers?.length) {
    return containers.filter((item) => !item?.deal).map(getterKtk);
  }
  return [];
};

export const mapperCompany = (payload: any[]) => {
  return payload.map((company: any) => ({
    ...company,
    phone: company?.['phone_first'] ?? '',
  }));
};

export const calculationTotalContainer = (containers: IContainer[]) => {
  return (
    containers?.reduce(
      (acc, curr) =>
        acc + Number(curr?.payment || 0) + Number(curr?.price || 0),
      0,
    ) || 0
  );
};

export const getAllReleasesOfContainers = (containers: IContainer[]) => {
  const release = containers?.length ? containers
    ?.map((cont) => cont?.release || null)
    ?.filter((rel) => rel) : [];
  return uniqBy(release, (el) => {
    return el?.id;
  });
};

export const mapperDealSales = (data: IDeal[] | IDeal) => {
  return Array.isArray(data) ? data?.map((deal) => {
    deal.comments = Array.isArray(deal?.comments) ? deal.comments : [];
    deal.containers = mapperFetchKtk(deal?.containers);
    deal.releases = getAllReleasesOfContainers(deal.containers);
    return deal;
  }) : data ? {
    ...data,
    comments : Array.isArray(data?.comments) ? data.comments : [],
    containers: mapperFetchKtk(data?.containers),
    releases: getAllReleasesOfContainers(data.containers),
  } : null;
};

// мапер для таблицы ктк в сделках
export const getterFetchKtk = (container: IContainer): IContainer => ({
  id: container?.id || undefined,
  num_container: container.num_container,
  container_id: container?.id,
  type_container: container?.type_container,
  status: container?.deal ? container?.deal?.status : null,
  status_id: container?.deal ? container?.deal?.status_id : null,
  bet: container?.deal ? container?.deal?.bet : null,
  price: container?.deal ? container?.deal?.price : null,
  payment: container?.deal ? container?.deal?.payment : "",
  gtd: container?.deal ? container?.deal?.gtd : false,
  issue_date: container?.deal ? container?.deal?.issue_date : null,
  departure_date: container?.deal ? container?.deal?.departure_date : null,
  delete: false,
  release: container?.release ?? null,
  deal: container?.deal ?? null,
  edit: false,
  active: container?.active
});
export const mapperFetchKtk = (containers: IContainer[] | IContainer): IContainer[] | [] => {
  if (Array.isArray(containers) && containers?.length) {
    return containers.map(getterFetchKtk);
  }
  if (!Array.isArray(containers) && containers?.id) {
    return [getterFetchKtk(containers)]
  }
  return [];
};
