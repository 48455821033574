import { Avatar, Flex, Typography } from "antd";
import React from "react";
import { useEffect } from "react";
import { checksDatePeriod } from "../../../helpers/date-helpers";
import moment from "moment";
import IconButton from "../../ui/IconButton";
import { Utils } from "../../../utils";
import styles from "../style.module.scss";
import { UserOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useStoreDispatch } from "../../../store";
import { deleteMessage } from "../../../store/chat";
import { IComment, IMessage } from "../../../store/chat";


export interface IItem {
  isWidth?: boolean;
  comment?: IMessage;
  onDelete: () => void;
}

export const ChatItem: React.FC<IItem> = ({ comment, onDelete, isWidth }) => {
  const dispatch = useStoreDispatch();
  const isDelete = false
    
    // comment?.id === Utils.getUserInfo()?.id;

  const data = moment(comment?.createdAt)?.isValid()
    ? moment(comment?.createdAt).format("DD MMM HH:mm")
    : "";

    // Функция для форматирования имени
  const formatName = (user?: {name?: string; last_name?: string}) => {
    return [user?.last_name, user?.name].filter(Boolean).join(' ');
  };

  // В компоненте
  const title = comment?.["user_sender.fio"]
    ? comment?.["user_sender.name"] && comment?.["user_sender.last_name"]
    : 'Неизвестный отправитель';

  const recipientTag = comment?.recipient_tag
    || comment?.["user_recipient.name"]
    || '';


  useEffect(() => {
      console.log('chat data:', comment)
    }, []);


  return (
    <div className={styles["box-comment"]}>
      <Flex className={styles["box-comment__row"]}>
        <Flex
          justify={isWidth ? "space-between" : "flex-start"}
          gap={15}
          className={styles["box-comment__row__message"]}
        >
          <Avatar
            src={comment?.["user_sender.avatar"] || '/default-avatar.png'}
            icon={!comment?.["user_sender.avatar"] && <UserOutlined />}
            alt={title}
          />
          <Flex vertical gap={5} style={isWidth ? { width: "100%" } : {}}>
            <Flex
              justify={isWidth ? "space-between" : "flex-start"}
              align={"baseline"}
              gap={30}
            >
              <Tooltip 
                  title={comment?.["user_sender.email"]}
                  placement="top"
                  overlayClassName={styles.tooltip}
                >
                {/* <Typography.Paragraph className={styles["box-comment__title"]}>
                  {`${comment?.sender_last_name } 
                  ${comment?.sender_name}`}
                </Typography.Paragraph> */}
                <Typography.Paragraph className={styles["box-comment__title"]}>
                  {formatName({
                    name: comment?.["user_sender.name"],
                    last_name: comment?.["user_sender.last_name"]
                  }) || comment?.["user_sender.fio"]}
                </Typography.Paragraph>
              </Tooltip>
            </Flex>
            <Flex>
              <Typography.Paragraph
                className={styles["box-comment__description"]}
                ellipsis={{
                  rows: 4,
                  tooltip: { placement: "topLeft", title: comment?.message_text },
                }}
              >
                {comment?.recipient_tag && (
                  <Tooltip 
                    title={comment?.["user_recipient.email"]}
                    placement="top"
                    overlayClassName={styles.tooltip}
                  >
                    <Typography.Link>
                      {comment.recipient_tag ?? ""}{" "}
                    </Typography.Link>{" "}
                  </Tooltip>
                )}
                {comment?.message_text}
              </Typography.Paragraph>
            </Flex>
          </Flex>
        </Flex>
        <Flex align="center" gap={10}>
          <Typography.Text className={styles["box-comment__data"]}>
            {data}
          </Typography.Text>
          {!isDelete && (
            <IconButton
              className={styles["box-comment__delete"]}
              iType="delete"
              onClick={() => onDelete()}
            />
          )}
        </Flex>
      </Flex>
    </div>
  );
};





































































// import { Avatar, Flex, Typography } from "antd";
// import React from "react";
// import { useEffect } from "react";
// import { checksDatePeriod } from "../../../helpers/date-helpers";
// import moment from "moment";
// import IconButton from "../../ui/IconButton";
// import { Utils } from "../../../utils";
// import styles from "../style.module.scss";
// import { UserOutlined } from "@ant-design/icons";
// import { Tooltip } from "antd";

// export interface IComment {
//   id?: number | null;

//   sender_avatar?: string;
//   sender_fio: string;
//   sender_email: string;
//   sender_id: number | null;
//   sender_name: string;
//   sender_last_name: string;

//   recipient_avatar?: string;
//   recipient_fio: string;
//   recipient_email: string;
//   recipient_id: number | null;
//   recipient_tag: string;
//   recipient_name: string;
//   recipient_last_name: string;

//   createdAt: any;
//   updatedAt: any;
//   message_text: string;
//   isDelete: boolean;
//   table: string;
//   row_id: number;
// }

// export interface IItem {
//   isWidth?: boolean;
//   comment: IComment;
//   onDelete: () => void;
// }

// export const ChatItem: React.FC<IItem> = ({ comment, onDelete, isWidth }) => {
//   const isDelete =
//     checksDatePeriod(comment?.updatedAt || comment?.createdAt) &&
//     comment?.sender_id === Utils.getUserInfo()?.id;
//   const deleteComment = (id?: number | null) => {
//     onDelete();
//   };
//   const data = moment(comment?.createdAt)?.isValid()
//     ? moment(comment?.createdAt).format("DD MMM HH:mm")
//     : "";
//   const title = comment?.sender_fio;
//   const [start, end] = typeof title === "string" ? title.split(" ") : [];
//   const alt = (start?.[0] ?? "") + (end?.[0] ?? "");
//   useEffect(() => {
//       console.log('chat data:', comment)
//     }, []);
//   return (
//     <div className={styles["box-comment"]}>
//       <Flex className={styles["box-comment__row"]}>
//         <Flex
//           justify={isWidth ? "space-between" : "flex-start"}
//           gap={15}
//           className={styles["box-comment__row__message"]}
//         >
//           <Avatar
//             className={styles["box-comment__avatar"]}
//             src={comment?.sender_avatar}
//             gap={5}
//             icon={alt ? null : <UserOutlined />}
//             alt={alt || "icon"}
//           >
//             <UserOutlined />
//           </Avatar>
//           <Flex vertical gap={5} style={isWidth ? { width: "100%" } : {}}>
//             <Flex
//               justify={isWidth ? "space-between" : "flex-start"}
//               align={"baseline"}
//               gap={30}
//             >
//               <Tooltip 
//                   title={comment.sender_email}
//                   placement="top"
//                   overlayClassName={styles.tooltip}
//                 >
//                 <Typography.Paragraph className={styles["box-comment__title"]}>
//                   {/* {comment?.sender_fio} */}
//                   {`${comment?.sender_last_name } 
//                   ${comment?.sender_name}`}
//                 </Typography.Paragraph>
//               </Tooltip>
//             </Flex>
//             <Flex>
//               <Typography.Paragraph
//                 className={styles["box-comment__description"]}
//                 ellipsis={{
//                   rows: 4,
//                   tooltip: { placement: "topLeft", title: comment?.message_text },
//                 }}
//               >
//                 <Tooltip 
//                   title={comment.recipient_email}
//                   placement="top"
//                   overlayClassName={styles.tooltip}
//                 >
//                   <Typography.Link>
//                     {comment?.recipient_tag ?? ""}{" "}
//                   </Typography.Link>{" "}
//                 </Tooltip>
//                 {comment?.message_text}
//               </Typography.Paragraph>
//             </Flex>
//           </Flex>
//         </Flex>
//         <Flex align="center" gap={10}>
//           <Typography.Text className={styles["box-comment__data"]}>
//             {data}
//           </Typography.Text>
//           {!isDelete && (
//             <IconButton
//               className={styles["box-comment__delete"]}
//               iType="delete"
//               onClick={() => deleteComment(comment?.id)}
//             />
//           )}
//         </Flex>
//       </Flex>
//     </div>
//   );
// };