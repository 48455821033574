import { Button, Flex, Form, Typography } from 'antd';
import React, { useEffect } from 'react';
import TooltipButton from '../../components/ui/TooltipButton/TooltipButton';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../components/ui/Checkbox/Checkbox';
import { mapperListCompany, mapperSelect } from '../../helpers/mappers-helpers';
import { findCompany } from '../../store/contractors';
import { RootState, useStoreDispatch } from '../../store';
import { useSelector } from 'react-redux';
import { IAllUpdate } from '../../store/containers';
import {
  disableDateBeforeToday,
  getDateSubmitString,
} from '../../helpers/date-helpers';
import { FormTable } from '../../components/ui/Form';
import { IColumnsEditTable } from '../../components/ui/Form/FormTable/FormTable';
import { IContainers } from '../../types/containers';
import { MIN_CURRENCY_PRICE } from '../../constants';
import { Rest_terminal } from '../../services/rest_terminal';
import { Rest_location } from '../../services/rest_location';

const mapperKtk = (containers: IContainers[]) => {
  if (!containers?.length) return [];
  return containers
    .filter((con) => !!con.id && !con?.active && !con.delete)
    .map((container) => ({
      id: container?.id, // айдишники контейнеров
      status_id: container?.status?.id || null, // Текущий статус ктк
      num_container: container?.num_container, // Номер ктк
      stock: container?.terminalStock?.label, //  Сток
      stock_id: container?.terminalStock?.value, //  Сток
      terminal_id: container?.terminal?.id, //  id терминала
      location_id: Number(container?.location?.id), //  id родителя
      terminal: container?.terminal?.name, //  имя терминала
      terminalStock: container?.terminalStock ? [container.terminalStock] : [], //  Сток
      booking_before: container?.booking_before, //  Бронь До
      repair: container?.repair, //   "В ремонте"
      owner_id: container?.owner_id, // Собственник
      currency: container?.currency, // валюта
      owner: container?.contractors?.name_company
        ? container.contractors.name_company
        : container?.['contractors.name_company']
        ? container['contractors.name_company']
        : '', // Собственник
      quality: container?.quality, // Состояние
      currency_price: container?.currency_price, // Входящая ставка
      comment: container?.comment, // Комментарий
    }));
};

interface IFormUpdateContainer {
  onSubmit: (val: any) => void;
  onClose: () => void;
  selectedKtk: IContainers[];
  typeForm: string;
  width?: number;
}
const FormUpdateContainer: React.FC<IFormUpdateContainer> = ({
  onClose,
  onSubmit,
  selectedKtk,
  typeForm,
  width,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const containers: any = Form.useWatch('containers', {
    form,
    preserve: true,
  });

  const dataКТКStatus = useSelector(
    (state: RootState) => state.containers.dataStatusContainer,
  );
  const qualityData = useSelector(
    (state: RootState) => state.types.dataTypeQuality,
  );
  const currencyData = useSelector(
    (state: RootState) => state.types.dataTypeCurrency,
  );

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ containers: mapperKtk(selectedKtk) });
  }, [form, selectedKtk, typeForm]);

  const checkSubmit = (value: { containers: IAllUpdate[] }) => {
    console.log(value);
    value.containers?.forEach((cont) => {
      delete cont.num_container;
      delete cont.terminal;
      delete cont.terminalStock;
      delete cont.stock;
      cont.booking_before = getDateSubmitString({ date: cont?.booking_before });
    });
    onSubmit(value.containers);
    form.resetFields();
  };

  const columns: IColumnsEditTable[] = [
    {
      isField: false,
      width: 30,
      title: '',
      dataIndex: 'action-delete',
      isDelete: true, // для удаления
    },
    {
      isField: false,
      width: 50,
      title: '№',
      dataIndex: 'index', // выводит номер
    },
    {
      isField: false,
      width: 200,
      title: '№KTK',
      dataIndex: 'num_container',
    },
    {
      isField: true,
      noFocus: true,
      width: 200,
      title: 'Текущий статус',
      dataIndex: 'status_id',
      type: 'select',
      placeholder: 'Бронь',
      source: mapperSelect({
        items: dataКТКStatus,
        keys: { label: 'name', value: 'id' },
      })?.filter((item) =>
        [1, 2, 3, 31, 32, 33, 34, 35].includes(item?.value),
      ),
      selectKey: 'value',
    },
    {
      isField: false,
      noFocus: true,
      width: 80,
      title: 'В ремонте',
      dataIndex: 'repair',
      placeholder: '',
      render: (value, record, index) => (
        <Checkbox
          isChoice={value}
          customChange={(val) => {
            const item = containers[index];
            item.repair = val;
            form.setFieldValue(['containers', index], item);
          }}
        />
      ),
    },
    {
      isField: true,
      noFocus: true,
      width: 150,
      title: 'Бронь до',
      dataIndex: 'booking_before',
      type: 'date',
      placeholder: 'Дата',
      propsOptions: {
        disabledDate: disableDateBeforeToday,
        format: 'DD.MM.YYYY',
      },
    },
    {
      isField: true,
      noFocus: true,
      width: 200,
      title: 'Терминал',
      dataIndex: 'terminal_id',
      addKey: 'location_id',
      keyDefault: 'terminal',
      type: 'select-search',
      mapperRes: (res) => res?.data,
      mapperList: (res) =>
        res?.map((el: any) => ({
          ...el,
          selectable: true,
        })),
      placeholder: 'Терминал',
      apiSearch: (text: string, index: any) => {
        return Rest_location.searchLocationName({ text });
      },
    },
    {
      isField: true,
      noFocus: true,
      width: 200,
      title: 'Сток',
      dataIndex: 'stock',
      addKey: 'stock_id',
      connectionKey: 'terminalStock',
      type: 'search',
      mapperRes: (res) => res?.data,
      mapperList: (arr) => arr?.map((el) => ({ ...el, value: el?.label })),
      placeholder: 'Без стока',
      apiSearch: (text: string, index: any) => {
        const id = containers[index]?.['terminal_id'];
        return Rest_terminal.findStock({ text, id, isEmpty: !id });
      },
    },
    {
      isField: true,
      noFocus: true,
      width: 150,
      title: 'Входящая ставка',
      dataIndex: 'currency_price',
      type: 'number',
      placeholder: 'Входящая ставка',
      propsOptions: {
        min: MIN_CURRENCY_PRICE,
      },
    },
    {
      isField: true,
      noFocus: true,
      width: 150,
      title: 'Валюта',
      dataIndex: 'currency',
      type: 'select',
      placeholder: 'Валюта',
      source: currencyData,
      selectKey: 'symbol',
    },
    {
      isField: true,
      noFocus: true,
      width: 150,
      title: 'Состояние',
      dataIndex: 'quality',
      type: 'select',
      source: qualityData,
      placeholder: 'Состояние',
      selectKey: 'value',
    },
    {
      isField: true,
      noFocus: true,
      width: 200,
      title: 'Собственник',
      dataIndex: 'owner',
      addKey: 'owner_id',
      type: 'search',
      apiSearch: (text: any) =>
        dispatch(findCompany(text?.toLocaleLowerCase())),
      mapperList: mapperListCompany,
      placeholder: 'Собственник',
    },
    {
      isField: true,
      noFocus: true,
      width: 150,
      title: 'Комментарий',
      dataIndex: 'comment',
      type: 'input',
      placeholder: 'Комментарий',
    },
  ];
  return (
    <Form
      noValidate
      layout="vertical"
      autoComplete="off"
      onFinish={checkSubmit}
      scrollToFirstError
      form={form}
      initialValues={{}}
      style={{ padding: '10px 20px', minHeight: '300px', display: 'flex' }}
    >
      <Flex vertical gap={20} justify="space-between" flex={1}>
        <Typography.Title level={5}>{t('Массовое изменение')}</Typography.Title>
        <div
          style={{
            minHeight: '80vh',
            maxWidth: width ? width - 50 + 'px' : '1150px',
            padding: '0 10px',
          }}
        >
          <FormTable
            noStyleList={['terminal', 'terminal_id']}
            scroll={
              selectedKtk?.length > 20 ? { x: 1120, y: 55 * 13 } : { x: 1100 }
            }
            tableLayout="fixed"
            isLoading={!!selectedKtk?.length}
            defaultColumns={columns}
            name="containers"
          />
        </div>
        <Form.Item>
          <Flex gap={8} justify="end" style={{ margin: '10px' }}>
            <TooltipButton
              propsTooltip={{
                title: t('Сохранить изменения'),
              }}
              propsButton={{
                type: 'primary',
                htmlType: 'submit',
                style: { maxWidth: '290px' },
              }}
            >
              {t('Сохранить изменения')}
            </TooltipButton>

            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              type={'text'}
              style={{ maxWidth: '134px', color: '#E14453' }}
            >
              {t('Отменить')}
            </Button>
          </Flex>
        </Form.Item>
      </Flex>
    </Form>
  );
};

export default FormUpdateContainer;
