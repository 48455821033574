import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import ru from "react-phone-input-2/lang/ru.json";
import Icon, {
  DeleteOutlined,
  PlusOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Utils } from "../../utils";
import { RootState } from "../../store";
import { messagesModal, MIN_CURRENCY_PRICE, TERMINAL } from "../../constants";
import { CREATE } from "../../helpers/string-helpers";
import ModalConfirm from "../../components/ui/ModalConfirm/ModalConfirm";
import { SelectSearch } from "../../components/ui/SelectSearch/SelectSearch";
import "react-phone-input-2/lib/style.css";
import "./style.scss";
import { useLink } from "../../hooks";
import IconButton from "../../components/ui/IconButton";
import { CopyBufferIconDefault } from "../../assets/image/svg";
import CopyButton from "../../components/ui/CopyButton/CopyButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormInputNumber } from "../../components/ui/Form";
import { mapperSelect } from "../../helpers/mappers-helpers";
import { ITerminal } from "../../types/terminal";
import { Rest_location } from "../../services/rest_location";
import { IDataCity } from "../../store/terminals";

const { title: warningTitle, subtitle } = messagesModal.warning;

export const titleErrorParent =
  'Ошибка: "Родитель" не соответствует адресу терминала!';

const defaultStock = { label: "", delete: false };

interface Props {
  title?: string;
  dataCityRedux?: any;
  defaultCity?: string;
  onFinishAddTerminal?: (data: any, open: boolean) => void | undefined;
  setIsModalOpenForm?: (
    value?: ((prevState: boolean) => boolean) | boolean
  ) => void;
  dataRow?: ITerminal;
  showMapEdit?: boolean;
  isCopy?: boolean;
  form: FormInstance<any>;
  typeForm?: string | null;
  nameTerminal?: string | undefined | number;
  onClose: () => void;
  onView?: () => void;
  canEdit?: boolean;
}

const FormModalTerminal: React.FC<Props> = ({
  title: titleForm,
  isCopy,
  dataCityRedux,
  onFinishAddTerminal,
  setIsModalOpenForm,
  dataRow,
  form,
  typeForm,
  nameTerminal,
  onClose,
  onView,
  canEdit,
}) => {
  const link = useLink({ key: TERMINAL, id: dataRow?.id as number });
  const { t } = useTranslation();

  const lang = useSelector((state: RootState) => state.stores.lang);

  const dataCurrency = useSelector(
    (state: RootState) => state.types.dataTypeCurrency
  );

  const [confirmation, setConfirmation] = useState<boolean>(false);

  const [nameCity, setNameCity] = useState<any>([]);
  const [terminals, setTerminals] = useState<
    { label: string; value: number }[]
  >([]);

  const [city, setCity] = useState<any[]>([]);

  const [searchCity, setSearchCity] = useState<string | null>(null);

  const [defaultCity, setDefaultCity] = useState<string | null>(null);

  const stocks: {
    label: string;
    id?: number;
    terminal_id?: number;
    delete?: boolean;
  }[] = Form.useWatch("stocks", {
    form,
    preserve: true,
  }) || [defaultStock];

  useEffect(() => {
    setCity(dataRow?.parent ? [dataRow?.parent] : []);
    setNameCity(dataRow?.short_address);
    setDefaultCity(dataRow?.parent?.label ?? "");
    form.setFieldsValue({
      name: dataRow?.name,
      parent_id: dataRow?.parent?.id,
      address_terminal: dataRow?.address_terminal,
      director: dataRow?.director,
      dispatcher: dataRow?.dispatcher,
      phone_director:
        !Array.isArray(dataRow) && dataRow?.phone_director !== null
          ? dataRow?.phone_director
          : "+7",
      last_phone_director: dataRow?.last_phone_director,
      last_phone_dispatcher: dataRow?.last_phone_dispatcher,
      phone_dispatcher:
        !Array.isArray(dataRow) && dataRow?.phone_dispatcher !== null
          ? dataRow?.phone_dispatcher
          : "+7",
      capacity: dataRow?.capacity,
      stocks:
        Array.isArray(dataRow?.stocks) && dataRow?.stocks?.length
          ? dataRow?.stocks
          : [defaultStock],
      text: dataRow?.stock_model?.text,
      currency_id: dataRow?.currency_id || null,
      currency_price: dataRow?.currency_price || null,
    });
  }, [typeForm, titleForm, dataRow?.id, dataRow?.parent_id, form]);

  const onFinishForm = async (data: any) => {
    data.id = dataRow?.id;
    data.last_phone_director =
      data?.last_phone_director?.length > 0 ? data?.last_phone_director : null;
    data.last_phone_dispatcher =
      data?.last_phone_dispatcher?.length > 0
        ? data?.last_phone_dispatcher
        : null;
    data.short_address = nameCity;
    data.phone_director =
      data?.phone_director === "+7" ? null : data?.phone_director;
    data.phone_dispatcher =
      data?.phone_dispatcher === "+7" ? null : data?.phone_dispatcher;
    data.stock_id_instruction = dataRow?.stock_model?.id || null;

    data.responsible_id = Utils.getUserInfo()?.id;
    setCity([]);
    setNameCity("");
    setDefaultCity("");
    if (data?.stocks?.length === 1) {
      data.stocks = data.stocks?.filter((el: any) => el.label?.trim());
    }

    if (onFinishAddTerminal) {
      onFinishAddTerminal(data, false);
    }
  };

  const handleSelectParent = (id: number, record: any, parent: IDataCity) => {
    if (parent && id) {
      setDefaultCity(parent?.label);
      setNameCity(parent?.label);
      form.setFieldValue("parent_id", Number(id));
      setTerminals(parent?.children);
    } else {
      setNameCity("");
      setDefaultCity("");
      form.setFieldValue("parent_id", null);
      setTerminals([]);
    }
  };

  const onNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLocaleLowerCase();
    setSearchCity(value);
    if (!value) {
      setSearchCity(null);
    } else {
      Rest_location.searchLocationName({
        name: value,
      })
        .then((response) => {
          setCity(response.data);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const onCloseForm = () => {
    form.resetFields();
    if (setIsModalOpenForm) {
      setIsModalOpenForm();
    }
    setCity([]);
    setNameCity("");
    setDefaultCity("");
    onClose();
  };

  const checkUniqueNameStock = (name: string, idx: number) => {
    const index = stocks?.findIndex(
      (item, i: number) =>
        item?.label?.toLocaleLowerCase() === name?.toLocaleLowerCase() &&
        i !== idx
    );
    return index < 0;
  };

  const checkUniqueNameTerminal = (name: string) => {
    const index = terminals?.findIndex(
      (item) => item?.label?.toLocaleLowerCase() === name?.toLocaleLowerCase()
    );
    return index < 0;
  };

  //==========================================================
  // если нет права на редактирование не отображаем форму
  // вместо нее станим заглушку
  //==========================================================
  if (!canEdit) {
    return (
      <div style={{ padding: 20, fontSize: 16, textAlign: "center" }}>
        {t("У вас нет прав на редактирование данной страницы")}
      </div>
    );
  }

  return (
    <Space direction={"vertical"}>
      {!!titleForm && (
        <Flex justify={"space-between"} align={"center"}>
          <h2>{titleForm}</h2>
          <Flex gap={20} align={"center"}>
            <Flex gap={5} align={"center"}>
              {!!dataRow?.id && (
                <IconButton iType="view-form" onClick={onView} />
              )}
              {isCopy && (
                <CopyButton
                  text={link}
                  textSuccess={t("Ссылка скопирована")}
                  icon={<Icon component={CopyBufferIconDefault} />}
                />
              )}
            </Flex>
            <IconButton
              iType="close"
              onClick={onClose}
              style={{ color: "currentcolor", outline: "node", border: "none" }}
            />
          </Flex>
        </Flex>
      )}
      {!!titleForm && <Divider style={{ marginTop: 0, marginBottom: 16 }} />}
      <Form
        autoComplete="off"
        onFinish={onFinishForm}
        form={form}
        layout="vertical"
        initialValues={{
          stocks: [defaultStock],
        }}
      >
        <Flex gap={12} vertical>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("Обязательное поле!"),
                    whitespace: true,
                  },
                  {
                    required: true,
                    message: t("Название повторяется"),
                    whitespace: true,
                    validator: (_, val) => {
                      if (!val?.trim()) {
                        return Promise.resolve();
                      }
                      if (checkUniqueNameTerminal(val)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject();
                      }
                    },
                  },
                ]}
              >
                <Input
                  allowClear
                  placeholder={t("Название терминала")}
                  disabled={!!dataRow?.id}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="parent_id"
                rules={[
                  { required: !defaultCity, message: t("Обязательное поле!") },
                ]}
              >
                <SelectSearch
                  dataSource={city}
                  onChangeSelect={handleSelectParent}
                  dropdownRender={onNameChange}
                  placeholder={t("Родитель")}
                  defaults={defaultCity}
                  style={{ width: "100%" }}
                  hiddenBtn={false}
                  disabled={!!dataRow?.id}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="address_terminal"
                rules={[
                  {
                    required: true,
                    message: t("Обязательное поле!"),
                    whitespace: true,
                  },
                ]}
              >
                <Input allowClear disabled={!!dataRow?.id} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item name="director">
                <Input placeholder={t("Руководитель")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Flex vertical gap={12}>
                <Flex gap={12}>
                  <Form.Item name="phone_director">
                    <PhoneInput
                      autoFormat={false}
                      defaultMask={"00000000000000"}
                      placeholder={t("Номер телефона")}
                      prefix="+"
                      enableSearch
                      searchPlaceholder={t("Поиск")}
                      searchNotFound={t("ничего не нашлось")}
                      country={lang === "ru" ? lang : "us"}
                      localization={lang === "ru" ? ru : undefined}
                    />
                  </Form.Item>
                  <Flex
                    gap={5}
                    align="center"
                    style={{
                      padding: "0 6px",
                      minHeight: "32px",
                      maxHeight: "32px",
                    }}
                  >
                    <Form.List name="last_phone_director">
                      {(_fields, { add }) => (
                        <>
                          <DeleteOutlined
                            style={{
                              fontSize: "16px",
                              color: "#D91528",
                              cursor: "pointer",
                            }}
                          />
                          <PlusOutlined
                            style={{
                              fontSize: "16px",
                              color: "#00B288",
                              cursor: "pointer",
                            }}
                            onClick={() => add()}
                          />
                        </>
                      )}
                    </Form.List>
                  </Flex>
                </Flex>
                <Form.List name="last_phone_director">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Flex gap={12} key={key}>
                          <Form.Item
                            {...restField}
                            name={[name, "add_phone_director"]}
                            rules={[
                              {
                                required: true,
                                message: t("Заполните или удалите!"),
                              },
                            ]}
                          >
                            <PhoneInput
                              autoFormat={false}
                              defaultMask={"00000000000000"}
                              placeholder={t("Номер телефона")}
                              prefix="+"
                              enableSearch
                              searchPlaceholder={t("Поиск")}
                              searchNotFound={t("ничего не нашлось")}
                              country={lang === "ru" ? lang : "us"}
                              localization={lang === "ru" ? ru : undefined}
                            />
                          </Form.Item>

                          <Flex
                            gap={5}
                            align="center"
                            style={{
                              padding: "0 6px",
                              minHeight: "32px",
                              maxHeight: "32px",
                            }}
                          >
                            <DeleteOutlined
                              onClick={() => remove(name)}
                              style={{
                                fontSize: "16px",
                                color: "#D91528",
                                cursor: "pointer",
                              }}
                            />
                            <PlusOutlined
                              style={{
                                fontSize: "16px",
                                color: "#00B288",
                                cursor: "pointer",
                              }}
                              onClick={() => add()}
                            />
                          </Flex>
                        </Flex>
                      ))}
                    </>
                  )}
                </Form.List>
              </Flex>
            </Col>

            <Col span={12}>
              <Form.Item name="dispatcher">
                <Input placeholder={t("Диспетчер")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Flex vertical gap={12}>
                <Flex gap={12}>
                  <Form.Item name="phone_dispatcher">
                    <PhoneInput
                      autoFormat={false}
                      defaultMask={"00000000000000"}
                      placeholder={t("Номер телефона")}
                      prefix="+"
                      enableSearch
                      searchPlaceholder={t("Поиск")}
                      searchNotFound={t("ничего не нашлось")}
                      country={lang === "ru" ? lang : "us"}
                      localization={lang === "ru" ? ru : undefined}
                    />
                  </Form.Item>
                  <Flex
                    gap={5}
                    align="center"
                    style={{
                      padding: "0 6px",
                      minHeight: "32px",
                      maxHeight: "32px",
                    }}
                  >
                    <Form.List name="last_phone_dispatcher">
                      {(_fields, { add }) => (
                        <>
                          <DeleteOutlined
                            style={{
                              fontSize: "16px",
                              color: "#D91528",
                              cursor: "pointer",
                            }}
                          />
                          <PlusOutlined
                            style={{
                              fontSize: "16px",
                              color: "#00B288",
                              cursor: "pointer",
                            }}
                            onClick={() => add()}
                          />
                        </>
                      )}
                    </Form.List>
                  </Flex>
                </Flex>
                <Form.List name="last_phone_dispatcher">
                  {(fields, { add, remove }) => (
                    <Flex vertical gap={12}>
                      {fields.map(({ key, name, ...restField }) => (
                        <Flex gap={12} key={key}>
                          <Form.Item
                            {...restField}
                            name={[name, "add_phone_dispatcher"]}
                            rules={[
                              {
                                required: true,
                                message: t("Заполните или удалите!"),
                              },
                            ]}
                          >
                            <PhoneInput
                              autoFormat={false}
                              defaultMask={"00000000000000"}
                              placeholder={t("Номер телефона")}
                              prefix="+"
                              enableSearch
                              searchPlaceholder={t("Поиск")}
                              searchNotFound={t("ничего не нашлось")}
                              country={lang === "ru" ? lang : "us"}
                              localization={lang === "ru" ? ru : undefined}
                            />
                          </Form.Item>
                          <Flex
                            gap={5}
                            align="center"
                            style={{
                              padding: "0 6px",
                              minHeight: "32px",
                              maxHeight: "32px",
                            }}
                          >
                            <DeleteOutlined
                              onClick={() => remove(name)}
                              style={{
                                fontSize: "16px",
                                color: "#D91528",
                                cursor: "pointer",
                              }}
                            />
                            <PlusOutlined
                              style={{
                                fontSize: "16px",
                                color: "#00B288",
                                cursor: "pointer",
                              }}
                              onClick={() => add()}
                            />
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </Form.List>
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="capacity">
                <Input placeholder={t("Вместимость")} />
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <Flex vertical gap={12}>
            <Form.List name="stocks">
              {(fields, { add, remove }) => (
                <Flex
                  vertical
                  gap={12}
                  className="all-custom-v-scroll"
                  style={{
                    paddingTop: "5px",
                    maxHeight: "350px",
                    overflow: "auto",
                  }}
                >
                  {fields.map(({ key, name, ...restField }) => (
                    <Flex key={key} flex={1} gap={5}>
                      <Form.Item
                        {...restField}
                        style={{ width: "100%", flex: 1 }}
                        name={[name, "label"]}
                        rules={[
                          {
                            required: fields?.length > 1,
                            message: t("Обязательное поле!"),
                            whitespace: true,
                          },
                          {
                            required: true,
                            message: t("Название повторяется"),
                            whitespace: true,
                            validator: (_, val) => {
                              if (!val?.trim()) {
                                return Promise.resolve();
                              }
                              if (checkUniqueNameStock(val, name)) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject();
                              }
                            },
                          },
                        ]}
                      >
                        <Input
                          allowClear
                          placeholder={"Сток"}
                          disabled={!!stocks?.[name]?.delete}
                          onBlur={() => {
                            const result = { ...stocks[name] };
                            result.label = result?.label
                              ? result.label?.trim()
                              : "";
                            form.setFieldValue(["stocks", name], result);
                          }}
                        />
                      </Form.Item>

                      <Flex
                        gap={5}
                        align="center"
                        style={{
                          padding: "0 6px",
                          minHeight: "32px",
                          maxHeight: "32px",
                        }}
                      >
                        {stocks?.[name]?.delete ? (
                          <UndoOutlined
                            onClick={() => {
                              const result = { ...stocks[name] };
                              result.delete = false;
                              form.setFieldValue(["stocks", name], result);
                            }}
                          />
                        ) : (
                          <DeleteOutlined
                            onClick={() => {
                              if (stocks?.[name]?.id) {
                                const result = { ...stocks[name] };
                                result.delete = true;
                                form.setFieldValue(["stocks", name], result);
                              } else {
                                remove(name);
                              }
                            }}
                            style={
                              stocks?.length === 1 ||
                              (!stocks?.[name]?.label && stocks?.[name]?.id)
                                ? {
                                    opacity: 0,
                                    visibility: "hidden",
                                    pointerEvents: "none",
                                    fontSize: "16px",
                                    color: "#D91528",
                                    cursor: "default",
                                  }
                                : {
                                    fontSize: "16px",
                                    color: "#D91528",
                                    cursor: "pointer",
                                  }
                            }
                          />
                        )}
                        <PlusOutlined
                          style={{
                            fontSize: "16px",
                            color: "#00B288",
                            cursor: "pointer",
                          }}
                          onClick={() => add(defaultStock, name + 1)}
                        />
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              )}
            </Form.List>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Form.Item label={t("Входящая ставка")} name={"currency_price"}>
                  <FormInputNumber min={MIN_CURRENCY_PRICE} allowClear />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={t("Валюта")} name={"currency_id"}>
                  <Select
                    optionLabelProp="label"
                    options={mapperSelect({ items: dataCurrency })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name={"text"}>
              <Input.TextArea
                autoSize
                placeholder={t("Краткая инструкция")}
                style={{ minHeight: 100 }}
              />
            </Form.Item>
          </Flex>
          <Row gutter={12} style={{ marginTop: 151 }}>
            <Col span={16}>
              <Button
                type={"primary"}
                style={{ width: "100%", background: "#0055FF" }}
                htmlType={"submit"}
              >
                {typeForm === CREATE ? t("Добавить") : t("Редактировать")}
              </Button>
            </Col>
            <Col span={8}>
              <Form.Item>
                <Button
                  type={"text"}
                  style={{ width: "100%", color: "#E14453" }}
                  onClick={() => setConfirmation(true)}
                >
                  Отменить
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Flex>
      </Form>
      <ModalConfirm
        title={warningTitle}
        subtitle={subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={onCloseForm}
      />
    </Space>
  );
};

export default FormModalTerminal;
