import React, { useEffect, useState, useMemo } from 'react';
import './style.scss';
import { Avatar, Button, Flex, Input, Space } from 'antd';
import { useStoreDispatch } from '../../store';
import {
  addCommentContainer,
  deleteCommentContainer,
} from '../../store/containers';
import { Utils } from '../../utils';
import socket from '../../socket';
import moment from 'moment';
import { DeleteRedBtn, depoIcon, MessageIcon } from '../../assets/image/svg';
import { IAddComment } from '../../types/containers';
import { checksDatePeriod } from '../../helpers/date-helpers';
import {
  handlePasteInputText,
  keyHandlerInputText,
} from '../../helpers/input-helpers';
import IconButton from '../../components/ui/IconButton';

interface Comment {
  id: number;
  text: string;
  'user.fio': string;
  createdAt: Date;
}

interface Props {
  rowId?: number;
  dataComment?: any;
  updateParentDataComment?: (newData: any, type: string) => void;
}

const FormModalComments: React.FC<Props> = ({
  rowId,
  dataComment,
  updateParentDataComment,
}) => {
  const dispatch = useStoreDispatch();
  const [textComment, setTextComment] = useState<string>('');
  const [chat, setChat] = useState<Comment[]>([]);

  const handleChangeComment = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setTextComment(evt.target.value);
  };

  const handleAddComment = () => {
    const text = textComment.trim();
    if (text) {
      const newComment = {
        text,
        user_id: Utils.getUserInfo().id,
        container_id: rowId,
      };
      dispatch(addCommentContainer(newComment));
      setTextComment('');
    }
  };

  const handleNewComment = (response: any) => {
    setChat((prevData: any) => [response[0], ...prevData]);
    const localDataComment = [...dataComment];
    localDataComment.push(response[0]);
    if (updateParentDataComment) {
      updateParentDataComment(response[0], 'add');
    }
  };

  const deleteComment = async (commentId: IAddComment) => {
    dispatch(deleteCommentContainer(commentId));
    const updatedData = chat.filter((item: any) => item.id !== commentId);
    setChat(updatedData);
    if (updateParentDataComment) {
      updateParentDataComment(updatedData, 'delete');
    }
  };

  useEffect(() => {
    socket.on('res-add-comment-container', handleNewComment);

    return () => {
      socket.off('res-add-comment-container', handleNewComment);
    };
  }, [rowId]);

  useEffect(() => {
    if (dataComment && dataComment.length === 0) {
      setChat([]);
    } else if (dataComment) {
      setChat([]);
      setChat((prevData) => [...dataComment, ...prevData]);
    }
  }, [dataComment]);

  const renderedChat = useMemo(
    () =>
      chat.map((comment: any, index) => {
        const isDelete =
          checksDatePeriod(comment?.updatedAt || comment?.createdAt) &&
          comment?.user?.id === Utils.getUserInfo()?.id;
        return (
          <div key={index} className="box-comment">
            <Flex gap={5} justify="space-between" align="center">
              <Flex align="center" gap={16}>
                <Avatar src={comment?.user?.avatar} size={36}>
                  <span
                    style={{ fontSize: 20, position: 'relative', bottom: 1 }}
                  >
                    {' '}
                    {comment?.user?.avatar === null &&
                      comment?.user?.fio?.slice(0, 2).toUpperCase()}
                  </span>
                </Avatar>
                <div className="label-card-20">{comment?.user?.fio ?? ''}</div>
              </Flex>
              <Flex align="center">
                <div className="rowChatInfo">
                  {moment(comment['createdAt']).format('DD MMM HH:mm')}
                </div>
                {!isDelete && (
                  <Button
                    type="text"
                    icon={<DeleteRedBtn />}
                    onClick={() => deleteComment(comment.id)}
                  ></Button>
                )}
              </Flex>
            </Flex>
            <div className="rowChat">{comment.text}</div>
          </div>
        );
      }),
    [chat],
  );

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddComment();
    }
  };

  return (
    <>
      <Flex className="form-comments" vertical gap={8}>
        {renderedChat}
      </Flex>
      <Flex className="add-comment-box">
        <Flex style={{ width: '100%' }}>
          <div style={{ width: '100%', position: 'relative' }}>
            <Input
              placeholder="Добавить комментарий"
              onChange={handleChangeComment}
              value={textComment}
              onKeyDown={handleKeyPress}
              allowClear
              style={{paddingRight: '40px'}}

            />
            <Button type="primary" icon={<MessageIcon />} style={{position: 'absolute', top: 0, right: '0', zIndex: 3, height: '31px', width: '31px', background: !textComment?.trim() ? '#808080' : '#05F'}} disabled={!textComment?.trim()} onClick={handleAddComment} />
          </div>
        </Flex>
      </Flex>
    </>
  );
};

export default FormModalComments;
