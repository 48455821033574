import React, { useEffect, useState } from 'react';

import { App, Upload, UploadProps, Button, GetProp } from 'antd';
import classnames from 'classnames';
import { delay, get } from 'lodash';
import { UploadFile } from 'antd/lib/upload';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { CameraOutlined } from '@ant-design/icons';
import {
  checkFileImages,
  fileTypes,
} from '../../../helpers/input-helpers';
import { Rest_files } from '../../../services/rest_files';
import FullScreen from '../FullScreen/FullScreen';
import Carousel from '../FullScreen/Carousel';
import style from './style.module.scss';
import { dataURLtoFile, getFileString } from '../../../helpers/link-helper';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

interface IUploaderDocs {
  buttonDescription?: string;
  className?: string;
  ids?: any[] | null;
  disabled?: boolean;
  isText?: boolean;
  setDataIds?: (value: number[]) => void;
  onChange?: (value: any) => void;
  name?: string;
  value?: any[];
  saveFiles?: any[];
  handlerSaveFiles?: (files: any[]) => void;
  handlerSaveFileString?: (file: any) => void;
  isView?: boolean;
  isOpen?: boolean;
  multiple?: boolean;
  customAddComponent?: React.ReactNode;
  limit?: number;
  size?: number;
  onEdit?: (file: any) => void;
  fileString?: string;
}

export const UploaderPreview: React.FC<IUploaderDocs> = ({
  name,
  limit,
  size,
  setDataIds,
  onChange,
  saveFiles,
  handlerSaveFiles,
  handlerSaveFileString,
  onEdit,
  ids = [],
  fileString = '',
  disabled = false,
  isView = false,
  isOpen = false,
  multiple = false,
}) => {
  const {message} = App.useApp();
  const [beforeFileList, setBeforeFileList] = useState<any[]>([]);
  const [fileListIds, setFileListIds] = useState<{ id: number; uid: string }[]>(
    [],
  );
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewList, setPreviewList] = useState<UploadFile[]>([]);
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (saveFiles && !isOpen) {
      setFileList(saveFiles);
      setBeforeFileList([])
    }
  }, [isOpen, JSON.stringify(saveFiles)])

  useEffect(() => {
    if (!isOpen) return;
    setFileList([]);
    setBeforeFileList([]);
    if (fileString) {
      const reg = /image\/.+;/;
      const arr = fileString.match(reg);
      if (arr?.length) {
        const fileType = arr[0].replace(';', '');
        const fileEnd = fileType?.split('/');
        const file = dataURLtoFile({file: fileString, type: fileType, filename: `file.${fileEnd?.[1] ?? ''}`})

        if (file) {
          const result = [{uid: '1', name: file.name, type: fileType, url: fileString}];
          setFileList(result)
          setPreviewList(result);
        }

      }

    }
    if (ids && ids?.length) {
      const fetchFiles = async () => {
        const getArrayFiles = (data: UploadFile[]) =>
          data.map((item: any) => ({
            id: item.id,
            uid: item.id,
            name: item.name,
            type: item.type,
            status: 'done' as UploadFileStatus,
            url: item.image,
          }));
        try {
          setLoading(true);
          const { data } = await Rest_files.getImageFiles(ids);
          const currentIds = data.map((file: any) => ({
            id: file.id,
            uid: file.id,
          }));
          const listIds = currentIds.map((file: any) => file.id);
          const images = getArrayFiles(data);
          setFileList(images);
          setPreviewList(images);
          setFileListIds(currentIds);
          if (setDataIds) {
            setDataIds(listIds);
          }
        } catch (error) {
          message.error('Не удалось загрузить файл');
        } finally {
          setLoading(false);
        }
      };
      fetchFiles();
    }
  }, [JSON.stringify(ids), isOpen]);

  const onCloseModal = () => setPreviewVisible(false);


  const handleUpload: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
    onError,
    onProgress,
  }: any) => {
    try {

      if (!checkFileImages(file?.type ?? '')) {
        message.error(`Файлы типа ${file?.type} запрещены`, 5);
        return onError(file);
      }
      const isSize = size ? file.size / 1024 / 1024 <= size : true;
      if (!isSize) {
        message.error(`Файл слишком большой`, 5);
        return onError(file);
      }
      const checkFile = fileList?.find(
        (fl) => fl?.name === file?.name && fl?.status === 'done',
      );
      if (checkFile) {
        message.error(`Файл ${file?.name} уже присутствует!`, 5);
        return onError(file);
      }
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64 = reader.result as unknown as Buffer;
        Rest_files.submitImageFiles({
          file: base64,
          name: file.name,
          type: file?.type,
        })
          .then((res) => {
            if (setDataIds) {
              setDataIds([...fileListIds.map(({ id }) => id), res.data]);
            }
            setFileListIds((prev) => [
              ...prev,
              { id: res.data, uid: file.uid },
            ]);
            onSuccess(file);
            message.success('Файл сохранен');
          })
          .catch((error) => {
            onError(error);
          });
      };
      reader.onerror = (error) => {
        message.error('Ошибка при загрузке файла');
        onError(error);
      };
    } catch (error) {
      message.error('Ошибка при загрузке файла');
      onError(error);
    }
  };

  const dummyRequest: UploadProps['customRequest'] = async ({
    file,
    onSuccess,
    onError,
  }: any) => {
    try {
      if (!checkFileImages(file?.type ?? '')) {
        message.error(`Файлы типа ${file?.type} запрещены`, 5);
        return onError(file);
      }
      const isSize = size ? file.size / 1024 / 1024 <= size : true;
      if (!isSize) {
        message.error(`Файл слишком большой`, 5);
        return onError(file);
      }
      const checkList = [...beforeFileList, ...fileList];
      const checkFile = checkList?.find(
        (fl) => fl?.name === file?.name && fl?.status === 'done',
      );
      if (checkFile) {
        message.error(`Файл ${file.name} уже присутствует!`, 5);
        return onError(file);
      }
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const base64 = reader.result as unknown as Buffer;
        setBeforeFileList((prev) => {
          const result = [
            {
              url: base64,
              image: base64,
              name: file.name,
              type: file.type,
            },
            ...prev,
          ];
          return result;
        });

        if (handlerSaveFileString) {
          handlerSaveFileString(base64);
        }

        delay(() => {
          onSuccess('ok');
        }, 1000);
      };
      reader.onerror = (error) => {
        message.error('Ошибка при загрузке файла');
        onError(error);
      };
    } catch (error) {
      message.error('Ошибка при загрузке файла');
      onError(error);
    }
  };

  const handleChange: UploadProps['onChange'] = ({
    fileList: newFileList,
    file,
  }) => {
    setFileList(newFileList);
    if (handlerSaveFiles) {
      handlerSaveFiles(beforeFileList);
    }

    if (file.status === 'uploading') {
      if (!checkFileImages(file?.type ?? '')) {
        message.error('Недопустимый тип файла');
        return;
      }
      if (fileList.find(({ uid }) => uid === file.uid)) {
        message.error('Такой файл уже есть');
        return;
      }
    }

    const result = newFileList.filter((file) => !file.error);

    if (file.status === 'done' || file.error) {
      setFileList(result);
    }
  };

  const handlePreview: UploadProps['onPreview'] = async (
    file: any,
    ...props
  ) => {
    const images = [...beforeFileList, ...fileList]?.filter((el) => el?.url);
    setPreviewList(images);
    const index = images?.findIndex((el) => el?.name === file?.name);
    setPreviewIndex(index >= 0 ? index : 0);
    setPreviewVisible(true);
  };

  const handleRemove = (file: any) => {
    const filesIds = fileListIds.filter(({ uid }) => uid !== file?.uid);
    const resultId = filesIds.map(({ id }) => id);
    setFileListIds((prev) => prev.filter(({ uid }) => uid !== file?.uid));
    if (onChange) {
      onChange(resultId);
    }
    if (setDataIds) {
      setDataIds(resultId);
    }
    setFileList((prev) => prev.filter(({ uid }) => uid !== file?.uid));
    const result = beforeFileList.filter(
      ({ name }) => name !== file?.name,
    );
    setBeforeFileList(result);
    if (handlerSaveFiles) {
      handlerSaveFiles(result);
    }
    if (handlerSaveFileString) {
      handlerSaveFileString('');
    }
  };

  return (
    <>
      <Upload
        className={classnames(style['upload'], {[style['upload-view']]: isView })}
        name={name}
        accept={fileTypes.image}
        customRequest={handlerSaveFiles || handlerSaveFileString ? dummyRequest : handleUpload}
        listType={'picture-card'}
        fileList={fileList}
        onChange={handleChange}
        multiple={multiple}
        disabled={disabled}
        onPreview={handlePreview}
        onRemove={handleRemove}
      >
        {isView || (limit && fileList?.length >= limit) ? null : (
          <Button loading={loading} icon={<CameraOutlined />} />
        )}
      </Upload>
      <FullScreen isOpen={previewVisible}>
        <Carousel
          previewIndex={previewIndex}
          images={previewList}
          onClick={onCloseModal}
        />
      </FullScreen>
    </>
  );
};
