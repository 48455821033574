import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Rest_User } from "../services/rest_users";
import { IProfile, IUsers } from "../types/users";

export interface IForm {
  title?: string;
  initialValue?: IProfile;
  isOpen: boolean;
  isCopy?: boolean;
  isEdit?: boolean;
  typeForm: string;
  onClose: (res?: boolean) => void;
  onEdit?: () => void;
  onView?: () => void;
  width?: number;
  isActive?: boolean;
  isShowActive?: boolean;
}

interface IUsersState {
  user?: IUsers;
  usersData: Array<IUsers>;
  allUsers: Array<IUsers>;
  usersActive?: { [key: number]: boolean } | any;
  userInfoData: Array<IUsers>;
  auth: boolean;
  dataEntities: [];
  permissionsData?: IPermissionsData | null;
}

export interface IPermissionsData {
  [key: string]: boolean | string | number | null | undefined;
}

const initialState: IUsersState = {
  user: undefined,
  usersData: [],
  allUsers: [],
  usersActive: {},
  userInfoData: [],
  auth: false,
  dataEntities: [],
  permissionsData: null,
};
interface IGetUserPermPayload {
  userId: number;
  entityId?: number | number[];
}

export const UserInfo = createAsyncThunk("UserInfo", async (payload: {}) => {
  return await Rest_User.userInfo(payload);
});
export const getUserPermissions = createAsyncThunk(
  "getUserPermissions",
  async (payload: IGetUserPermPayload) => {
    const { userId, entityId } = payload;
    // console.log(payload);
    return await Rest_User.getUserPermissions(userId, entityId);
  }
);
export const refUserInfo = createAsyncThunk(
  "refUserInfo",
  async (payload: {}) => {
    const response: any = await Rest_User.refUserInfo(payload);
    return response;
  }
);
export const entitiesInfo = createAsyncThunk(
  "entitiesInfo",
  async (payload: {}) => {
    const response: any = await Rest_User.entitiesInfo(payload);
    return response;
  }
);
export const setUserActive = createAsyncThunk(
  "setUserActive",
  async (payload: { id: number; active: boolean; getActive?: boolean }) => {
    return await Rest_User.setUserActive(payload);
  }
);
export const checkUsersActive = createAsyncThunk(
  "checkUsersActive",
  async (payload: { ids: number[]; }) => {
    return await Rest_User.checkUsersActive(payload);
  }
);
export const updateUser = createAsyncThunk(
  "updateUser",
  async (payload: {
    id: number;
    avatar?: string | null;
    vacation?: boolean;
    travel?: boolean;
  }) => {
    return await Rest_User.updateUser(payload);
  }
);

export const getAllUsers = createAsyncThunk("getAllUsers", async () => {
  return await Rest_User.getAllUsers();
});

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    resetStoreUsers(state) {
      state.auth = initialState.auth;
      state.user = initialState.user;
      state.usersData = initialState.usersData;
      state.usersActive = initialState.usersActive;
      state.dataEntities = initialState.dataEntities;
      state.userInfoData = initialState.userInfoData;
      state.permissionsData = initialState.permissionsData;
    },
    setAuth(state, action) {
      state.auth = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setUsersData(state, action) {
      const result = { ...state.usersActive };

      state.usersData = action.payload
        ?.map((el: any) => ({ ...el, active: result?.[el?.id] }))
        .filter((item: any) => item?.id !== state.user?.id);
    },
    setUserPermissions(state, action) {
      if (action.payload && typeof action.payload === "object") {
        state.permissionsData = action.payload;
      } else {
        state.permissionsData = null;
        console.warn(
          "Получен некорректный формат permissionsData:",
          action.payload
        );
      }
    },
    updateUserActive(state, action) {
      const id: number = action.payload.id;
      const result = { ...state.usersActive };

      if (id) {
        result[id] = !!action.payload.active;
        state.usersActive = result;
      }
      state.usersData = [...state.usersData]?.map((el) => ({
        ...el,
        active: id === el?.id ? result?.[el?.id] : el?.active,
      }));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(UserInfo.fulfilled, (state, action) => {
      state.userInfoData = action.payload;
    });
    builder.addCase(refUserInfo.fulfilled, (state, action) => {
      state.userInfoData = action.payload;
    });
    builder.addCase(entitiesInfo.fulfilled, (state, action) => {
      state.dataEntities = action.payload;
    });
    builder.addCase(getUserPermissions.fulfilled, (state, action) => {
      if (action.payload && typeof action.payload === "object") {
        state.permissionsData = action.payload;
      } else {
        state.permissionsData = null;
      }
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.allUsers = action.payload;
    });
  },
});
export default usersSlice.reducer;
export const {
  setAuth,
  setUser,
  setUsersData,
  setUserPermissions,
  resetStoreUsers,
  updateUserActive,
} = usersSlice.actions;
