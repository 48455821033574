import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './store';
import './i18n/i18n';
import './assets/scss/font/Manrope-Regular.ttf';
import './assets/scss/font/Manrope-SemiBold.ttf';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';


const container: any = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
  </ErrorBoundary>
);
