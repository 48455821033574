import React from 'react';
import { App, ButtonProps, Tooltip, Typography } from 'antd';
import classnames from 'classnames';

import IconButton from '../IconButton';

interface ICopyButton extends ButtonProps {
  textButton?: string;
  className?: string;
  text: string;
  textSuccess?: string;
  textError?: string;
  onClick?: () => void;
}

const CopyButton: React.FC<ICopyButton> = ({
  text,
  textError,
  textSuccess,
  textButton,
  onClick,
  className = '',
  ...props
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { message } = App.useApp();

  const copyTextBuffer = (text: string) => {
    if (isLoading) return;
    setIsLoading(true);
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success(textSuccess || 'Текст скопирован');
      })
      .catch((err) => {
        message.error(textError || 'Ошибка копирования');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return text ? (
    <>
      {textButton ? (
        <Tooltip title="Копировать" placement="topLeft">
          <Typography.Link
            onClick={() => {
              copyTextBuffer(text);
              if (onClick) {
                onClick();
              }
            }}
            style={{ color: 'currentColor' }}
          >
            {textButton}
          </Typography.Link>
        </Tooltip>
      ) : (
        <IconButton
          {...props}
          className={classnames({ [className]: className })}
          onClick={() => {
            copyTextBuffer(text);
            if (onClick) {
              onClick();
            }
          }}
          iType="buffer"
        />
      )}
    </>
  ) : null;
};

export default CopyButton;
