import {useEffect, useRef, useState} from 'react';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Dropdown,
    Flex,
    Form,
    Input,
    App,
    Modal,
    notification,
    Row,
    Select,
    Tooltip,
    Typography,
} from 'antd';
import Icon, {
    CheckOutlined,
    CloseOutlined,
    // PlusOutlined,
} from '@ant-design/icons';
import {
    blackCloseIcon,
    copyIcon,
    EditIcon,
    errorImportIcon,
    SaveIcon,
} from '../../assets/image/svg';
import {useTranslation} from 'react-i18next';
import {
    ColumnMapping,
    fileColumns,
    IColumnsImport,
    TerminalService,
} from './service';
import {RootState, useStoreDispatch} from '../../store';
import {IDataCityChildren, setTerminal} from '../../store/terminals';
import {SelectSearch} from '../../components/ui/SelectSearch/SelectSearch';
import FormModalTerminal from '../terminals/formModalTerminal';
import {Utils} from '../../utils';
import {validCompanyContainer} from '../../store/containers';
import socket from '../../socket';
import {getDateSubmitString} from '../../helpers/date-helpers';
import {Rest_location} from '../../services/rest_location';
import {useSelector} from 'react-redux';
import {ITypeContainers} from '../../types';
import get from 'lodash.get';

const {Title, Text} = Typography;
const {Option} = Select;

interface FormModalImportProps {
    closeModalImport?: any;
    loadings?: boolean;
    crmColumns: IColumnsImport[];
    setLoadings: (value: ((prevState: boolean) => boolean) | boolean) => void;
    onFinish: (val: any) => void;
}

const FormModalImport = ({
                             closeModalImport,
                             loadings,
                             crmColumns,
                             setLoadings,
                             onFinish,
                         }: FormModalImportProps) => {
    const {message} = App.useApp();
    const {t} = useTranslation();
    const dispatch = useStoreDispatch();
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const [mapping, setMapping] = useState<any[]>([]);
    const [errors, setErrors] = useState<any[]>([]);
    const [isAddTerminal, setIsAddTerminal] = useState<boolean>(false);
    const [isOpenModalTerminal, setIsOpenModalTerminal] =
        useState<boolean>(false);
    const [terminals, setTerminals] = useState<any[]>([]);

    const [locations, setLocations] = useState<any>({});

    const [owners, setOwners] = useState<any>({});

    const [fileNameTerminal, setFileNameTerminal] = useState<
        string | undefined | number
    >('');
    const [isOpenModalErrorCompany, setIsOpenModalErrorCompany] =
        useState<boolean>(false);
    const [infoDataError, setInfoDataError] = useState<
        {
            parent?: any[];
            terminal?: any[];
            stock?: any[];
            currency?: any[];
            quality?: any[];
            company?: any[];
            date?: any[];
            num_container?: any[];
            type_container?: any[];
        }[]
    >([]);
    const [disabledSave, setDisabledSave] = useState<boolean>(true);
    const [clickedIcons, setClickedIcons] = useState<boolean[]>([]);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isMuted, setIsMuted] = useState<boolean>(true);

    const [editNumContainer, setEditNumContainer] = useState<boolean>(false);
    const [editingIndexNumContainer, setEditingIndexNumContainer] =
        useState<number>(-1);
    const [editedNumContainer, setEditedNumContainer] = useState<any>({});
    const [resNumContainer, setResNumContainer] = useState<any>({
        data: [],
        itemIndex: 0,
    });

    const [editParent, setEditParent] = useState<boolean>(false);
    const [editingIndexParent, setEditingIndexParent] = useState<number>(-1);
    const [editedParent, setEditedParent] = useState<any>({});
    const [resParent, setResParent] = useState<any>({
        data: [],
        itemIndex: 0,
    });

    const [editStock, setEditStock] = useState<boolean>(false);
    const [editingIndexStock, setEditingIndexStock] = useState<number>(-1);
    const [editedStock, setEditedStock] = useState<any>({});
    const [resStock, setResStock] = useState<any>({
        data: [],
        itemIndex: 0,
    });

    const [editTerminal, setEditTerminal] = useState<boolean>(false);
    const [editingIndexTerminal, setEditingIndexTerminal] = useState<number>(-1);
    const [editedTerminal, setEditedTerminal] = useState<any>({});
    const [resTerminal, setResTerminal] = useState<any>({
        data: [],
        itemIndex: 0,
    });

    const [editTypeContainer, setEditTypeContainer] = useState<boolean>(false);
    const [editingIndexTypeContainer, setEditingIndexTypeContainer] =
        useState<number>(-1);
    const [editedTypeContainer, setEditedTypeContainer] = useState<any>({});
    const [resTypeContainer, setResTypeContainer] = useState<any>({
        data: [],
        itemIndex: 0,
    });

    const [editCompany, setEditCompany] = useState<boolean>(false);
    const [editingIndexCompany, setEditingIndexCompany] = useState<number>(-1);
    const [editedCompany, setEditedCompany] = useState<any>({});
    const [resCompany, setResCompany] = useState<any>({data: [], itemIndex: 0});

    const [editQuality, setEditQuality] = useState<boolean>(false);
    const [editingIndexQuality, setEditingIndexQuality] = useState<number>(-1);
    const [editedQuality, setEditedQuality] = useState<any>({});
    const [resQuality, setResQuality] = useState<any>({data: [], itemIndex: 0});

    const [editCurrency, setEditCurrency] = useState<boolean>(false);
    const [editingIndexCurrency, setEditingIndexCurrency] = useState<number>(-1);
    const [editedCurrency, setEditedCurrency] = useState<any>({});
    const [resCurrency, setResCurrency] = useState<any>({
        data: [],
        itemIndex: 0,
    });

    const [editDate, setEditDate] = useState<boolean>(false);
    const [editingIndexDate, setEditingIndexDate] = useState<number>(-1);
    const [editedDate, setEditedDate] = useState<any>({});
    const [resDate, setResDate] = useState<any>({data: [], itemIndex: 0});

    const [itemsErrors, setItemsErrors] = useState<any>([]);
    const notificationKey = 'unique-notification-key';

    const dataTypeContainers: ITypeContainers[] = useSelector(
        (state: RootState) => state.types.dataTypeContainers,
    );

    const dataCurrency = useSelector(
        (state: RootState) => state.types.dataTypeCurrency,
    );

    const dataTypeQuality = useSelector(
        (state: RootState) => state.types.dataTypeQuality,
    );

    const initial = () => {
        return new Promise((resolve, reject) => {
            resolve(TerminalService.initialMapping(crmColumns));
        });
    };

    useEffect(() => {
        setLoadings(false)
        socket.on('return-set-terminal', (res) => {
            if (res.payload.success === 0) {
                Utils.InfoOpenNotification('topRight', res.payload.message, 20, api);
            } else {
                setIsOpenModalTerminal(false);
                setIsAddTerminal(false);
                Utils.openNotification({
                    placement: 'topLeft',
                    message: 'Добавлено',
                    api,
                });
            }
        });
        return () => {
            socket.off('return-set-terminal');
        };
    }, [loadings]);

    useEffect(() => {
        setDisabledSave(true);
        initial()
            .then((initialMapping: any) => {
                const errorsName = initialMapping.reduce(
                    (acc: any, item: ColumnMapping) => {
                        if (!item.disabled && item.crmColumn.title) {
                            acc.push({
                                key: item.crmColumn.key,
                                label: item.crmColumn.title,
                                dataIndex: item.crmColumn.dataIndex,
                                type: item.crmColumn.type,
                            });
                        }
                        return acc;
                    },
                    [],
                );
                setItemsErrors(errorsName);
                setMapping(initialMapping);
                initialMapping.forEach((item: ColumnMapping) => {
                    if (item.crmColumn.type === 'terminal') {

                        const indexTerminal = fileColumns[0].indexOf(item.fileColumn);
                        setFileNameTerminal(fileColumns[1][indexTerminal]);
                    }
                });
            })
            .catch((e) => {
                console.error(e);
            });
    }, [crmColumns, fileColumns[0]]);

    const getLocation = ({
                             location = '',
                             terminal = '',
                             stock = '',
                         }: {
        location?: string;
        terminal?: string;
        stock?: string;
    }) => {
        if (!location) return null;

        return get(locations, `${location}${terminal}${stock}`, null);
    };
    const getCompany = ({
                            name = '',
                        }: {
        name?: string;
    }) => {
        if (!name) return null;

        return get(owners, `${name}`, null);
    };

    const getTypeKtk = (value?: string) =>
        dataTypeContainers.find(
            ({label}) => label.toLocaleLowerCase() === value?.toLocaleLowerCase(),
        );
    const getCurrency = (value?: string) =>
        dataCurrency.find(
            (item) => item.symbol.toLocaleLowerCase() === value?.toLocaleLowerCase(),
        );
    const getQuality = (value?: string) =>
        dataTypeQuality.find(
            (item) => item.label.toLocaleLowerCase() === value?.toLocaleLowerCase(),
        );

    const handleCopy = async (
        row: string,
        index: number,
        correctedValues: any[],
        type: string,
    ) => {
        // Копируем текст в буфер обмена
        await navigator.clipboard.writeText(row);
        // Устанавливаем флаг clickedIcons для текущего элемента
        const newClickedIcons = [...clickedIcons];
        newClickedIcons[index] = true;
        setClickedIcons(newClickedIcons);

        // Обновляем уведомление с новыми данными
        if (type === 'container') {
            updateNotification(
                correctedValues,
                newClickedIcons,
                'Ошибка в номере контейнера',
            );
        } else {
            updateNotification(correctedValues, newClickedIcons, 'Компании');
        }
    };

    const updateNotification = (
        correctedValues: any[],
        clickedIcons: boolean[],
        title: string,
    ) => {
        setClickedIcons([]);
        notification.open({
            key: notificationKey,
            message: title,
            description: (
                <div>
                    <ul>
                        {correctedValues.map(
                            (row, index) =>
                                index < 10 && (
                                    <div
                                        key={index}
                                        style={{display: 'flex', justifyContent: 'space-between'}}
                                    >
                                        <li>{row}</li>
                                        {clickedIcons[index] ? (
                                            <CheckOutlined style={{color: 'green'}}/>
                                        ) : (
                                            <Icon
                                                component={copyIcon}
                                                onClick={() =>
                                                    handleCopy(
                                                        row,
                                                        index,
                                                        correctedValues,
                                                        title !== 'Компании' ? 'container' : '',
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                ),
                        )}
                    </ul>
                </div>
            ),
            duration: null,
        });
    };

    const handleFileColumnChange = async ({
                                              value,
                                              index,
                                              key,
                                          }: {
        value: string;
        index: number;
        key: string;
    }) => {
        // Создание копии mapping и обновление значения fileColumn
        const newMapping: any = [...mapping];
        newMapping[index].fileColumn = value;

        const errorsList = itemsErrors.filter((item: any) => item.key !== key);

        if (index) {
            if (value === 'Пустое поле') {
                newMapping[index].disabled = true;
                setItemsErrors(errorsList);
            } else {
                setItemsErrors(errorsList);
            }
        } else {
            if (value !== 'Пустое поле') {
                setItemsErrors(errorsList);
            }
        }

        // Валидация и обновление состояния mapping
        await validateMapping(newMapping)
            .then((response: any) => {
                if (newMapping[index].fileColumn !== 'Пустое поле') {
                    newMapping[index].disabled = response.length <= 0;
                    setMapping(newMapping);
                } else {
                    setMapping(newMapping);
                }
            })
            .catch((e) => {
                console.error(e);
            });

        // Снятие блокировки кнопки сохранения при условии

        if (!errorsList?.length) {
            setDisabledSave(false);
        } else {
            setDisabledSave(true);
        }
    };

    const validateMapping = async (newMapping: any[]) => {
        return new Promise(async (resolve, reject) => {
            const errors = newMapping.reduce((acc: any[], current: any) => {
                if (
                    current.fileColumn !== 'Пустое поле' &&
                    current.fileColumn &&
                    newMapping.filter(
                        (item: any) =>
                            item.fileColumn === current.fileColumn && item !== current,
                    ).length > 0
                ) {
                    acc.push({
                        crmColumn: current.crmColumn.title,
                    });
                }
                resolve(acc);
                return acc;
            }, []);

            setErrors(errors);
        });
    };

    const cancelDisabled = (value: string, index: number) => {
        const newMapping = [...mapping];

        newMapping[index].fileColumn = value;
        newMapping[index].disabled = false;
        const isError = itemsErrors?.find((el: any) => +el?.key === index);
        if (!isError && newMapping[index]?.crmColumn?.title) {
            setItemsErrors((prev: any) => [
                ...prev,
                {key: index + '', label: newMapping[index].crmColumn.title},
            ]);
        }
        setMapping(newMapping);
        validateMapping(newMapping);
    };

    const handleSave = async () => {
        setDisabledSave(true);
        const arResult = [];
        setEditedNumContainer({});
        setEditedTypeContainer({});
        setEditedCurrency({});
        setEditedQuality({});
        setEditedDate({});
        setEditedCompany({});
        setEditedParent({});
        setEditedTerminal({});
        setEditedStock({});
        //Обновляем дату в fileColumns

        if (resDate.length > 0) {
            resDate.forEach((itemDate: any) => {
                if (itemDate && itemDate.data && itemDate.itemIndex >= 0) {
                    const data = itemDate.data.find((item: any) => item.index >= 0);
                    if (data) {
                        if (
                            fileColumns[itemDate.itemIndex] &&
                            fileColumns[itemDate.itemIndex].length > data.index
                        ) {
                            fileColumns[itemDate.itemIndex][data.index] = data.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resDate');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resDate');
                }
            });
        }

        if (resCurrency.length > 0) {
            resCurrency.forEach((itemDate: any) => {
                if (itemDate && itemDate.data && itemDate.itemIndex >= 0) {
                    const data = itemDate.data.find((item: any) => item.index >= 0);
                    if (data) {
                        if (
                            fileColumns[itemDate.itemIndex] &&
                            fileColumns[itemDate.itemIndex].length > data.index
                        ) {
                            fileColumns[itemDate.itemIndex][data.index] = data.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resCurrency');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resCurrency');
                }
            });
        }

        if (resQuality.length > 0) {
            resQuality.forEach((itemDate: any) => {
                if (itemDate && itemDate.data && itemDate.itemIndex >= 0) {
                    const data = itemDate.data.find((item: any) => item.index >= 0);
                    if (data) {
                        if (
                            fileColumns[itemDate.itemIndex] &&
                            fileColumns[itemDate.itemIndex].length > data.index
                        ) {
                            fileColumns[itemDate.itemIndex][data.index] = data.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resQuality');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resQuality');
                }
            });
        }

        //Обновляем номера контейнеров в fileColumns
        if (resNumContainer.length > 0) {
            resNumContainer.forEach((itemNum: any) => {
                if (itemNum && itemNum.data && itemNum.itemIndex >= 0) {
                    const dataNum = itemNum.data.find((item: any) => item.index >= 0);
                    if (dataNum) {
                        if (
                            fileColumns[itemNum.itemIndex] &&
                            fileColumns[itemNum.itemIndex].length > dataNum.index
                        ) {
                            fileColumns[itemNum.itemIndex][dataNum.index] = dataNum.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resNumContainer');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resNumContainer');
                }
            });
        }

        //Обновляем терминалы в fileColumns
        if (resTerminal.length > 0) {
            resTerminal.forEach((itemTer: any) => {
                if (itemTer && itemTer.data && itemTer.itemIndex >= 0) {
                    const dataNum = itemTer.data.find((item: any) => item.index >= 0);
                    if (dataNum) {
                        if (
                            fileColumns[itemTer.itemIndex] &&
                            fileColumns[itemTer.itemIndex].length > dataNum.index
                        ) {
                            fileColumns[itemTer.itemIndex][dataNum.index] = dataNum.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resTerminal');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resTerminal');
                }
            });
        }

        if (resParent.length > 0) {
            resParent.forEach((itemTer: any) => {
                if (itemTer && itemTer.data && itemTer.itemIndex >= 0) {
                    const dataNum = itemTer.data.find((item: any) => item.index >= 0);
                    if (dataNum) {
                        if (
                            fileColumns[itemTer.itemIndex] &&
                            fileColumns[itemTer.itemIndex].length > dataNum.index
                        ) {
                            fileColumns[itemTer.itemIndex][dataNum.index] = dataNum.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resParent');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resParent');
                }
            });
        }

        if (resStock.length > 0) {
            resStock.forEach((itemTer: { data: any, itemIndex: number }) => {
                console.log('newStock', itemTer);

                if (itemTer && itemTer.data && itemTer.itemIndex >= 0) {
                    const dataNum = itemTer.data.find((item: any) => item.index >= 0);
                    if (dataNum) {
                        if (
                            fileColumns[itemTer.itemIndex] &&
                            fileColumns[itemTer.itemIndex].length > dataNum.index
                        ) {
                            fileColumns[itemTer.itemIndex][dataNum.index] = dataNum.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resStock');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resStock');
                }
            });
        }

        if (resTypeContainer.length > 0) {
            resTypeContainer.forEach((itemTer: any) => {
                if (itemTer && itemTer.data && itemTer.itemIndex >= 0) {
                    const dataNum = itemTer.data.find((item: any) => item.index >= 0);
                    if (dataNum) {
                        if (
                            fileColumns[itemTer.itemIndex] &&
                            fileColumns[itemTer.itemIndex].length > dataNum.index
                        ) {
                            fileColumns[itemTer.itemIndex][dataNum.index] = dataNum.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resTypeContainer');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resTypeContainer');
                }
            });
        }

        //Обновляем компании в fileColumns
        if (resCompany.length > 0) {
            resCompany.forEach((itemComp: any) => {
                if (itemComp && itemComp.data && itemComp.itemIndex >= 0) {
                    const dataComp = itemComp.data.find((item: any) => item.index >= 0);
                    if (dataComp) {
                        if (
                            fileColumns[itemComp.itemIndex] &&
                            fileColumns[itemComp.itemIndex].length > dataComp.index
                        ) {
                            fileColumns[itemComp.itemIndex][dataComp.index] = dataComp.item;
                        } else {
                            console.error('Ошибка: индекс вне диапазона массива fileColumns');
                        }
                    } else {
                        console.error('Ошибка: неверный формат данных в resDate');
                    }
                } else {
                    console.error('Ошибка: неверный формат данных в resDate');
                }
            });
        }

        // Проверяем терминалы локации и сток
        try {
            const resTerminals: {
                location: string;
                terminal: string;
                stock: string;
                index: number;
            }[] = await TerminalService.validDataImportLocationTerminalStock(mapping);
            if (resTerminals?.length) {
                const res = await Rest_location.checkImport(resTerminals);
                if (res.data) {
                    res.data?.location?.forEach(
                        (
                            {
                                name,
                                id,
                                terminal,
                                stock,
                            }: {
                                name: string;
                                id?: number;
                                terminal: { name: string; id: number };
                                stock: { name: string; id: number };
                            },
                            i: number,
                        ) => {
                            if (name?.trim()) {
                                setLocations((prev: any) => {
                                    const result = prev;
                                    const keyTerminal = `${name}${terminal?.name}`;
                                    const keyStock = `${name}${terminal?.name}${stock?.name}`;
                                    result[name] = id || null;
                                    if (terminal?.name) {
                                        result[keyTerminal] = terminal?.id || null;
                                    }
                                    if (terminal?.name && stock?.name) {
                                        result[keyStock] = stock?.id || null;
                                    }
                                    return result;
                                });
                            }
                        },
                    );

                    const currentParent = res.data?.location?.filter(
                        (el: { name: string; index: number; isValid: boolean }) =>
                            !el?.isValid && el?.name?.trim(),
                    );
                    if (currentParent?.length) {
                        arResult.push({parent: currentParent});
                    }
                    const currentTerminal = res.data?.terminal?.filter(
                        (el: { name: string; index: number; isValid: boolean }) =>
                            !el?.isValid && el?.name?.trim(),
                    );
                    if (currentTerminal?.length) {
                        arResult.push({terminal: currentTerminal});
                    }
                    const currentStock = res.data?.stock?.filter(
                        (el: { name: string; index: number; isValid: boolean }) =>
                            !el?.isValid && el?.name?.trim(),
                    );
                    if (currentStock?.length) {
                        arResult.push({stock: currentStock});
                    }
                }
            }
        } catch (error) {
            console.error('Error processing terminals:', error);
        }

        // Проверяем компании
        try {
            const resValidCompany: any = await TerminalService.validDataImportCompany(
                mapping,
            );
            const companyResponse = await dispatch(
                validCompanyContainer(resValidCompany),
            );
            const res = companyResponse.payload;
            res?.forEach(
                (
                    {
                        name,
                        id,
                    }: {
                        name: string;
                        id?: number;
                    },
                    i: number,
                ) => {
                    setOwners((prev: any) => {
                        const result = prev;
                        result[name] = id || null;
                        return result;
                    });
                },
            );
            const currentCompany = res?.filter(
                (el: { name: string; index: number; id: number, isValid: boolean }) =>
                    !el?.isValid,
            );
            if (currentCompany.length) {
                arResult.push({company: currentCompany});
            }
        } catch (error) {
            console.error('Error processing companies:', error);
        }

        // Проверяем номера контейнеров на валидность
        try {
            const resValidNumContainers: any =
                await TerminalService.validDataImportNumContainers(mapping);
            if (resValidNumContainers.length > 0) {
                arResult.push({num_container: resValidNumContainers});
            }
        } catch (error) {
            console.error('Error validating container num_container:', error);
        }
        // проверяем состояние валюту
        try {
            const resValidCurrency: any =
                await TerminalService.validDataImportCurrency(mapping, getCurrency);
            if (resValidCurrency.length > 0) {
                arResult.push({currency: resValidCurrency});
            }
        } catch (error) {
            console.error('Error validating Currency:', error);
        }
        // проверяем состояние ктк
        try {
            const resValidQualityContainers: any =
                await TerminalService.validDataImportQualityContainers(
                    mapping,
                    getQuality,
                );
            if (resValidQualityContainers.length > 0) {
                arResult.push({quality: resValidQualityContainers});
            }
        } catch (error) {
            console.error('Error validating quality:', error);
        }
        // проверяем тип ктк
        try {
            const resValidTypeContainers: any =
                await TerminalService.validDataImportType(mapping, getTypeKtk);
            if (resValidTypeContainers.length > 0) {
                arResult.push({type_container: resValidTypeContainers});
            }
        } catch (error) {
            console.error('Error validating quality:', error);
        }

        // Проверяем дату прибытия
        try {
            const isValidDate: any = await TerminalService.validDataImportDate(
                mapping,
            );
            if (isValidDate.length > 0) {
                arResult.push({date: isValidDate});
            }
        } catch (error) {
            setDisabledSave(false);
            console.error('Error processing date:', error);
        } finally {
            setDisabledSave(false);
        }

        if (arResult.length) {

            setInfoDataError(arResult);
            console.log('Ошибки');
            console.log('arResult', arResult);
            console.log('editedStock', editedStock);
            console.log('editingIndexStock === index', editingIndexStock);

            setIsOpenModalErrorCompany(true);
        } else {
            setLoadings(true);

            TerminalService.onSave(mapping, fileNameTerminal)
                .then((res) => {
                    const result = res?.map((data) => ({
                        ...data,
                        location_id: Number(getLocation({location: data?.location, terminal: '', stock: ''})) || null,
                        terminal_id: getLocation({location: data?.location, terminal: data?.terminal, stock: ''}),
                        stock_id: getLocation({location: data?.location, terminal: data?.terminal, stock: data?.stock}),
                        type_container: getTypeKtk(data?.type_container)?.value ?? "",
                        quality: getQuality(data?.quality)?.label,
                        owner_id: getCompany({name: data?.owner}),
                        currency_id: getCurrency(data?.currency)?.id,
                        currency: getCurrency(data?.currency)?.symbol,
                        booking_before: getDateSubmitString({date: data.booking_before}),
                        date_entry: getDateSubmitString({date: data.date_entry}),
                        arrival_date: getDateSubmitString({date: data.arrival_date}),
                        departure_date: getDateSubmitString({date: data.departure_date}),
                    }));
                    onFinish(result);
                    closeModalImport(false);
                    setLoadings(true);
                    setMapping([]);
                })
                .catch((e) => {
                    console.error(e);
                });
        }
    };

    const handleAddTerminal = () => {
        setIsOpenModalTerminal(true);
    };

    const closeCancelViewModal = () => {
        setIsOpenModalTerminal(false);
        form.resetFields();
    };

    const onFinishAddTerminal = (payload: any, open: boolean) => {
        Object.assign(payload, {type_form: 'create'});
        dispatch(setTerminal(payload));
    };

    const selectTerminal = (
        event: any,
        option: { item?: IDataCityChildren },
        parent: any,
    ) => {
        const terminalItem = option?.item || null;
        const terminalSelectName = terminalItem?.label || '';
        setFileNameTerminal(terminalSelectName);

        const newMapping = mapping.map((elem) => {
            if (elem.crmColumn.title === 'Терминал') {
                elem.disabled = true;
            }
            return elem;
        });
        setMapping(newMapping);
    };

    const handleClick = () => {
        if (videoRef.current && isMuted) {
            setIsMuted(false);
            videoRef.current.muted = false;
            videoRef.current.play();
        }
    };

    const handleEditNumContainer = (index: number) => {
        setEditNumContainer(true);
        setEditingIndexNumContainer(index);
    };

    const handleEditCurrency = (index: number) => {
        setEditCurrency(true);
        setEditingIndexCurrency(index);
    };

    const handleEditTerminal = (index: number) => {
        setEditTerminal(true);
        setEditingIndexTerminal(index);
    };
    const handleEditParent = (index: number) => {
        setEditParent(true);
        setEditingIndexParent(index);
    };
    const handleEditStock = (index: number) => {
        setEditStock(true);
        setEditingIndexStock(index);
    };

    const handleEditCompany = (index: number) => {
        setEditCompany(true);
        setEditingIndexCompany(index);
    };

    const handleEditDate = (index: number) => {
        setEditDate(true);
        setEditingIndexDate(index);
    };
    const handleEditQuality = (index: number) => {
        setEditQuality(true);
        setEditingIndexQuality(index);
    };
    const handleEditTypeContainer = (index: number) => {
        setEditTypeContainer(true);
        setEditingIndexTypeContainer(index);
    };

    const saveEditNumContainer = (
        index: number,
        itemIndex: number,
        num: string,
    ) => {
        setResNumContainer({data: [], itemIndex: 0});
        const isValid: boolean = Utils.isValidContainerNumber(
            editedNumContainer[0],
        );
        if (isValid) {
            if (editingIndexNumContainer === index) {
                const result = fileColumns[itemIndex]
                    .map((el: string, ind: number) => {
                        if (el === num) {
                            setEditNumContainer(false);
                            return {item: editedNumContainer[index], index: ind};
                        }
                        return undefined;
                    })
                    .filter((row: any) => row !== undefined);
                setResNumContainer((prev: any) => {
                    if (Array.isArray(prev)) {
                        return [
                            {data: result, itemIndex: itemIndex, index: result[0].index},
                            ...prev,
                        ];
                    } else {
                        return [
                            {data: result, itemIndex: itemIndex, index: result[0].index},
                        ];
                    }
                });
            }
        } else {
            message.error('Некорректный номер контейнера');
        }
    };

    const saveEditTerminal = (index: number, itemIndex: number, item: any) => {
        setResTerminal({data: [], itemIndex: 0});
        if (editingIndexTerminal === index) {
            const result = fileColumns[itemIndex]
                .map((el: string, ind: number) => {
                    if (el === item.name) {
                        setEditTerminal(false);
                        return {item: editedTerminal[index], index: ind};
                    }
                    return undefined;
                })
                .filter((row: any) => row !== undefined);
            setResTerminal((prev: any) => {
                if (Array.isArray(prev)) {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                        ...prev,
                    ];
                } else {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                    ];
                }
            });
        }
    };

    const saveEditParent = (index: number, itemIndex: number, item: any) => {
        setResParent({data: [], itemIndex: 0});
        if (editingIndexParent === index) {
            const result = fileColumns[itemIndex]
                .map((el: string, ind: number) => {
                    if (el === item.name) {
                        setEditTerminal(false);
                        return {item: editedParent[index], index: ind};
                    }
                    return undefined;
                })
                .filter((row: any) => row !== undefined);
            setResParent((prev: any) => {
                if (Array.isArray(prev)) {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                        ...prev,
                    ];
                } else {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                    ];
                }
            });
        }
    };

    const saveEditStock = (index: number, itemIndex: number, item: { name: string, index: number }) => {
        setResStock({data: [], itemIndex: 0});
        if (editingIndexStock === index) {
            const result = fileColumns[itemIndex]
                .map((el: string, ind: number) => {
                    if (el === item.name) {
                        setEditStock(false);
                        const res = {item: editedStock[index], index: ind};
                        return res;

                    }
                    return undefined;
                })
                .filter((row: any) => row !== undefined);
            setResStock((prev: any) => {
                if (Array.isArray(prev)) {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                        ...prev,
                    ];
                } else {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                    ];
                }
            });
        }
    };

    const saveEditTypeContainer = (
        index: number,
        itemIndex: number,
        item: any,
    ) => {
        setResTypeContainer({data: [], itemIndex: 0});
        if (editingIndexTypeContainer === index) {
            const result = fileColumns[itemIndex]
                .map((el: string, ind: number) => {
                    if (el === item.name) {
                        setEditTypeContainer(false);
                        return {item: editedTypeContainer[index], index: ind};
                    }
                    return undefined;
                })
                .filter((row: any) => row !== undefined);
            setResTypeContainer((prev: any) => {
                if (Array.isArray(prev)) {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                        ...prev,
                    ];
                } else {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                    ];
                }
            });
        }
    };

    const saveEditCompany = (index: number, itemIndex: number, item: any) => {
        setResCompany({data: [], itemIndex: 0});
        if (editingIndexCompany === index) {
            const result = fileColumns[itemIndex]
                .map((el: string, ind: number) => {
                    if (el === item.name) {
                        setEditCompany(false);
                        return {item: editedCompany[index], index: ind};
                    }
                    return undefined;
                })
                .filter((row: any) => row !== undefined);
            setResCompany((prev: any) => {
                if (Array.isArray(prev)) {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                        ...prev,
                    ];
                } else {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                    ];
                }
            });
        }
    };

    const saveEditQuality = (index: number, itemIndex: number, item: any) => {
        setResQuality({data: [], itemIndex: 0});
        if (editingIndexQuality === index) {
            const result = fileColumns[itemIndex]
                .map((el: string, ind: number) => {
                    if (el === item.name) {
                        setEditQuality(false);
                        return {item: editedQuality[index], index: ind};
                    }
                    return undefined;
                })
                .filter((row: any) => row !== undefined);
            setResQuality((prev: any) => {
                if (Array.isArray(prev)) {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                        ...prev,
                    ];
                } else {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                    ];
                }
            });
        }
    };

    const saveEditCurrency = (index: number, itemIndex: number, item: any) => {
        setResCurrency({data: [], itemIndex: 0});
        if (editingIndexCurrency === index) {
            const result = fileColumns[itemIndex]
                .map((el: string, ind: number) => {
                    if (el === item.name) {
                        setEditCurrency(false);
                        return {item: editedCurrency[index], index: ind};
                    }
                    return undefined;
                })
                .filter((row: any) => row !== undefined);
            setResCurrency((prev: any) => {
                if (Array.isArray(prev)) {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                        ...prev,
                    ];
                } else {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                    ];
                }
            });
        }
    };

    const saveEditDate = (index: number, itemIndex: number, item: any) => {
        setResDate({data: [], itemIndex: 0});
        if (editingIndexDate === index) {
            const result = fileColumns[itemIndex]
                .map((el: string, ind: number) => {
                    if (el === editedDate || el === item.date) {
                        setEditDate(false);
                        return {item: editedDate[index], index: ind};
                    }
                    return undefined;
                })
                .filter((row: any) => row !== undefined);
            setResDate((prev: any) => {
                if (Array.isArray(prev)) {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                        ...prev,
                    ];
                } else {
                    return [
                        {data: result, itemIndex: itemIndex, index: result[0].index},
                    ];
                }
            });
        }
    };

    const handleCloseModalImport = () => {
        setIsOpenModalErrorCompany(false);
    };

    return (
        <>
            {contextHolder}
            <Flex
                justify={'space-between'}
                align={'center'}
                className="page-container"
            >
                <Flex
                    className={'box-import'}
                    align={'flex-start'}
                    justify={'space-between'}
                    style={{width: '100%'}}
                >
                    <Flex align={'center'} justify={'center'} gap={24}>
                        <div className={'box-icon-import'}>
                            <Icon component={errorImportIcon}/>
                        </div>
                        <div
                            style={{display: 'flex', flexDirection: 'column', gap: '20px'}}
                        >
                            <div className="label-card-20">{t('importHeaderTitle')}</div>
                            <Row gutter={20}>
                                <Col xs={24} sm={12} md={12} lg={15}>
                                    <div className="text-card-12 box-text-import">
                                        {t('importHeaderText')}
                                    </div>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={7}>
                                    <Flex vertical gap={10}>
                                        <Dropdown menu={{items: itemsErrors}} arrow>
                                            <Button
                                                style={{
                                                    color: 'black',
                                                    borderColor: itemsErrors.length ? 'red' : 'green',
                                                }}
                                            >
                                                {'Количество ошибок: ' + itemsErrors?.length}
                                            </Button>
                                        </Dropdown>
                                        {isAddTerminal && (
                                            <Flex vertical>
                                                <p
                                                    className={'text-card-12'}
                                                    style={{marginBottom: 0}}
                                                >
                                                    {t('chooseTerminal')}
                                                </p>
                                                <SelectSearch
                                                    onChangeSelect={selectTerminal}
                                                    dataSource={terminals}
                                                    hiddenBtn
                                                    type
                                                    onClick={handleAddTerminal}
                                                />
                                            </Flex>
                                        )}
                                    </Flex>
                                </Col>
                            </Row>
                        </div>
                    </Flex>
                    <Icon component={blackCloseIcon} onClick={closeModalImport}/>
                </Flex>
            </Flex>
            <Divider style={{marginTop: 14, marginBottom: 26}}/>
            <div className={'box-import'}>
                <Row gutter={[50, 50]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <Flex gap={5}>
                            <Col span={12}>
                                <Title level={5}>{t('columnNameCRM')}</Title>
                                <Flex gap={8} vertical>
                                    {mapping?.map((item: any, index: number) => (
                                        <Row key={index}>
                                            <Col span={2}>{index + 1}</Col>
                                            <Col span={20}>
                                                <div
                                                    className={
                                                        !item.disabled
                                                            ? 'column-name-crm'
                                                            : 'column-name-crm disabled'
                                                    }
                                                >
                                                    {item.crmColumn.title}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </Flex>
                            </Col>
                            <Col span={12}>
                                <Title level={5}>Имя столбца в файле:</Title>
                                <Flex gap={8} vertical>
                                    {mapping.map((item: any, index: number) => (
                                        <Flex key={index} gap={5}>
                                            <Select
                                                placeholder={'Выберите имя'}
                                                disabled={item.disabled}
                                                style={{
                                                    width: '90%',
                                                    maxWidth: '142px',
                                                    minHeight: '36px',
                                                }}
                                                value={item.fileColumn}
                                                onChange={(value) =>
                                                    handleFileColumnChange({
                                                        value,
                                                        index,
                                                        key: item.crmColumn.key,
                                                    })
                                                }
                                            >
                                                {fileColumns[0]
                                                    ?.filter((el: string) => el)
                                                    .map((fileColumn: string, indx: number) => (
                                                        <Option key={`${indx}`} value={fileColumn}>
                                                            {fileColumn}
                                                        </Option>
                                                    ))}
                                            </Select>
                                            <Flex style={{minWidth: '54px'}}>
                                                {errors.find(
                                                    (error: any) =>
                                                        error.crmColumn === item.crmColumn.title,
                                                ) ? (
                                                    <Tooltip title="Изменить" placement="topLeft">
                                                        <Flex gap={5} align={'center'}>
                                                            <Button
                                                                icon={
                                                                    <CloseOutlined
                                                                        style={{
                                                                            fontSize: '12px',
                                                                            color: 'red',
                                                                        }}
                                                                    />
                                                                }
                                                                onClick={() => cancelDisabled('', index)}
                                                            />
                                                            <span>Дубликат</span>
                                                        </Flex>
                                                    </Tooltip>
                                                ) : (
                                                    <Flex gap={8} align={'center'}>
                                                        {item.disabled && (
                                                            <Tooltip title="Изменить" placement="topLeft">
                                                                <Flex gap={5} align={'center'}>
                                                                    <Button
                                                                        icon={
                                                                            <CloseOutlined
                                                                                style={{
                                                                                    fontSize: '12px',
                                                                                    color: 'red',
                                                                                }}
                                                                            />
                                                                        }
                                                                        onClick={() => cancelDisabled(item, index)}
                                                                    />
                                                                </Flex>
                                                            </Tooltip>
                                                        )}
                                                        <Flex
                                                            justify={item.disabled ? 'start' : 'center'}
                                                            style={{minWidth: '30px'}}
                                                        >
                                                            {item.disabled ? (
                                                                <CheckOutlined style={{color: 'green'}}/>
                                                            ) : (
                                                                <CheckOutlined style={{color: 'gray'}}/>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                )}
                                            </Flex>
                                        </Flex>
                                    ))}
                                </Flex>
                            </Col>
                        </Flex>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                        <video
                            onClick={handleClick}
                            width="90%"
                            ref={videoRef}
                            autoPlay
                            muted={isMuted}
                            loop
                            controls
                        >
                            <source src="/videos.mp4" type="video/mp4"/>
                            Your browser doesn't support HTML5 video tag.
                        </video>
                    </Col>
                    <Col span={24} offset={6}>
                        <Button
                            disabled={disabledSave}
                            type={'primary'}
                            onClick={handleSave}
                            loading={loadings}
                        >
                            Сохранить изменения и загрузить
                        </Button>
                    </Col>
                </Row>
            </div>
            <Modal
                title={'Добавить терминал'}
                maskClosable={false}
                footer={null}
                onCancel={closeCancelViewModal}
                width={548}
                open={isOpenModalTerminal}
            >
                <Divider style={{marginTop: 0, marginBottom: 16}}/>
                <Flex vertical className={'modal-content'}>
                    <FormModalTerminal
                        nameTerminal={fileNameTerminal}
                        form={form}
                        typeForm="create"
                        onFinishAddTerminal={onFinishAddTerminal}
                        dataCityRedux={terminals}
                        onClose={closeCancelViewModal}
                    />
                </Flex>
            </Modal>
            <Modal
                closable={false}
                footer={null}
                width={'94%'}
                open={isOpenModalErrorCompany}
            >
                <Flex className={'modal-content'} vertical>
                    <Flex justify={'space-between'} style={{width: '100%'}}>
                        <Flex
                            align={'center'}
                            justify={'start'}
                            gap={24}
                            style={{width: '100%'}}
                        >
                            <div className={'box-icon-import'}>
                                <Icon component={errorImportIcon}/>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px',
                                }}
                            >
                                <Flex justify={'space-between'} style={{width: '100%'}}>
                                    <div className="label-card-20">Исправьте ошибки</div>
                                </Flex>
                                <Row gutter={20}>
                                    <Col xs={24} sm={12} md={12} lg={15}>
                                        <div className="text-card-12 box-text-import">
                                            Пожалуйста, исправьте ошибки перечисленные ниже
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Flex>
                        <Flex align={'flex-start'}>
                            <Icon
                                component={blackCloseIcon}
                                onClick={handleCloseModalImport}
                            />
                        </Flex>
                    </Flex>
                </Flex>
                <Divider style={{marginTop: 10}}/>
                <Flex className={'modal-content'} gap={10}>
                    {infoDataError.map((item, index) => (
                        <Flex vertical gap={10} key={index}>
                            {item?.parent?.map((item: any, index: number) => (
                                <Flex key={index} gap={10} vertical>
                                    {index === 0 && (
                                        <div className={'label-card-16'}>
                                            Этих родителей нет в базе или не нашли терминалы:
                                        </div>
                                    )}
                                    <Flex justify={'space-between'} gap={5}>
                                        {editingIndexParent === index && editParent ? (
                                            <Input
                                                defaultValue={editedParent[index] ?? item.name}
                                                size="small"
                                                style={{width: 130}}
                                                onChange={(e: any) => {
                                                    setEditedParent({
                                                        ...editedParent,
                                                        [index]: e.target.value,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            editedParent[index] ?? item.name
                                        )}
                                        <Flex gap={5}>
                                            <Tooltip title="Сохранить" placement="topLeft">
                                                <Button
                                                    icon={<SaveIcon/>}
                                                    size="small"
                                                    onClick={() =>
                                                        saveEditParent(index, item.index, item)
                                                    }
                                                />
                                            </Tooltip>
                                            <Tooltip title="Изменить" placement="topLeft">
                                                <Button
                                                    icon={<EditIcon/>}
                                                    size="small"
                                                    onClick={() => handleEditParent(index)}
                                                />
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                            {item?.terminal?.map(
                                (
                                    item: { name: string; index: number; parent: string },
                                    index: number,
                                ) => (
                                    <Flex key={index} gap={10} vertical>
                                        <div className={'label-card-16'}>
                                            <strong>{item.parent || 'Нет родителя'}</strong> не нашел терминал:
                                        </div>
                                        <Flex justify={'space-between'} gap={5}>
                                            {editingIndexTerminal === index && editTerminal ? (
                                                <Input
                                                    defaultValue={editedTerminal[index] ?? item.name}
                                                    size="small"
                                                    style={{width: 130}}
                                                    onChange={(e: any) => {
                                                        setEditedTerminal({
                                                            ...editedTerminal,
                                                            [index]: e.target.value,
                                                        });
                                                    }}
                                                />
                                            ) : (
                                                editedTerminal[index] ?? item.name
                                            )}
                                            <Flex gap={5}>
                                                <Tooltip title="Сохранить" placement="topLeft">
                                                    <Button
                                                        icon={<SaveIcon/>}
                                                        size="small"
                                                        onClick={() =>
                                                            saveEditTerminal(index, item.index, item)
                                                        }
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Изменить" placement="topLeft">
                                                    <Button
                                                        icon={<EditIcon/>}
                                                        size="small"
                                                        onClick={() => handleEditTerminal(index)}
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                ),
                            )}
                            {item?.stock?.map(
                                (
                                    item: { name: string; index: number; parent: string },
                                    index: number,
                                ) => (
                                    <Flex key={index} gap={10} vertical>
                                        <div className={'label-card-16'}>
                                            <strong>{item.parent || 'Нет терминала'}</strong> не нашел сток:
                                        </div>
                                        <Flex justify={'space-between'} gap={5}>
                                            {editingIndexStock === index && editedStock[index] ? (
                                                <Input
                                                    defaultValue={editedStock[index]}
                                                    size="small"
                                                    style={{width: 130}}
                                                    onChange={(e: any) => {
                                                        setEditedStock({
                                                            ...editedStock,
                                                            [index]: e.target.value,
                                                        });
                                                    }}
                                                />
                                            ) : (
                                                editedStock[index] ?? item.name
                                            )}
                                            <Flex gap={5}>
                                                <Tooltip title="Сохранить" placement="topLeft">
                                                    <Button
                                                        icon={<SaveIcon/>}
                                                        size="small"
                                                        onClick={() =>
                                                            saveEditStock(index, item.index, item)
                                                        }
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Изменить" placement="topLeft">
                                                    <Button
                                                        icon={<EditIcon/>}
                                                        size="small"
                                                        onClick={() => {
                                                            setEditedStock({
                                                                ...editedStock,
                                                                [index]: item?.name
                                                            });
                                                            handleEditStock(index)
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                ),
                            )}
                            {item?.num_container?.map((item: any, index: number) => (
                                <Flex key={index} gap={10} vertical>
                                    {index === 0 && (
                                        <div className={'label-card-16'}>
                                            Номера контейнера некорректны:
                                        </div>
                                    )}
                                    <Flex justify="space-between" gap={10} align="center">
                                        {editingIndexNumContainer === index && editNumContainer ? (
                                            <Input
                                                defaultValue={editedNumContainer[index] || item.num}
                                                size="small"
                                                style={{width: 130}}
                                                onChange={(e: any) => {
                                                    setEditedNumContainer({
                                                        ...editedNumContainer,
                                                        [index]: e.target.value,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            editedNumContainer[index] || item.num
                                        )}
                                        <Flex gap={5}>
                                            <Tooltip title="Сохранить" placement="topLeft">
                                                <Button
                                                    icon={<SaveIcon/>}
                                                    size="small"
                                                    onClick={() =>
                                                        saveEditNumContainer(index, item.index, item.num)
                                                    }
                                                />
                                            </Tooltip>
                                            <Tooltip title="Изменить" placement="topLeft">
                                                <Button
                                                    icon={<EditIcon/>}
                                                    size="small"
                                                    onClick={() => handleEditNumContainer(index)}
                                                />
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                            {item?.currency?.map((item: any, index: number) => (
                                <Flex key={index} gap={10} vertical>
                                    {index === 0 && (
                                        <div className={'label-card-16'}>
                                            Этих валют нет в базе:
                                        </div>
                                    )}
                                    <Flex justify={'space-between'} gap={5}>
                                        {editingIndexCurrency === index && editCurrency ? (
                                            <Input
                                                defaultValue={editedCurrency[index] || item.name}
                                                size="small"
                                                style={{width: 130}}
                                                onChange={(e: any) => {
                                                    setEditedCurrency({
                                                        ...editedCurrency,
                                                        [index]: e.target.value,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            editedCurrency[index] || item.name
                                        )}
                                        <Flex gap={5}>
                                            <Tooltip title="Сохранить" placement="topLeft">
                                                <Button
                                                    icon={<SaveIcon/>}
                                                    size="small"
                                                    onClick={() =>
                                                        saveEditCurrency(index, item.index, item)
                                                    }
                                                />
                                            </Tooltip>
                                            <Tooltip title="Изменить" placement="topLeft">
                                                <Button
                                                    icon={<EditIcon/>}
                                                    size="small"
                                                    onClick={() => handleEditCurrency(index)}
                                                />
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                            {item?.quality?.map((item: any, index: number) => (
                                <Flex key={index} gap={10} vertical>
                                    {index === 0 && (
                                        <div className={'label-card-16'}>
                                            Этих состояний нет в ктк:
                                        </div>
                                    )}
                                    <Flex justify={'space-between'} gap={5}>
                                        {editingIndexQuality === index && editQuality ? (
                                            <Input
                                                defaultValue={editedQuality[index] || item.name}
                                                size="small"
                                                style={{width: 130}}
                                                onChange={(e: any) => {
                                                    setEditedQuality({
                                                        ...editedQuality,
                                                        [index]: e.target.value,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            editedQuality[index] || item.name
                                        )}
                                        <Flex gap={5}>
                                            <Tooltip title="Сохранить" placement="topLeft">
                                                <Button
                                                    icon={<SaveIcon/>}
                                                    size="small"
                                                    onClick={() =>
                                                        saveEditQuality(index, item.index, item)
                                                    }
                                                />
                                            </Tooltip>
                                            <Tooltip title="Изменить" placement="topLeft">
                                                <Button
                                                    icon={<EditIcon/>}
                                                    size="small"
                                                    onClick={() => handleEditQuality(index)}
                                                />
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                            {item?.type_container?.map((item: any, index: number) => (
                                <Flex key={index} gap={10} vertical>
                                    {index === 0 && (
                                        <div className={'label-card-16'}>Этих типов нет в ктк:</div>
                                    )}
                                    <Flex justify={'space-between'} gap={5}>
                                        {editingIndexTypeContainer === index &&
                                        editTypeContainer ? (
                                            <Input
                                                defaultValue={editedTypeContainer[index] || item.name}
                                                size="small"
                                                style={{width: 130}}
                                                onChange={(e: any) => {
                                                    setEditedTypeContainer({
                                                        ...editedTypeContainer,
                                                        [index]: e.target.value,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            editedTypeContainer[index] || item.name
                                        )}
                                        <Flex gap={5}>
                                            <Tooltip title="Сохранить" placement="topLeft">
                                                <Button
                                                    icon={<SaveIcon/>}
                                                    size="small"
                                                    onClick={() =>
                                                        saveEditTypeContainer(index, item.index, item)
                                                    }
                                                />
                                            </Tooltip>
                                            <Tooltip title="Изменить" placement="topLeft">
                                                <Button
                                                    icon={<EditIcon/>}
                                                    size="small"
                                                    onClick={() => handleEditTypeContainer(index)}
                                                />
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                            {item?.company?.map((item: any, index: number) => (
                                <Flex key={index} gap={10} vertical>
                                    {index === 0 && (
                                        <div className={'label-card-16'}>
                                            Этих компаний нет в базе:
                                        </div>
                                    )}
                                    <Flex justify={'space-between'} gap={5}>
                                        {editingIndexCompany === index && editCompany ? (
                                            <Input
                                                defaultValue={editedCompany[index] || item.name}
                                                size="small"
                                                style={{width: 130}}
                                                onChange={(e: any) => {
                                                    setEditedCompany({
                                                        ...editedCompany,
                                                        [index]: e.target.value,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            editedCompany[index] || item.name
                                        )}
                                        <Flex gap={5}>
                                            <Tooltip title="Сохранить" placement="topLeft">
                                                <Button
                                                    icon={<SaveIcon/>}
                                                    size="small"
                                                    onClick={() =>
                                                        saveEditCompany(index, item.index, item)
                                                    }
                                                />
                                            </Tooltip>
                                            <Tooltip title="Изменить" placement="topLeft">
                                                <Button
                                                    icon={<EditIcon/>}
                                                    size="small"
                                                    onClick={() => handleEditCompany(index)}
                                                />
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                            {item?.date?.map((item: any, index: number) => (
                                <Flex key={index} gap={10} vertical>
                                    {index === 0 && (
                                        <div className={'label-card-16'}>Дата некорректна:</div>
                                    )}
                                    <Flex gap={10} align="center">
                                        {editingIndexDate === index && editDate ? (
                                            <Flex vertical>
                                                <Text>{item.num}</Text>
                                                <Text>{item.date}</Text>
                                                <DatePicker
                                                    format="DD.MM.YYYY"
                                                    size="small"
                                                    style={{width: 130}}
                                                    onChange={(e: any, dateString: any) => {
                                                        setEditedDate({
                                                            ...editedDate,
                                                            [index]: e,
                                                        });
                                                    }}
                                                />
                                            </Flex>
                                        ) : (
                                            editedDate[index] || item.date
                                        )}
                                        <Flex gap={5}>
                                            <Tooltip title="Сохранить" placement="topLeft">
                                                <Button
                                                    icon={<SaveIcon/>}
                                                    size="small"
                                                    onClick={() => saveEditDate(index, item.index, item)}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Изменить" placement="topLeft">
                                                <Button
                                                    icon={<EditIcon/>}
                                                    size="small"
                                                    onClick={() => handleEditDate(index)}
                                                />
                                            </Tooltip>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                        </Flex>
                    ))}
                </Flex>
            </Modal>
        </>
    );
};

export default FormModalImport;
