import { AutoComplete, Button, Col, Flex, Form, Row, Spin, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import TooltipButton from '../../../../../components/ui/TooltipButton/TooltipButton';
import ModalConfirm from '../../../../../components/ui/ModalConfirm/ModalConfirm';
import { messagesModal } from '../../../../../constants';
import { useTranslation } from 'react-i18next';
import { IForm } from '../types';
import style from '../style.module.scss';
import { useStoreDispatch } from '../../../../../store';
import { findCompany } from '../../../../../store/contractors';
import { delay } from 'lodash';
import { Checkbox } from '../../../../../components/ui/Checkbox/Checkbox';

export const AddGuide: React.FC<IForm> = ({ onClose, onSubmit, title }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useStoreDispatch();

  const [confirmation, setConfirmation] = useState<boolean>(false);

  const [dataCompany, setDataCompany] = useState<{id: number, value: string}[]>([]);

  const [dataSubmitCompany, setDataSubmitCompany] = useState<number[]>([]);

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose(false);
    form.resetFields();
  };

  const handleSearchCompany = (text: string) => {
    if (text?.length >= 2) {
      setLoading(true);
      setDataCompany([]);
      delay(() => {
        dispatch(findCompany(text.toLocaleLowerCase()))
          .then((res) => {
            const arr = res.payload
            console.log(arr);

            setDataCompany(arr);
          })
          .catch(console.error).finally(() => {
            setLoading(false);
          })
      }, 1000);
    }
  };

  const handleSelectCompany = useCallback((id: string, record: any) => {
    const fields = form.getFieldsValue();
    form.setFieldsValue({
      ...fields,
      client: record?.value,
      client_id: record?.id,
    });
  }, []);

  const onUpdate = (changedValues: any, allValues: any) => {
    // console.log(allValues);

    return;
  };

  const handlerCheckedCompany = ({add, id}: {add: boolean, id: number}) => {
    if (add) {
      setDataSubmitCompany((prev) => [...prev, id]);
    } else {
      setDataSubmitCompany((prev) => prev?.filter((num: number) => num !== id))
    }
  }

  const handlerSubmit = (val: any) => {
    onSubmit(dataSubmitCompany);
    setDataCompany([]);
    setDataSubmitCompany([]);
  }
  return (
    <Form
      className={style['form']}
      noValidate
      autoComplete="off"
      layout="vertical"
      form={form}
      initialValues={{}}
      scrollToFirstError
      onFinish={handlerSubmit}
      onValuesChange={onUpdate}
    >
      <Flex vertical gap={20}>
        <Typography.Title level={4}>{t(title)}</Typography.Title>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <AutoComplete
              popupClassName="certain-category-search-dropdown"
              allowClear
              style={{ width: '100%' }}
              onSearch={handleSearchCompany}
              onSelect={handleSelectCompany}
              placeholder="Поиск компаний"
            />
          </Col>
        </Row>
        <div className={style['list']}>
          {loading && <Spin />}
          {dataCompany?.length ? (
            dataCompany?.map((company, i) => (
              <Checkbox
                key={i}
                onChange={(val: boolean) => {
                  handlerCheckedCompany({ add: val, id: company?.id });
                }}
              >
                {company?.value}
              </Checkbox>
            ))
          ) : (
            loading ? null : <Flex justify="center">Нет данных</Flex>
          )}
        </div>
      </Flex>
      <Form.Item>
        <Flex
          gap={8}
          justify={'space-between'}
          style={{ margin: '32px auto 10px auto', maxWidth: '500px' }}
        >
          <TooltipButton
            propsTooltip={{
              title: t('Добавить'),
            }}
            propsButton={{
              type: 'primary',
              htmlType: 'submit',
              style: { maxWidth: '290px', minWidth: '290px' },
            }}
          >
            {t('Удалить')}
          </TooltipButton>
          <Button
            onClick={() => setConfirmation(true)}
            type={'text'}
            style={{ maxWidth: '134px', color: '#E14453' }}
          >
            {t('Отменить')}
          </Button>
        </Flex>
      </Form.Item>
      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleClose}
      />
    </Form>
  );
};
