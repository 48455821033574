import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Select,
  Typography,
} from "antd";

import Icon from "@ant-design/icons";

import ITabs from "../ui/Tabs";
import { useLink } from "../../hooks";
import style from "./style.module.scss";
import CustomDate from "../CustomDate";
import { ITab } from "../ui/Tabs/Tabs";
import { IForm } from "../../store/users";
import IconButton from "../ui/IconButton";
import {
  AvatarProfile,
  ProfileCalendar,
  ProfileStatus,
  ProfileTape,
} from "./components";
import { Checkbox } from "../ui/Checkbox/Checkbox";
import UploaderPreview from "../ui/UploaderPreview";
import CopyButton from "../ui/CopyButton/CopyButton";
import { Rest_files } from "../../services/rest_files";
import { PROFILE_USER, messagesModal } from "../../constants";
import { EDIT, VIEW } from "../../helpers/string-helpers";
import ModalConfirm from "../ui/ModalConfirm/ModalConfirm";
import TooltipButton from "../ui/TooltipButton/TooltipButton";
import { CopyBufferIconDefault } from "../../assets/image/svg";
import {
  BUSINESS_TRIP,
  noStyleField,
  statusIcons,
  TAB_CALENDAR,
  TAB_TAPE,
  TAB_TASK,
  VACATION,
} from "./constants";
import { IUsers } from "../../types/users";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ProfileTasks } from "./components/ProfileTasks/ProfileTasks";

export const FormProfile: React.FC<IForm> = ({
  initialValue,
  isOpen,
  isCopy,
  isEdit,
  title,
  typeForm,
  width,
  isActive,
  onClose,
  onEdit,
  onView,
}) => {
  const link = useLink({ key: PROFILE_USER, id: initialValue?.id as number });
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const user: IUsers | undefined = useSelector(
    (state: RootState) => state.users.user
  );

  const [confirmation, setConfirmation] = React.useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);

  const [icons, setIcons] = useState<any[]>([]);

  const [departmentData, setDepartmentData] = useState<any[]>([]);

  const [isModalFiles, setIsModalFiles] = useState<boolean>(false);

  const [isFiles, setIsFiles] = useState<boolean>(false);

  const [activeKey, setActiveKey] = useState(() => TAB_TASK);

  const saveFiles: any[] = Form.useWatch("saveFiles", {
    form,
    preserve: true,
  });
  const avatar: string = Form.useWatch("avatar", {
    form,
    preserve: true,
  });
  const fio: string = Form.useWatch("fio", {
    form,
    preserve: true,
  });
  const status: boolean = Form.useWatch("status", {
    form,
    preserve: true,
  });
  const travel: boolean = Form.useWatch("travel", {
    form,
    preserve: true,
  });
  const vacation: boolean = Form.useWatch("vacation", {
    form,
    preserve: true,
  });
  const director: string = Form.useWatch("director", {
    form,
    preserve: true,
  });

  const isDisabled = !!initialValue?.id;

  useEffect(() => {
    form.setFieldValue("director", "ФИО");
    form.setFieldsValue(initialValue);
  }, []);

  useEffect(() => {
    const title = vacation ? VACATION : travel ? BUSINESS_TRIP : "";
    setIcons(title ? [statusIcons?.[title]] : []);
  }, [vacation, travel]);

  const handleClose = (res?: boolean) => {
    onClose(res);
    form.resetFields();
  };

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      if (values?.saveFiles?.length) {
        const res = await Rest_files.submitFiles(values.saveFiles);
        if (res.data?.length) {
          values.file_ids.push(...res.data);
        }
      }
      delete values.saveFiles;
      // console.log(values);

      handleClose(true);
      form.resetFields();
    } catch (err) {
      console.error("submit", err);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdate = async (changedValues: any, allValues: any) => {};

  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    if (!outOfDate && errorFields) {
      const isComplectCheckErrors = [];
      for (const errors of errorFields) {
        const title = errors?.[0];
        if (isComplectCheckErrors.length === 2) {
          break;
        }
      }
    }

    return;
  };

  const toggleStatus =
    (name: "vacation" | "business_trip") => (bol: boolean) => {
      switch (name) {
        case VACATION:
          if (bol) {
            form.setFieldValue("vacation", bol);
            if (travel) {
              form.setFieldValue("travel", false);
            }
            return;
          } else {
            form.setFieldValue("vacation", bol);
          }
          return;
        case BUSINESS_TRIP:
          if (bol) {
            form.setFieldValue("travel", bol);
            if (vacation) {
              form.setFieldValue("vacation", false);
            }
            return;
          } else {
            if (vacation) {
              form.setFieldValue("travel", bol);
            }
          }
      }
    };

  const handlerSaveFiles = (files: any[]) => {
    form.setFieldValue("saveFiles", files);
  };

  const openModalFile = () => {
    setIsFiles(true);
    setIsModalFiles(true);
  };

  const closeModalFile = () => {
    setIsModalFiles(false);
    setIsFiles(false);
    const file = saveFiles?.[0];
    form.setFieldValue("avatar", file?.image ?? "");
    form.setFieldValue("saveFiles", []);
  };

  const changeClickTab = (key: string) => {
    setActiveKey(key);
  };

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: TAB_CALENDAR,
        label: "Календарь",
        content: <ProfileCalendar activeKey={activeKey} />,
      },
      {
        key: TAB_TASK,
        label: "Мои задачи",
        forceRender: false,
        content: (
          <ProfileTasks
            activeKey={activeKey}
            initialValue={initialValue}
            width={width}
          />
        ),
      },
      {
        key: TAB_TAPE,
        label: "Лента",
        content: <ProfileTape activeKey={activeKey} />,
      },
    ],
    [activeKey]
  );

  return (
    <Form
      className={style["form"]}
      autoComplete="off"
      noValidate
      layout="vertical"
      form={form}
      initialValues={initialValue}
      scrollToFirstError
      onFinish={onSubmit}
      onFinishFailed={onFinishError}
      onValuesChange={onUpdate}
      disabled={typeForm === VIEW}
    >
      <Flex vertical gap={24}>
        {noStyleField.map((name) => (
          <Form.Item key={name} name={name} noStyle />
        ))}
        <Flex vertical gap={40}>
          <Flex justify="space-between" align="center">
            <Flex gap={20} align="center" style={{ marginTop: 15 }}>
              <Typography.Title level={2} className={style.title}>
                {title}
              </Typography.Title>
              <ProfileStatus
                isActive={isActive || user?.id === initialValue?.id}
                icons={icons}
              />
            </Flex>
            <Flex gap={5} align={"center"}>
              {isEdit && (
                <IconButton
                  disabled={false}
                  iType={`${VIEW}-form`}
                  onClick={() => {
                    closeModalFile();
                    if (onView) {
                      onView();
                    }
                    form.setFieldsValue(initialValue);
                  }}
                />
              )}
              {isDisabled && isCopy && (
                <CopyButton
                  text={link}
                  textSuccess={t("Ссылка скопирована")}
                  icon={<Icon component={CopyBufferIconDefault} />}
                />
              )}
            </Flex>
          </Flex>
          <Flex gap={5} vertical>
            <Typography.Title level={3} className={style["subtitle"]}>
              {t("Контактная информация")}
            </Typography.Title>
            <Divider style={{ margin: 0 }} />
          </Flex>
        </Flex>
        <Flex gap={30}>
          <div className={style["avatar-block"]}>
            {isModalFiles ? (
              <>
                <IconButton
                  className={style["avatar-block__close"]}
                  onClick={closeModalFile}
                  iType="close"
                />
                <UploaderPreview
                  isOpen={isFiles}
                  saveFiles={saveFiles}
                  limit={1}
                  handlerSaveFiles={handlerSaveFiles}
                />
              </>
            ) : (
              <>
                <AvatarProfile avatar={avatar} size={90} title={fio} />
                <IconButton
                  className={style["avatar-block__edit"]}
                  onClick={openModalFile}
                  iType="edit"
                />
              </>
            )}
          </div>
          <Flex gap={20} flex={1}>
            <Col flex={1} style={{ maxWidth: "33%" }}>
              <Flex vertical gap={15}>
                <Form.Item label={t("Должность")} name="position">
                  <Input allowClear />
                </Form.Item>
                <Form.Item label={t("Руководитель")} shouldUpdate>
                  <div className={style["input-block"]}>
                    <Typography.Text
                      style={{ maxWidth: "100%" }}
                      ellipsis={{ tooltip: { title: director || "" } }}
                    >
                      {t(director || "")}
                    </Typography.Text>
                  </div>
                </Form.Item>
              </Flex>
            </Col>
            <Col flex={1} style={{ maxWidth: "33%" }}>
              <Flex vertical gap={15}>
                <Form.Item
                  label={"Email"}
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Обязательное поле!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input allowClear />
                </Form.Item>
                <Form.Item label={t("Отдел")} name="department">
                  <Select
                    options={departmentData}
                    onSelect={(id) => {
                      form.setFieldValue("department_id", id);
                    }}
                  />
                </Form.Item>
              </Flex>
            </Col>
            <Col flex={1}>
              <Flex vertical gap={15}>
                <Flex vertical style={{ minHeight: "55px" }}>
                  <Typography.Text className={style.label}>
                    {t("Локальное время")}
                  </Typography.Text>
                  <CustomDate format="HH:mm" duration={60000} />
                </Flex>
                <Flex vertical style={{ minHeight: "55px" }}>
                  <Typography.Text className={style.label}>
                    {t("Статус")}
                  </Typography.Text>
                  <Flex
                    justify="flex-start"
                    align="center"
                    gap={30}
                    style={{ minHeight: "32px" }}
                  >
                    <Checkbox
                      isChoice={vacation}
                      customChange={toggleStatus(VACATION)}
                    >
                      <Typography.Text className={style.checkboxes__label}>
                        {t("Отпуск")}
                      </Typography.Text>
                    </Checkbox>
                    <Checkbox
                      isChoice={travel}
                      customChange={toggleStatus(BUSINESS_TRIP)}
                    >
                      <Typography.Text className={style.checkboxes__label}>
                        {t("Командировка")}
                      </Typography.Text>
                    </Checkbox>
                  </Flex>
                </Flex>
              </Flex>
            </Col>
          </Flex>
        </Flex>

        <ITabs
          className={style["form__tab"]}
          isErrorTabs
          activeKey={activeKey}
          tabs={tabs}
          onChange={changeClickTab}
        />
      </Flex>
      <Form.Item>
        <Flex
          gap={8}
          justify={"space-between"}
          style={{
            maxWidth: "550px",
            width: "100%",
            margin: "auto auto 10px auto",
          }}
        >
          <TooltipButton
            propsTooltip={{
              title: isDisabled ? t("Сохранить изменения") : t("Сохранить"),
            }}
            propsButton={{
              type: "primary",
              htmlType: "submit",
              style: { maxWidth: "290px", minWidth: "290px" },
              disabled: isLoading,
            }}
          >
            {isLoading
              ? "...Сохранение"
              : initialValue?.id
              ? t("Сохранить изменения")
              : t("Сохранить")}
          </TooltipButton>
          <Button
            onClick={() => setConfirmation(true)}
            type={"text"}
            style={{
              marginLeft: "auto",
              maxWidth: "290px",
              width: "100%",
              color: "#E14453",
            }}
            disabled={isLoading}
          >
            {t("Отменить")}
          </Button>
        </Flex>
      </Form.Item>

      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleClose}
      />
    </Form>
  );
};
