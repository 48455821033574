import { createSlice } from '@reduxjs/toolkit';

interface IMenu {
  activeKeys: string[];
  openKeys: string[];
}
const initialState: IMenu = {
  activeKeys: [],
  openKeys: [],
};

const checkAddStringFromArray = (arrStr: string[], str: string) => {
  if (arrStr.includes(str)) {
    return arrStr;
  } else {
    return [...arrStr, str];
  }
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    resetStoreMenu(state) {
      state.activeKeys = initialState.activeKeys;
      state.openKeys = initialState.openKeys;
    },
    setActiveKeys(state, action) {
      state.activeKeys = action.payload;
    },
    setOpenKeys(state, action) {
      state.openKeys = Array.isArray(action.payload)
        ? action.payload
        : checkAddStringFromArray(state.openKeys, action.payload);
    },
  },
});
export default menuSlice.reducer;
export const { setActiveKeys, setOpenKeys, resetStoreMenu } = menuSlice.actions;
