import React from 'react';

import { Dropdown, MenuProps, Tooltip, Typography, UploadFile } from 'antd';

import Icon from '@ant-design/icons/lib/components/Icon';
import {
  DeleteOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
} from '@ant-design/icons';

import IconButton from '../../IconButton';
import style from '../UploaderDocs.module.scss';
import { moreIcon } from '../../../../assets/image/svg';
import TooltipButton from '../../TooltipButton/TooltipButton';
import { getFileNameAndType } from '../../../../helpers/input-helpers';
import { useTranslation } from 'react-i18next';

interface IUploaderDocsItem {
  file: UploadFile;
  handlePreview: (file: UploadFile) => void;
  openConfirmDeleteFile: (file: UploadFile) => void;
  isView?: boolean;
  isText?: boolean;
}

const getIcons = (type: string) => {
  const currentType = typeof type === 'string' ? type.toLowerCase() : '';
  switch (currentType) {
    case 'txt':
      return <FileTextOutlined />;
    case 'jpg':
    case 'jpeg':
      return <FileJpgOutlined />;
    case 'png':
      return <FileImageOutlined />;
    case 'pdf':
      return <FilePdfOutlined />;
    case 'doc':
    case 'docx':
      return <FileWordOutlined />;
    case 'xls':
    case 'xlsx':
      return <FileExcelOutlined />;
    default:
      return <FileUnknownOutlined />;
  }
};

const UploaderDocsItem: React.FC<IUploaderDocsItem> = ({
  file,
  isText,
  isView,
  handlePreview,
  openConfirmDeleteFile,
}) => {
  const {t} = useTranslation();
  const getItems = (
    preview: (e: any) => void,
    remove: (e: any) => void,
  ): MenuProps['items'] => [
    {
      key: 1,
      label: (
        <TooltipButton
          propsTooltip={{ placement: 'leftTop', title: t('Скачать') }}
          propsButton={{
            onClick: preview,
            className: style['file__button'],
            datatype: 'file-menu-button',
            icon: <DownloadOutlined />,
          }}
        >
          {t('Скачать')}
        </TooltipButton>
      ),
    },
    isView
      ? null
      : {
          key: 2,
          label: (
            <TooltipButton
              propsTooltip={{ placement: 'leftTop', title: t('Удалить') }}
              propsButton={{
                onClick: remove,
                datatype: 'file-menu-button',
                className: [
                  style['file__button'],
                  style['file__button_delete'],
                ].join(' '),
                icon: <DeleteOutlined />,
              }}
            >
              {t('Удалить')}
            </TooltipButton>
          ),
        },
  ];
  return (
    <>
      {isText ? (
        <div className={style['links']}>
          <Tooltip title={file?.name ?? ''} placement="top">
            <Typography.Link
              ellipsis
              style={{ cursor: 'pointer' }}
              data-type="file-title"
              onClick={() => handlePreview(file)}
            >
              {getFileNameAndType(file?.name).title}
            </Typography.Link>
          </Tooltip>
          <IconButton
            iType="delete"
            className={style['links__delete']}
            onClick={() => {
              openConfirmDeleteFile(file);
            }}
          />
        </div>
      ) : (
        <>
          <div
            className="ant-upload ant-upload-select"
            style={{ cursor: 'default' }}
          >
            <div className={style['file']} data-type="file-container">
              <div className={style['file__icon']}>
                {getIcons(getFileNameAndType(file?.name).type)}
              </div>

              <div className={style['file__menu']} data-type="file-menu">
                <Dropdown
                  menu={{
                    items: getItems(
                      () => handlePreview(file),
                      () => {
                        openConfirmDeleteFile(file);
                      },
                    ),
                  }}
                  placement="bottomLeft"
                  trigger={['click']}
                >
                  <Typography.Link
                    className=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <Icon component={moreIcon} />
                  </Typography.Link>
                </Dropdown>
              </div>
              <div className={style['box-description']}>
                <Typography.Paragraph
                  ellipsis={{ rows: 2, tooltip: { placement: 'top' } }}
                  className={style['file-description']}
                  data-type="file-title"
                >
                  {getFileNameAndType(file?.name).title}
                </Typography.Paragraph>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UploaderDocsItem;
