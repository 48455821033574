import { createSlice } from '@reduxjs/toolkit';

interface IFiltersState {
  dataFilters: [];
  checkedRows: number[];
  checkedData: any[];
}

const initialState: IFiltersState = {
  dataFilters: [],
  checkedData: [],
  checkedRows: [],
};

const filtersSlice = createSlice({
  name: 'filtersSlice',
  initialState,
  reducers: {
    resetStoreFilters(state) {
      state.dataFilters = initialState.dataFilters;
      state.checkedData = initialState.checkedData;
      state.checkedRows = initialState.checkedRows;
    },
    clearFiltersFetch(state) {
      state.dataFilters = [];
    },
    setFiltersFetch(state, action) {
      state.dataFilters = [];
      state.dataFilters = action.payload.rows;
    },
    addCheckedData(state, action) {
      action.payload?.forEach((el: any) => {
        const index = state.checkedData.find((item) => item?.id === el?.id);
        if (!index) {
          state.checkedData.push(el);
        }
      });
    },
    setCheckedRows(state, action) {
      state.checkedRows = action.payload;
    },
    setCheckedData(state, action) {
      state.checkedData = action.payload;
    },
    resetStateData(state) {
      state.checkedRows = [];
      state.checkedData = [];
    }
  },
});
export default filtersSlice.reducer;
export const {
  clearFiltersFetch,
  setFiltersFetch,
  resetStateData,
  setCheckedData,
  addCheckedData,
  setCheckedRows,
  resetStoreFilters
} = filtersSlice.actions;
