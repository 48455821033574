import { Card, Flex, Row, Typography } from 'antd';
import { FC } from 'react';
import './style.scss';
import ForgetLogin from '../../components/ForgetLogin/ForgetLogin';
import Button from '../../components/ui/Button/Button';
import { RouteNames } from '../../router/names';
import { useHistory } from 'react-router-dom';


const ForgetPassword: FC = () => {
  const router = useHistory();
  const clickRegistration = () => {
    router.push(RouteNames.REGISTRATION);
  };
  return (
    <>
      <Flex align='center' gap={10} style={{ position: 'absolute', right: 20, top: 30 }}>
        <Typography.Paragraph
          style={{
            fontWeight: 600,
            margin: 0
          }}
        >
          Нет аккаунта?
        </Typography.Paragraph>
        <Button
          onClick={clickRegistration}
          type="primary"
          style={{ width: 200 }}
        >
          Зарегистрироваться
        </Button>
      </Flex>
      <Row justify="space-around" align="middle" className="h100">
        <Flex vertical align="center" gap={30} className="container-login">
          <Card
            style={{
              borderRadius: 12,
              width: 600,
              height: 306,
              paddingTop: '4%',
              paddingLeft: 100,
              paddingRight: 100,
              boxShadow: '5px 8px 24px 5px rgba(208, 216, 243, 0.6)',
            }}
          >
            <ForgetLogin />
          </Card>
          <Flex gap={10}>
            <Typography.Paragraph
              style={{
                fontWeight: 600,
                margin: 0
              }}
            >
              Нет аккаунта?
            </Typography.Paragraph>
            <Typography.Link
              onClick={clickRegistration}
              style={{
                color: 'black',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Зарегистрироваться
            </Typography.Link>
          </Flex>
        </Flex>
      </Row>
    </>
  );
};

export default ForgetPassword;
