import { Typography } from 'antd';
import { IColumnType } from '../../../../../components/ui/Table/Table';

export const columns: IColumnType<any>[] = [
  {
    dataIndex: 'id',
    filterIndex: 'id',
    checkbox: true,
    menu: true,
    type: 'string',
    key: '0',
  },
  {
    key: '1',
    title: 'Компания',
    dataIndex: 'company',
    filterIndex: 'company',
    link: true,
    type: 'string',
  },
  {
    key: '2',
    title: 'Терминал',
    dataIndex: 'terminal',
    filterIndex: 'terminal',
    type: 'string',
  },

  {
    key: '3',
    title: 'Сток',
    dataIndex: 'stock',
    filterIndex: 'stock',
    type: 'string',
  },
  {
    key: '4',
    title: 'Валюта расчета',
    dataIndex: 'currency_payment',
    filterIndex: 'currency_payment',
    type: 'string',
  },
  {
    key: '5',
    title: 'Ставка день 20',
    dataIndex: 'bet_day_20',
    filterIndex: 'bet_day_20',
    type: 'string',
  },
  {
    key: '6',
    title: 'Ставка кран 20',
    dataIndex: 'bet_crane_20',
    filterIndex: 'bet_crane_20',
    type: 'string',
  },
  {
    key: '7',
    title: 'Ставка день 40',
    dataIndex: 'bet_day_40',
    filterIndex: 'bet_day_40',
    type: 'string',
  },
  {
    key: '8',
    title: 'Ставка кран 40',
    dataIndex: 'bet_crane_40',
    filterIndex: 'bet_crane_40',
    type: 'string',
  },
  {
    key: '9',
    title: 'Бесплатные дни',
    dataIndex: 'free_days',
    filterIndex: 'free_days',
    type: 'string',
  },
  {
    title: '',
    dataIndex: 'hidden',
    filterIndex: 'hidden',
    key: '6',
    width: '60px',
  },
];
