import { IContainer } from '../../../store/releases';
import { Utils } from '../../../utils';

export const getEmpty = (release: {
  release_id?: string;
  type: string;
  count: number;
  terminal_id: number;
  location_id: number;
  location: any;
  terminal: any;
}) => ({
  id: undefined,
  num_container: '',
  container_id: null,
  size: getSizeKtk(release?.type),
  type_container_id: release?.type,
  type_container: release?.type,
  terminal: release?.terminal,
  location: release?.location,
  location_id: release?.location_id,
  terminal_id: release?.terminal_id,
  comment: '',
  count: release.count,
  release_id: release?.release_id ?? null,
  release: release ?? null,
  delete: false,
  active: false,
});

export const getterKtk = (container: IContainer): IContainer => ({
  id: undefined,
  num_container: container?.num_container,
  container_id: container?.id,
  size: getSizeKtk(container?.type_container),
  type_container_id: container?.type_container_id,
  type_container: container?.type_container,
  child_terminal_id: container?.child_terminal_id,
  terminal_id: container?.terminal_id,
  comment: '',
  count: container?.count || 1,
  release_id: container?.release_id ?? null,
  release: container?.release ?? null,
  deal: container?.deal ?? null,
  delete: false,
  active: container?.active,
});

export const mapperKtk = (containers: any[]): IContainer[] | [] => {
  if (Array.isArray(containers) && containers?.length) {
    return containers.map(getterKtk);
  }
  return [];
};

export const getSizeKtk = (type?: string) =>
  type && typeof type === 'string' ? type.replace(/\D/g, '') : '';

export const filterKtk = ({
  containers,
  terminal_id,
}: {
  containers: IContainer[];
  terminal_id: number;
}) =>
  containers?.filter((item: IContainer) =>
    item?.terminal_id ? item?.terminal_id === terminal_id : true,
  ) || [];


export const mapperHistoryRelease = (items: any[]) => {
  return items?.map((item: any) => {
    const data = Utils.checkIsJson(item.data);
    item.data = data ? {
          ...data,
          date_action:
            (data?.date_action_start_string || '') +
            ' - ' +
            (data?.date_action_end_string || ''),
        } : {};
      return item;
      }) || [];
};
