import { useEffect, useRef, useState } from 'react';

import { ConfigProvider, Layout, App as AppLayout } from 'antd';
import {  useHistory } from 'react-router-dom';

import { TOK_EN, Utils } from './utils';
import Loading from './pages/Loading';
import { setAuth } from './store/users';
import { RootState, useStoreDispatch } from './store';
import { RouteNames } from './router/names';
import AppRouter from './components/AppRouter';
import { useLocalizeDocumentAttributes } from './hooks';
import ru from 'antd/es/locale/ru_RU';
import en from 'antd/es/locale/en_US';

import './assets/scss/index.scss';
import { useSelector } from 'react-redux';


function App() {
  const history = useHistory();
  const dictionary = useRef({
    ru,
    en
  });
  const dispatch = useStoreDispatch();
  useLocalizeDocumentAttributes();
  const [isLoading, setIsLoading] = useState(true);
  const lang = useSelector((state: RootState) => state.stores.lang);


  useEffect(() => {
    setIsLoading(true);
    if (Utils.getStorage(TOK_EN) === null) {
      dispatch(setAuth(false));
      history.push(RouteNames.LOGIN);
    } else {
      dispatch(setAuth(true));
    }
    setIsLoading(false);
  }, [dispatch, history]);

  return (
    <ConfigProvider
      locale={dictionary.current[lang]}
      theme={{
        token: {
          colorTextBase: '#0E1117',
          colorText: '#0E1117',
          colorTextLabel: '#a3a9b1',
          fontFamily:
            "-apple-system, Manrope, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        },
      }}
    >
      <AppLayout style={{ height: 'calc(100vh - 0)' }} message={{maxCount: 1}}>
        <Layout.Content>
          {isLoading ? (
            <Loading />
          ) : (
              <AppRouter />
          )}
        </Layout.Content>
      </AppLayout>
    </ConfigProvider>
  );
}

export default App;
