import { Key, ReactNode, useRef, useState, useEffect } from "react";

import { Menu, MenuProps } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import Icon from "@ant-design/icons";

import { RootState, useStoreDispatch } from "../../store";
import style from "./style.module.scss";
import { useParamsHistory } from "../../hooks";
import { RouteNames } from "../../router/names";
import {
  depoDefaultIcon,
  financeDefaultIcon,
  logisticsDefaultIcon,
  rentDefaultIcon,
  saleDefaultIcon,
  userDefaultIcon,
} from "../../assets/image/svg";
import { setOpenKeys } from "../../store/menu";
import { getUserPermissions, getAllUsers } from "../../store/users";
import { Utils } from "../../utils";
import socket from "../../socket";
import { hasViewPermission } from "./permissions";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItem[] | null,
  style?: boolean | { display: string },
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    style,
    type,
  } as MenuItem;
}

// заменить пропсы
const Menus = (props: any) => {
  const dispatch = useStoreDispatch();
  const ref = useRef(null);

  //=========================================
  // получаем id текущего юзера
  // для передачи в метод получения прав
  // идет из localstorage (авторизация)
  //=========================================
  const [currentUser, setCurrentUser] = useState(Utils.getUserInfo().id);

  //=========================================
  // записываем пришедшие права на просмотр
  // для отображения модулей в меню
  //=========================================
  const [showAgreements, setShowAgreements] = useState(false);
  const [showContractors, setShowContractors] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [showTerminals, setShowTerminals] = useState(false);
  const [showContainers, setShowContainers] = useState(false);
  const [showReleases, setShowReleases] = useState(false);
  const [showSalesDeals, setShowSalesDeals] = useState(false);
  const [showRentDeals, setShowRentDeals] = useState(false);
  const [showRentRequests, setShowRentRequests] = useState(false);
  const [showBilling, setShowBilling] = useState(false);
  const [showPayment, setShowPayment] = useState(false);

  // Здесь берем весь объект прав из хранилища
  const permissionsData = useSelector(
    (state: RootState) => state.users.permissionsData
  );

  const activeKeys: string[] = useSelector(
    (state: RootState) => state.menu.activeKeys
  );
  const openKeys: string[] = useSelector(
    (state: RootState) => state.menu.openKeys
  );
  const { t } = useTranslation();
  const { history } = useParamsHistory();

  const handleClickMenu = (keys: string[]) => {
    dispatch(setOpenKeys(keys));
  };

  useEffect(() => {
    // запрашиваем права
    dispatch(getUserPermissions({ userId: currentUser }));

    socket.on("res-update-entities", () => {
      dispatch(getUserPermissions({ userId: currentUser }));
    });
    socket.on("res-update-group-entities", () => {
      dispatch(getUserPermissions({ userId: currentUser }));
    });

    return () => {
      socket.off("res-update-entities");
      socket.off("res-update-group-entities");
    };
  }, [dispatch, currentUser]);

  // Когда permissionsData в Redux обновилось, пересчитываем локальные стейты
  useEffect(() => {
    if (!permissionsData) return; // Если прав нет, ничего не показываем
    // console.log("permissionsData:", permissionsData);

    setShowAgreements(hasViewPermission(permissionsData, "agreements"));
    setShowContractors(hasViewPermission(permissionsData, "contractors"));
    setShowContacts(hasViewPermission(permissionsData, "contacts"));
    setShowTerminals(hasViewPermission(permissionsData, "terminals"));
    setShowContainers(hasViewPermission(permissionsData, "containers"));
    setShowReleases(hasViewPermission(permissionsData, "releases"));
    setShowSalesDeals(hasViewPermission(permissionsData, "salesDeals"));
    setShowRentDeals(hasViewPermission(permissionsData, "rentDeals"));
    setShowRentRequests(hasViewPermission(permissionsData, "rentRequests"));
    setShowBilling(hasViewPermission(permissionsData, "billing"));
    setShowPayment(hasViewPermission(permissionsData, "payment"));
  }, [permissionsData]);

  // Определяем, показывать ли модуль, содержащий разделы
  // если есть хоть одно право на просмотр, показываем
  const showCRM = showAgreements || showContractors || showContacts;
  const showDepot = showTerminals || showContainers || showReleases;
  const showSales = showSalesDeals;
  const showRent = showRentDeals || showRentRequests;
  const showFinance = showBilling || showPayment;

  //=========================================
  // определяем элементы в меню
  // настраиваем отображение по правам
  //=========================================
  const items: MenuProps["items"] = [
    ...(showCRM
      ? [
          getItem(
            <Link className={style.menu__link} to={RouteNames.CRM}>
              CRM
            </Link>,
            RouteNames.CRM,
            <Icon
              className={style.menu__icon}
              onClick={(e) => {
                history.replace(RouteNames.CRM);
              }}
              component={userDefaultIcon}
            />,
            null,
            !props.isCompanys && { display: "none" }
          ),
        ]
      : []),
    ...(showDepot
      ? [
          getItem(
            t("Депо"),
            RouteNames.DEPOT,
            <Icon className={style.menu__icon} component={depoDefaultIcon} />,
            [
              ...(showTerminals
                ? [
                    getItem(
                      <Link
                        className={classnames(
                          style["menu__link"],
                          style["menu__link_child"]
                        )}
                        to={RouteNames.TERMINALS}
                      >
                        {t("Терминалы")}
                      </Link>,
                      RouteNames.TERMINALS,
                      null,
                      null,
                      !props.isStaff && { display: "none" }
                    ),
                  ]
                : []),
              ...(showContainers
                ? [
                    getItem(
                      <Link
                        className={classnames(
                          style["menu__link"],
                          style["menu__link_child"]
                        )}
                        to={RouteNames.CONTAINERS}
                      >
                        {t("Контейнеры")}
                      </Link>,
                      RouteNames.CONTAINERS,
                      null,
                      null,
                      !props.isStaff && { display: "none" }
                    ),
                  ]
                : []),
              ...(showReleases
                ? [
                    getItem(
                      <Link
                        className={classnames(
                          style["menu__link"],
                          style["menu__link_child"]
                        )}
                        to={RouteNames.RELEASES}
                      >
                        {t("Релизы")}
                      </Link>,
                      RouteNames.RELEASES,
                      null,
                      null,
                      !props.isStaff && { display: "none" }
                    ),
                  ]
                : []),
              getItem(
                <Link
                  className={classnames(
                    style["menu__link"],
                    style["menu__link_child"]
                  )}
                  to={RouteNames.STORAGE_CONDITIONS}
                >
                  {t("Условия хранения")}
                </Link>,
                RouteNames.STORAGE_CONDITIONS,
                null,
                null
                // { display: "none" }
              ),
            ],
            !props.isStaff && { display: "none" }
          ),
        ]
      : []),
    ...(showSales
      ? [
          getItem(
            t("Продажи"),
            RouteNames.SALES,
            <Icon className={style.menu__icon} component={saleDefaultIcon} />,
            [
              ...(showSalesDeals
                ? [
                    getItem(
                      <Link
                        className={classnames(
                          style["menu__link"],
                          style["menu__link_child"]
                        )}
                        to={RouteNames.SALES_DEAL}
                      >
                        {t("Сделки")}
                      </Link>,
                      RouteNames.SALES_DEAL,
                      null,
                      null,
                      !props.isStaff && { display: "none" }
                    ),
                  ]
                : []),
            ],
            !props.isStaff && { display: "none" }
          ),
        ]
      : []),
    ...(showRent
      ? [
          getItem(
            t("Rent"),
            RouteNames.RENT,
            <Icon className={style.menu__icon} component={rentDefaultIcon} />,
            [
              ...(showRentDeals
                ? [
                    getItem(
                      <Link
                        className={classnames(
                          style["menu__link"],
                          style["menu__link_child"]
                        )}
                        to={RouteNames.RENT_DEAL}
                      >
                        {t("Сделки")}
                      </Link>,
                      RouteNames.RENT_DEAL,
                      null,
                      null,
                      !props.isStaff && { display: "none" }
                    ),
                  ]
                : []),
              ...(showRentRequests
                ? [
                    getItem(
                      <Link
                        className={classnames(
                          style["menu__link"],
                          style["menu__link_child"]
                        )}
                        to={RouteNames.RENT_REQUESTS}
                      >
                        {t("Запросы аренды")}
                      </Link>,
                      RouteNames.RENT_REQUESTS,
                      null,
                      null,
                      !props.isStaff && { display: "none" }
                    ),
                  ]
                : []),
            ],
            !props.isStaff && { display: "none" }
          ),
        ]
      : []),
    ...(showFinance
      ? [
          getItem(
            t("Финансы"),
            RouteNames.FINANCE,
            <Icon
              className={style.menu__icon}
              component={financeDefaultIcon}
            />,
            [
              ...(showBilling
                ? [
                    getItem(
                      <Link
                        className={classnames(
                          style["menu__link"],
                          style["menu__link_child"]
                        )}
                        to={RouteNames.FINANCE_BILLING}
                      >
                        {t("Выставление счета")}
                      </Link>,
                      RouteNames.FINANCE_BILLING,
                      null,
                      null,
                      !props.isStaff && { display: "none" }
                    ),
                  ]
                : []),
              ...(showPayment
                ? [
                    getItem(
                      <Link
                        className={classnames(
                          style["menu__link"],
                          style["menu__link_child"]
                        )}
                        to={RouteNames.FINANCE_PAYMENT}
                      >
                        {t("Оплата счета")}
                      </Link>,
                      RouteNames.FINANCE_PAYMENT,
                      null,
                      null,
                      !props.isStaff && { display: "none" }
                    ),
                  ]
                : []),
            ],
            !props.isStaff && { display: "none" }
          ),
        ]
      : []),
  ];

  //==============================
  // рендер меню и его элементов
  //==============================
  return (
    <Menu
      ref={ref}
      className={style.menu}
      mode="inline"
      selectedKeys={activeKeys}
      openKeys={openKeys}
      items={items}
      onOpenChange={handleClickMenu}
    />
  );
};

export default Menus;
