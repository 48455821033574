import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Typography } from 'antd';

interface ICustomDate {
  duration?: number;
  format?: string;
}

export const CustomDate: React.FC<ICustomDate> = ({
  duration,
  format = 'dd, DD MMMM YYYY',
}) => {
  const [data, setDate] = useState<string>(() =>
    moment().local().format(format),
  );
  const lang = useSelector((state: RootState) => state.stores.lang) as
    | 'ru'
    | 'en';

  useEffect(() => {
    let timer: any = undefined;
    if (duration) {
      timer = setInterval(() => {
        setDate(moment().local().format(format));
      }, duration);
    } else {
      setDate(moment().local().format(format));
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [duration]);

  useEffect(() => {
    moment.locale(lang);
    setDate(moment().local().format(format));
  }, [lang]);


  return (
    <Typography.Text ellipsis={{ tooltip: { title: data } }}>
      {data}
    </Typography.Text>
  );
};
