import React from 'react';
import PhoneInput from 'react-phone-input-2';
import inputStyle from './inputPhone.module.scss';
// текст телефона с выбранным флагом
export const PhoneText = ({ text }: { text: string }) => {
  return (
    <PhoneInput
      defaultMask={'00000000000000'}
      containerClass={inputStyle.container}
      buttonClass={inputStyle.button}
      inputClass={inputStyle.input}
      dropdownClass={inputStyle.dropdown}
      autoFormat={false}
      value={text}
      placeholder={''}
      disabled
    />
  );
};
