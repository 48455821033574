import { Button, Flex, Form, Input, notification, Typography } from 'antd';
import { createContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteNames } from '../../router/names';
import s from './ForgetLogin.module.scss';
import { EmailInputIcon } from '../../assets/image/svg';
import { Utils } from '../../utils';
import { Rest_User } from '../../services/rest_users';

const Context = createContext({ name: 'Default' });

const ForgetLogin = () => {
  const router = useHistory();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (data: any) => {
    setLoading(true);
    Rest_User.resetPasswordAxios({email: data.email}).then((res) => {
      const response = res?.data;
      if (response.success === 0) {
        Utils.ErrorOpenNotification(
          'topRight',
          null,
          response?.message,
          response?.description,
          api,
        );
        setLoading(false);
    } else {
        setLoading(false);
        if (response.message) {
            Utils.InfoOpenNotification(
              'topRight',
              response?.message,
              5,
              api,
            );
        }
      }
    }).catch((e) => {
      console.error(e);
    });
  };

  const contextValue = useMemo(() => ({ name: '' }), []);
  const clickLogin = () => {
    router.push(RouteNames.LOGIN);
  };
  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <Flex align={'center'} justify={'center'} vertical>
        <h1>Забыли пароль?</h1>
        <Form onFinish={onFinish} layout="vertical" style={{ width: '100%' }}>
          <Form.Item
            name="email"
            className={s.smallStep}
            rules={[
              {
                type: 'email',
                message: 'Введенный адрес электронной почты неверен!',
              },
              {
                required: true,
                message: 'Пожалуйста, введите свой адрес электронной почты!',
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="e-mail" prefix={<EmailInputIcon />} />
          </Form.Item>
          <Form.Item name="button" className={s.bigStep}>
            <Flex align={'center'} vertical gap={20}>
              <Button type="primary" block htmlType="submit" loading={loading}>
                Отправить ссылку
              </Button>
              <Typography.Link onClick={clickLogin}>
                или войдите
              </Typography.Link>
            </Flex>
          </Form.Item>
        </Form>
      </Flex>
    </Context.Provider>
  );
};

export default ForgetLogin;
