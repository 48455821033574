import {
  AutoComplete,
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FormDatePicker,
  FormSelectSearch,
} from '../../../../components/ui/Form';
import { disableDateBefore } from '../../../../helpers/date-helpers';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { delay } from 'lodash';
import style from '../../style.module.scss';
import { RootState, useStoreDispatch } from '../../../../store';
import { findCompany } from '../../../../store/contractors';
import { mapperCompany } from '../../../rent/deals/utils';
import {
  IResponsible,
  IResponsiblePersons,
  ITab,
} from '../../../../store/finance';
import { useSelector } from 'react-redux';
import UploaderDocs from '../../../../components/ui/UploaderDocs';
import { Link } from 'react-router-dom';
import { getSearchLink } from '../../../../helpers/link-helper';
import { AGREEMENT, TASK } from '../../../../constants';
import { findAgreementsCompany } from '../../../../store/sales';
import socket from '../../../../socket';
import { fileTypes } from '../../../../helpers/input-helpers';
import { findExecutor } from '../../../../store/contact';
import ResponsibleList from '../../../../components/ResponsibleList';
import { FormList } from '../../../../components/ui/Form/FormList/FormList';
import { defaultResponsiblePerson, INVOICE_APPROVAL } from '../constants';

const arrOpt = [
  {
    selectable: false,
    value: '1',
    title: 'Test',
    children: [{ value: '1.1', title: 'Test11' }],
  },
];

export const TabAccount: React.FC<ITab> = ({
  isDisabled,
  initialValue,
  isActive,
}) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  const dispatch = useStoreDispatch();
  const dataCurrency = useSelector(
    (state: RootState) => state.types.dataTypeCurrency,
  );
  const dataExpense = useSelector(
    (state: RootState) => state.types.dataTypeVidExpense,
  );
  const dataMyCompany = useSelector(
    (state: RootState) => state.companies.dataMyCompany,
  ) as { id: number; value: string }[];
  const [dataAgreements, setDataAgreements] = useState<any>([]);
  const [dataCompany, setDataCompany] = useState<
    { id: number; value: string }[]
  >([]);
  const dataDso = [
    { value: t('Да'), label: t('Да') },
    { value: t('Нет'), label: t('Нет') },
  ];

  const file_ids: number[] = Form.useWatch('file_ids', {
    form,
    preserve: true,
  });
  const paymentFileIds: number[] = Form.useWatch('paymentFileIds', {
    form,
    preserve: true,
  });
  const company_id: number = Form.useWatch('company_id', {
    form,
    preserve: true,
  });
  const my_company_id: number = Form.useWatch('my_company_id', {
    form,
    preserve: true,
  });
  const agreement_id: number = Form.useWatch('agreement_id', {
    form,
    preserve: true,
  });
  const agreement: string = Form.useWatch('agreement', {
    form,
    preserve: true,
  });
  const status: string = Form.useWatch('status', {
    form,
    preserve: true,
  });

  const observers: IResponsible[] = Form.useWatch('observers', {
    form,
    preserve: true,
  });
  const responsible_persons: IResponsiblePersons[] = Form.useWatch(
    'responsible_persons',
    {
      form,
      preserve: true,
    },
  );
  const approval_director: string = Form.useWatch('approval_director', {
    form,
    preserve: true,
  });
  const approval_fin: string = Form.useWatch('approval_fin', {
    form,
    preserve: true,
  });

  useEffect(() => {
    if (initialValue?.agreement_ids) {
      setDataAgreements(initialValue.agreement_ids);
    }
    if (initialValue?.companies) {
      setDataCompany(initialValue.companies);
    }
  }, []);

  const updateResponsible = (item: IResponsible) => {
    form.setFieldValue('observer', item.name);
    form.setFieldValue('observer_id', item.id);
  };
  const updateResponsibleList = (items: IResponsible[]) => {
    form.setFieldValue('observers', items);
  };

  const getAgreements = ({
    client,
    company,
  }: {
    client: number;
    company: number;
  }) => {
    if (client && company) {
      const payload = { executor_id: company, client_id: client };
      dispatch(findAgreementsCompany(payload))
        .then((res) => {
          setDataAgreements(res.payload);
        }).catch((e) => {
          console.error(e);
        })
        .finally(() => {
          socket.off('find-agreements-company');
        });
    } else {
      form.setFieldsValue({
        agreement: '',
        agreement_id: null,
      });
      setDataAgreements([]);
    }
  };

  const handleAddUser =
    ({ id, name }: { id: string; name: string }) =>
    (e: any, option: any) => {
      const resp = {
        avatar: option.avatar,
        name: option.fio,
        id: option.id,
      };
      form.setFieldValue(id, resp.id);
      form.setFieldValue(name, resp.name);
    };

  const handleSelectMyCompany = useCallback((id: string, record: any) => {
    form.setFieldValue('account_num', record?.score);
    form.setFieldValue('my_company', record?.value);
    form.setFieldValue('my_company_id', record?.id);
  }, []);

  const handleSearchBlurMyCompany = (
    evt: React.FocusEvent<HTMLInputElement>,
  ) => {
    const item = dataMyCompany.find((comp) => comp?.value === evt.target.value);
    if (item) {
      getAgreements({ client: company_id, company: item?.id });
      return;
    }
    if (!item) {
      form.setFieldValue('my_company', '');
      form.setFieldValue('my_company_id', null);
    }
  };

  const toggleApproval = ({
    key,
    prevValue,
    currentValue,
  }: {
    key: string;
    prevValue: string;
    currentValue: string;
  }) => {
    form.setFieldValue(key, prevValue === currentValue ? null : currentValue);
  };

  const handleSelectCompany = useCallback((id: string, record: any) => {
    const fields = form.getFieldsValue();

    form.setFieldsValue({
      ...fields,
      email: record?.email,
      phone: record?.phone,
      company: record?.value,
      company_id: record?.id,
      contact_id: record?.contact_id,
    });
  }, []);

  const handleSearchBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const client = dataCompany.find(
      (comp: any) => comp?.value === evt.target.value,
    );
    if (client) {
      getAgreements({ company: my_company_id, client: client?.id });
      return;
    }
    if (!client) {
      form.setFieldValue('company', '');
      form.setFieldValue('company_id', null);
    }
  };

  const handleSearchCompany = (text: string) => {
    if (text?.length >= 2) {
      delay(() => {
        dispatch(findCompany(text.toLocaleLowerCase()))
          .then((res) => {
            setDataCompany(mapperCompany(res.payload));
          })
          .catch(console.error);
      }, 1000);
    }
  };
  const handlerSaveFiles = (files: any[]) => {
    form.setFieldValue('saveFiles', files);
  };

  if (!isActive) return null;

  return (
    <Flex vertical gap={10} style={{ marginBottom: '24px' }}>
      <Row gutter={[12, 12]} justify="space-between" align="middle">
        <Col span={12}>
          <Typography.Text className={style['subtitle']}>
            {t('О счете')}
          </Typography.Text>
        </Col>
        <Col span={5}>
          <Form.Item name="date_request" label={t('Дата запроса')}>
            <FormDatePicker
              isString={false}
              disabledDate={disableDateBefore(null)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="space-between">
        <Col span={12}>
          <Form.Item name="name_payment" label={t('Название')}>
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('Клиент')}
            name={'company'}
            className={classnames(style.label, {
              [style.label__disabled]: isDisabled,
            })}
            rules={[{ required: true, message: t('Обязательное поле') }]}
          >
            <AutoComplete
              popupClassName="certain-category-search-dropdown"
              style={{ width: '100%' }}
              allowClear
              placeholder={t('Выберите клиента')}
              options={dataCompany}
              onSelect={handleSelectCompany}
              onSearch={handleSearchCompany}
              onBlur={handleSearchBlur}
              disabled={isDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="space-between">
        <Col span={12}>
          <Form.Item name="expense" label={t('Вид расхода')}>
            <Select
              options={dataExpense}
              onSelect={(_, opt) => {
                form.setFieldValue('expense_id', opt?.id);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('Счет пришел по ЭДО (воронка терминалка)')}
            name={'edo'}
            className={classnames(style.label, {
              [style.label__disabled]: isDisabled,
            })}
            rules={[
              {
                required: true,
                message: t('Обязательное поле'),
                whitespace: true,
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="space-between">
        <Col span={12}>
          <Form.Item
            name="dso"
            label={t('ДСО')}
            rules={[{ required: true, message: t('Обязательное поле') }]}
          >
            <Select options={dataDso} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('Сумма платежа')}
            name={'total_payment'}
            className={classnames(style.label, {
              [style.label__disabled]: isDisabled,
            })}
            rules={[
              {
                required: true,
                message: t('Обязательное поле'),
                whitespace: true,
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="space-between">
        <Col span={6}>
          <Form.Item label={t('Валюта')} name={'currency'}>
            <Select
              optionLabelProp="label"
              options={dataCurrency}
              onSelect={(_, opt) => form.setFieldValue('currency_id', opt?.id)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t('Курс валют')}
            name={'course'}
            className={classnames(style.label, {
              [style.label__disabled]: isDisabled,
            })}
            rules={[
              {
                required: true,
                message: t('Обязательное поле'),
                whitespace: true,
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t('ЮЛ')}
            name={'my_company'}
            className={classnames(style.label, {
              [style.label__disabled]: isDisabled,
            })}
            rules={[{ required: true, message: t('Обязательное поле') }]}
          >
            <AutoComplete
              popupClassName="certain-category-search-dropdown"
              style={{ width: '100%' }}
              allowClear
              placeholder={t('Выберите компанию')}
              options={dataMyCompany}
              onSelect={handleSelectMyCompany}
              onBlur={handleSearchBlurMyCompany}
              disabled={isDisabled}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t('Уникальный номер сделки')}
            name={'unique_id'}
            className={classnames(style.label, {
              [style.label__disabled]: isDisabled,
            })}
            rules={[
              {
                required: true,
                message: t('Обязательное поле'),
                whitespace: true,
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
        </Col>
      </Row>
      {/* возможно несколько номеров */}
      <Row gutter={[12, 12]} justify="space-between">
        <Col span={6}>
          <Form.Item label={t('№ Релиза')} name={'release_id'}>
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={t('Номер КТК/Принадлежность')}
            name={'num_container'}
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t('Цель закупа')}
            name={'purposeOfPurchase'}
            className={classnames(style.label, {
              [style.label__disabled]: isDisabled,
            })}
            rules={[
              {
                required: true,
                message: t('Обязательное поле'),
                whitespace: true,
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="space-between">
        <Col span={6}>
          <Flex vertical align="stretch" style={{ flex: 1 }}>
            <Form.Item name={'paymentFileIds'} noStyle />
            <Form.Item name={['paymentSaveFiles']} noStyle />
            <div className={style['list__label']}>
              <Typography.Text className={style['list__label-text']}>
                {t('Счет на оплату')}
              </Typography.Text>
            </div>
            <UploaderDocs
              className={classnames(style['list__upload'], {
                [style['list__upload_disabled']]: false,
              })}
              isOpen
              isText
              ids={paymentFileIds}
              limit={1}
              customAddComponent={
                <div className={style['list__upload-button']}>
                  {t('Загрузить файл')}
                </div>
              }
              handlerSaveFiles={(files) => {
                form.setFieldValue('paymentSaveFiles', files);
              }}
            />
          </Flex>
        </Col>
        <Col span={12}>
          <Form.Item
            name={'agreement'}
            label={
              agreement_id ? (
                <Typography.Paragraph
                  ellipsis={{
                    rows: 2,
                    tooltip: { placement: 'top', title: agreement },
                  }}
                  style={{ maxWidth: '250px', margin: 0, color: '#0148D7' }}
                >
                  <Link
                    style={{ color: 'currentcolor' }}
                    to={getSearchLink({
                      key: AGREEMENT,
                      id: agreement_id,
                      noUrl: true,
                    })}
                  >
                    {t('Ссылка на договор')}
                  </Link>
                </Typography.Paragraph>
              ) : (
                t('Ссылка на договор')
              )
            }
          >
            <Select
              showSearch
              options={dataAgreements}
              onSelect={(_, opt) => {
                form.setFieldValue('agreement_id', opt?.id);
              }}
              disabled={!dataAgreements?.length || isDisabled}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="space-between">
        <Col span={12}>
          <Flex vertical gap={20}>
            <Form.Item name="comment" label={t('Комментарий к счету')}>
              <Input.TextArea
                className={classnames('all-custom-v-scroll', style.textarea)}
                placeholder={t('Введите комментарий')}
                autoSize={{ minRows: 3, maxRows: 8 }}
              />
            </Form.Item>
            <Flex vertical gap={10}>
              <Typography.Text className={style['label-subtitle']}>
                {t('Согласование')}
              </Typography.Text>
              <Form.Item
                className={style.nowrap}
                label={t('Директор согласования')}
                name={'operating_director'}
              >
                <FormSelectSearch
                  allowClear
                  showSearch
                  suffixIcon={null}
                  filterOption={false}
                  style={{ width: '100%' }}
                  api={(text) => dispatch(findExecutor(text))}
                  mapResponse={(res) => res.payload}
                  onSelect={handleAddUser({
                    id: 'operating_director_id',
                    name: 'operating_director',
                  })}
                  placeholder={t('Поиск')}
                />
              </Form.Item>
              <Flex vertical>
                <Typography.Text className={style.label}>
                  {t('Согласование управляющего директора')}
                </Typography.Text>
                <Flex gap={20} align="center">
                  <Button
                    type="default"
                    className={classnames(style['button'], {
                      [style['button_success']]:
                        approval_director === 'success',
                    })}
                    onClick={() =>
                      toggleApproval({
                        key: 'approval_director',
                        currentValue: 'success',
                        prevValue: approval_director,
                      })
                    }
                  >
                    {t('Согласовано')}
                  </Button>
                  <Button
                    type="default"
                    className={classnames(style['button'], {
                      [style['button_error']]: approval_director === 'error',
                    })}
                    onClick={() =>
                      toggleApproval({
                        key: 'approval_director',
                        currentValue: 'error',
                        prevValue: approval_director,
                      })
                    }
                  >
                    {t('Не согласовано')}
                  </Button>
                </Flex>
              </Flex>
              <Flex vertical>
                <Typography.Text className={style.label}>
                  {t('Согласование финансового отдела')}
                </Typography.Text>
                <Flex gap={20} align="center">
                  <Button
                    type="default"
                    className={classnames(style['button'], {
                      [style['button_success']]: approval_fin === 'success',
                    })}
                    onClick={() =>
                      toggleApproval({
                        key: 'approval_fin',
                        currentValue: 'success',
                        prevValue: approval_fin,
                      })
                    }
                  >
                    {t('Согласовано')}
                  </Button>
                  <Button
                    type="default"
                    className={classnames(style['button'], {
                      [style['button_error']]: approval_fin === 'error',
                    })}
                    onClick={() =>
                      toggleApproval({
                        key: 'approval_fin',
                        currentValue: 'error',
                        prevValue: approval_fin,
                      })
                    }
                  >
                    {t('Не согласовано')}
                  </Button>
                </Flex>
              </Flex>
              <Form.Item
                className={style.nowrap}
                label={t('Автор заявки')}
                name={'author'}
              >
                <FormSelectSearch
                  allowClear
                  showSearch
                  suffixIcon={null}
                  filterOption={false}
                  style={{ width: '100%' }}
                  api={(text) => dispatch(findExecutor(text))}
                  mapResponse={(res) => res.payload}
                  onSelect={handleAddUser({
                    id: 'author_id',
                    name: 'author',
                  })}
                  placeholder={t('Поиск')}
                />
              </Form.Item>
              <FormList
                nameList="responsible_persons"
                title="Ответственный"
                titleName="responsible_id"
                type="search"
                defaultItem={defaultResponsiblePerson}
                disabled={isDisabled}
                filterSelect={(id: number, index: any) => {
                  if (responsible_persons?.[index]?.responsible_id === id) {
                    return true;
                  }
                  return !responsible_persons
                    ?.map((item) => item?.responsible_id)
                    .includes(id);
                }}
                typeOption={{
                  style: { maxWidth: '100%' },
                  allowClear: false,
                  placeholder: 'Поиск',
                  suffixIcon: null,
                  notFoundContent: null,
                  api: (text: string) => dispatch(findExecutor(text)),
                  mapResponse: (res: any) => res.payload,
                }}
              />
            </Flex>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex vertical gap={20}>
            <Form.Item name={'task'} label={t('Ссылка на задачу')}>
              <Input allowClear />
            </Form.Item>
            <Button
              type="default"
              className={style['button-link']}
              onClick={() => form.setFieldValue('status', INVOICE_APPROVAL)}
              disabled={status === INVOICE_APPROVAL}
            >
              {t('Отправить на согласование')}
            </Button>
            <Flex vertical>
              <Typography.Text className={style['label-subtitle']}>
                {t('Отчетная документация')}
              </Typography.Text>
              <div
                className={classnames('all-custom-v-scroll', style['file-box'])}
              >
                <UploaderDocs
                  multiple
                  ids={file_ids}
                  buttonDescription={t('Загрузить файл')}
                  accept={[
                    fileTypes.doc,
                    fileTypes.xls,
                    fileTypes.pdf,
                    fileTypes.jpeg,
                  ].join(',')}
                  setDataIds={(files) => {
                    form.setFieldValue('file_ids', files);
                  }}
                  handlerSaveFiles={handlerSaveFiles}
                />
              </div>
            </Flex>

            <ResponsibleList
              responsible={observers}
              descriptionItem="роль"
              handlerSubmit={updateResponsible}
              handlerSubmitList={updateResponsibleList}
              textButtonAdd="Добавить наблюдателя"
              textButtonEdit="Сменить наблюдателя"
              descriptionItemModal="наблюдателя"
            />
          </Flex>
        </Col>
      </Row>
    </Flex>
  );
};
