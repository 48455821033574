import React, { useState } from 'react';
import { useEffect } from 'react';

import { Space } from 'antd';

import { columns } from './columns';
import style from '../style.module.scss';
import { GUIDE } from '../../../../../constants';
import { Table } from '../../../../../components/ui/Table/Table';
import { RootState, useStoreDispatch } from '../../../../../store';
import {
  CREATE,
  EDIT,
  VIEW,
  DELETE,
} from '../../../../../helpers/string-helpers';
import CustomDrawer from '../../../../../components/CustomDrawer/CustomDrawer';
import { useParamsHistory } from '../../../../../hooks';
import {
  deleteIds,
} from '../../../../../store/conditions';
import { useGettingDataForTables } from '../../../../../hooks/useGettingDataForTables/useGettingDataForTables';
import { AddGuide } from './AddGuide';
import { DeleteGuide } from './DeleteGuide';
import ViewCompany from './ViewCompany';
import { defaultValueCompany } from '../../constants';
import { IContractors } from '../../../../../types/contractors';
import { Rest_conditions } from '../../../../../services/rest_conditions';

interface ITableGuide {
  paths: { parent: string };
  activeKey: string;
  typeForm: string;
  isOpenDrawer: boolean;
  toggleDrawer: (val: boolean) => void;
  editTypeForm: (val: string) => void;
}

const addTitle = 'Выберите компании, которые необходимо добавить в справочник';
const deleteTitle = 'Выберите компании, которые необходимо удалить из справочника';

export const TableGuide: React.FC<ITableGuide> = ({ paths, activeKey, typeForm, isOpenDrawer, toggleDrawer, editTypeForm }) => {
  const { history, location } = useParamsHistory();
  const { pathname } = location;
  const dispatch = useStoreDispatch();

  const [initialValue, setInitialValue] =
    useState<IContractors>(defaultValueCompany);


  const { dataTable, optionsTable, updateEffectDate, setLoadingSubmit } =
    useGettingDataForTables({
      titlePage: 'справочник',
      keyItemName: 'company_id',
      subscribeStringCreateForm: 'return-set-guide',
      subscribeStringDelete: 'return-delete-guide',
      subscribeError: 'error',
      table: GUIDE,
      location,
      columns,
      fetchTables: Rest_conditions.getGuide,
      fetchDelete: (arg: any) => dispatch(deleteIds(arg)),
    });

  useEffect(() => {
    if (activeKey !== GUIDE) return;

    if (pathname === paths.parent) {
      // dispatch(resetStoreAgreement());
      updateEffectDate();
      const row = location?.state?.[GUIDE];
      if (row) {
        window.history.replaceState({ state: null }, document.title);
        if (row?.edit) {
          // dispatch(setOneAgreement(row.edit));
          editTypeForm(EDIT);
          toggleDrawer(true);
          return;
        }
        editTypeForm(CREATE);
        // dispatch(setOneAgreement(row));

        toggleDrawer(true);
        return;
      }
      return;
    }

    return history.replace(paths.parent);
  }, [activeKey, pathname]);

  const onRow = (row: any) => {
    editTypeForm(VIEW);
    setInitialValue(defaultValueCompany);
    toggleDrawer(true);
  };

  const handleShowEditForm = (row: any) => {
    editTypeForm(EDIT);
    // dispatch(setOneAgreement(row));
    if (row?.delete) {
      editTypeForm(VIEW);
    }
    toggleDrawer(true);
  };

  const onCloseDrawer = (res?: boolean) => {
    toggleDrawer(false);
    // dispatch(setOneAgreement({ ...defaultAgreement }));
    setLoadingSubmit(!!res);
  };

  const onClose = () => {
    onCloseDrawer(false);
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Table
        {...optionsTable}
        className={style.container__table}
        onClickLink={onRow}
        editRow={handleShowEditForm}
        onRow={onRow}
        dataSource={dataTable}
        style={{ width: '100%' }}
        height={'calc(100vh - 350px)'}
      />

      <CustomDrawer
        isHeight
        minWidth={700}
        open={isOpenDrawer}
        onClose={onClose}
      >
        {typeForm === CREATE ? (
          <AddGuide onClose={onClose} onSubmit={console.log} title={addTitle} />
        ) : typeForm === DELETE ? (
          <DeleteGuide onClose={onClose} onSubmit={console.log} title={deleteTitle} />
        ) : typeForm === VIEW ? (
          <ViewCompany initialValue={initialValue} onClose={onClose} />
        ) : null}
      </CustomDrawer>
    </Space>
  );
};
