import { UserOutlined } from "@ant-design/icons";
import { Avatar, Flex, Typography } from "antd";
import style from '../style.module.scss';

const Responsible: React.FC<any> = ({ avatar, title, description }) => {
  const result = typeof title === 'string' ? title.split(' ') : [];
  const alt = (result?.length > 1 ? (result[0]?.[0] ?? '') + (result.at(-1)?.[0] ?? ''): (result?.[0]?.[0] ?? '')).toUpperCase();
  return (
    <div className={style["responsible"]}>
        <Avatar className={style["responsible__avatar"]} src={avatar} gap={5} icon={alt ? null : <UserOutlined />} alt={alt || 'icon'}>
          {alt}
        </Avatar>
        <Flex vertical gap={2} style={{alignSelf: 'baseline'}}>
          <Typography.Paragraph className={style["responsible__title"]} ellipsis={{ rows: 1, tooltip: { placement: 'topLeft', title: title } }}>{title}</Typography.Paragraph>
          <Typography.Paragraph className={style["responsible__description"]} ellipsis={{ rows: 1, tooltip: { placement: 'topLeft', title: description } }}>{description}</Typography.Paragraph>
        </Flex>
    </div>
  );
};

export default Responsible