import { Key, useEffect, useState, CSSProperties, useMemo } from "react";
import classnames from "classnames";
import { Flex, MenuProps, App, Pagination, Spin } from "antd";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";
import { ColPage, ColPageMenu, Footer, TableWrapper } from "./styled";
import ModalConfirm from "../ModalConfirm/ModalConfirm";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../Checkbox/Checkbox";
import styleTable from "./style.module.scss";

export interface IColumnType<T> {
  key: Key;
  title?: string;
  dataIndex: string;
  filterIndex: string;
  subTitle?: string;
  date?: boolean;
  avatar?: string;
  checkbox?: boolean;
  link?: boolean;
  menu?: boolean;
  type?: string | undefined;
  width?: number | string;
  widthHeader?: number | string;
  repair?: boolean;
  render?: (
    column: IColumnType<T>,
    item: T,
    index: number
  ) => JSX.Element | null;
  isHidden?: boolean;
  [key: number]: string;
}
export interface IPagination {
  total?: number;
  current: number;
  pageSize: number;
  showSizeChanger?: boolean;
}

interface Props<T> {
  className?: string;
  dataSource: T[];
  columns: IColumnType<T>[];
  setColumns: (column: IColumnType<T>) => void;
  style?: CSSProperties;
  pagination: IPagination;
  onChangePagination?: (pagination: IPagination) => void;
  onRow?: (record: T, rowIndex: number) => void;
  editRow?: (item: T) => void;
  handlerDeleteRow?: (row: T) => void;
  onClickLink?: (record: T, rowIndex: number) => void;
  height?: string;
  loading?: boolean;
  handlerOrder?: (sort: string) => void;
  filtersFetch: (text: string, column: string) => void;
  filtersColumn?: ({
    data,
    current,
    pageSize,
    order,
    options,
  }: {
    data: any;
    current: number;
    pageSize: number;
    order?: string;
    options?: { [key: string]: any };
  }) => void;
  handlerCopyAll?: (selectedRows: any) => void;
  handlerDeleteCopyAll?: (selectedRows: any) => void;
  subMenu?: {
    label: string;
    getSubMenu: ({
      item,
      rows,
      data,
      onClose,
    }: {
      item: any;
      rows: Set<any>;
      data: T[];
      onClose: () => void;
    }) => MenuProps["items"];
  };
  canView?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  setColumnFilters?: (value: ((prevState: {}) => {}) | {}) => void;
  columnFilters?: {};
  allSelected?: boolean;
  setAllSelected?: (bool: boolean) => void;
  setSelectedRows: (value: any, bool: boolean) => void;
  selectedRows: Set<number>;
  onUploadFile?: (selectedRows: any) => void;
  isUpload?: boolean;
  handlerAllUpdate?: () => void;
  dataFilters: any;
  clearFiltersFetch: () => void;
}

export function Table<T>({
  style,
  height,
  columns,
  subMenu,
  loading,
  isUpload,
  pagination,
  dataSource,
  selectedRows,
  columnFilters,
  dataFilters,
  canView,
  canEdit,
  canDelete,
  onRow,
  editRow,
  setColumns,
  onClickLink,
  handlerOrder,
  filtersFetch,
  onUploadFile,
  filtersColumn,
  handlerCopyAll,
  setSelectedRows,
  handlerAllUpdate,
  setColumnFilters,
  handlerDeleteRow,
  clearFiltersFetch,
  onChangePagination,
  handlerDeleteCopyAll,
  className = "",
}: Props<T>): JSX.Element {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [sortedData, setSortedData] = useState<T[]>(dataSource);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const isAllChecked = useMemo(() => {
    if (!dataSource?.length) return false;
    return !!dataSource?.every((el: any) => selectedRows.has(el?.id));
  }, [JSON.stringify(dataSource), selectedRows?.size]);

  const content = (
    <div>
      {columns.map(
        (column, index) =>
          column.dataIndex !== "hidden" &&
          !!index && (
            <ColPageMenu key={index}>
              <Checkbox
                stopChange
                isChoice={!!column?.isHidden}
                onChange={(bol: boolean) =>
                  setColumns({ ...column, isHidden: bol })
                }
              />{" "}
              {t(column.title ?? "")}
            </ColPageMenu>
          )
      )}
    </div>
  );

  const handlePageChange = (current: number, pageSize: number) => {
    if (onChangePagination) {
      onChangePagination({ ...pagination, current, pageSize });
    }
  };

  useEffect(() => {
    setSortedData(dataSource);
  }, [dataSource]);

  const handleSelectAll = (checked: boolean) => {
    const ids = new Set(dataSource.map((item: any) => item.id));
    if (checked) {
      if (setSelectedRows) {
        setSelectedRows(ids, checked);
      }
    } else {
      if (setSelectedRows) {
        setSelectedRows(ids, checked);
      }
    }
  };

  const handleRowSelect = (id: number, checked: boolean) => {
    const newSelectedRows = new Set(selectedRows);
    if (checked) {
      newSelectedRows.add(id);
    } else {
      newSelectedRows.delete(id);
    }
    if (setSelectedRows) {
      setSelectedRows([id], checked);
    }
  };

  const onCloseModalConfirm = () => {
    setConfirmation(false);
    if (setSelectedRows) {
      setSelectedRows([...selectedRows], false);
    }
  };

  const onOkModalConfirm = () => {
    if (handlerDeleteCopyAll) {
      handlerDeleteCopyAll(selectedRows);
    }
    setConfirmation(false);
  };

  const handleDeleteAll = (selectedRows: any) => {
    if (selectedRows.size > 0) {
      setConfirmation(true);
    } else {
      message.warning(t("Нет выделенных ячеек"), 3);
    }
  };

  const onCopyAll = (row?: any) => {
    if (!selectedRows.size && !row) {
      message.warning(t("Нет выделенных ячеек"), 3);
      return;
    }
    if (handlerCopyAll) {
      handlerCopyAll(row ? row : selectedRows);
      message.success(t("Данные скопированы"), 3);
    }
  };
  const onUploadAll = () => {
    if (!selectedRows.size) {
      message.warning(t("Нет выделенных ячеек"), 3);
      return;
    }
    if (onUploadFile) {
      onUploadFile(selectedRows);
      message.success(t("Начинаем выгрузку в файл..."), 3);
    }
  };

  const onUpdateAll = () => {
    if (!selectedRows.size) {
      message.warning(t("Нет выделенных ячеек"), 3);
      return;
    }
    if (handlerAllUpdate) {
      handlerAllUpdate();
    }
  };

  return (
    <div style={style}>
      <div
        className={classnames(styleTable.container__over, {
          [className]: className,
        })}
        style={{
          height,
        }}
      >
        <Spin tip={`${t("Загрузка")}...`} spinning={loading}>
          <TableWrapper>
            <thead>
              <TableHeader
                dataFilters={dataFilters}
                clearFiltersFetch={clearFiltersFetch}
                loading={loading}
                setColumnFilters={setColumnFilters}
                columnFilters={columnFilters}
                columns={columns}
                content={content}
                filtersFetch={filtersFetch}
                filtersColumn={filtersColumn}
                order={handlerOrder}
                onSelectAll={handleSelectAll}
                allSelected={isAllChecked}
                selectedRows={selectedRows}
                pagination={pagination}
              />
            </thead>
            <tbody>
              <TableRow
                deleteRow={handlerDeleteRow}
                editRow={editRow}
                data={sortedData}
                columns={columns}
                onRow={onRow}
                onClickLink={onClickLink}
                selectedRows={selectedRows}
                handleCopyAll={onCopyAll}
                handleDeleteCopyAll={() => handleDeleteAll(selectedRows)}
                handleRowSelect={handleRowSelect}
                handlerAllUpdate={handlerAllUpdate ? onUpdateAll : undefined}
                t={t}
                subMenu={subMenu}
                onUploadFileAll={isUpload ? onUploadAll : undefined}
                canView={canView}
                canEdit={canEdit}
                canDelete={canDelete}
              />
            </tbody>
          </TableWrapper>
        </Spin>
      </div>
      <Footer>
        <Flex
          justify={"space-between"}
          align={"flex-start"}
          style={{ width: "100%" }}
        >
          <ColPage>
            {pagination.current} {t("страница из")}{" "}
            {Math.ceil(Number(pagination.total || 0) / pagination.pageSize)}
          </ColPage>
          <div>
            <Pagination
              {...pagination}
              onChange={handlePageChange}
              onShowSizeChange={handlePageChange}
              defaultPageSize={pagination.pageSize}
            />
          </div>
        </Flex>
      </Footer>
      <ModalConfirm
        isOpen={confirmation}
        closeModal={onCloseModalConfirm}
        actionAfterConsent={onOkModalConfirm}
      />
    </div>
  );
}
