import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { Button, Divider, Flex, Form, Typography } from "antd";
import { Utils } from "../../../utils";
import style from "../style.module.scss";
import { FINANCE_PAYMENT, messagesModal } from "../../../constants";
import { RootState, useStoreDispatch } from "../../../store";
import ModalConfirm from "../../../components/ui/ModalConfirm/ModalConfirm";
import TooltipButton from "../../../components/ui/TooltipButton/TooltipButton";
import { useLink } from "../../../hooks";

import { CREATE, EDIT } from "../../../helpers/string-helpers";
import { Rest_files } from "../../../services/rest_files";
import {
  defaultValuesPayment,
  IBilling,
  IFormDeal,
  IPayment,
} from "../../../store/finance";
import {
  noStyleField,
  PAYMENT,
  PRIMARY_INFORMATION,
  COMPLETED,
  INCONSISTENT_ACCOUNTS,
  INVOICE_APPROVAL,
  CHECKING_ECONOMICS_DEPARTMENT,
  titles,
} from "./constants";
import { ITab, ITabs } from "../../../components/ui/Tabs/Tabs";
import { TabAccount } from "./tabs/TabAccount";
import Chat from "../../../components/Chat";

export const FormPayment: React.FC<IFormDeal<IPayment>> = ({
  typeForm,
  isCopy,
  onClose,
  onEdit,
  onView,
  isOpen,
  initialValue,
  title = "Заявка",
  canEdit,
}) => {
  const dispatch = useStoreDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const link = useLink({
    key: FINANCE_PAYMENT,
    id: initialValue?.id as number,
  });
  const [activeKey, setActiveKey] = useState<string>(CREATE);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const status: string = Form.useWatch("status", {
    form,
    preserve: true,
  });

  const isDisabled = !!initialValue?.id;

  useEffect(() => {
    if (status) {
      setActiveKey(status);
    }
  }, [status]);

  useEffect(() => {
    if (!isOpen) return;

    form.resetFields();
    const {
      email,
      avatar,
      id: responsible_id,
      fio: responsible,
      phone,
    } = Utils.getUserInfo();
    const responsible_persons = [
      {
        id: responsible_id,
        avatar,
        name: responsible,
        fio: responsible,
        isResponsible: true,
      },
    ];
    form.setFieldsValue({
      ...defaultValuesPayment,
      ...initialValue,
      responsible_id,
      responsible,
      responsible_persons,
      user: { email, fio: responsible, id: responsible_id, avatar, phone },
    });
  }, [isDisabled, isOpen, typeForm]);

  const tabs: ITab[] = useMemo(
    () => [
      {
        key: CREATE,
        label: titles[CREATE],
        content: null,
      },
      {
        key: INVOICE_APPROVAL,
        label: titles[INVOICE_APPROVAL],
        content: null,
      },
      {
        key: INCONSISTENT_ACCOUNTS,
        label: titles[INCONSISTENT_ACCOUNTS],
        content: null,
      },
      {
        key: CHECKING_ECONOMICS_DEPARTMENT,
        label: titles[CHECKING_ECONOMICS_DEPARTMENT],
        content: null,
      },
      {
        key: PAYMENT,
        label: titles[PAYMENT],
        content: null,
      },
      {
        key: PRIMARY_INFORMATION,
        label: titles[PRIMARY_INFORMATION],
        content: null,
      },
      {
        key: COMPLETED,
        label: titles[COMPLETED],
        content: null,
      },
    ],
    [activeKey]
  );

  const onSubmit = async (values: IBilling) => {
    try {
      if (values?.saveFiles?.length) {
        const res = await Rest_files.submitFiles(values.saveFiles);
        if (res?.data?.length) {
          values.file_ids.push(...res.data);
        }
      }

      const submit = {
        ...initialValue,
        ...values,
      };
      console.log("submit", submit);

      setIsSave(true);
      // onClose(true);
      setIsSave(false);
    } catch (err) {
      console.error("submit", err);
    }
  };
  const onUpdate = (changedValues: IBilling, allValues: any) => {
    console.log(changedValues);

    return;
  };

  const onFinishError = async ({ values, errorFields, outOfDate }: any) => {
    console.log("errorFields", values, errorFields, outOfDate);
    if (errorFields) {
      for (const errors of errorFields) {
        const title = errors?.name?.[0];
      }
    }
    return;
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  //==========================================================
  // если нет права на редактирование не отображаем форму
  // вместо нее станим заглушку
  //==========================================================
  if (!canEdit) {
    return (
      <div style={{ padding: 20, fontSize: 16, textAlign: "center" }}>
        {t("У вас нет прав на редактирование данной страницы")}
      </div>
    );
  }

  return (
    <Form
      className={style["form"]}
      noValidate
      autoComplete="off"
      layout="vertical"
      form={form}
      initialValues={initialValue}
      scrollToFirstError
      onFinish={onSubmit}
      onFinishFailed={onFinishError}
      onValuesChange={onUpdate}
    >
      <Flex vertical gap={10} style={{ marginBottom: "20px" }}>
        {noStyleField.map((name) => (
          <Form.Item key={name} name={name} noStyle />
        ))}
        <Flex justify="space-between" align="center" gap={10}>
          <Typography.Text className={style["title"]}>
            {t(title)} № {initialValue?.payment_id ?? ""}
          </Typography.Text>
        </Flex>
        <Divider style={{ margin: 0 }} />
        <ITabs
          className={style["form__tab"]}
          activeKey={activeKey}
          tabs={tabs}
        />

        <TabAccount
          isActive
          initialValue={initialValue}
          isDisabled={isDisabled}
          form={form}
        />

        <Chat
          row_id={initialValue?.id}
          user={form.getFieldValue("toUser")}
          table={FINANCE_PAYMENT}
        />
      </Flex>

      <Form.Item>
        <Flex
          gap={8}
          justify={"space-between"}
          style={{
            maxWidth: "550px",
            width: "100%",
            margin: "auto auto 10px auto",
          }}
        >
          <TooltipButton
            propsTooltip={{
              title: isDisabled ? t("Сохранить изменения") : t("Сохранить"),
            }}
            propsButton={{
              type: "primary",
              htmlType: "submit",
              style: { maxWidth: "290px", minWidth: "290px" },
              disabled: isSave,
            }}
          >
            {isDisabled ? t("Сохранить изменения") : t("Сохранить")}
          </TooltipButton>
          <Button
            onClick={() => setConfirmation(true)}
            type={"text"}
            style={{ maxWidth: "290px", width: "100%", color: "#E14453" }}
            disabled={isSave}
          >
            {t("Отменить")}
          </Button>
        </Flex>
      </Form.Item>

      <ModalConfirm
        title={messagesModal.warning.title}
        subtitle={messagesModal.warning.subtitle}
        isOpen={confirmation}
        closeModal={() => setConfirmation(false)}
        actionAfterConsent={handleClose}
      />
    </Form>
  );
};
