import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Rest_conditions } from '../services/rest_conditions';

const initialState = {

};


export const deleteIds = createAsyncThunk(
  'deleteIds',
  async (payload: any) => {
    return await Rest_conditions.deleteIds(payload);
  },
);

const conditionsSlice = createSlice({
  name: 'conditions',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {

  },
});
export default conditionsSlice.reducer;
